import {
  GET_FAQ_CATEGORIES,
  GET_FAQ_CATEGORIES_SUCCESS,
  GET_FAQ_CATEGORIES_FAILURE,
  ADD_FAQ_CATEGORY,
  ADD_FAQ_CATEGORY_SUCCESS,
  ADD_FAQ_CATEGORY_FAILURE,
  GET_SINGLE_FAQ_CATEGORY,
  GET_SINGLE_FAQ_CATEGORY_SUCCESS,
  GET_SINGLE_FAQ_CATEGORY_FAILURE,
  EDIT_FAQ_CATEGORY,
  EDIT_FAQ_CATEGORY_SUCCESS,
  EDIT_FAQ_CATEGORY_FAILURE,
  DELETE_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY_SUCCESS,
  DELETE_FAQ_CATEGORY_FAILURE,
} from "./actionTypes";

export const getFaqCategories = () => {
  return {
    type: GET_FAQ_CATEGORIES,
  };
};

export const getFaqCategoriesSuccess = (blogCategories) => {
  return {
    type: GET_FAQ_CATEGORIES_SUCCESS,
    payload: blogCategories,
  };
};

export const getFaqCategoriesFailure = (error) => {
  return {
    type: GET_FAQ_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const getSingleFaqCategory = (id) => {
  return {
    type: GET_SINGLE_FAQ_CATEGORY,
    payload: id,
  };
};

export const getSingleFaqCategorySuccess = (blogCategory) => {
  return {
    type: GET_SINGLE_FAQ_CATEGORY_SUCCESS,
    payload: blogCategory,
  };
};

export const getSingleFaqCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_FAQ_CATEGORY_FAILURE,
    payload: error,
  };
};

export const addFaqCategory = (payload) => {
  return {
    type: ADD_FAQ_CATEGORY,
    payload,
  };
};

export const addFaqCategorySuccess = (payload) => {
  return {
    type: ADD_FAQ_CATEGORY_SUCCESS,
    payload,
  };
};

export const addFaqCategoryFailure = (error) => {
  return {
    type: ADD_FAQ_CATEGORY_FAILURE,
    payload: error,
  };
};

export const editFaqCategory = (payload) => {
  return {
    type: EDIT_FAQ_CATEGORY,
    payload,
  };
};

export const editFaqCategorySuccess = (payload) => {
  return {
    type: EDIT_FAQ_CATEGORY_SUCCESS,
    payload,
  };
};

export const editFaqCategoryFailure = (error) => {
  return {
    type: EDIT_FAQ_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deleteFaqCategory = (id) => {
  return {
    type: DELETE_FAQ_CATEGORY,
    payload: id,
  };
};

export const deleteFaqCategorySuccess = (id) => {
  return {
    type: DELETE_FAQ_CATEGORY_SUCCESS,
    payload: id,
  };
};

export const deleteFaqCategoryFailure = (error) => {
  return {
    type: DELETE_FAQ_CATEGORY_FAILURE,
    payload: error,
  };
};
