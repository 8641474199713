/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { addGroup, editGroup, getSingleGroup } from "store/actions";
import StickyLoader from "components/shared/StickyLoader";
import { getId } from "helpers/functions";
import { useNavigate, useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Col, Dropdown, Row } from "react-bootstrap";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { ReactComponent as DeleteIcon } from "assets/svgs/trash.svg";
import ImageUpload from "components/shared/ImageUpload";

const GroupModal = ({ params }) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rulesTypes = ["lessThan", "greaterThan", "equal"].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));

  const {
    loading,
    singleGroup: sngle,
    groupRules,
  } = useSelector((state) => state.groups);

  const [singleGroup, setSingleGroup] = useState({});
  const [selectedImage, setSelectedImage] = useState({});

  const id = params.get("group");

  useEffect(() => {
    if (id === "new") return;

    dispatch(getSingleGroup(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (id !== "new") setSingleGroup(sngle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sngle]);

  const handleClose = () => {
    params.delete("group");
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`/customers${paramsStr}`);
  };

  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { rules: [{}] } });

  useEffect(() => {
    setSelectedImage({ path: singleGroup?.icon });
  }, [singleGroup]);

  useEffect(() => {
    setValue("icon", selectedImage?.path);
  }, [selectedImage, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rules",
  });

  // useEffect(() => {
  //   if (!images.find((img) => img?.isMain) && images?.length > 0) {
  //     let imagesCopy = images;
  //     imagesCopy[0].isMain = true;
  //     setImages(imagesCopy);
  //   }
  //   setValue("images", images);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [images]);

  useEffect(() => {
    if (getId(singleGroup) !== id) return;

    reset({
      ...singleGroup,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, singleGroup]);

  const onSubmit = (data) => {
    // if (!images?.length > 0) {
    //   setError("images", { type: "requried" });
    //   return toast.error("صور المنتج الاساسية مفقودة");
    // }

    // data.images =
    //   data?.images?.map((img) => ({
    //     ...img,
    //     image: "uploads/" + img.image.split("/").pop(),
    //   })) || [];

    if (id !== "new")
      dispatch(
        editGroup({
          data,
          id: getId(singleGroup),
          // callBack: handleClose,
          navigate,
        })
      );
    else
      dispatch(
        addGroup({
          data,
          // callBack: handleClose,
          navigate,
        })
      );
  };

  const getOptionsByName = (arr) =>
    //add default option
    [
      {
        label: "اختر",
        value: "",
      },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale],
        value: getId(item),
      }))
    );

  return (
    // <div className="productModal">
    <Modal
      dialogClassName="productDataModal"
      show={true}
      onHide={handleClose}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {id === "new" ? "اضافة مجموعة جديدة" : "تعديل بيانات المجموعة"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ImageUpload
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
          />
          <Form.Group className="mb-3">
            <Form.Label>
              <FormattedMessage id="groupName" />
            </Form.Label>
            <MultiLangWrapper
              errors={errors?.name}
              customClass="form-group selectHolder col-12"
            >
              {({ lang, classes, key }) => (
                <Form.Control
                  key={key}
                  className={`${classes}`}
                  type="text"
                  placeholder={formatMessage({ id: "groupName" })}
                  {...register(`name.${lang}`, { required: true })}
                />
              )}
              <span className="error-hint" key="1">
                {errors?.name && <FormattedMessage id="nameErr" />}
              </span>
            </MultiLangWrapper>
          </Form.Group>
          <hr />
          {/* <Form.Label>
            <FormattedMessage id="conditionToAddCustomersToGroup" />
          </Form.Label> */}
          {(fields || [])?.map((_, index) => (
            <Row>
              <Form.Group className="mb-3 col-12 col-md-4">
                <Form.Label>
                  <FormattedMessage id="conditionToAddCustomersToGroup" />
                </Form.Label>
                <Controller
                  control={control}
                  name={`rules.${index}.groupRule`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, selected } }) => (
                    <Select
                      isRtl={true}
                      isSearchable={false}
                      className="basic-single"
                      classNamePrefix="select"
                      options={getOptionsByName(groupRules)}
                      placeholder={formatMessage({ id: "conditionRule" })}
                      isMulti={false}
                      value={getOptionsByName(groupRules)?.find(
                        (o) => o?.value === value
                      )}
                      onChange={({ value }) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-12 col-md-4">
                <Form.Label>
                  <FormattedMessage id="conditionType" />
                </Form.Label>
                <Controller
                  control={control}
                  name={`rules.${index}.type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, selected } }) => (
                    <Select
                      isRtl={true}
                      isSearchable={false}
                      className="basic-single"
                      classNamePrefix="select"
                      options={rulesTypes}
                      placeholder={formatMessage({ id: "conditionType" })}
                      isMulti={false}
                      value={rulesTypes?.find((o) => o?.value === value)}
                      onChange={({ value }) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group
                className={`mb-3 col-12 col-md-${fields?.length === 1 ? 4 : 3}`}
              >
                <Form.Label>
                  <FormattedMessage id="conditionValue" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formatMessage({ id: "conditionValue" })}
                  {...register(`rules.${index}.value`, { required: true })}
                />
              </Form.Group>
              {fields?.length !== 1 && (
                <Form.Group className="col-1">
                  <Form.Label className="opacity-0">delete</Form.Label>
                  <button
                    type="button"
                    className="col-1 form-control btn btn-danger align-self-center"
                    onClick={() => remove(index)}
                    aria-label="delete"
                    title="delete"
                  >
                    <DeleteIcon width={20} height="auto" color="#fff" />
                  </button>
                </Form.Group>
              )}
            </Row>
          ))}
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => append({})}
          >
            <FormattedMessage id="add" />
          </button>
          <hr />
          <button className="btn btn-blue">
            <FormattedMessage id={id === "new" ? "addGroup" : "save"} />
          </button>

          {loading && <StickyLoader fill="#FC6B14" />}
        </Form>
      </Modal.Body>
    </Modal>
    // </div>
  );
};

export default GroupModal;
