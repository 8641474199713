/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";

import { ReactComponent as SeoIcon } from "assets/svgs/product/eye.svg";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export default function SeoTabBody({ register }) {
  const { locale } = useIntl();
  const { singleProduct: product } = useSelector((state) => state.products);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  return (
    <div className="form-group">
      <div className="form-head">
        <i>
          <SeoIcon fill="#FC6B14" />
        </i>
        <div>
          <h5>تحسينات SEO </h5>
          <span>تحسين الظهور على محركات البحث </span>
        </div>
      </div>
      <div className="form-body">
        <Row>
          <Col xs={12}>
            <label htmlFor="">العنوان </label>
            <div>
              <input
                type="text"
                id=""
                className="form-control"
                placeholder="عنوان المتجر"
                {...register("seo.pageTitle", {
                  onChange: ({ target: { value } }) => setTitle(value),
                })}
              />
              <StockIcon fill="#E4E4E4" />
            </div>
          </Col>
          <Col xs={12}>
            <label htmlFor="">رابط صفحة المنتج </label>
            <div>
              <input
                type="text"
                id=""
                className="form-control"
                placeholder="عنوان المتجر"
                {...register("seo.pageUrl", {
                  onChange: ({ target: { value } }) => setUrl(value),
                })}
              />
              <StockIcon fill="#E4E4E4" />
            </div>
          </Col>

          <Col xs={12}>
            <label htmlFor="">الوصف</label>
            <div>
              <input
                type="text"
                id=""
                className="form-control"
                placeholder="الوصف"
                {...register("seo.pageDescription", {
                  onChange: ({ target: { value } }) => setDescription(value),
                })}
              />
              <StockIcon fill="#E4E4E4" />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <div className="seoPreview">
              <h1>{title || product?.seo?.title || product?.name?.[locale]}</h1>
              <a
                href={
                  url ||
                  `${process.env.REACT_APP_FRONT_WEBSITE}/${product?.store?.slug}/products/${product?.slug}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {url ||
                  `${process.env.REACT_APP_FRONT_WEBSITE}/
                ${product?.store?.slug}/products/${product?.slug}`}
              </a>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    description?.slice(0, 160) ||
                    product?.seo?.pageDescription?.slice(0, 160),
                }}
              ></p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
