import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getGroupsApi,
  addGroupApi,
  editGroupApi,
  deleteGroupApi,
  getSingleGroupApi,
  getGroupRulesApi,
} from "../../api/groups";

// Login Redux States
import {
  ADD_GROUP,
  DELETE_GROUP,
  EDIT_GROUP,
  GET_SINGLE_GROUP,
  GET_GROUPS,
  GET_GROUP_RULES,
} from "./actionTypes";
import {
  addGroupFailure,
  addGroupSuccess,
  getGroupsSuccess,
  getGroupsFailure,
  editGroupSuccess,
  editGroupFailure,
  deleteGroupSuccess,
  deleteGroupFailure,
  getSingleGroupSuccess,
  getSingleGroupFailure,
  getGroupRulesSuccess,
  getGroupRulesFailure,
} from "./actions";

function* getGroups({ payload }) {
  try {
    const { data } = yield call(getGroupsApi, payload);
    yield put(getGroupsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getGroupsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getGroupRules({ payload }) {
  try {
    const { data } = yield call(getGroupRulesApi, payload);
    yield put(getGroupRulesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getGroupRulesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleGroup({ payload }) {
  try {
    const { data } = yield call(getSingleGroupApi, payload);
    yield put(getSingleGroupSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleGroupFailure(error?.response?.data || error));
  }
}

function* addGroup({ payload }) {
  try {
    const { data } = yield call(addGroupApi, payload.data);
    yield put(addGroupSuccess(data));
    yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(addGroupFailure(error.response?.data?.message || error));
  }
}

function* editGroup({ payload }) {
  try {
    const { data } = yield call(editGroupApi, payload);
    yield put(editGroupSuccess(data));
    yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(editGroupFailure(error.response?.data?.message || error));
  }
}

function* deleteGroup({ payload }) {
  try {
    yield call(deleteGroupApi, payload);
    yield put(deleteGroupSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteGroupFailure(error?.response?.data || error));
  }
}

export function* watchGetGroups() {
  yield takeEvery(GET_GROUPS, getGroups);
}

export function* watchGetGroupRules() {
  yield takeEvery(GET_GROUP_RULES, getGroupRules);
}

export function* watchSingleGroup() {
  yield takeEvery(GET_SINGLE_GROUP, getSingleGroup);
}

export function* watchAddGroup() {
  yield takeEvery(ADD_GROUP, addGroup);
}

export function* watchEditGroup() {
  yield takeEvery(EDIT_GROUP, editGroup);
}

export function* watchDeleteGroup() {
  yield takeEvery(DELETE_GROUP, deleteGroup);
}

function* GroupsSaga() {
  yield all([fork(watchGetGroups)]);
  yield all([fork(watchGetGroupRules)]);
  yield all([fork(watchSingleGroup)]);
  yield all([fork(watchAddGroup)]);
  yield all([fork(watchEditGroup)]);
  yield all([fork(watchDeleteGroup)]);
}

export default GroupsSaga;
