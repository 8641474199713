import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ReactComponent as ArrangeIcon } from "assets/svgs/settings/arrange-square.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getId, getOptionsFrom } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import Select from "react-select";
import {
  editStoreSlugSetting,
  getBankAccounts,
  getBanks,
  getPaymentMethods,
} from "store/actions";

const DefaultPaymentModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const navigate = useNavigate();
  const { handleSubmit, reset } = useForm();
  const [params] = useSearchParams();

  const { settings, loading, bankAccounts } =
    useSelector((state) => state?.settings) || {};

  const { paymentMethods, loading: paymentMethodsLoading } =
    useSelector((state) => state?.paymentMethods) || {};

  const [mainPayment, setMainPayment] = useState();
  const [alternativePayments, setAlternativePayments] = useState([]);
  const [OfficialInvoiceReceiptEmail, setOfficialInvoiceReceiptEmail] =
    useState("");

  useEffect(() => {
    dispatch(getPaymentMethods());
    dispatch(getBanks());
    dispatch(getBankAccounts());
  }, [dispatch]);

  useEffect(() => {
    let defaultPaymentMethodId, alternativePaymentMethodsIds;

    if (settings?.billingSettings?.paymentMethods?.length > 0) {
      defaultPaymentMethodId = settings?.billingSettings?.paymentMethods?.find(
        (p) => p?.isDefault
      )?.id;
      alternativePaymentMethodsIds = settings?.billingSettings?.paymentMethods
        ?.filter((p) => !p?.isDefault)
        ?.map((e) => e?.id);

      setMainPayment(
        getOptionsFrom(
          paymentMethods.find((p) => getId(p) === defaultPaymentMethodId) || {},
          locale
        )
      );

      setAlternativePayments(
        getOptionsFrom(
          paymentMethods?.filter((p) =>
            alternativePaymentMethodsIds?.includes(p?.id)
          ) || [],
          locale
        )
      );

      setOfficialInvoiceReceiptEmail(
        settings?.billingSettings?.OfficialInvoiceReceiptEmail
      );
    }
  }, [locale, paymentMethods, settings]);

  useEffect(() => {
    reset({});
  }, [coupon, reset]);

  const renderMethodsOptions = paymentMethods?.map((c) => ({
    label: c?.name?.[locale] || c?.name,
    value: c?.id,
  }));

  const filterAgainst = (type = "", data, filter) =>
    (data?.length > 0 &&
      data?.filter((m) =>
        type === "arr"
          ? filter?.find && !filter?.find((f) => f?.value === m?.value)
          : m?.value !== filter
      )) ||
    [];

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = () => {
    // e.preventDefault();

    let data = {
      billingSettings: {
        paymentMethods: [
          { isDefault: true, id: mainPayment?.value },
          ...alternativePayments?.map((e) => ({
            isDefault: false,
            id: e?.value,
          })),
        ],
        bankAccount: getId(
          bankAccounts?.find((e) => e?.isDefault) || bankAccounts?.[0]
        ),
        OfficialInvoiceReceiptEmail,
      },
    };

    dispatch(
      editStoreSlugSetting({
        data,
        slug: "walletSettings",
        navigate: handleClose,
      })
    );
  };

  return (
    <Modal
      dialogClassName="productDataModal cash-on-deliver"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ArrangeIcon fill="#FC6B14" />
            <FormattedMessage id="defaultPaymentSettings" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="form-group required">
                <h5>طريقة الدفع الاساسية</h5>
                <div className="selectHolder">
                  <Select
                    isRtl={true}
                    isSearchable={true}
                    className="basic-single form-control"
                    classNamePrefix="select"
                    options={filterAgainst(
                      "arr",
                      renderMethodsOptions,
                      alternativePayments
                    )}
                    value={mainPayment}
                    placeholder="اختار طريقة الدفع الاساسية"
                    isMulti={false}
                    onChange={(e) => setMainPayment(e)}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-group ">
                <h5>طرق الدفع البديلة</h5>
                <div className="selectHolder ">
                  <Select
                    isRtl={true}
                    isSearchable={true}
                    className="basic-single form-control"
                    classNamePrefix="select"
                    options={filterAgainst(
                      "string",
                      renderMethodsOptions,
                      mainPayment?.value
                    )}
                    value={alternativePayments}
                    placeholder="اختار طريقة الدفع البديلة"
                    isMulti={true}
                    onChange={(e) => {
                      setAlternativePayments(e);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-group required">
                <h5>بريد استلام الفواتير</h5>
                <div className="selectHolder ">
                  <input
                    type="email"
                    className="form-control form-outline"
                    value={OfficialInvoiceReceiptEmail}
                    onChange={(e) =>
                      setOfficialInvoiceReceiptEmail(e?.target?.value)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="form-group">
             
              <BankAccountsCards
                setSelectedBank={(data) => {
                  setSelectedBank(data);
                  setBankAccountModal(true);
                }}
              />
              <Col xs="6" md="3">
                <div className="selectHolder ">
                  <button
                    type="button"
                    onClick={() => {
                      setBankAccountModal(true);
                      setSelectedBank();
                    }}
                    className="btn btn-secondary"
                  >
                    <FormattedMessage id="addBankAccount" />
                  </button>
                </div>
              </Col>
            </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {(loading || paymentMethodsLoading) && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default DefaultPaymentModal;
