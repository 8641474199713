import superAdminServer from "./superAdminServer";

export const getThemeTemplatesApi = async (deviceType) => {
  const response = await superAdminServer().get("/themeTemplate", {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};
