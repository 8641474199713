import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAILURE,
  ADD_FAQ,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_FAILURE,
  GET_SINGLE_FAQ,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_FAILURE,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILURE,
} from "./actionTypes";

const initialState = {
  faqs: [],
  loading: false,
  error: "",
  singleFaq: {},
  FaqTags: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FAQS_SUCCESS:
      state = {
        ...state,
        loading: false,
        faqs: action.payload.faqs,
      };
      break;
    case GET_FAQS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_FAQ_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleFaq: action.payload.faq,
      };
      break;
    case GET_SINGLE_FAQ_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleFaq: {},
      };
      break;

    case ADD_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_FAQ_SUCCESS:
      state = {
        ...state,
        loading: false,
        faqs: action.payload.faqs,
      };
      break;
    case ADD_FAQ_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FAQ_SUCCESS:
      const FAQSAfterDeleteing = [
        ...state?.faqs?.filter((Faq) => Faq?.id !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        faqs: FAQSAfterDeleteing,
      };
      break;
    case DELETE_FAQ_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
