import StickyLoader from "components/shared/StickyLoader";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ReactComponent as BankIcon } from "assets/svgs/bank.svg";
import CheckCard from "../storeWallet/CheckCard";

function BankAccounts({ setSelectedBank, editDeleteFlag = true }) {
  const { locale } = useIntl();
  const { loading, bankAccounts } =
    useSelector((state) => state?.settings) || {};

  const items = bankAccounts || [];

  const renderItems = items?.length ? (
    items?.map((item, index) => (
      <CheckCard
        key={index}
        editDeleteFlag={editDeleteFlag}
        data={item}
        selectBank={(data) => setSelectedBank(data)}
      />
    ))
  ) : (
    <div className="no-data">
      <BankIcon />
      <h4>
        <FormattedMessage id="noBankAccountsFound" />
      </h4>
    </div>
  );

  if (loading) return <StickyLoader />;
  return <div className="bank-accounts">{renderItems}</div>;
}

export default BankAccounts;
