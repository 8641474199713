import React from "react";
import visits from "../../../src/assets/images/visits.png";
import upAngle from "../../../src/assets/images/1.svg";
import downAngle from "../../../src/assets/images/2.svg";

const Visits = ({ data }) => {
  return (
    <div className="card">
      <h5>الزيـارات</h5>
      <div className="card-body">
        <div className="inner visits">
          <div className="i-icon">
            <img src={visits} alt="" />
          </div>
          <div className="i-text">
            <h4>{data?.currentMonthViews}</h4>
            <p>
              <img
                src={
                  data?.monthlyViewsPresntStatus === "up" ? upAngle : downAngle
                }
                alt=""
              />
              <i
                className={`${
                  data?.monthlyViewsPresntStatus === "up" ? "up" : "down"
                }`}
              >
                {data?.monthlyViewsPresnt?.toFixed(0)}%
              </i>{" "}
              أخر شهر
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visits;
