export const GET_THEME_PAGES = "GET_THEME_PAGES";
export const GET_THEME_PAGES_SUCCESS = "GET_THEME_PAGES_SUCCESS";
export const GET_THEME_PAGES_FAILURE = "GET_THEME_PAGES_FAILURE";

export const GET_THEME_COMPONENTS_TYPE = "GET_THEME_COMPONENTS_TYPE";
export const GET_THEME_COMPONENTS_TYPE_SUCCESS =
  "GET_THEME_COMPONENTS_TYPE_SUCCESS";
export const GET_THEME_COMPONENTS_TYPE_FAILURE =
  "GET_THEME_COMPONENTS_TYPE_FAILURE";

export const GET_THEME_FONTS = "GET_THEME_FONTS";
export const GET_THEME_FONTS_SUCCESS = "GET_THEME_FONTS_SUCCESS";
export const GET_THEME_FONTS_FAILURE = "GET_THEME_FONTS_FAILURE";

export const GET_THEME = "GET_THEME";
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const GET_THEME_FAILURE = "GET_THEME_FAILURE";

export const EDIT_THEME_COMPONENT = "EDIT_THEME_COMPONENT";
export const EDIT_THEME_COMPONENT_SUCCESS = "EDIT_THEME_COMPONENT_SUCCESS";
export const EDIT_THEME_COMPONENT_FAILURE = "EDIT_THEME_COMPONENT_FAILURE";

export const EDIT_THEME_CSS = "EDIT_THEME_CSS";
export const EDIT_THEME_CSS_SUCCESS = "EDIT_THEME_CSS_SUCCESS";
export const EDIT_THEME_CSS_FAILURE = "EDIT_THEME_CSS_FAILURE";

export const EDIT_THEME_SETTINGS = "EDIT_THEME_SETTINGS";
export const EDIT_THEME_SETTINGS_SUCCESS = "EDIT_THEME_SETTINGS_SUCCESS";
export const EDIT_THEME_SETTINGS_FAILURE = "EDIT_THEME_SETTINGS_FAILURE";

export const EDTI_STORE_SETTINGS = "EDTI_STORE_SETTINGS";
export const EDTI_STORE_SETTINGS_SUCCESS = "EDTI_STORE_SETTINGS_SUCCESS";
export const EDTI_STORE_SETTINGS_FAILURE = "EDTI_STORE_SETTINGS_FAILURE";
