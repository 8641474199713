import axios from "axios";
// import { store as reduxStore } from "store";

const server = () => {
  const headers = {};
  const token = (localStorage.getItem("token") || "")?.replace(
    /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
    ""
  );
  const store = (localStorage.getItem("store") || "")?.replace(
    /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
    ""
  );

  if (token) headers.Authorization = token;
  if (store) headers.store = store;

  //// if (!headers.store?.length) return {};
  // const { loading, activeStore } = reduxStore.getState().stores;
  // if (loading)

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
};

export default server;
