import { Input } from "components/shared/FormComponents";
import { EditorComponent } from "components/shared/FormComponents copy";
import Loader from "components/shared/Loader";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editPage, getPage } from "store/actions";

const PageContent = ({ id }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, singlePage } = useSelector((state) => state.pages);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getPage(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singlePage?.title,
    });
    setDescription(singlePage?.content?.description);
  }, [reset, singlePage]);
  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage({
        error: formatMessage({ id: "sendingErrorMessage" }),
      });
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    data.content = {
      description,
    };

    dispatch(editPage({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col md={12}>
          <input type="hidden" {...register("title.ar")} />
          <input type="hidden" {...register("title.en")} />

          <Row>
            <Col lg={6} xs={12}>
              <div className="form-group ">
                <h5>
                  <FormattedMessage id={"title"} />
                  (<FormattedMessage id={"arabic-language"} />)
                </h5>
                <Input
                  name="title.ar"
                  register={register}
                  errors={errors}
                  rules={{ required: false }}
                />
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="form-group ">
                <h5>
                  <FormattedMessage id={"title"} />
                  (<FormattedMessage id={"english-language"} />)
                </h5>
                <Input
                  name="title.en"
                  register={register}
                  errors={errors}
                  rules={{ required: false }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={12}>
              <div className="form-group ">
                <h5>
                  <FormattedMessage id={"description"} />
                  (<FormattedMessage id={"arabic-language"} />)
                </h5>

                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <EditorComponent
                      name="description.ar"
                      locale="ar"
                      setData={setDescription}
                      initialValue={singlePage?.content?.description?.ar}
                    />
                  )}
                />
              </div>
            </Col>

            <Col lg={12} xs={12}>
              <div className="form-group ">
                <h5>
                  <FormattedMessage id={"description"} />
                  (<FormattedMessage id={"english-language"} />)
                </h5>

                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <EditorComponent
                      name="description.en"
                      locale="en"
                      setData={setDescription}
                      initialValue={singlePage?.content?.description?.en}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <SaveChangesButton />
        </Col>
      </form>
    </>
  );
};

export default PageContent;
