import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as BankIcon } from "assets/svgs/bank.svg";
import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";
import Select from "react-select";
import { addBankAccount, editBankAccount } from "store/actions";
import { Input } from "components/shared/FormComponents";
import { getId, getOptionsFrom } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const BankModal = ({ selectedBank, modalShow, setModalShow, settings }) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { banks } = useSelector((state) => state?.paymentMethods) || {};
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let selectedBankTmp = { ...selectedBank };
    // console.log("selectedBankTmp", selectedBankTmp);
    if (selectedBankTmp?.bank)
      selectedBankTmp.bank = getId(selectedBankTmp?.bank);
    reset(selectedBankTmp || {});
    // reset({
    //   userName: settings?.billingSettings?.bankAccount?.userName,
    //   bank: getOptionsFrom(
    //     banks?.find(
    //       (e) => e?.id === settings?.billingSettings?.bankAccount?.bank
    //     ),
    //     locale
    //   ),
    //   iban: settings?.billingSettings?.bankAccount?.iban,
    //   swift: settings?.billingSettings?.bankAccount?.swift,
    //   bankNumber: settings?.billingSettings?.bankAccount?.bankNumber,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, settings, selectedBank]);

  // const handleClose = () => setModalShow();
  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
    setModalShow();
  };

  const onSubmit = (data) => {
    data.bank = data?.bank?.value || data?.bank;

    if (!getId(selectedBank))
      return dispatch(
        addBankAccount({
          data,
          callBack: () => {
            setModalShow();
            toast.success(formatMessage({ id: "bankAccountAdded" }));
          },
          // navigate,
        })
      );
    dispatch(
      editBankAccount({
        data,
        id: getId(selectedBank),
        callBack: setModalShow,
        // navigate,
      })
    );
  };
  return (
    <Modal
      dialogClassName="productDataModal storePayment"
      backdrop="static"
      show={modalShow}
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <BankIcon fill="#E4E4E4" />
          <FormattedMessage id="addBankAccount" />
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-group">
            <div className="form-body">
              <Row>
                <Col xs={12}>
                  <label htmlFor="">اسم المستخدم</label>
                  <Input
                    name="userName"
                    register={register}
                    errors={errors}
                    rules={{ required: false }}
                    icon={<StockIcon fill="#E4E4E4" />}
                  />
                </Col>

                <Col xs="12">
                  <label htmlFor="">البنك</label>
                  <div className="selectHolder w-100">
                    <Controller
                      control={control}
                      name="bank"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isRtl={true}
                          isSearchable={true}
                          className="basic-single w-100"
                          classNamePrefix="select"
                          options={getOptionsFrom(banks, locale)}
                          placeholder="اختار البنك"
                          // isMulti={true}
                          onChange={({ value }) => {
                            onChange(value);
                          }}
                          value={getOptionsFrom(banks, locale)?.find(
                            (e) => e?.value === value
                          )}
                        />
                      )}
                    />
                    <BankIcon fill="#E4E4E4" />
                  </div>
                </Col>

                <Col xs={12}>
                  <label htmlFor="">رقم (iban)</label>
                  <Input
                    name="iban"
                    register={register}
                    errors={errors}
                    rules={{ required: false }}
                    icon={<StockIcon fill="#E4E4E4" />}
                  />
                </Col>

                <Col xs={12}>
                  <label htmlFor="">رقم (swift)</label>
                  <Input
                    type="number"
                    name="swift"
                    register={register}
                    errors={errors}
                    rules={{ required: false }}
                    icon={<StockIcon fill="#E4E4E4" />}
                  />
                </Col>
                <Col xs={12}>
                  <label htmlFor="">رقم البنك</label>
                  <Input
                    type="number"
                    name="bankNumber"
                    register={register}
                    errors={errors}
                    rules={{ required: false }}
                    icon={<StockIcon fill="#E4E4E4" />}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            الغاء
          </button>
          <button className="btn btn-blue">تم</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BankModal;
