import server from "./server";

export const getProductsReviewsApi = async (payload) => {
  const response = await server().get(`/productReviews${payload || ""}`);
  return response.data;
};

export const getSingleProductsReviewApi = async (id) => {
  const response = await server().get(`/productReviews/${id}`);
  return response.data;
};

export const addProductsReviewApi = async (payload) => {
  const response = await server().post("/productReviews", payload);
  return response.data;
};

export const editProductsReviewApi = async ({ id, data }) => {
  const response = await server().put(`/productReviews/${id}`, data);
  return response.data;
};

export const replyToProductsReviewApi = async ({ id, data }) => {
  const response = await server().post(`/productReviews/addAnswer/${id}`, data);
  return response.data;
};

export const changeProductsReviewStatusApi = async ({ id, data }) => {
  const response = await server().post(
    `/productReviews/changeStatus/${id}`,
    data
  );
  return response.data;
};

export const deleteProductsReviewApi = async (id) => {
  const response = await server().delete(`/productReviews/${id}`);
  return response.data;
};
