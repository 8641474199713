import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Editor } from "components/shared/Editor";

import {
  editFaq,
  // getFaqCategories,
  // getLanguages,
  getSingleFaq,
  // getTags,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
// import server from "api/server";
// import imageUpload from "../../assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import StickyLoader from "components/shared/StickyLoader";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
// import CreatableSelect from "react-select/creatable";

const Edit = () => {
  const { locale, formatMessage } = useIntl();
  const dir = locale === "ar" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const { singleFaq, loading } = useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(getSingleFaq(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset(singleFaq);
  }, [reset, singleFaq]);

  const onSubmit = (data) => {
    dispatch(editFaq({ id, data, navigate }));
  };

  const renderCategoriesSelectOptions = () => {
    return ["product", "store", "blog"].map((category, index) => {
      return (
        <option value={category} key={index}>
          {formatMessage({ id: category })}
        </option>
      );
    });
  };

  return (
    <>
      {loading && <StickyLoader fill="#FC6B14" />}

      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل السؤال</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <MultiLangWrapper
                        label={<FormattedMessage id="question" />}
                        errors={errors?.question}
                      >
                        {({ lang, classes, key }) => (
                          <input
                            key={key}
                            type="text"
                            className={`form-control form-outline ${classes}`}
                            {...register(`question[${lang}]`, {
                              required: true,
                            })}
                          />
                        )}
                        <p className="error-hint">
                          {errors?.question && "هذا الحقل مطلوب"}
                        </p>
                      </MultiLangWrapper>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <MultiLangWrapper
                        label={<FormattedMessage id="questionReply" />}
                        errors={errors?.reply}
                      >
                        {({ lang, classes, key }) => (
                          <div className={classes} key={key}>
                            <Controller
                              control={control}
                              name={`reply.${lang}`}
                              rules={{ required: true }}
                              render={({ field: { value, onChange } }) => (
                                <Editor
                                  index={key}
                                  initialValue={value}
                                  onChange={(e, editor) => {
                                    const data = editor.getContent();
                                    onChange(data);
                                    // setDescription(data);
                                  }}
                                  tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    menubar:
                                      "file edit view insert format tools table help",
                                    toolbar:
                                      "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
                                    toolbar_sticky: true,
                                    autosave_ask_before_unload: true,
                                    autosave_interval: "30s",
                                    autosave_prefix: "{path}{query}-{id}-",
                                    autosave_restore_when_empty: false,
                                    autosave_retention: "2m",
                                    mobile: {
                                      menubar: true,
                                    },
                                    directionality: `${dir}`,
                                    language: `${locale}`,
                                    height: 400,
                                    image_caption: true,
                                    quickbars_selection_toolbar:
                                      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                    noneditable_noneditable_class:
                                      "mceNonEditable",
                                    toolbar_mode: "wrap",
                                    contextmenu: "link image imagetools table",
                                    content_style: "body {font-size:14px}",
                                    setup: function (editor) {
                                      editor.ui.registry.addButton(
                                        "customInsertButton",
                                        {
                                          text: "custom Button",
                                          onAction: function (_) {
                                            editor.insertContent(
                                              `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
																148deg,#1d5cd1,#0ba1d8 84%);
															color: #fff;
															padding: 10px 20px;
															border-radius: 50px;
															display: inline-block;
															cursor: pointer;
															text-decoration: none;">my button!</a>&nbsp;`
                                            );
                                          },
                                        }
                                      );
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                        )}
                        <p className="error-hint">
                          {errors?.reply && "هذا الحقل مطلوب"}
                        </p>
                      </MultiLangWrapper>
                    </div>
                  </Col>
                  {/* <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="questionDescEn" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="description.en"
                          render={({ field: { value, onChange } }) => (
                            <Editor
                              initialValue={value}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                                // setDescription(data);
                              }}
                              tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                              init={{
                                plugins:
                                  "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                menubar:
                                  "file edit view insert format tools table help",
                                toolbar:
                                  "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
                                toolbar_sticky: true,
                                autosave_ask_before_unload: true,
                                autosave_interval: "30s",
                                autosave_prefix: "{path}{query}-{id}-",
                                autosave_restore_when_empty: false,
                                autosave_retention: "2m",
                                mobile: {
                                  menubar: true,
                                },
                                directionality: `${dir}`,
                                language: `${locale}`,
                                height: 400,
                                image_caption: true,
                                quickbars_selection_toolbar:
                                  "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                noneditable_noneditable_class: "mceNonEditable",
                                toolbar_mode: "wrap",
                                contextmenu: "link image imagetools table",
                                content_style: "body {font-size:14px}",
                                setup: function (editor) {
                                  editor.ui.registry.addButton(
                                    "customInsertButton",
                                    {
                                      text: "custom Button",
                                      onAction: function (_) {
                                        editor.insertContent(
                                          `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
																148deg,#1d5cd1,#0ba1d8 84%);
															color: #fff;
															padding: 10px 20px;
															border-radius: 50px;
															display: inline-block;
															cursor: pointer;
															text-decoration: none;">my button!</a>&nbsp;`
                                        );
                                      },
                                    }
                                  );
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col> */}

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="questionType" />
                      </h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("type", { required: true })}
                        >
                          <option disabled>اختر النوع</option>
                          {renderCategoriesSelectOptions()}
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
