import server from "./server";

export const getOptionsApi = async () => {
  const response = await server().get("/options");
  return response.data;
};

export const addOptionApi = async (payload) => {
  const response = await server().post("/options", payload);
  return response.data;
};

export const editOptionApi = async ({ id, data }) => {
  const response = await server().put(`/options/${id}`, data);
  return response.data;
};

export const deleteOptionApi = async (id) => {
  const response = await server().delete(`/options/${id}`);
  return response.data;
};
