import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_SETTINGS,
  EDIT_SETTINGS,
  ADD_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  EDIT_STORE_SLUG_SETTING,
  GET_BANK_ACCOUNTS,
  SET_DEFAULT_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
} from "./actionTypes";

import {
  getSettingsApi,
  editSettingsApi,
  editBankAccountApi,
  editStoreSlugSettingsApi,
  addBankAccountApi,
  getBankAccountApi,
  setDefaultBankAccountApi,
  deleteBankAccountApi,
} from "api/settings";

import {
  getSettingsSuccess,
  getSettingsFailure,
  editSettingsSuccess,
  editSettingsFailure,
  editStoreSlugSettingFailure,
  editStoreSlugSettingSuccess,
  getBankAccountsFailure,
  getBankAccountsSuccess,
  setDefaultBankAccountSuccess,
  setDefaultBankAccountFailure,
  addBankAccountSuccess,
  addBankAccountFailure,
  deleteBankAccountSuccess,
  deleteBankAccountFailure,
} from "./actions";
import { editBankAccountFailure, editBankAccountSuccess } from "store/actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

// api calls
function* getSettings({ payload }) {
  try {
    const { data } = yield call(getSettingsApi, payload);
    yield put(getSettingsSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    yield put(getSettingsFailure(error.response?.data?.message || error));
  }
}

function* editSettings({ payload }) {
  try {
    const { data } = yield call(editSettingsApi, payload);

    yield put(editSettingsSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    yield put(editSettingsFailure(error.response?.data?.message || error));
  }
}

// bank accounts---------------------
function* getBankAccounts() {
  try {
    const { data } = yield call(getBankAccountApi);
    yield put(getBankAccountsSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    yield put(getBankAccountsFailure(error.response?.data?.message || error));
  }
}

function* addBankAccount({ payload }) {
  try {
    const { data } = yield call(addBankAccountApi, payload);
    yield put(addBankAccountSuccess(data));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(addBankAccountFailure(error.response?.data?.message || error));
  }
}

function* editBankAccount({ payload }) {
  try {
    const { data } = yield call(editBankAccountApi, payload);
    yield put(editBankAccountSuccess(data));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(editBankAccountFailure(error.response?.data?.message || error));
  }
}

function* deleteBankAccount({ payload }) {
  try {
    yield call(deleteBankAccountApi, payload);
    yield put(deleteBankAccountSuccess(payload));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(deleteBankAccountFailure(error.response?.data?.message || error));
  }
}

function* setDefaultBankAccount({ payload }) {
  try {
    const { data } = yield call(setDefaultBankAccountApi, payload);
    yield put(setDefaultBankAccountSuccess(data));
    if (payload?.callBack) payload?.callBack();
    yield getBankAccounts();
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(
      setDefaultBankAccountFailure(error.response?.data?.message || error)
    );
  }
}

function* editStoreSlugSetting({ payload }) {
  try {
    const { data } = yield call(editStoreSlugSettingsApi, payload);
    yield put(editStoreSlugSettingSuccess(data));
    // yield payload.navigate("/settings");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(
      editStoreSlugSettingFailure(error.response?.data?.message || error)
    );
  }
}

// watchers
export function* watchgetSettings() {
  yield takeEvery(GET_SETTINGS, getSettings);
}

export function* watchgetBankAccounts() {
  yield takeEvery(GET_BANK_ACCOUNTS, getBankAccounts);
}

export function* watcheditSettings() {
  yield takeEvery(EDIT_SETTINGS, editSettings);
}

export function* watchAddBankAccount() {
  yield takeEvery(ADD_BANK_ACCOUNT, addBankAccount);
}

export function* watchEditBankAccount() {
  yield takeEvery(EDIT_BANK_ACCOUNT, editBankAccount);
}

export function* watchDeleteBankAccount() {
  yield takeEvery(DELETE_BANK_ACCOUNT, deleteBankAccount);
}

export function* watchSetDefaultBankAccount() {
  yield takeEvery(SET_DEFAULT_BANK_ACCOUNT, setDefaultBankAccount);
}

export function* watchEditStoreSlugSetting() {
  yield takeEvery(EDIT_STORE_SLUG_SETTING, editStoreSlugSetting);
}

function* settingsSaga() {
  yield all([fork(watchgetSettings)]);
  yield all([fork(watchgetBankAccounts)]);
  yield all([fork(watcheditSettings)]);
  yield all([fork(watchAddBankAccount)]);
  yield all([fork(watchEditBankAccount)]);
  yield all([fork(watchDeleteBankAccount)]);
  yield all([fork(watchSetDefaultBankAccount)]);
  yield all([fork(watchEditStoreSlugSetting)]);
}

export default settingsSaga;
