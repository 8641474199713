import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_BANKS, GET_PAYMENT_METHODS } from "./actionTypes";
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  getBanksSuccess,
  getBanksFailure,
} from "./actions";
import { getPaymentMethodsApi, getBanksApi } from "api/paymentMethods";

function* getBanks() {
  try {
    const { data } = yield call(getBanksApi);
    yield put(getBanksSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getBanksFailure(error.response?.data?.message || error));
  }
}

function* getPaymentMethods() {
  try {
    const { data } = yield call(getPaymentMethodsApi);
    yield put(getPaymentMethodsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getPaymentMethodsFailure(error.response?.data?.message || error));
  }
}

export function* watchGetBanks() {
  yield takeEvery(GET_BANKS, getBanks);
}

export function* watchGetPaymentMethods() {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethods);
}

function* storesSaga() {
  yield all([fork(watchGetBanks)]);
  yield all([fork(watchGetPaymentMethods)]);
}

export default storesSaga;
