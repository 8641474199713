import {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  ADD_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  EDIT_BRAND,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
  ORDER_BRAND,
  ORDER_BRAND_SUCCESS,
  ORDER_BRAND_FAILURE,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  GET_SINGLE_BRAND,
  GET_SINGLE_BRAND_SUCCESS,
  GET_SINGLE_BRAND_FAILURE,
  ADD_EMPTY_BRAND,
  ADD_EMPTY_BRAND_SUCCESS,
  ADD_EMPTY_BRAND_FAILURE,
  DELETE_EMPTY_BRAND,
  DELETE_EMPTY_BRAND_SUCCESS,
  DELETE_EMPTY_BRAND_FAILURE,
  EDIT_BRANDS_SETTING_FAILURE,
  EDIT_BRANDS_SETTING_SUCCESS,
  EDIT_BRANDS_SETTING,
  GET_BRANDS_SETTING_FAILURE,
  GET_BRANDS_SETTING_SUCCESS,
  GET_BRANDS_SETTING,
  DELETE_MULTIPLE_BRANDS,
  DELETE_MULTIPLE_BRANDS_SUCCESS,
  DELETE_MULTIPLE_BRANDS_FAILURE,
} from "./actionTypes";

export const getBrands = (payload) => {
  return {
    type: GET_BRANDS,
    payload
  };
};

export const getBrandsSuccess = (brands) => {
  return {
    type: GET_BRANDS_SUCCESS,
    payload: brands,
  };
};

export const getBrandsFailure = (error) => {
  return {
    type: GET_BRANDS_FAILURE,
    payload: error,
  };
};

export const getSingleBrand = (id) => {
  return {
    type: GET_SINGLE_BRAND,
    payload: id,
  };
};

export const getSingleBrandSuccess = (brand) => {
  return {
    type: GET_SINGLE_BRAND_SUCCESS,
    payload: brand,
  };
};

export const getSingleBrandFailure = (error) => {
  return {
    type: GET_SINGLE_BRAND_FAILURE,
    payload: error,
  };
};

export const addBrand = (payload) => {
  return {
    type: ADD_BRAND,
    payload,
  };
};

export const addBrandSuccess = (payload) => {
  return {
    type: ADD_BRAND_SUCCESS,
    payload,
  };
};

export const addBrandFailure = (error) => {
  return {
    type: ADD_BRAND_FAILURE,
    payload: error,
  };
};

// ------------------------------------------------
export const editBrand = (payload) => {
  return {
    type: EDIT_BRAND,
    payload,
  };
};

export const editBrandSuccess = (payload) => {
  return {
    type: EDIT_BRAND_SUCCESS,
    payload,
  };
};

export const editBrandFailure = (error) => {
  return {
    type: EDIT_BRAND_FAILURE,
    payload: error,
  };
};

// ------------------------------------------------
export const orderBrand = (payload) => {
  return {
    type: ORDER_BRAND,
    payload,
  };
};

export const orderBrandSuccess = (payload) => {
  return {
    type: ORDER_BRAND_SUCCESS,
    payload,
  };
};

export const orderBrandFailure = (error) => {
  return {
    type: ORDER_BRAND_FAILURE,
    payload: error,
  };
};

// ----------------------------------
export const deleteBrand = (id) => {
  return {
    type: DELETE_BRAND,
    payload: id,
  };
};

export const deleteBrandSuccess = (id) => {
  return {
    type: DELETE_BRAND_SUCCESS,
    payload: id,
  };
};

export const deleteBrandFailure = (error) => {
  return {
    type: DELETE_BRAND_FAILURE,
    payload: error,
  };
};

export const addEmptyBrand = (payload) => {
  return {
    type: ADD_EMPTY_BRAND,
    payload,
  };
};

export const addEmptyBrandSuccess = (payload) => {
  return {
    type: ADD_EMPTY_BRAND_SUCCESS,
    payload,
  };
};

export const addEmptyBrandFailure = (error) => {
  return {
    type: ADD_EMPTY_BRAND_FAILURE,
    payload: error,
  };
};

export const deleteEmptyBrand = (id) => {
  return {
    type: DELETE_EMPTY_BRAND,
    payload: id,
  };
};

export const deleteEmptyBrandSuccess = (id) => {
  return {
    type: DELETE_EMPTY_BRAND_SUCCESS,
    payload: id,
  };
};

export const deleteEmptyBrandFailure = (error) => {
  return {
    type: DELETE_EMPTY_BRAND_FAILURE,
    payload: error,
  };
};

// ================================================

export const getBrandsSettings = (payload) => {
  return {
    type: GET_BRANDS_SETTING,
    payload,
  };
};

export const getBrandsSettingsSuccess = (payload) => {
  return {
    type: GET_BRANDS_SETTING_SUCCESS,
    payload,
  };
};

export const getBrandsSettingsFailure = (payload) => {
  return {
    type: GET_BRANDS_SETTING_FAILURE,
    payload,
  };
};

// ================================================

export const editBrandsSettings = (payload) => {
  return {
    type: EDIT_BRANDS_SETTING,
    payload,
  };
};

export const editBrandsSettingsSuccess = (payload) => {
  return {
    type: EDIT_BRANDS_SETTING_SUCCESS,
    payload,
  };
};

export const editBrandsSettingsFailure = (payload) => {
  return {
    type: EDIT_BRANDS_SETTING_FAILURE,
    payload,
  };
};

// ================================================

export const deleteMultipleBrands = (payload) => {
  return {
    type: DELETE_MULTIPLE_BRANDS,
    payload,
  };
};

export const deleteMultipleBrandsSuccess = (payload) => {
  return {
    type: DELETE_MULTIPLE_BRANDS_SUCCESS,
    payload,
  };
};

export const deleteMultipleBrandsFailure = (payload) => {
  return {
    type: DELETE_MULTIPLE_BRANDS_FAILURE,
    payload,
  };
};

// ================================================
