import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/shared/Loader";
import { getSettings } from "store/actions";
import StoreInfo from "./StoreInfo";
import { Nav, Tab } from "react-bootstrap";
import settingsIcon from "assets/svgs/Setting.svg";
import StoreSupport from "./StoreSupport";
import StoreActivity from "./StoreActivity";
import StoreSocials from "./StoreSocials";
import StickyLoader from "components/shared/StickyLoader";
import BreadCrumb from "components/shared/BreadCrumb";
import { useIntl } from "react-intl";
import OtherLinks from "./OtherLinks";

const EditCategory = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { loading, settings, dataSaveLoading } = useSelector(
    (state) => state.settings
  );

  useEffect(() => {
    dispatch(getSettings("basicSettings"));
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <>
      {/* <div className="acc-form"> */}
      <BreadCrumb
        // pageName="الطلبات"
        pageName={formatMessage({ id: "basicSettings" })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <div className="settings">
        {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
        <Tab.Container defaultActiveKey="0" transition={true} timeout={1000}>
          <div className="settingsHead">
            <div className="card">
              {/* <div>
                <span>
                  <img src={settingsIcon} alt="" />
                </span>
                الإعدادات
              </div> */}
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey={0}>بيانات المتجر </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={1}>نشاطات المتجر </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={2}> الدعم الفني </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={3}>روابط الوسائل الاجتماعية</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={4}>روابط اخرى</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="0">
              <div className="card">
                <StoreInfo data={settings?.storeInfo} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="1">
              <div className="card">
                <StoreActivity data={settings?.storeActivity} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <div className="card">
                <StoreSupport data={settings?.technicalSupport} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <div className="card">
                <StoreSocials data={settings?.socials} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <div className="card">
                <OtherLinks data={settings?.otherUrls} />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* </div> */}
    </>
  );
};

export default EditCategory;
