import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getDashboardHome } from "store/actions";

import OrdersTable from "components/dashboard/OrdersTable";
import Visits from "components/dashboard/Visits";
import Sales from "components/dashboard/Sales";
import Orders from "components/dashboard/Orders";
import Reports from "components/dashboard/Reports";
import OutOfStock from "components/dashboard/OutOfStock";
import NewClients from "components/dashboard/NewClients";

import selectImg from "assets/images/select-img.gif";
// import previewImg from "assets/images/preview.gif";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state) => state.dashboard);
  const store = localStorage.getItem("store");

  useEffect(() => {
    dispatch(getDashboardHome());
  }, [dispatch]);

  return (
    <div className="reports-blocks">
      {store ? (
        <div>
          <Row>
            <Col xxl={6} lg={6} md={12} xs={12}>
              <Reports data={dashboard?.monthSummary} />
            </Col>
            <Col xxl={6} lg={6} md={12} xs={12}>
              <Row>
                <Col xxl={6} lg={6} md={6} xs={6}>
                  <Visits data={dashboard?.views} />
                </Col>
                <Col xxl={6} lg={6} md={6} xs={6}>
                  <Sales data={dashboard?.totalOrders} />
                </Col>
                <Col xxl={6} lg={6} md={6} xs={6}>
                  <Orders data={dashboard?.totalOrders} />
                </Col>
                <Col xxl={6} lg={6} md={6} xs={6}>
                  <NewClients data={dashboard?.clients} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xxl={9} lg={9} md={12} xs={12}>
              <div className="homeOrders">
                <OrdersTable data={dashboard?.lastOrders} />
              </div>
            </Col>
            <Col xxl={3} lg={3} md={12} xs={12}>
              <OutOfStock
                title="منـتجات نـفذت"
                data={dashboard?.stockProduct?.outStockProducts}
              />
              <OutOfStock
                title="منتجات قاربت على النفاذ"
                data={dashboard?.stockProduct?.reorderQuantityProducts}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="no-store">
          <Row>
            <Col xxl={10} lg={10} md={12} xs={12}>
              <div className="card">
                <div>
                  <h4>أختيار متجر</h4>
                  <p>
                    برجاء اختيار متجر من المتاجر الموجودة للمتابة واظهار
                    التفاصيل والبيانات الخاصه بالمتجر كا المبيعات .. الخ
                  </p>
                </div>
                <div className="s-img">
                  <img src={selectImg} alt="" />
                </div>
              </div>
              <div className="card preview">
                {/* <img src={previewImg} alt="" /> */}
                <img src="https://i.postimg.cc/G3yZHTGm/preview.gif" alt="" />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
