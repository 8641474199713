import React, { useEffect } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { getActiveStore, getStores } from "store/actions";
import { useIntl } from "react-intl";

import Notifications from "components/shared/Notifications";

import userimg from "assets/images/user.svg";
import logoIcon from "assets/images/logo-icon.svg";
import logoInfo from "assets/images/logo-info.svg";
import userIcon from "assets/svgs/user.svg";
import logoutIcon from "assets/svgs/power.svg";
import searchIcon from "assets/svgs/search.svg";
import darkSwitcherIcon from "assets/svgs/dark-mode.svg";
import rippon from "assets/images/rippon.svg";
import { ReactComponent as BurgerIcon } from "assets/svgs/burger.svg";
import { handleImageLink, sidebarToggle } from "helpers/functions";

const Header = () => {
  const dispatch = useDispatch();
  const store = localStorage.getItem("store");
  const { user } = useSelector((state) => state.authentication);
  const { locale } = useIntl();
  const { stores } = useSelector((state) => state.stores);
  const { activeStore } = useSelector((state) => state.stores);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const searchToggle = () => {
    document.querySelector("html").classList.toggle("offScroll");
    document.querySelector(".searchWrap").classList.toggle("active");
  };

  // store the theme in the localStorage and invoke the theme function
  // const themeSwitcher = () => {
  //   const theme = localStorage.getItem("data-theme");
  //   if (!theme) {
  //     localStorage.setItem("theme", "dark");
  //   }
  //   if (theme) {
  //     localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
  //   }
  //   themeSwitcherFunction();
  // };

  const themeSwitcher = () => {
    const theme = localStorage.getItem("data-theme") || "light";
    document.documentElement.setAttribute(
      "data-theme",
      theme === "light" ? "dark" : "light"
    );
    localStorage.setItem("data-theme", theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    const theme = localStorage.getItem("data-theme") || "light";
    document.documentElement.setAttribute("data-theme", theme);
  }, []);

  useEffect(() => {
    dispatch(getActiveStore({ storeId: store, deviceType }));
    dispatch(getStores());
  }, [deviceType, dispatch, store]);

  return (
    <>
      <div className="main-head">
        <div className="logo">
          <div className="inner">
            <NavLink to="/">
              <div className="logo-icon">
                <img src={logoIcon} alt="" />
              </div>
              <div className="logo-info">
                <img src={logoInfo} alt="" />
              </div>
            </NavLink>
          </div>
          <div>
            <NavLink
              to="/stores/addstore"
              className="btn btn-blue"
              title="انشئ متجرك"
            >
              انشئ متجرك
            </NavLink>
            <div className="burgerBtn">
              <button className="toggleSidebar" onClick={sidebarToggle}>
                <BurgerIcon alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="head-wrp">
          <div className="burgerBtn">
            <button className="toggleSidebar" onClick={sidebarToggle}>
              <BurgerIcon alt=""  />
            </button>
            <NavLink
              to="/stores/addstore"
              className="btn btn-blue"
              title="انشئ متجرك"
            >
              انشئ متجرك
            </NavLink>
          </div>

          <div className="stores-selection">
            <Dropdown>
              <span>
                {store && (
                  <>
                    <i>برو</i>
                    <img src={rippon} alt="" className="rippon" />
                  </>
                )}
                <img src={handleImageLink(activeStore?.logo) || ""} alt="" />
              </span>
              <div>
                <Dropdown.Toggle>
                  {(activeStore && activeStore.name?.[locale]) || "أختر المتجر"}
                </Dropdown.Toggle>
                {store && (
                  <a
                    href={`${process.env.REACT_APP_FRONT_WEBSITE}/${activeStore?.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    زيـارة المـتجر
                  </a>
                )}
              </div>

              <Dropdown.Menu>
                <div className="drop-scrollable">
                  {stores?.map((store) => (
                    <Dropdown.Item
                      key={store.id}
                      onClick={() => {
                        localStorage.setItem("store", store.id);
                        dispatch(
                          getActiveStore({ storeId: store.id, deviceType })
                        );
                        window.location.href = "/";
                      }}
                      className={
                        activeStore?.id === store.id ? "active-store" : ""
                      }
                    >
                      {store.name?.[locale]}
                      <img src={handleImageLink(store?.logo)} alt="" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="searchWrap">
            <form action="#">
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="ابحث برقم الطلب ، اسم العميل "
                ></input>
                <select className="form-control">
                  <option>الطلبات</option>
                  <option>المنتجات</option>
                  <option>العملاء</option>
                </select>

                <button>
                  <img src={searchIcon} alt="" />
                </button>
              </div>
            </form>
          </div>

          <div className="left-actions">
            <button className="searchToggle" onClick={searchToggle}>
              <img src={searchIcon} alt="" />
            </button>
            <button className="darkSwitcher" onClick={themeSwitcher}>
              <img src={darkSwitcherIcon} alt="" />
            </button>
            <Notifications />
            <div className="authWrap">
              <ul>
                <li className="menu-item-has-children">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div className="au-flow">
                        <div className="au-img">
                          <img
                            src={handleImageLink(user?.image) || userimg}
                            alt=""
                          />
                        </div>
                        <div className="au-data">
                          <h4>{user?.username}</h4>
                          <p>{user?.role}</p>
                        </div>
                      </div>
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                          fill="black"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={NavLink} to="/profile">
                        {/* <NavLink to="/profile" className="p-0 text"> */}
                        <img src={userIcon} alt="" />
                        الملف الشخصي
                        {/* </NavLink> */}
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/logout">
                        {/* <NavLink to="/logout" className="p-0"> */}
                        <img src={logoutIcon} alt="" />
                        تسجيل الخروج
                        {/* </NavLink> */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-s" onClick={sidebarToggle}></div>
    </>
  );
};

export default Header;
