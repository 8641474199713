import { getId } from "helpers/functions";
import {
  GET_PRODUCTS_REVIEWS,
  GET_PRODUCTS_REVIEWS_SUCCESS,
  GET_PRODUCTS_REVIEWS_FAILURE,
  ADD_PRODUCTS_REVIEW,
  ADD_PRODUCTS_REVIEW_SUCCESS,
  ADD_PRODUCTS_REVIEW_FAILURE,
  DELETE_PRODUCTS_REVIEW,
  DELETE_PRODUCTS_REVIEW_SUCCESS,
  DELETE_PRODUCTS_REVIEW_FAILURE,
  GET_SINGLE_PRODUCTS_REVIEW,
  GET_SINGLE_PRODUCTS_REVIEW_SUCCESS,
  GET_SINGLE_PRODUCTS_REVIEW_FAILURE,
  EDIT_PRODUCTS_REVIEW,
  EDIT_PRODUCTS_REVIEW_SUCCESS,
  EDIT_PRODUCTS_REVIEW_FAILURE,
} from "./actionTypes";

const initialState = {
  productsReviews: [],
  loading: false,
  error: "",
  singleProductsReview: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REVIEWS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCTS_REVIEWS_SUCCESS:
      state = {
        ...state,
        loading: false,
        productsReviews: action.payload?.reviews,
        metadata: {
          ...(action.payload.metadata || {}),
          results: action.payload?.reviews?.length,
        },
      };
      break;
    case GET_PRODUCTS_REVIEWS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PRODUCTS_REVIEW:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PRODUCTS_REVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleProductsReview: action.payload.ProductsReview,
      };
      break;
    case GET_SINGLE_PRODUCTS_REVIEW_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleProductsReview: {},
      };
      break;

    case ADD_PRODUCTS_REVIEW:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PRODUCTS_REVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        productsReviews: action.payload.review,
      };
      break;
    case ADD_PRODUCTS_REVIEW_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_PRODUCTS_REVIEW:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_PRODUCTS_REVIEW_SUCCESS:
      const ProductsReviews = state?.productsReviews?.map((item) =>
        getId(item) === getId(action.payload?.review)
          ? {
              ...action.payload?.review,
              user: item?.user,
              product: item?.product,
            }
          : item
      );

      state = {
        ...state,
        loading: false,
        productsReviews: ProductsReviews,
      };
      break;
    case EDIT_PRODUCTS_REVIEW_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case DELETE_PRODUCTS_REVIEW:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PRODUCTS_REVIEW_SUCCESS:
      const ProductsReviewsAfterDeleteing = [
        ...state?.productsReviews?.filter(
          (group) => getId(group) !== action.payload
        ),
      ];

      state = {
        ...state,
        loading: false,
        productsReviews: ProductsReviewsAfterDeleteing,
      };
      break;
    case DELETE_PRODUCTS_REVIEW_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
