import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getNotifications } from "store/actions";

import arrowIcon from "../../assets/svgs/arrow-left.svg";
import productImg from "../../assets/images/tshirt.png";
import { ReactComponent as NotificationIcon } from "../../assets/svgs/notification.svg";
// Notification Status
// import toCartIcon from "../../assets/images/notifications/1.png";
// import newOrderIcon from "../../assets/images/notifications/2.png";
// import toFavouriteIcon from "../../assets/images/notifications/3.png";
// import newComment from "../../assets/images/notifications/4.png";
// import cancelOrder from "../../assets/images/notifications/5.png";
import TimeInst from "./TimeInst";
import { FormattedMessage } from "react-intl";

const Notifications = () => {
  const dispatch = useDispatch();
  const [realTimeNotifs] = useState([]);

  const { notifications } = useSelector((state) => state.notifications);

  // useEffect(() => {
  //   if (href?.includes("products")) return setRealTimeNotifs([]);

  //   const unsubscribe = onSnapshot(collection(db, "notifications"), (snap) => {
  //     setRealTimeNotifs(snap?.docs?.map((e) => ({ ...e?.data(), id: e?.id })));
  //   });

  //   return () => unsubscribe();
  // }, [href]);

  // const readNotifs = useCallback(
  //   (open) => {
  //     if (open || realTimeNotifs?.filter((e) => !e?.read)?.length === 0) return;

  //     realTimeNotifs
  //       ?.filter((e) => !e?.read)
  //       ?.map(async (notif) => {
  //         await updateDoc(doc(db, "notifications", notif?.id), {
  //           read: true,
  //         });
  //       });
  //   },
  //   [realTimeNotifs]
  // );
  const notifsData = [...realTimeNotifs, ...notifications]?.sort(
    (a, b) =>
      new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime()
  );

  const unreadNotifsLength = notifsData?.filter(
    (notif) => notif?.read === false
  ).length;

  const renderNotifications = notifsData.map((notification, index) => {
    const isTypeOutOfStock = notification?.type === "outOfStock";
    return (
      <Link
        to={
          notification?.product
            ? `/products/${notification?.product}${
                notification?.variation ? "?variation=true" : ""
              }`
            : ""
        }
        key={index}
      >
        <li className="w-100">
          <div className="i-img">
            <img src={notification?.image} alt="" />
          </div>
          <div className="i-data">
            <div>
              <h5>
                {isTypeOutOfStock ? (
                  <FormattedMessage
                    id={
                      notification?.variation
                        ? "productVariationOutOfStock"
                        : "productOutOfStock"
                    }
                  />
                ) : (
                  notification?.title
                )}
              </h5>
              <strong>
                <TimeInst date={new Date(notification?.createdAt)} />
              </strong>
            </div>
            <div className="product-img">
              <img src={productImg} alt="" />
            </div>
          </div>
        </li>
      </Link>
    );
  });

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <div className="noificationWrap">
      <ul>
        <li className="menu-item-has-children">
          <Dropdown
          //  onToggle={readNotifs}
          >
            <Dropdown.Toggle>
              <span>
                {/* <svg
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z"
                    fill="#1B2559"
                  ></path>
                  <path
                    d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z"
                    fill="#1B2559"
                  ></path>
                </svg> */}
                <NotificationIcon fill="" />
                <i className="badgo">{unreadNotifsLength}</i>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="notification-head">
                <span>التنبيهات</span>
              </div>
              <ul>{renderNotifications}</ul>
              <div className="notification-footer">
                <Link to="/notifications">
                  رؤيـة الـمزيد <img src={arrowIcon} alt="" />
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
};

export default Notifications;
