import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import TimeInst from "components/shared/TimeInst";

import location from "../../../src/assets/svgs/location.svg";
import statusIcon from "../../../src/assets/svgs/status.svg";
import dateIcon from "../../../src/assets/svgs/calendar.svg";
import editIcon from "../../../src/assets/svgs/edit.svg";
import { Dropdown } from "react-bootstrap";

const OrdersTable = ({ data, showHead = true, href = "/customers" }) => {
  const fetchOrders = data?.map((order, index) => {
    return (
      <li key={index}>
        <Link to={`${href}/show/${order.id}`}></Link>
        <div className="order-img">
          <img src={order?.user?.image} alt="" />
        </div>
        <div className="order-data">
          <h4>{order?.user?.username}</h4>
          <div>
            <div>
              <span>#{order?.uniqueCode}</span>
              <span>
                <img src={location} alt="" />
                {order?.userAddress?.addressDetails}
              </span>

              <strong className={order?.status}>
                <FormattedMessage id={order?.status || " "} />
              </strong>
            </div>
            <div>
              <i>
                {order?.total} <FormattedMessage id="ryial" />
              </i>
              <span>
                <TimeInst date={new Date(order?.createdAt)} />
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="card">
      {showHead && (
        <div className="card-head">
          <div>
            <h4>جميع الطلبات</h4>
          </div>
        </div>
      )}
      <div className="card-body">
        <div className="orders-list">
          <ul>
            {data?.length > 0 ? (
              fetchOrders
            ) : (
              <div className="no-customers alert alert-warning text-center m-0">
                <p>لا يوجد طلبات حالياً</p>
              </div>
            )}
          </ul>
        </div>
        {data?.length > 4 ? (
          <div className="more-customers">
            <Link to="/customers">المزيد من الطلبات +</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OrdersTable;
