import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

const OutOfStock = ({ title, data }) => {
  const { locale } = useIntl();

  const fetchProducts = data?.slice(0, 6)?.map((product, index) => {
    return (
      <li key={index}>
        <div className="product-img">
          <img src={product?.mainImage} alt="" />
        </div>
        <div className="product-data">
          <Link to={`/products/${product?.id}`}>
            <h4>{product?.name?.[locale]}</h4>
          </Link>
          <p>
            {product?.price} <FormattedMessage id="ryial" />
          </p>
        </div>
      </li>
    );
  });

  return (
    <>
      {data?.length > 0 ? (
        <div className="card">
          <div className="card-head">
            <h4>{title}</h4>
          </div>
          <div className="card-body">
            <div className="products-list">
              <ul>{fetchProducts}</ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OutOfStock;
