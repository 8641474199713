import { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as ModalHeaderIcon } from "assets/svgs/product/modal-header.svg";
import BankAccountsCards from "./BankAccountsCards";

const WithdrawModal = ({ defaultBank, modalShow, setModalShow, settings }) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [selectedBank, setSelectedBank] = useState();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleClose = () => setModalShow();

  const onSubmit = (data) => {
    data.selectedBank = selectedBank;
    console.log("data", data);
  };

  return (
    <Modal
      dialogClassName="productDataModal bankModal storePayment"
      show={modalShow}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <ModalHeaderIcon fill="#FC6B14" />
          <FormattedMessage id="withdrawBalanceToBankAccount" />
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} className="balance-modal">
        <Modal.Body>
          <Col lg={6} xs={12}>
            <div className="form-group required">
              <h5>
                <FormattedMessage id="amount" />
              </h5>
              <div className="selectHolder ">
                <input
                  type="email"
                  className="form-control form-outline"
                  {...register("amount")}
                />
              </div>
            </div>
          </Col>

          <BankAccountsCards
            editDeleteFlag={false}
            setSelectedBank={(data) => {
              setSelectedBank(data);
              // setBankAccountModal(true);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            الغاء
          </button>
          <button className="btn btn-blue">تم</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default WithdrawModal;
