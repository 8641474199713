import server from "./server";

export const postUserLogin = async (data) => {
  const response = await server().post("/auth/login", data);
  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/auth/profile", data);
  return response.data;
};

export const editProfileApi = async ({ data }) => {
  const response = await server().put("/auth/editProfile", data);
  return response.data;
};
