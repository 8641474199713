import {
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_FAILURE,
  GET_SINGLE_ORDER_SUCCESS,
} from "./actionTypes";

export const getOrders = (payload) => {
  return {
    type: GET_ORDERS,
    payload,
  };
};

export const getOrdersSuccess = (orders) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const getOrdersFailure = (error) => {
  return {
    type: GET_ORDERS_FAILURE,
    payload: error,
  };
};

export const getSingleOrder = (id) => {
  return {
    type: GET_SINGLE_ORDER,
    payload: id,
  };
};

export const getSingleOrdersuccess = (order) => {
  return {
    type: GET_SINGLE_ORDER_SUCCESS,
    payload: order,
  };
};

export const getSingleOrderFailure = (error) => {
  return {
    type: GET_SINGLE_ORDER_FAILURE,
    payload: error,
  };
};

export const addOrder = (payload) => {
  return {
    type: ADD_ORDER,
    payload,
  };
};

export const addOrderSuccess = (payload) => {
  return {
    type: ADD_ORDER_SUCCESS,
    payload,
  };
};

export const addOrderFailure = (error) => {
  return {
    type: ADD_ORDER_FAILURE,
    payload: error,
  };
};

export const editOrder = (payload) => {
  return {
    type: EDIT_ORDER,
    payload,
  };
};

export const editOrderSuccess = (payload) => {
  return {
    type: EDIT_ORDER_SUCCESS,
    payload,
  };
};

export const editOrderFailure = (error) => {
  return {
    type: EDIT_ORDER_FAILURE,
    payload: error,
  };
};

export const deleteOrder = (id) => {
  return {
    type: DELETE_ORDER,
    payload: id,
  };
};

export const deleteOrderSuccess = (id) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: id,
  };
};

export const deleteOrderFailure = (error) => {
  return {
    type: DELETE_ORDER_FAILURE,
    payload: error,
  };
};
