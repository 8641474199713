import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import TimeInst from "components/shared/TimeInst";

import location from "../../../src/assets/svgs/location.svg";
// import { useDispatch } from "react-redux";
// import { addOrder } from "store/actions";
// import { getId } from "helpers/functions";

const CustomerOrdersTable = ({ data, showHead = true }) => {
  // const dispatch = useDispatch();

  // ---------------postponed in task 1310-----------------
  // const handleRepeat = (fullOrder) => {
  //   fullOrder.user = getId(fullOrder?.user);
  //   fullOrder.store = getId(fullOrder?.store);
  //   fullOrder.paymentMethod = getId(fullOrder?.paymentMethod);
  //   fullOrder.userAddress = getId(fullOrder?.userAddress);
  //   dispatch(addOrder(fullOrder));
  // };

  const renderOrders = data?.map((order, index) => {
    return (
      <li key={index}>
        {/* <Link to={`/customers/show/${order.id}`}></Link> */}
        {/* <div className="order-img">
          <img src={order?.user?.image} alt="" />
        </div> */}
        <div className="order-data">
          {/* <h4>{order?.user?.username}</h4> */}
          <div>
            <div>
              <span>#{order?.uniqueCode}</span>
              <span>
                <img src={location} alt="" />
                {order?.userAddress?.addressDetails}
              </span>

              <strong className={order?.status}>
                <FormattedMessage id={order?.status || " "} />
              </strong>
            </div>
            <div>
              <i>
                {order?.total} <FormattedMessage id="ryial" />
              </i>
              <span>
                <TimeInst date={new Date(order?.createdAt)} />
              </span>
            </div>
            {/* ---------------postponed in task 1310----------------- */}
            {/* <div>
              <button
                className="btn btn-blue"
                onClick={() => handleRepeat(order)}
              >
                <FormattedMessage id="repeat" />
              </button>
            </div> */}
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="card">
      <div className="card-body">
        <div className="orders-list">
          <ul>
            {data?.length > 0 ? (
              renderOrders
            ) : (
              <div className="no-customers alert alert-warning text-center m-0">
                <p>لا يوجد طلبات حالياً</p>
              </div>
            )}
          </ul>
        </div>
        {data?.length > 4 ? (
          <div className="more-customers">
            <Link to="/customers">المزيد من الطلبات +</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerOrdersTable;
