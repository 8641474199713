import { GET_UNITS, GET_UNITS_SUCCESS, GET_UNITS_FAILURE } from "./actionTypes";

export const getUnits = () => {
  return {
    type: GET_UNITS,
  };
};

export const getUnitsSuccess = (units) => {
  return {
    type: GET_UNITS_SUCCESS,
    payload: units,
  };
};

export const getUnitsFailure = (error) => {
  return {
    type: GET_UNITS_FAILURE,
    payload: error,
  };
};
