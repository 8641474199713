import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting, getProductCategories } from "store/actions";
import Loader from "components/shared/Loader";
import SelectMultiple from "react-select";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getId } from "helpers/functions";
// import { registerVersion } from "firebase/app";
import { ReactComponent as CubesIcon } from "assets/svgs/cubes.svg";
import { ReactComponent as SubCubesIcon } from "assets/svgs/sub-cubes.svg";

const StoreActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, settings } = useSelector((state) => state?.settings);
  const { flatCategories } =
    useSelector((state) => state?.productCategories) || {};
  const { locale } = useIntl();

  const { handleSubmit, reset, control, register, watch } = useForm();

  useEffect(() => {
    if (!flatCategories?.length > 0) dispatch(getProductCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    reset({
      storeActivities: settings?.storeActivities,
      // getOptionsFrom(
      //   flatCategories?.filter((e) =>
      //     settings?.storeActivities?.includes(e?.id)
      //   )
      // ) || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, settings]);

  const getOptionsFrom = (data) => {
    return (
      data?.map((c) => ({
        label: c?.name?.[locale],
        value: getId(c),
      })) || []
    );
  };

  const subCats = flatCategories?.filter((item) =>
    (watch("storeActivities") || [])?.includes(item?.parentCategory)
  );

  const onSubmitActivity = (data) => {
    // data.storeActivities = data?.storeActivities?.map((e) => e?.value);
    dispatch(
      editStoreSlugSetting({
        data,
        slug: "basicSettings/updateStoreActivity",
        navigate,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="acc-form store-activity">
      <Row>
        <div className="card-head">
          <h4>النشاط التجاري</h4>
        </div>
      </Row>
      <Row>
        <form onSubmit={handleSubmit(onSubmitActivity)}>
          <Col xl={10} md={12}>
            <div className="">
              <Row>
                <Col lg={12} xs={12}>
                  <div className="form-group">
                    <div>
                      <h5 className="fs-6 heading">
                        <CubesIcon />
                        التصنيفات الاساسية
                      </h5>
                      <div className="activities">
                        {flatCategories
                          ?.filter((item) => !item?.parentCategory)
                          ?.map((item, index) => (
                            <label key={index} className="activity">
                              <input
                                type="checkbox"
                                value={getId(item)}
                                {...register("storeActivities")}
                              />
                              <span>{item?.name?.[locale]}</span>
                            </label>
                          ))}
                      </div>
                    </div>
                    {subCats?.length > 0 && (
                      <div className="mt-4">
                        <h5 className={`fs-6 heading`}>
                          <SubCubesIcon />
                          التصنيفات الفرعية
                        </h5>

                        <div className="activities">
                          {subCats?.map((item, index) => (
                            <label key={index} className="activity">
                              <input
                                type="checkbox"
                                value={getId(item)}
                                {...register("storeActivities")}
                              />
                              <span>{item?.name?.[locale]}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <Col lg={4} xs={12}>
              <div className="form-group my-3">
                <button type="submit" className="btn btn-blue">
                  حفظ التعديلات
                </button>
              </div>
            </Col>
          </Col>
        </form>
      </Row>
    </div>
  );
};

export default StoreActivity;
