import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
import { useMemo } from "react";
import MultiImageBtnComponent from "./MultiImageBtnComponent";
import ColorsList from "./ColorsList";
import VariationChild from "./VariationChild";

function ProductVariationsChildren({
  variation,
  //   register,
  control,
  index: variationIndex,
  handleRemove,
  forceRerender,
  setValue,
}) {
  const [optionText, setOptionText] = useState("");
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);

  const formName = `variationsOptions[${variationIndex}].options`;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: formName,
  });
  const renderFields = useMemo(
    () =>
      fields.map((item, index) => {
        return (
          <VariationChild
            variation={variation}
            variationIndex={variationIndex}
            item={item}
            index={index}
            remove={remove}
            forceRerender={forceRerender}
            setValue={setValue}
            formName={formName}
            update={update}
            key={index}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, variation]
  );

  const addField = () => {
    if (
      (variation?.value === "color" && selectedOption?.value) ||
      (variation?.value !== "color" && optionText?.length > 0) // ----non-multi lang feature
    ) {
      let option = {
        label: selectedOption?.label,
        value: optionText || selectedOption?.value,
      };

      if (selectedImage?.length > 0)
        option.extraData = { ...option.extraData, images: selectedImage };
      if (selectedOption?.value?.length > 0)
        option.extraData = {
          ...option?.extraData,
          text: selectedOption?.value,
        };
      append(option);

      setOptionText(""); // ----non-multi lang feature

      setSelectedImage([]);
      setSelectedOption({});
      forceRerender();
    }
  };
  return (
    <Row className="variation-child">
      <Col xs="11" lg="4">
        <div className="d-flex align-items-center gap-2">
          <label htmlFor="">{variation?.label} </label>
          <div className="add-option">
            <input
              type="text"
              className="form-control w-50"
              value={optionText || selectedOption?.value || ""}
              placeholder="اكتب نص العرض"
              onChange={({ target: { value: text } }) => {
                setOptionText(text);
                forceRerender();
              }}
            />

            {variationIndex === 0 && (
              <MultiImageBtnComponent
                selectedImage={selectedImage}
                setSelectedImage={(arr) => {
                  setSelectedImage(arr);
                }}
              />
            )}
            {variation?.value === "color" && (
              <ColorsList
                variation={variation}
                selectedOption={selectedOption}
                onChange={(option) =>
                  setSelectedOption({ ...selectedOption, ...option })
                }
              />
            )}
          </div>
          <button
            type="button"
            className="btn btn-blue w-auto"
            onClick={addField}
          >
            <PlusIcon width="17" fill="#fff" />
          </button>
        </div>
      </Col>

      <Col>
        <div className="variations-option">
          <div className="variations-wrapper">{renderFields}</div>
        </div>
      </Col>
    </Row>
  );
}
export default ProductVariationsChildren;
