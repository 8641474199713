import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Map from "./Map";

let searchTimeout = null;

export default function RuleTypeInputs({ register, control, index, watch }) {
  const { locale, formatMessage } = useIntl();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { flatCategories } = useSelector((state) => state.productCategories);
  const { groups } = useSelector((state) => state.groups);

  const { countries, loading: loadingCustomers } = useSelector(
    (state) => state.customers
  );

  const rulesTypes = ["lessThan", "greaterThan", "equal"].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));
  const rulesTypesIncluded = ["included", "excluded"].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));

  const getOptionsByName = (arr) =>
    arr?.map((item) => ({
      label: item.name?.[locale] || item,
      value: getId(item),
    }));

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    if (searchTimeout !== null) clearInterval(searchTimeout);
    searchTimeout = setTimeout(() => {
      navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
    }, 400);
  };

  const filteredSelectedItems = (txt) =>
    flatCategories?.filter(
      (item) =>
        JSON.stringify({ id: getId(item), name: item?.name })
          .toLowerCase()
          .indexOf(txt.toLowerCase()) !== -1
    );

  const ruleType = watch(`rules.${index}.type`);

  if (ruleType === "cartTotal" || ruleType === "productsQuantity")
    return (
      <>
        <Form.Group className="mb-3 col-12">
          <Controller
            control={control}
            name={`rules.${index}.condition`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={rulesTypes}
                placeholder={formatMessage({
                  id: "conditionType",
                })}
                isMulti={false}
                value={rulesTypes?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group>
        <Form.Group className={`mb-3 col-12`}>
          <Form.Control
            type="text"
            placeholder={formatMessage({ id: "conditionValue" })}
            {...register(`rules.${index}.value`, {
              required: true,
            })}
          />
        </Form.Group>
      </>
    );

  if (ruleType === "categoryProducts")
    return (
      <>
        <Form.Group className="mb-3 col-12">
          <Controller
            control={control}
            name={`rules.${index}.condition`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={rulesTypesIncluded}
                placeholder={formatMessage({
                  id: "conditionType",
                })}
                isMulti={false}
                value={rulesTypesIncluded?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group>
        <Form.Group className={`mb-3 col-12`}>
          <Form.Label>
            <FormattedMessage id="conditionValue" />
          </Form.Label>
          <Controller
            control={control}
            name={`rules.${index}.value`}
            rules={{ required: true }}
            render={({ field: { onChange, value: value1, selected } }) => (
              <Select
                isMulti={true}
                isRtl={true}
                isSearchable={true}
                className="basic-single w-100 form-control"
                classNamePrefix="select"
                options={getOptionsByName(flatCategories)}
                placeholder={formatMessage({
                  id: "selectProductCategory",
                })}
                onChange={(selected) => {
                  onChange(selected.map((selected) => selected?.value));
                }}
                value={getOptionsByName(
                  flatCategories?.filter((s) => value1?.includes(getId(s)))
                )}
                onInputChange={(value) => {
                  if (filteredSelectedItems(value)?.length <= 0)
                    updateParams("nameText", value);
                }}
              />
            )}
          />
        </Form.Group>
      </>
    );

  if (
    ruleType === "countryCode" ||
    ruleType === "countryIp" ||
    ruleType === "cardCountry"
  )
    return (
      <>
        <Form.Group className="mb-3 col-12">
          <Controller
            control={control}
            name={`rules.${index}.condition`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={rulesTypesIncluded}
                placeholder={formatMessage({
                  id: "conditionType",
                })}
                isMulti={false}
                value={rulesTypesIncluded?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-md-12">
          <Controller
            control={control}
            name={`rules.${index}.value`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={getOptionsByName(countries)}
                placeholder={formatMessage({ id: "selectCountry" })}
                isMulti={true}
                value={getOptionsByName(
                  countries?.filter((s) => value?.includes(getId(s)))
                )}
                onChange={(selected) => {
                  onChange(selected.map((selected) => selected?.value));
                }}
              />
            )}
          />
        </Form.Group>
      </>
    );

  if (ruleType === "customerGroup")
    return (
      <>
        {/* <Form.Group className="mb-3 col-12">
          <Controller
            control={control}
            name={`rules.${index}.condition`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={rulesTypesIncluded}
                placeholder={formatMessage({
                  id: "conditionType",
                })}
                isMulti={false}
                value={rulesTypesIncluded?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group> */}
        <Form.Group className="mb-3 col-12">
          <Form.Label>
            <FormattedMessage id="group" />
          </Form.Label>
          <Controller
            control={control}
            name={`rules.${index}.value`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={getOptionsByName(groups)}
                placeholder={formatMessage({ id: "group" })}
                isMulti={false}
                value={getOptionsByName(groups)?.find(
                  (o) => o?.value === value
                )}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group>
      </>
    );
}
