import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addBlogCategoryApi,
  deleteBlogCategoryApi,
  editBlogCategoryApi,
  getBlogCategoriesApi,
  getSingleBlogCategoryApi,
} from "api/blogCategories";

// Redux States
import {
  addBlogCategoryFailure,
  addBlogCategorySuccess,
  deleteBlogCategoryFailure,
  deleteBlogCategorySuccess,
  editBlogCategoryFailure,
  editBlogCategorySuccess,
  getBlogCategoriesFailure,
  getBlogCategoriesSuccess,
  getSingleBlogCategoryFailure,
  getSingleBlogCategorySuccess,
} from "./actions";
import {
  ADD_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  EDIT_BLOG_CATEGORY,
  GET_BLOG_CATEGORIES,
  GET_SINGLE_BLOG_CATEGORY,
} from "./actionTypes";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getBlogCategories() {
  try {
    const { data } = yield call(getBlogCategoriesApi);
    yield put(getBlogCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(
      getBlogCategoriesFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* addBlogCategory({ payload }) {
  try {
    const { data } = yield call(addBlogCategoryApi, payload.data);
    yield put(addBlogCategorySuccess(data));
    yield payload.navigate("/blogs/categories");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(addBlogCategoryFailure(error.response?.data?.message || error));
  }
}

function* getSingleBlogCategory({ payload }) {
  try {
    const { data } = yield call(getSingleBlogCategoryApi, payload);
    yield put(getSingleBlogCategorySuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getSingleBlogCategoryFailure(error?.response?.data || error));
  }
}

function* editBlogCategory({ payload }) {
  try {
    yield call(editBlogCategoryApi, payload);
    yield put(editBlogCategorySuccess(payload));
    yield payload.navigate("/blogs/categories");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(editBlogCategoryFailure(error?.response?.data || error));
  }
}

function* deleteBlogCategory({ payload }) {
  try {
    yield call(deleteBlogCategoryApi, payload);
    yield put(deleteBlogCategorySuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(deleteBlogCategoryFailure(error?.response?.data || error));
  }
}

export function* watchGetBlogCategories() {
  yield takeEvery(GET_BLOG_CATEGORIES, getBlogCategories);
}

export function* watchAddBlogCategory() {
  yield takeEvery(ADD_BLOG_CATEGORY, addBlogCategory);
}

export function* watchGetSingleBlogCategory() {
  yield takeEvery(GET_SINGLE_BLOG_CATEGORY, getSingleBlogCategory);
}

export function* watchEditBlogCategory() {
  yield takeEvery(EDIT_BLOG_CATEGORY, editBlogCategory);
}

export function* watchDeleteBlogCategory() {
  yield takeEvery(DELETE_BLOG_CATEGORY, deleteBlogCategory);
}

function* blogCategoriesSaga() {
  yield all([fork(watchGetBlogCategories)]);
  yield all([fork(watchAddBlogCategory)]);
  yield all([fork(watchGetSingleBlogCategory)]);
  yield all([fork(watchEditBlogCategory)]);
  yield all([fork(watchDeleteBlogCategory)]);
}

export default blogCategoriesSaga;
