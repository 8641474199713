import {
  GET_PRODUCTS_REVIEWS,
  GET_PRODUCTS_REVIEWS_FAILURE,
  GET_PRODUCTS_REVIEWS_SUCCESS,
  ADD_PRODUCTS_REVIEW,
  ADD_PRODUCTS_REVIEW_SUCCESS,
  ADD_PRODUCTS_REVIEW_FAILURE,
  EDIT_PRODUCTS_REVIEW,
  EDIT_PRODUCTS_REVIEW_SUCCESS,
  EDIT_PRODUCTS_REVIEW_FAILURE,
  DELETE_PRODUCTS_REVIEW,
  DELETE_PRODUCTS_REVIEW_SUCCESS,
  DELETE_PRODUCTS_REVIEW_FAILURE,
  GET_SINGLE_PRODUCTS_REVIEW,
  GET_SINGLE_PRODUCTS_REVIEW_FAILURE,
  GET_SINGLE_PRODUCTS_REVIEW_SUCCESS,
  CHANGE_PRODUCTS_REVIEW_STATUS,
  REPLY_PRODUCTS_REVIEW,
} from "./actionTypes";

export const getProductsReviews = (payload) => {
  return {
    type: GET_PRODUCTS_REVIEWS,
    payload,
  };
};

export const getProductsReviewsSuccess = (orders) => {
  return {
    type: GET_PRODUCTS_REVIEWS_SUCCESS,
    payload: orders,
  };
};

export const getProductsReviewsFailure = (error) => {
  return {
    type: GET_PRODUCTS_REVIEWS_FAILURE,
    payload: error,
  };
};

export const getSingleProductsReview = (id) => {
  return {
    type: GET_SINGLE_PRODUCTS_REVIEW,
    payload: id,
  };
};

export const getSingleProductsReviewsuccess = (order) => {
  return {
    type: GET_SINGLE_PRODUCTS_REVIEW_SUCCESS,
    payload: order,
  };
};

export const getSingleProductsReviewFailure = (error) => {
  return {
    type: GET_SINGLE_PRODUCTS_REVIEW_FAILURE,
    payload: error,
  };
};

export const addProductsReview = (payload) => {
  return {
    type: ADD_PRODUCTS_REVIEW,
    payload,
  };
};

export const addProductsReviewSuccess = (payload) => {
  return {
    type: ADD_PRODUCTS_REVIEW_SUCCESS,
    payload,
  };
};

export const addProductsReviewFailure = (error) => {
  return {
    type: ADD_PRODUCTS_REVIEW_FAILURE,
    payload: error,
  };
};

export const editProductsReview = (payload) => {
  return {
    type: EDIT_PRODUCTS_REVIEW,
    payload,
  };
};

export const replyProductsReview = (payload) => {
  return {
    type: REPLY_PRODUCTS_REVIEW,
    payload,
  };
};

export const changeProductsReviewStatus = (payload) => {
  return {
    type: CHANGE_PRODUCTS_REVIEW_STATUS,
    payload,
  };
};

export const editProductsReviewSuccess = (payload) => {
  return {
    type: EDIT_PRODUCTS_REVIEW_SUCCESS,
    payload,
  };
};

export const editProductsReviewFailure = (error) => {
  return {
    type: EDIT_PRODUCTS_REVIEW_FAILURE,
    payload: error,
  };
};

export const deleteProductsReview = (id) => {
  return {
    type: DELETE_PRODUCTS_REVIEW,
    payload: id,
  };
};

export const deleteProductsReviewSuccess = (id) => {
  return {
    type: DELETE_PRODUCTS_REVIEW_SUCCESS,
    payload: id,
  };
};

export const deleteProductsReviewFailure = (error) => {
  return {
    type: DELETE_PRODUCTS_REVIEW_FAILURE,
    payload: error,
  };
};
