import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getDashboardHomeApi } from "api/dashboard";

// Login Redux States
import { GET_DASHBOARD_HOME } from "./actionTypes";
import { getDashboardHomeFailure, getDashboardHomeSuccess } from "./actions";

function* getDashboardHome() {
  try {
    const { data } = yield call(getDashboardHomeApi);
    yield put(getDashboardHomeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getDashboardHomeFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetDashboardHome() {
  yield takeEvery(GET_DASHBOARD_HOME, getDashboardHome);
}

function* dashboardHomeSaga() {
  yield all([fork(watchGetDashboardHome)]);
}

export default dashboardHomeSaga;
