import { useRef } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";
import { useIntl } from "react-intl";
import { ReactComponent as Drag } from "assets/svgs/drag.svg";
import { ReactComponent as AngleDown } from "assets/svgs/angle-down.svg";
import { ReactComponent as ThreeDots } from "assets/svgs/three-dots.svg";
import { getId, handleImage } from "helpers/functions";
import { useDispatch } from "react-redux";
import {
  editProductCategories,
  editProductCategoriesOrder,
} from "store/actions";

export default function DndElement({
  category,
  brothersCategories,
  children,
  parentSlugPath,

  index,
  catsLength,
  // deepUpdateSubCat,
  parentIndex,
}) {
  const dispatch = useDispatch();
  const dragElement = useRef(null);
  const { locale } = useIntl();

  const [{ isDragging }, connectDragSource, preview] = useDrag({
    type: "element",
    item: {
      ...category,
      id: getId(category),
      index,
      parentSlugPath,
      parentIndex,
      order: category?.order || index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver, canDrop }, connectDropTarget] = useDrop({
    accept: "element",
    drop: (source, monitor) => {
      if (!canDrop) {
        return;
      }

      if (monitor.didDrop()) {
        console.error("monitor.didDrop()");
        return;
      }

      // return deepUpdateSubCat(source, category?.slug);
      return editCategory({
        id: getId(source),
        data: {
          // ...source,
          icon: source?.icon,
          image: source?.image,
          name: source?.name,
          seo: source?.seo,
          parentCategory: getId(category),
        },
      });
    },
    canDrop: (source, monitor) => {
      let check =
        !category?.subcategories?.find((cat) => getId(cat) === getId(source)) &&
        getId(source) !== getId(category);

      return check;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const outline = isOver ? "2px solid rgba(186, 186, 186, 0.679)" : "none";
  const opacity = isDragging ? 0 : 1;
  attachRef();
  function attachRef() {
    connectDragSource(dragElement);
  }

  const editCategory = (data) => {
    dispatch(editProductCategories(data));
  };

  return (
    <div
      ref={(card) => {
        preview(card);
        connectDropTarget(card);
      }}
      className="category"
      style={{ opacity, outline }}
    >
      <Categorychild
        category={category}
        brothersCategories={brothersCategories}
        dragProps={{ ref: dragElement }}
        locale={locale}
        parentSlugPath={parentSlugPath}
        index={index}
        editCategory={editCategory}
        catsLength={catsLength}
      />

      {children}
    </div>
  );
}

export const Categorychild = ({
  category,
  brothersCategories,
  dragProps = null,
  locale,
  parentSlugPath = "",
  index,
  editCategory,
  catsLength,
}) => {
  const dispatch = useDispatch();

  const array_move = (old_index, new_index) => {
    let newArr = [...brothersCategories];

    var f = { ...newArr.splice(old_index, 1)[0] };
    newArr.splice(new_index, 0, f);

    newArr = newArr?.map((e, index) => ({
      parentCategory: e?.parentCategory,
      order: index,
      id: getId(e),
    }));

    editCategories(newArr);

    // return arr; // for testing
  };

  const editCategories = (data) => {
    dispatch(editProductCategoriesOrder({ categories: data }));
  };

  return (
    <div>
      <Col xs="8" lg="5" md="7">
        <div className="first-section">
          <div {...dragProps} className="drag cursor-pointer d-inline-block">
            <Drag width="24" />
          </div>
          <div
            className="order d-flex flex-column"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => array_move(index, index - 1)}
              disabled={index === 0}
            >
              &uarr;
            </button>

            <button
              onClick={() => array_move(index, index + 1)}
              disabled={index === catsLength - 1}
            >
              &darr;
            </button>
          </div>

          {category?.image && (
            <img
              alt=""
              src={handleImage(category?.image)}
              className="store-logo"
            />
          )}
          {category?.subcategories?.length > 0 && (
            <label
              htmlFor={parentSlugPath + category?.slug + "check"}
              className={`cursor-pointer ${
                category?.collapsed ? "rotate-180" : ""
              }`}
            >
              <AngleDown />
            </label>
          )}
          <h5 className="">{category?.name?.[locale]}</h5>
        </div>
      </Col>
      <Col className="drop-menu col-2">
        <Row className="align-items-center">
          <Col>
            <div className="d-inline">
              <Dropdown.Toggle>
                <ThreeDots width="18" />
              </Dropdown.Toggle>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
