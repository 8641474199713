export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";

export const GET_SINGLE_BRAND = "GET_SINGLE_BRAND";
export const GET_SINGLE_BRAND_SUCCESS = "GET_SINGLE_BRAND_SUCCESS";
export const GET_SINGLE_BRAND_FAILURE = "GET_SINGLE_BRAND_FAILURE";

export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";

export const EDIT_BRAND = "EDIT_BRAND";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_FAILURE = "EDIT_BRAND_FAILURE";

export const ORDER_BRAND = "ORDER_BRAND";
export const ORDER_BRAND_SUCCESS = "ORDER_BRAND_SUCCESS";
export const ORDER_BRAND_FAILURE = "ORDER_BRAND_FAILURE";

export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

export const ADD_EMPTY_BRAND = "ADD_EMPTY_BRAND";
export const ADD_EMPTY_BRAND_SUCCESS = "ADD_EMPTY_BRAND_SUCCESS";
export const ADD_EMPTY_BRAND_FAILURE = "ADD_EMPTY_BRAND_FAILURE";

export const DELETE_EMPTY_BRAND = "DELETE_EMPTY_BRAND";
export const DELETE_EMPTY_BRAND_SUCCESS = "DELETE_EMPTY_BRAND_SUCCESS";
export const DELETE_EMPTY_BRAND_FAILURE = "DELETE_EMPTY_BRAND_FAILURE";

export const GET_BRANDS_SETTING = "GET_BRANDS_SETTING";
export const GET_BRANDS_SETTING_SUCCESS = "GET_BRANDS_SETTING_SUCCESS";
export const GET_BRANDS_SETTING_FAILURE = "GET_BRANDS_SETTING_FAILURE";

export const EDIT_BRANDS_SETTING = "EDIT_BRANDS_SETTING";
export const EDIT_BRANDS_SETTING_SUCCESS = "EDIT_BRANDS_SETTING_SUCCESS";
export const EDIT_BRANDS_SETTING_FAILURE = "EDIT_BRANDS_SETTING_FAILURE";

export const DELETE_MULTIPLE_BRANDS = "DELETE_MULTIPLE_BRANDS";
export const DELETE_MULTIPLE_BRANDS_SUCCESS = "DELETE_MULTIPLE_BRANDS_SUCCESS";
export const DELETE_MULTIPLE_BRANDS_FAILURE = "DELETE_MULTIPLE_BRANDS_FAILURE";
