export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES";
export const GET_BLOG_CATEGORIES_SUCCESS = "GET_BLOG_CATEGORIES_SUCCESS";
export const GET_BLOG_CATEGORIES_FAILURE = "GET_BLOG_CATEGORIES_FAILURE";

export const GET_SINGLE_BLOG_CATEGORY = "GET_SINGLE_BLOG_CATEGORY";
export const GET_SINGLE_BLOG_CATEGORY_SUCCESS =
  "GET_SINGLE_BLOG_CATEGORY_SUCCESS";
export const GET_SINGLE_BLOG_CATEGORY_FAILURE =
  "GET_SINGLE_BLOG_CATEGORY_FAILURE";

export const ADD_BLOG_CATEGORY = "ADD_BLOG_CATEGORY";
export const ADD_BLOG_CATEGORY_SUCCESS = "ADD_BLOG_CATEGORY_SUCCESS";
export const ADD_BLOG_CATEGORY_FAILURE = "ADD_BLOG_CATEGORY_FAILURE";

export const EDIT_BLOG_CATEGORY = "EDIT_BLOG_CATEGORY";
export const EDIT_BLOG_CATEGORY_SUCCESS = "EDIT_BLOG_CATEGORY_SUCCESS";
export const EDIT_BLOG_CATEGORY_FAILURE = "EDIT_BLOG_CATEGORY_FAILURE";

export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";
export const DELETE_BLOG_CATEGORY_FAILURE = "DELETE_BLOG_CATEGORY_FAILURE";
