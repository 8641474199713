import {
  GET_VISITS_REPORT,
  GET_VISITS_REPORT_SUCCESS,
  GET_VISITS_REPORT_FAILURE,
} from "./actionTypes";

export const getVisitsReport = () => {
  return {
    type: GET_VISITS_REPORT,
  };
};

export const getVisitsReportSuccess = (payload) => {
  return {
    type: GET_VISITS_REPORT_SUCCESS,
    payload,
  };
};

export const getVisitsReportFailure = (error) => {
  return {
    type: GET_VISITS_REPORT_FAILURE,
    payload: error,
  };
};
