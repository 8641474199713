import { Col, Modal, Row, Table } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as Dollar } from "assets/svgs/product/dollar.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";

const RecordsModal = ({ data, modalShow }) => {
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();

  const [params] = useSearchParams();

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };
  const handleClose = () => removeParams("modal");

  const renderRows = data?.map((item, index) => (
    <tr key={index}>
      <td>{getId(item)}</td>
      <td>{item?.wallet}</td>
      <td>{item?.type}</td>
      <td>{item?.details}</td>
      <td>{item?.amount}</td>
      <td>{item?.date}</td>
    </tr>
  ));

  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal storePayment"
        show={modalShow}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Dollar fill="#FC6B14" />
            <FormattedMessage id="recordsTable" /> -{" "}
            <FormattedMessage id={data?.title || "storeBalance"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrapper records">
            <Table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="transactionId" />
                  </th>
                  <th>
                    <FormattedMessage id="wallet" />
                  </th>
                  <th>
                    <FormattedMessage id="transactionType" />
                  </th>
                  <th>
                    <FormattedMessage id="transactionDetails" />
                  </th>
                  <th>
                    <FormattedMessage id="amount" />
                  </th>
                  <th>
                    <FormattedMessage id="transactionDate" />
                  </th>
                </tr>
              </thead>
              <tbody>{renderRows}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            <FormattedMessage id="close" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RecordsModal;
