import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import GroupCard from "./GroupCard";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import BootyPagination from "components/shared/Pagination";
import { getGroupRules } from "store/groups/actions";
import GroupModal from "./GroupModal";

const GroupsList = ({ listView }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { groups, metadata } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(getGroupRules());
  }, [dispatch]);

  const renderGroups = groups
    ?.map((group, index) => {
      return (
        <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={3} key={index}>
          <GroupCard group={group} params={params} />
        </Col>
      );
    })
    .concat(
      <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={3} key={groups?.length}>
        <GroupCard group={{ id: "new" }} params={params} />
      </Col>
    );

  return (
    <div className={listView ? "productsList listView" : "productsList"}>
      <Row>
        {groups?.length > 0 ? (
          renderGroups
        ) : (
          <div className="no-data alert alert-warning text-center my-4">
            <p>
              <FormattedMessage id="noDataFound" />
            </p>
          </div>
        )}
      </Row>
      {params?.has("group") && <GroupModal params={params} />}
      <BootyPagination metaData={metadata} />
    </div>
  );
};

export default GroupsList;
