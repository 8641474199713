import { Input, Textarea } from "components/shared/FormComponents";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import ImageUpload from "components/shared/ImageUpload";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";

const StoreInfo = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageFavIcon, setSelectedImageFavIcon] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register: registerInfo,
    handleSubmit: handleSubmitInfo,
    reset: resetInfo,
    formState: { errors },
  } = useForm();
  const { loading } = useSelector((state) => state?.settings);
  useEffect(() => {
    resetInfo({
      name: data?.name,
      description: data?.description,
      address: data?.address,
      phone: data?.phone,
      logo: data?.logo,
      favIcon: data?.favIcon,
    });
    if (data?.logo && data?.logo?.length > 0)
      setSelectedImage({
        path: data?.logo,
      });
    if (data?.favIcon && data?.favIcon?.length > 0)
      setSelectedImageFavIcon({
        path: data?.favIcon,
      });
  }, [resetInfo, data]);

  const onSubmitInfo = (data) => {
    if (selectedImage && selectedImage.path) data["logo"] = selectedImage?.path;
    if (selectedImageFavIcon && selectedImageFavIcon.path)
      data["favIcon"] = selectedImageFavIcon?.path;

    dispatch(
      editStoreSlugSetting({ data, slug: "basicSettings/storeInfo", navigate })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="acc-form">
      <div className="card-head">
        <h4>بيانات المتجر</h4>
      </div>
      <form onSubmit={handleSubmitInfo(onSubmitInfo)}>
        <Col xl={10} md={12}>
          <div className="d-flex justify-content-center gap-5">
            <div className="form-group required align-items-end d-flex flex-column">
              <h5>صورة المتجر</h5>
              <div className="image-uploader image-contain">
                <ImageUpload
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
                <p className="error-hint">
                  {errors.mainImage?.type === "required" &&
                    "يرجي اختيار صورة المتجر"}
                </p>
              </div>
            </div>
            <div className="form-group required">
              <h5> ايقونة المتجر</h5>
              <div className="image-uploader image-contain">
                <ImageUpload
                  selectedImage={selectedImageFavIcon}
                  setSelectedImage={setSelectedImageFavIcon}
                />
                <p className="error-hint">
                  {errors.favIcon?.type === "required" &&
                    " FavIcon يرجي اختيار صورة المتجر"}
                </p>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={6} xs={12}>
              <MultiLangWrapper
                errors={errors?.name}
                label={<label>إسم المتجر</label>}
              >
                {({ lang, classes, key }) => (
                  <Input
                    key={key}
                    className={classes}
                    name={`name.${lang}`}
                    register={registerInfo}
                    errors={errors}
                    rules={{ required: true }}
                  />
                )}
                <span className="error-hint">
                  {!!errors?.name && "هذا الحقل مطلوب"}
                </span>
              </MultiLangWrapper>
            </Col>
            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>الهاتف</h5>
                <Input name="phone" register={registerInfo} errors={errors} />
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <MultiLangWrapper
                errors={errors?.address}
                label={
                  <div className="form-group required">
                    <h5>العنوان</h5>
                  </div>
                }
                customClass="form-group"
              >
                {({ lang, classes, key }) => (
                  <Textarea
                    key={key}
                    className={`form-control form-outline ${classes}`}
                    name={`address.${lang}`}
                    register={registerInfo}
                    errors={errors}
                  />
                )}
              </MultiLangWrapper>
            </Col>

            <Col lg={6} xs={12}>
              <MultiLangWrapper
                errors={errors?.description}
                label={
                  <div className="form-group required">
                    <h5>الوصف</h5>
                  </div>
                }
                customClass="form-group"
              >
                {({ lang, classes, key }) => (
                  <Textarea
                    key={key}
                    className={`form-control form-outline ${classes}`}
                    name={`description.${lang}`}
                    register={registerInfo}
                    errors={errors}
                  />
                )}
              </MultiLangWrapper>
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={12}>
              <div className="form-group">
                <button type="submit" className="btn btn-blue">
                  حفظ التعديلات
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </div>
  );
};

export default StoreInfo;
