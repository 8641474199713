export function Input({
  className,
  register,
  name = "",
  errors,
  rules = { required: "هذا الحقل مطلوب" },
  ...rest
}) {
  const nameWithoutLocale = name.split(".").at(0);
  const locale = name.split(".").length >= 2 ? name.split(".").at(-1) : "";

  return (
    <>
      <div>
        <input
          type="text"
          className={"form-control form-outline " + className}
          {...register(name, { ...rules })}
          {...rest}
        />
        {rest?.icon}
      </div>
      <p className="error-hint text-danger">
        {locale !== ""
          ? errors?.[nameWithoutLocale]?.[locale] &&
            errors?.[nameWithoutLocale]?.[locale]?.message
          : errors?.[nameWithoutLocale] && errors?.[nameWithoutLocale]?.message}
      </p>
    </>
  );
}

export function Textarea({
  register,
  name,
  errors,
  rules = { required: "هذا الحقل مطلوب" },
  ...rest
}) {
  const nameWithoutLocale = name.split(".").at(0);
  const locale = name.split(".").length >= 2 ? name.split(".").at(-1) : "";

  return (
    <>
      <div>
        <textarea
          type="text"
          className="form-control form-outline"
          {...register(name, { ...rules })}
          {...rest}
        ></textarea>
      </div>
      <p className="error-hint text-danger">
        {locale !== ""
          ? errors?.[nameWithoutLocale]?.[locale] &&
            errors?.[nameWithoutLocale]?.[locale]?.message
          : errors?.[nameWithoutLocale] && errors?.[nameWithoutLocale]?.message}
      </p>
    </>
  );
}
