import { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { deleteEmptyProduct } from "store/actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Autoplay } from "swiper/modules";
import "swiper/css";
import NotificationModal from "./NotificaionModal";

import ImagesModal from "./ImagesModal";

import { ReactComponent as ProductTitleIcon } from "assets/svgs/product/title.svg";
import { ReactComponent as ProductPriceIcon } from "assets/svgs/product/price.svg";
import { ReactComponent as ProductQtyIcon } from "assets/svgs/product/quantity.svg";
import { ReactComponent as ProductSettingIcon } from "assets/svgs/product/settings.svg";
import { ReactComponent as ProductMoreIcon } from "assets/svgs/product/more.svg";
// import { ReactComponent as EyeSlashIcon } from "assets/svgs/product/eye-slash.svg";
import { ReactComponent as ProductCategoryIcon } from "assets/svgs/product/category.svg";
// import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
// import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import { ReactComponent as RemoveIcon } from "assets/svgs/x.svg";
import { ReactComponent as Drag } from "assets/svgs/drag.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleImage } from "helpers/functions";

const ProductCard = ({
  preview,
  drag,
  product,
  index,
  isCheck,
  handleClick,
  duplicateProduct,
  // handleHighOrderDrop = () => {},
}) => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { reset } = useForm({
    mode: "onBlur",
  });

  const swiperRef = useRef(null);

  const [modalType, setModalType] = useState("");
  const [minQuantityToNotify, setMinQuantityToNotify] = useState("");
  const [reorderQuantity, setReorderQuantity] = useState("");
  const [isUnlimitedQuantity, setIsUnlimitedQuantity] = useState(false);
  const [images, setImages] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState();

  // Setting Modals Data On Start
  useEffect(() => {
    if (product?.isNew === true) handleShow("settingModal", product?.id);
  }, [reset, isUnlimitedQuantity, product]);

  const handleShow = (modalType) => {
    if (modalType === "notifyModal") {
      setModalType("notifyModal");
    } else if (modalType === "settingModal") {
      setModalType("settingModal");
    } else {
      setModalType("imagesModal");
    }
  };

  const handleClose = () => {
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`/products${paramsStr}`);
  };

  const selectedProduct =
    product?.hasVariations === true
      ? product.variationsOptionsValues?.find((p) =>
          p?.variationsOptionsValues?.name?.includes(
            product?.variationsOptions?.[0]?.options?.find(
              (option) => option?.extraData?.images?.length > 0
            )?.extraData?.text
          )
        )
      : product;

  const sliderImages =
    product?.variationsOptions?.[0]?.options?.find(
      (option) => option?.extraData?.images?.length > 0
    )?.extraData?.images || product?.images;

  const displayedPrice = selectedProduct?.price || "0";

  const displayedQuantity = selectedProduct?.quantity || "0";

  const renderImages = sliderImages?.map((image, index) => (
    <SwiperSlide key={index}>
      <div className="img-holder">
        <img src={handleImage(image?.image)} alt="productImage" />
      </div>
    </SwiperSlide>
  ));

  // Inject Modals Data On Start
  useEffect(() => {
    setMinQuantityToNotify(product?.minQuantityToNotify);
    setReorderQuantity(product?.reorderQuantity);
    setImages(product?.images || []);
    setIsUnlimitedQuantity(product?.isUnlimitedQuantity);
  }, [
    product?.minQuantityToNotify,
    product?.reorderQuantity,
    product?.images,
    product?.isUnlimitedQuantity,
  ]);

  const handleNavigateToProduct = () => {
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`/products/${product?.id}${paramsStr}`);
  };

  const DragBtn = () =>
    params.has("arrange") &&
    params.has("categories") && (
      <div ref={(ref) => drag(ref)} className="drag">
        <Drag width={24} />
      </div>
    );

  return (
    <div className="card" style={{ opacity: product?.isShown ? 1 : 0.5 }}>
      <div className="productCard">
        <div className={product?.isNew ? "newProduct p-img" : "p-img"}>
          <Swiper
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            centeredSlides={true}
            className="mySwiper2"
            loop={false}
            modules={[Thumbs, Autoplay]}
            ref={swiperRef}
            initialSlide={
              sliderImages?.findIndex((image) => image?.isMain) || 0
            }
          >
            {renderImages}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            slidesPerView={5}
            className="mySwiper"
            spaceBetween={5}
            loop={false}
            modules={[Thumbs]}
            initialSlide={
              sliderImages?.findIndex((image) => image?.isMain) || 0
            }
          >
            {renderImages}
          </Swiper>

          {product?.isNew ? (
            <button
              type="button"
              onClick={() => {
                dispatch(
                  deleteEmptyProduct({
                    id: product?.id,
                  })
                );
              }}
              className="deleteNewProduct"
            >
              <RemoveIcon fill="#fff" />
            </button>
          ) : (
            <div className="corner-actions">
              <DragBtn />

              <input
                id={product?.id}
                type="checkbox"
                onChange={handleClick}
                checked={isCheck.includes(product?.id)}
              />
            </div>
          )}
        </div>
        <div className="p-data">
          <div className="form-group">
            <div>
              <ProductTitleIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                min={0}
                id="stock"
                placeholder="نوع المنتج"
                value={product?.type?.name?.[locale]}
                disabled
              />
            </div>
          </div>
          <div className="form-group" ref={(ref) => preview(ref)}>
            <div>
              <ProductTitleIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                placeholder="اسم المنتج"
                value={product?.name?.[locale]}
                disabled
              />
            </div>
          </div>

          <div className="form-group">
            <div>
              <ProductPriceIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                placeholder="السعر"
                value={displayedPrice}
                disabled
              />
              <span className="currency">ر.س</span>
            </div>
          </div>
          <div className="form-group">
            <div>
              <ProductQtyIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                min={0}
                id="stock"
                placeholder="الكمية"
                value={displayedQuantity}
                disabled
              />
            </div>
          </div>
          <div className="form-group">
            <div>
              <ProductCategoryIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                min={0}
                id="stock"
                placeholder=" التصنيف"
                value={product?.category?.name?.[locale]}
                disabled
              />
            </div>
          </div>
          <div className="form-group has-btns last-row">
            <button type="button" onClick={handleNavigateToProduct}>
              <ProductSettingIcon fill="#FC6B14" />
              بيـانات المنتج
            </button>
            {/* <Dropdown
              // onToggle={handleHighOrderDrop}
              key={getId(product)}
            > */}
            <Dropdown.Toggle className="w-auto">
              <ProductMoreIcon fill="#FC6B14" />
              المـزيد
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item
                className="delete"
                onClick={() => {
                  duplicateProduct(product);
                }}
              >
                <CopyIcon fill="#FC6B14" />
                <FormattedMessage id="duplicate" />
              </Dropdown.Item>
              <Dropdown.Item onClick={handleHideProduct}>
                <EyeSlashIcon fill="#FC6B14" />
                <FormattedMessage
                  id={product?.isShown ? "hideProduct" : "showProduct"}
                />
              </Dropdown.Item>
              <Dropdown.Item
                className="delete"
                onClick={() => handleDeleteProduct}
              >
                <DeleteIcon fill="#f00" />
                <FormattedMessage id="delete" />
              </Dropdown.Item>
            </Dropdown.Menu> */}
            {/* </Dropdown> */}
          </div>
        </div>
      </div>
      <NotificationModal
        handleClose={handleClose}
        minQuantityToNotify={minQuantityToNotify}
        setMinQuantityToNotify={setMinQuantityToNotify}
        reorderQuantity={reorderQuantity}
        setReorderQuantity={setReorderQuantity}
        modalType={modalType}
      />

      <ImagesModal
        handleClose={handleClose}
        modalType={modalType}
        images={images}
        setImages={setImages}
      />
    </div>
  );
};

export default ProductCard;
