import React from "react";
import target from "../../../src/assets/images/target.png";
import targetAngle from "../../../src/assets/images/4.svg";
import downAngle from "../../../src/assets/images/2.svg";
const NewClients = ({ data }) => {
  return (
    <div className="card">
      <h5>العملاء الجدد</h5>
      <div className="card-body">
        <div className="inner target">
          <div className="i-icon">
            <img src={target} alt="" />
          </div>
          <div className="i-text">
            <h4>{data?.currentMonthClients}</h4>
            <p>
              <img
                src={
                  data?.monthlyClientsPresntStatus === "up"
                    ? targetAngle
                    : downAngle
                }
                alt=""
              />
              <i
                className={`${
                  data?.monthlyClientsPresntStatus === "up" ? "up" : "down"
                }`}
              >
                {data?.monthlyClientsPresnt?.toFixed(0)}%
              </i>{" "}
              أخر شهر
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClients;
