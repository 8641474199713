import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
} from "./actionTypes";

export const getCurrencies = () => {
  return {
    type: GET_CURRENCIES,
  };
};

export const getCurrenciesSuccess = (coupons) => {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: coupons,
  };
};

export const getCurrenciesFailure = (error) => {
  return {
    type: GET_CURRENCIES_FAILURE,
    payload: error,
  };
};
