import { MapContainer, TileLayer } from "react-leaflet";
// import dijon from "./data";
import EditableGroup from "./Editable";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw-src.css";

export default function Map({ data, setData }) {
  const center = [45.76342, 4.834277];
  return (
    <div
      className="w-100 position-relative"
      style={{ height: 50 + "vh", zIndex: 1 }}
    >
      <MapContainer
        center={center}
        style={{ width: 100 + "%", height: 100 + "%" }}
        zoom={13}
        scrollWheelZoom={true}
      >
        <EditableGroup polygons={data} setPolygons={setData} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
}
