import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import QuickModal from "components/shared/QuickModal";
import { toast } from "react-toastify";
import { getId } from "helpers/functions";

export default function AddVariantModal({
  quickModal,
  variationsDataList,
  formVariationsOptions,
  displayedListType,
  appendSelectedVariations,
  handleClose,
}) {
  const [variationOption, setVariationOption] = useState();
  const [variationDisplayStyle, setVariationDisplayStyle] = useState();

  const handleAdd = () => {
    if (!variationOption?.value || !variationDisplayStyle?.value)
      return toast("قيمة مفقودة");
    appendSelectedVariations({
      ...variationOption,
      variation: getId(variationOption),
      variationStyle: getId(variationDisplayStyle),
      variationStyleValue: variationDisplayStyle?.value,
      variationStyleLabel: variationDisplayStyle?.label,
    });
    setVariationOption();
    setVariationDisplayStyle();
    handleClose();
  };
  const processVariationsOptions = variationsDataList
    ?.map((vary) => ({
      value: vary?.name?.en,
      label: vary?.name?.ar,
      id: vary?.id,
    }))
    ?.filter(
      (option) =>
        !formVariationsOptions?.find(
          (selected) => selected?.variation === option?.id
        )
    );
  if (!quickModal?.show) return null;

  return (
    <QuickModal {...quickModal}>
      <Row className="gap-4">
        <Col xs="12">
          <div className="form-group">
            <Select
              // {...field}
              value={variationOption?.value && variationOption}
              onChange={(e) => {
                setVariationOption(e);
              }}
              options={processVariationsOptions}
              className="basic-single"
              classNamePrefix="select"
              placeholder="اختر نوع المتغير"
            />
          </div>
        </Col>
        <Col xs="12">
          <div className="form-group">
            <Select
              // {...field}
              value={variationDisplayStyle?.value && variationDisplayStyle}
              onChange={(e) => {
                setVariationDisplayStyle(e);
              }}
              options={displayedListType?.map((vary) => ({
                value: vary?.name?.en,
                label: vary?.name?.ar,
                id: vary?.id,
              }))}
              className="basic-single"
              placeholder="اختر نوع عرض المتغير"
              classNamePrefix="select"
            />
          </div>
        </Col>
        <button type="button" className="btn btn-green" onClick={handleAdd}>
          إضافة متغير
        </button>
      </Row>
    </QuickModal>
  );
}
