import { useEffect, useState } from "react";
import server from "api/server";

import { ReactComponent as Remove } from "assets/svgs/x.svg";
import { ReactComponent as Upload } from "assets/svgs/product/upload.svg";
import { Col, Row } from "react-bootstrap";
import StickyLoader from "components/shared/StickyLoader";
import { getPublicImage, handleImage } from "helpers/functions";
import { smallProductImageSize } from "helpers/configs";

const MultiUpload = ({
  images,
  setImages,
  pathType = "path",
  lg = "6",
  xl = "4",
  xxl = "4",
  smallImageCheck = false,
}) => {
  const [loading, setLoading] = useState(false);

  const onImageChange = async (e) => {
    setLoading(true);
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);

        const { data } = await server().post("/upload", formData, {
          headers: {
            uploadsType: "products",
          },
        });
        setImages((oldImages) => [
          ...oldImages,
          {
            preview: e.target.files[i],
            image: getPublicImage(data.data.files[0]),
            isMain: false,
          },
        ]);
      }
      setLoading(false);
    } else setLoading(false);
  };

  const removeImages = (image) => {
    setImages(images.filter((img) => img.image !== image.image));
  };

  const ImageComp = ({ imageSrc, index }) => {
    const [smallImgFlag, setSmallImgFlag] = useState(false);

    useEffect(() => {
      if (!smallImageCheck) return;
      const img = new Image();
      img.src = handleImage(imageSrc?.image);
      img.onload = function () {
        const isSmallImg =
          this.width < smallProductImageSize ||
          this.height < smallProductImageSize;

        setSmallImgFlag(isSmallImg);
      };
    }, [imageSrc]);

    return (
      <Col xxl={xxl} xl={xl} lg={lg} md={6} sm={6} xs={12}>
        <div className="thumb">
          <button
            type="button"
            className="btn-remove"
            onClick={() => removeImages(imageSrc)}
          >
            <Remove fill="#fff" />
          </button>
          <img
            src={
              imageSrc.preview
                ? URL.createObjectURL(imageSrc.preview)
                : imageSrc?.image
            }
            width={100}
            height={100}
            alt=""
          />
          <div>
            <label>
              <input
                type="radio"
                name="mainImage"
                onChange={(e) => {
                  setImages(() =>
                    images?.map((image, i) => {
                      return i === index
                        ? { ...image, isMain: true }
                        : { ...image, isMain: false };
                    })
                  );
                }}
                defaultChecked={imageSrc.isMain}
              />
              <span>الصورة الرئيسية</span>
            </label>
            <span className="text-danger small">
              {smallImgFlag && smallImageCheck ? "*صورة صغيرة" : ""}
            </span>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div className="form-group required">
      <div>
        <label className="form-uploader">
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={onImageChange}
            id="filo"
            name="images"
          />

          <span className="txt">
            <Upload fill="#FC6B14" />
            اضافة صور جديدة للمنتج
          </span>
        </label>
        {images.length > 0 ? (
          <div className="thumbs">
            <Row>
              {images?.map((imageSrc, index) => (
                <ImageComp key={index} index={index} imageSrc={imageSrc} />
              ))}
            </Row>
            {loading && <StickyLoader fill="#FC6B14" />}
          </div>
        ) : (
          <div className="emptyPhotos">
            <Upload fill="#ddd" />
            لا توجد صور لهذا المنتج , يمكنك اضافة صور جديدة !
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiUpload;
