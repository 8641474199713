import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Loader from "../../components/shared/Loader";

const ShowStore = () => {
  const { locale } = useIntl();

  const { SingleStore, loading } = useSelector((state) => state?.stores);

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4> بيانات المتجر</h4>
          </div>
          <div className="card-body">
            <img src={SingleStore.logo} alt="" />
            <h1>{SingleStore?.name?.[locale]}</h1>

            <ul>
              <li>
                <b>التصنيف:</b>
                {SingleStore?.category?.name?.[locale]}
              </li>
              <li>
                <b>الباقة:</b>
                {SingleStore?.package?.name?.[locale]}
              </li>
              <li>
                <b>الوصف:</b>
                {SingleStore?.description?.[locale]}
              </li>
              <li>
                <b>النطاق:</b>
                {SingleStore?.domain}
              </li>
              <li>
                <ul>
                  <b>الفروع</b>
                  {SingleStore?.branches?.map((branch) => (
                    <li key={branch}>
                      <h4>
                        <b>اسم الفرع</b>
                        {branch?.name?.[locale]}
                      </h4>
                      <p>
                        <b>جوال الفرع</b>
                        {branch?.phone}
                      </p>
                      <p>
                        <b>عنوان الفرع</b>
                        {branch?.address?.addressDetail}
                      </p>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowStore;
