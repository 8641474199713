import { useEffect } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/trash.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getGroups,
  getProductCategories,
  getProducts,
} from "store/actions";
import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import Select from "react-select";
import RuleTypeInputs from "./RuleTypeInputs";
import BreadCrumb from "components/shared/BreadCrumb";

const DeliveryCompaniesRules = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [params] = useSearchParams();
  const { register, handleSubmit, reset, control, watch } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rules",
  });

  const { loading } = useSelector((state) => state.customers);

  const { loading: loadingCategories } = useSelector(
    (state) => state.productCategories
  );
  const { loading: loadingGroups } = useSelector((state) => state.groups);

  const companies = [1, 2, 3, 4, 5];

  const selectedItemsType = watch("selectedItemsType");

  const rulesGenres = [
    "cartTotal",
    "cartWeight",
    "categoryProducts",
    "perLocations",
    "customerGroup",
  ].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getGroups());
    dispatch(getProductCategories());
  }, [dispatch]);

  useEffect(() => {
    let searchParams1 = new URLSearchParams(params);
    searchParams1.delete("modal");
    const paramsStr =
      searchParams1?.size > 0 ? `?${searchParams1.toString()}` : "";
    selectedItemsType === "categories"
      ? dispatch(getProductCategories(paramsStr))
      : dispatch(getProducts(paramsStr));
  }, [dispatch, params, reset, selectedItemsType]);

  useEffect(() => {
    reset({});
  }, [reset]);

  const getOptionsByName = (arr) =>
    //add default option
    [
      // {
      //   label: "اختر او ابحث",
      //   value: "",
      // },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale] || item,
        value: getId(item),
      }))
    );

  const onSubmit = (data) => {
    console.log("data", data);
    navigate("/settings/store-shipping");
  };

  return (
    <>
      <BreadCrumb
        pageName={formatMessage({
          id: "deliveryRules",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
        child={{
          text: formatMessage({ id: "storeShipping" }),
          link: "/settings/store-shipping",
        }}
      />
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Title className="mb-4">
            <h5>
              <ModalHeaderIcon fill="#FC6B14" />{" "}
              <FormattedMessage id="deliveryRules" />
            </h5>
          </Card.Title>
          <Card.Body>
            <div className="d-flex flex-column gap-1 w-100">
              <div defaultActiveKey="0" flush>
                {fields?.map((_, index) => (
                  <div key={index} className="card">
                    <Row>
                      <Form.Group className={`mb-3 col-12 col-md-4`}>
                        <Form.Label>
                          <FormattedMessage id="ruleName" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={formatMessage({ id: "ruleName" })}
                          {...register(`rules.${index}.name`, {
                            required: true,
                          })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 col-12 col-md-8">
                        <Form.Label>
                          <FormattedMessage id="companies" />
                        </Form.Label>
                        <Controller
                          control={control}
                          name={`rules.${index}.companies`}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <Select
                              isRtl={true}
                              isMulti={true}
                              // menuIsOpen
                              isSearchable={true}
                              className="basic-single"
                              classNamePrefix="select"
                              options={getOptionsByName(companies)}
                              placeholder={formatMessage({ id: "companies" })}
                              // value={companies?.find((o) => o?.value === value)}
                              // onChange={({ value }) => {
                              //   onChange(value);
                              // }}
                              onChange={(selected) => {
                                onChange(
                                  selected.map((selected) => selected?.value)
                                );
                              }}
                              value={getOptionsByName(
                                companies?.filter((item) =>
                                  value?.includes(getId(item))
                                )
                              )}
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3 col-12 col-md-4">
                        <Form.Label>
                          <FormattedMessage id="ruleType" />
                        </Form.Label>
                        <Controller
                          control={control}
                          name={`rules.${index}.ruleType`}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={rulesGenres}
                              placeholder={formatMessage({ id: "ruleType" })}
                              isMulti={false}
                              value={rulesGenres?.find(
                                (o) => o?.value === value
                              )}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                      <RuleTypeInputs
                        control={control}
                        index={index}
                        ruleType={watch(`rules.${index}.ruleType`)}
                        register={register}
                      />
                    </Row>
                    {fields?.length !== 1 && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => remove(index)}
                          aria-label="delete"
                          title="delete"
                        >
                          <DeleteIcon width={20} height="auto" color="#fff" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary my-2"
                  onClick={() => append({})}
                >
                  <FormattedMessage id="add" />
                </button>
              </div>
            </div>
            <button type="submit" className="btn btn-blue">
              حفظ
            </button>
          </Card.Body>
        </Form>
        {(loading || loadingGroups || loadingCategories) && (
          <StickyLoader fill="#FC6B14" />
        )}
      </Card>
    </>
  );
};

export default DeliveryCompaniesRules;
