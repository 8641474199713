import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import Select from "react-select";

const CalculateShipingModal = ({ show, setShow, coupon, type }) => {
  const { locale, formatMessage } = useIntl();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, control, watch, setValue } = useForm();
  const [params] = useSearchParams();

  const {
    countries,
    cities,
    loading: loadingCustomers,
  } = useSelector((state) => state.customers);

  const companies = [];

  useEffect(() => {
    if (type === "add") reset();
  }, [reset, type]);

  useEffect(() => {
    reset({});
  }, [coupon, reset]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const getOptionsByName = (arr) =>
    //add default option
    [
      // {
      //   label: "اختر او ابحث",
      //   value: "",
      // },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale],
        value: getId(item),
      }))
    );

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {};

  return (
    <Modal
      dialogClassName="productDataModal cash-on-deliver"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeaderIcon fill="#FC6B14" />
            <FormattedMessage id="calculateShipingModal" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Row lg={12} className="">
              <Col xs="12" md="4" className="form-body required">
                <h5>
                  <FormattedMessage id="deliveryCompany" />
                </h5>
                <div className="selectHolder">
                  <Controller
                    control={control}
                    name={`deliveryCompany`}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value: value1, selected },
                    }) => (
                      <Select
                        // isMulti={true}
                        isRtl={true}
                        isSearchable={true}
                        className="basic-single w-100"
                        classNamePrefix="select"
                        options={getOptionsByName(companies)}
                        placeholder={formatMessage({
                          id: "deliveryCompany",
                        })}
                        onChange={(selected) => {
                          onChange(selected?.value);
                        }}
                        value={getOptionsByName(companies)?.find(
                          (p) => p.value === value1
                        )}
                      />
                    )}
                  />
                  {/* <CodeIcon fill="#E4E4E4" /> */}
                </div>
              </Col>

              <Col xs="12" md="4" className="form-body required">
                <h5>
                  <FormattedMessage id="country" />
                </h5>
                <div className="selectHolder">
                  <Controller
                    control={control}
                    name={`country`}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value: value1, selected },
                    }) => (
                      <Select
                        // isMulti={true}
                        isRtl={true}
                        isSearchable={true}
                        className="basic-single w-100"
                        classNamePrefix="select"
                        options={getOptionsByName(countries)}
                        placeholder={formatMessage({
                          id: "country",
                        })}
                        onChange={(selected) => {
                          onChange(selected?.value);
                        }}
                        value={getOptionsByName(countries)?.find(
                          (p) => p.value === value1
                        )}
                      />
                    )}
                  />
                  {/* <CodeIcon fill="#E4E4E4" /> */}
                </div>
              </Col>

              <Col xs="12" md="4" className="form-body required">
                <h5>
                  <FormattedMessage id="cities" />
                </h5>
                <div className="selectHolder">
                  <Controller
                    control={control}
                    name={`cities`}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value: value1, selected },
                    }) => (
                      <Select
                        // isMulti={true}
                        isRtl={true}
                        isSearchable={true}
                        className="basic-single w-100"
                        classNamePrefix="select"
                        options={getOptionsByName(cities)}
                        placeholder={formatMessage({
                          id: "cities",
                        })}
                        onChange={(selected) => {
                          onChange(selected?.value);
                        }}
                        value={getOptionsByName(cities)?.find(
                          (p) => p.value === value1
                        )}
                      />
                    )}
                  />
                  {/* <CodeIcon fill="#E4E4E4" /> */}
                </div>
              </Col>
            </Row>
            <Row className="my-5">
              <Col xs="12" md="6" className="m-auto">
                <input
                  type="range"
                  {...register("weight")}
                  min={0}
                  max={1000}
                  className="w-100"
                />
                <div className="info d-flex justify-content-between align-items-center">
                  <h5 className="text-center">
                    <FormattedMessage id="weight" /> : {watch("weight")}
                  </h5>

                  <h5 className="text-center">
                    <FormattedMessage id="price" /> : {watch("weight") * 5}
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {loadingCustomers && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default CalculateShipingModal;
