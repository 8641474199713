import server from "./server";

export const getOrdersApi = async (payload) => {
  const response = await server().get(`/orders${payload || ""}`);
  return response.data;
};

export const getSingleOrderApi = async (id) => {
  const response = await server().get(`/orders/${id}`);
  return response.data;
};

export const addOrderApi = async (payload) => {
  const response = await server().post("/orders", payload);
  return response.data;
};

export const editOrderApi = async ({ id, data }) => {
  const response = await server().post(`/orders/changeStatus/${id}`, data);
  return response.data;
};

export const deleteOrderApi = async (id) => {
  const response = await server().delete(`/orders/${id}`);
  return response.data;
};
