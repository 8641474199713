import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import CategoriesHolder from "./categoriesHolder";
// import categoriesIcon from "../../assets/svgs/categories.svg";
// import ControlArea from "components/Layout/ControlArea";
import BreadCrumb from "components/shared/BreadCrumb";
import { ReactComponent as CategoriesIcon } from "../../assets/svgs/categories.svg";
import { Modal, Dropdown } from "react-bootstrap";
// import CategoryModalForm from "components/categories-old2/CategoryModalForm";
import { useDispatch, useSelector } from "react-redux";
import DndWrapper from "components/categories/DndWrapper";
import { getProductCategories } from "store/actions";
import StickyLoader from "components/shared/StickyLoader";
import useSearchFormParam from "components/shared/SearchHook";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { ReactComponent as SettingIcon } from "assets/svgs/Setting.svg";
import * as XLSX from "xlsx";
import { FormattedMessage, useIntl } from "react-intl";
import { getId, handleIsoDate } from "helpers/functions";
import Papa from "papaparse";
import EditCategory from "views/categories/EditCategory";
import AddCategory from "views/categories/AddCategory";

const Index = () => {
  const { formatMessage, locale, formatDate } = useIntl();
  const dispatch = useDispatch();
  const [registerSearch, handleSubmitSearch, params, EmptyButton] =
    useSearchFormParam();

  const [xlsxData, setXlsxData] = useState([]);

  const { categories, editLoading, loading } = useSelector(
    (state) => state.productCategories
  );

  const [modalProps, setModalProps] = useState({ show: false });
  // const [modalType, setModalType] = useState("add");
  // const [selectedCategory, setSelectedCategory] = useState(null);

  // useEffect(() => {
  //   dispatch(getProductCategories());
  // }, [dispatch]);

  useEffect(() => {
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    dispatch(getProductCategories(paramsStr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    // fetchMetaData();
    if (categories?.length > 0)
      setXlsxData(
        categories.map((user) => {
          return {
            [formatMessage({ id: "createdAt" })]: handleIsoDate(
              user?.createdAt
            ),
            [formatMessage({ id: "name" })]: user?.name?.[locale],
            [formatMessage({ id: "slug" })]: user?.slug,
            [formatMessage({ id: "ordersNum" })]: user?.order,
            [formatMessage({ id: "parentCategory" })]:
              categories?.find((c) => getId(c) === user?.parentCategory)
                ?.name?.[locale] || "",
            [formatMessage({ id: "showProducts" })]: formatMessage({
              id: user?.showProducts || " ",
            }),
          };
        })
      );
  }, [formatMessage, locale, categories]);

  // const fetchMetaData = async () => {
  //   const response = await server().get(`/users`);
  //   setMetadata(response.data.data.metadata);
  //   return response.data;
  // };

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `categories-${formatDate(new Date(), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}.xlsx`
    );
  };

  const exportToCSV = () => {
    // Ensure data is an array of objects with keys representing column headers
    if (
      !Array.isArray(xlsxData) ||
      xlsxData.length === 0 ||
      typeof xlsxData[0] !== "object"
    ) {
      console.error("Invalid data format for CSV export");
      return;
    }

    // Convert data to a CSV string
    const csv = Papa.unparse(xlsxData);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `categories-${formatDate(new Date(), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })}.xlsx`;
    link.click();
  };

  // const handleShowModal = (category) => {
  //   if (category) {
  //     setModalType("edit");
  //     setSelectedCategory(category);
  //   } else {
  //     setModalType("add");
  //   }
  //   setModalProps({
  //     show: true,
  //   });
  // };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="stores-wrap categories-page">
          <BreadCrumb pageName="التصنيفات" />
          <div className="ordersActions">
            <button
              className="btn btn-blue"
              onClick={() => {
                setModalProps({
                  show: true,
                  add: true,
                  category: {
                    parentCategory: null,
                  },
                });

                // handleShowModal();
              }}
            >
              <CategoriesIcon fill="#fff" />
              إضافة تصنيف
              <i>+</i>
            </button>
          </div>
          <div className="filter-toolbar">
            <div className="ordersServices">
              <Dropdown>
                <Dropdown.Toggle>
                  <SettingIcon />
                  <FormattedMessage id="exportCategories" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={exportToXLSX}>
                    <SettingIcon alt="" />
                    <FormattedMessage id="exportCategoriesXlsx" />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={exportToCSV}>
                    <SettingIcon alt="" />
                    <FormattedMessage id="exportCategoriesCsv" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-toolbar__left">
              {/* <div className="filter-toolbar__action">
                  <button className="btn btn-black" onClick={filterToggle}>
                    <img src={filterIcon} alt="settings" />
                    <FormattedMessage id="filter" />
                    {!!filterLength && (
                      <span className="filterLength">{filterLength}</span>
                    )}
                  </button>
                </div> */}
              <form
                className="filter-toolbar__search"
                onSubmit={handleSubmitSearch}
              >
                <input
                  type="search"
                  placeholder={formatMessage({ id: "categoryNameSearch" })}
                  className="form-control"
                  {...registerSearch("txt")}
                />
                <EmptyButton />

                <button>
                  <SearchIcon />
                </button>
              </form>
            </div>
            {/* <ParamsFilters
                href="/customers"
                countries={countries}
                cities={cities}
                groups={groups}
              /> */}
          </div>
          {/* <CategoriesHolder /> */}
          {categories?.length === 0 && (
            <div className="no-data alert alert-warning text-center m-0 mt-4">
              <p>
                <FormattedMessage id="noData" />
              </p>
            </div>
          )}
          <DndWrapper categories={categories} setModalProps={setModalProps} />
        </div>
      </DndProvider>
      <Modal
        dialogClassName="productDataModal"
        show={modalProps?.show}
        onHide={() => setModalProps({})}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!modalProps?.add
              ? ` تعديل التصنيف : ${modalProps?.category?.name?.[locale]}`
              : "إضافة تصنيف"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!modalProps?.add ? (
            <EditCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          ) : (
            <AddCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          )}
        </Modal.Body>
      </Modal>
      {/* 
      <Modal
        dialogClassName="productDataModal categoriesModal"
        show={modalProps.show}
        onHide={() =>
          setModalProps({
            show: false,
          })
        }
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "add" ? "إضافة تصنيف" : "تعديل التصنيف"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CategoryModalForm
            category={selectedCategory}
            modalType={modalType}
            handleClose={() => {
              setModalProps({
                show: false,
              });
            }}
            categories={categories}
          />
        </Modal.Body>
      </Modal> */}
      {(editLoading || loading) && <StickyLoader fill="#FC6B14" />}
    </>
  );
};

export default Index;
