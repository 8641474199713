import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { ReactComponent as GlobIcon } from "assets/svgs/product/global-edit.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getId, getOptionsFrom } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import Select from "react-select";
import { editStoreSlugSetting } from "store/actions";

const LinkDomainModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, control, watch, setValue } = useForm();
  const [params] = useSearchParams();

  const { settings, loading } = useSelector((state) => state?.settings) || {};

  useEffect(() => {
    reset(settings || {});
  }, [reset, settings]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {
    // e.preventDefault();

    dispatch(
      editStoreSlugSetting({
        data,
        slug: "walletSettings",
        navigate: handleClose,
      })
    );
  };

  return (
    <Modal
      dialogClassName="productDataModal transfere-domain"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <GlobIcon />
            <FormattedMessage id="linkOutsideTjaara" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-group">
          <Row className="form-body">
            <Col xs={12} className="form-group p-0 px-2">
              <h5>
                <FormattedMessage id="domainAddress" />
              </h5>
              <div className="">
                <input
                  className="form-control form-outline"
                  placeholder="Tjaara.Com"
                  {...register("domainAddress", { required: true })}
                />
                <GlobIcon fill="#E4E4E4" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="radio-inputs">
              <h5>
                <FormattedMessage id="linkMethod" />
              </h5>
              <div className="inputs-wrap">
                <label>
                  <input
                    type="radio"
                    value="nameServer"
                    className="form-outline"
                    {...register("linkMethod", { required: true })}
                  />
                  <div>
                    <h5>Name server</h5>
                    <p>
                      الطريقة الانسب للاستفادة من كل مميزات متجرك والخدمات
                      والتطبيقات التى تعتمد على الدومين
                    </p>
                  </div>
                </label>

                <label>
                  <input
                    type="radio"
                    value="cName"
                    className="form-outline"
                    {...register("linkMethod", { required: true })}
                  />
                  <div>
                    <h5>C Name</h5>
                    <p>خيار بديل للطريقة الاولى عندما الدومين subdomain</p>
                  </div>
                </label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {loading && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default LinkDomainModal;
