import server from "./server";

export const getProductCategoriesApi = async (payload) => {
  const response = await server().get(`/productCategories${payload || ""}`);
  return response.data;
};

export const getSingleProductCategoryApi = async (id) => {
  const response = await server().get(`/productCategories/${id}`);
  return response.data;
};

export const addProductCategoriesApi = async (payload) => {
  const response = await server().post("/productCategories", payload);
  return response.data;
};

export const editProductCategoriesApi = async ({ id, data }) => {
  const response = await server().put(`/productCategories/${id}`, data);
  return response.data;
};

export const editProductCategoriesOrderApi = async (data) => {
  const response = await server().put(
    `/productCategories/reorderCategories`,
    data
  );
  return response.data;
};

export const deleteProductCategoriesApi = async (id) => {
  const response = await server().delete(`/productCategories/${id}`);
  return response.data;
};
