import React from "react";
import orders from "../../../src/assets/images/orders.png";
import ordersAngle from "../../../src/assets/images/3.svg";
import downAngle from "../../../src/assets/images/2.svg";
const Orders = ({ data }) => {
  return (
    <div className="card">
      <h5>الطلبات</h5>
      <div className="card-body">
        <div className="inner orders">
          <div className="i-icon">
            <img src={orders} alt="" />
          </div>
          <div className="i-text">
            <h4>{data?.monthCountOrders}</h4>

            <p>
              <img
                src={data?.total_Percentage > 0 ? ordersAngle : downAngle}
                alt=""
              />
              <i className={`${data?.total_Percentage > 0 ? "up" : "down"}`}>
                {data?.total_Percentage?.toFixed(0)}%
              </i>{" "}
              أخر شهر
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
