import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files

// Login Redux States
import { GET_THEME_TEMPLATES } from "./actionTypes";
import { getThemeTemplatesFailure, getThemeTemplatesSuccess } from "./actions";
import { getThemeTemplatesApi } from "api/themes";

function* getThemeTemplates({ payload }) {
  try {
    const { data } = yield call(getThemeTemplatesApi, payload);
    yield put(getThemeTemplatesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemeTemplatesFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

export function* watchGetThemeTemplates() {
  yield takeEvery(GET_THEME_TEMPLATES, getThemeTemplates);
}

function* themeTemplatesSaga() {
  yield all([fork(watchGetThemeTemplates)]);
}

export default themeTemplatesSaga;
