import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";

const DeliveryCompaniesOptions = ({ show, setShow, coupon, type }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [params] = useSearchParams();

  const { loading: loadingGroups } = useSelector((state) => state.groups);

  useEffect(() => {
    if (type === "add") reset();
  }, [reset, type]);

  useEffect(() => {
    reset({});
  }, [coupon, reset]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {};

  return (
    <Modal
      dialogClassName="productDataModal delivery-settings"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeaderIcon fill="#FC6B14" />
            <FormattedMessage id="deliveryCompaniesOptions" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group d-flex flex-column gap-3 w-100">
            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="allowStatusChange" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("allowStatusChange")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>

            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="allowQuantitySync" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("allowQuantitySync")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>

            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="checkoutShowOneOptionSameFee" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("checkoutShowOneOptionSameFee")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>

            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="allowProductVariations" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("allowProductVariations")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>

            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="autoDetectNearbyWarehouse" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("autoDetectNearbyWarehouse")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>

            <div className="form-body">
              <label className="w-100 cursor-pointer position-relative mb-0">
                <FormattedMessage id="allowUncompletedOrderReceipt" />

                <div className="switcher-wrapper">
                  <label className="switcher mb-0">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      {...register("allowUncompletedOrderReceipt")}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </label>
                </div>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {loadingGroups && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default DeliveryCompaniesOptions;
