import {
  GET_STORES,
  GET_STORES_FAILURE,
  GET_STORES_SUCCESS,
  ADD_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAILURE,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
  GET_ACTIVE_STORE,
  GET_ACTIVE_STORE_SUCCESS,
  GET_ACTIVE_STORE_FAILURE,
  SET_ACTIVE_THEME,
  SET_ACTIVE_THEME_SUCCESS,
  SET_ACTIVE_THEME_FAILURE,
  ADD_THEME_TO_STORE,
  ADD_THEME_TO_STORE_SUCCESS,
  ADD_THEME_TO_STORE_FAILURE,
  CHANGE_STORE_STATUS,
  CHANGE_STORE_STATUS_SUCCESS,
  CHANGE_STORE_STATUS_FAILURE,
  EDIT_STORE_THEME,
  EDIT_STORE_THEME_SUCCESS,
  EDIT_STORE_THEME_FAILURE,
  GET_ACTIVE_STORE_THEMES,
  GET_ACTIVE_STORE_THEMES_SUCCESS,
  GET_ACTIVE_STORE_THEMES_FAILURE,
} from "./actionTypes";

const initialState = {
  stores: [],
  loading: true,
  error: "",
  activeStore: {},
  activeStoreThemes: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_STORES_SUCCESS:
      state = {
        ...state,
        loading: false,
        stores: action.payload.stores,
      };
      break;
    case GET_STORES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_ACTIVE_STORE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ACTIVE_STORE_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeStore: action.payload.store,
      };
      break;
    case GET_ACTIVE_STORE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        activeStore: {},
      };
      break;

    case GET_ACTIVE_STORE_THEMES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ACTIVE_STORE_THEMES_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeStoreThemes: action.payload.storeThemes,
      };
      break;
    case GET_ACTIVE_STORE_THEMES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        activeStoreThemes: [],
      };
      break;

    case SET_ACTIVE_THEME:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_ACTIVE_THEME_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeStoreThemes: state.activeStoreThemes.map((activeStoreTheme) => {
          if (activeStoreTheme._id === action?.payload?.storeTheme?._id)
            return {
              ...activeStoreTheme,
              isActive: true,
            };

          return {
            ...activeStoreTheme,
            isActive: false,
          };
        }),
      };
      break;
    case SET_ACTIVE_THEME_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ADD_THEME_TO_STORE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THEME_TO_STORE_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeStoreThemes: [
          ...state.activeStoreThemes,
          action.payload.storeTheme,
        ],
      };
      break;

    case ADD_THEME_TO_STORE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ADD_STORE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_STORE_SUCCESS:
      state = {
        ...state,
        loading: false,
        stores: action.payload.stores,
      };
      break;
    case ADD_STORE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_STORE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_STORE_SUCCESS:
      const storesAfterDeleteing = [
        ...state?.stores?.filter((store) => store?.id !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        stores: storesAfterDeleteing,
      };
      break;
    case DELETE_STORE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case CHANGE_STORE_STATUS:
      state = {
        ...state,
        loading: true,
      };

      break;
    case CHANGE_STORE_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        SingleStore: action.payload.store,
      };

      break;
    case CHANGE_STORE_STATUS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_STORE_THEME:
      state = {
        ...state,
        loading: true,
      };

      break;
    case EDIT_STORE_THEME_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeStore: action.payload.store,
      };

      break;
    case EDIT_STORE_THEME_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
