const { default: server } = require("./server");

export const getBanksApi = async () => {
  const response = await server().get(`/banks`);

  return response.data;
};

export const getPaymentMethodsApi = async () => {
  const response = await server().get(`/paymentMethods`);

  return response.data;
};
