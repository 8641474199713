import { Modal, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Editor } from "components/shared/Editor";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { editStoreSlugSetting, getSettings } from "store/actions";

import { ReactComponent as EyeIcon } from "assets/svgs/product/eye.svg";
import { toast } from "react-toastify";

const contactMethods = ["sms", "email"];

export default function ProductsStockSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage, locale } = useIntl();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [editorObj, setEditorObj] = useState();
  const [editor, setEditor] = useState();

  const { settings, dataSaveLoading } =
    useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("stockAvailable"));
  }, [dispatch]);

  useEffect(() => {
    reset({
      NotifyAvailable: settings?.NotifyAvailable,
      NotifyAvailableVariations: settings?.NotifyAvailableVariations,
      transmitterChannel: settings?.transmitterChannel,
      messageText: settings?.messageText,
      messageTitle: settings?.messageTitle,
    });
  }, [reset, settings]);

  const contactMethodsOptions = contactMethods?.map((contact) => ({
    value: contact,
    label: formatMessage({ id: contact }),
  }));

  // commented pattern words feature
  // const handleAddText = (text) => {
  //   console.log("editorObj", editorObj);
  //   editorObj.focus();
  //   var caretPosition = editorObj.getSelection(true);
  //   console.log("caretPosition", caretPosition);
  //   editorObj.insertText(caretPosition, text);
  //   // if (editor?.mceinsertcontent)
  //   //   editor?.mceinsertcontent("mceInsertContent", false, text);
  //   // else toast(<FormattedMessage id="selectPlaceToAddText" />);
  // };

  const onSubmit = (data) => {
    dispatch(editStoreSlugSetting({ data, slug: "stockAvailable", navigate }));
  };

  return (
    <Modal
      show={true}
      size="lg"
      className="productDataModal"
      onHide={() => navigate(-1)}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="products-stock-settings"
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="stockNotifySettings" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            <h5 className="required">
              <FormattedMessage id="activateNotifyOnAvailability" />
            </h5>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              // label="Check this switch"
              {...register("NotifyAvailable")}
            />
          </label>
          <label>
            <h5 className="required">
              <FormattedMessage id="activateNotifyOnAvailabilityVariations" />
            </h5>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              // label="Check this switch"
              {...register("NotifyAvailableVariations")}
            />
          </label>
          <Form.Group className="mb-3 col-12">
            <Form.Label className="required">
              <FormattedMessage id="selectGroup" />
            </Form.Label>
            <Controller
              control={control}
              name={`transmitterChannel`}
              rules={{ required: true }}
              render={({ field: { onChange, value, selected } }) => (
                <Select
                  isRtl={true}
                  isSearchable={false}
                  className="basic-single"
                  classNamePrefix="select"
                  options={contactMethodsOptions}
                  placeholder={formatMessage({ id: "selectGroup" })}
                  isMulti={false}
                  value={contactMethodsOptions?.find(
                    (contact) => contact.value === value
                  )}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-12">
            {/* <Form.Label>
              <FormattedMessage id="messageTitle" />
            </Form.Label> */}
            <MultiLangWrapper
              label={<FormattedMessage id="messageTitle" />}
              errors={errors?.messageTitle}
              required={true}
            >
              {({ lang, classes, key }) => (
                <Form.Control // prettier-ignore
                  className={classes}
                  key={key}
                  id="custom-switch"
                  // label="Check this switch"
                  {...register(`messageTitle.${lang}`)}
                />
              )}
            </MultiLangWrapper>
          </Form.Group>
          <Form.Group className="mb-3 col-12">
            <MultiLangWrapper
              label={<FormattedMessage id="messageText" />}
              errors={errors?.messageText}
              required={true}
            >
              {({ lang, classes, key }) => (
                <div className={classes} key={key}>
                  <Editor
                    index={key}
                    init={setEditorObj}
                    onFocus={(focus) => {
                      console.log(focus);
                      // setEditor(focus?.target?.editorCommands?.commands?.exec);
                    }}
                    // ref={editor}
                    initialValue={settings?.messageText?.[lang]}
                    onChange={(e, editor) => {
                      const data = editor.getContent();
                      setValue(`messageText.${lang}`, data);
                    }}
                    tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                  />
                </div>
              )}
            </MultiLangWrapper>
            {/* commented pattern words feature */}
            {/* <div className="editor-additions">
              <div className="template-text">
                <FormattedMessage id="name" /> :
                <button type="button" onClick={() => handleAddText("{name}")}>
                  {"{name}"}
                </button>
              </div>
              <div className="template-text">
                <FormattedMessage id="productName" /> :
                <button
                  type="button"
                  onClick={() => handleAddText("{product}")}
                >
                  {"{product}"}
                </button>
              </div>
              <div className="template-text">
                <FormattedMessage id="productLink" /> :
                <button
                  type="button"
                  onClick={() => handleAddText("{product_link}")}
                >
                  {"{product_link}"}
                </button>
              </div>
              <div className="template-text">
                <FormattedMessage id="store" /> :
                <button type="button" onClick={() => handleAddText("{store}")}>
                  {"{store}"}
                </button>
              </div>
              <div className="template-text">
                <FormattedMessage id="storeLink" /> :
                <button
                  type="button"
                  onClick={() => handleAddText("{store_link}")}
                >
                  {"{store_link}"}
                </button>
              </div>
            </div> */}
          </Form.Group>

          <div className="separator">
            <span>
              <EyeIcon />
              <FormattedMessage id="previewMessage" />
            </span>
          </div>
          <div className="preview">
            <h4 dir="auto">{watch(`messageTitle.${locale}`)}</h4>
            <div
              dir="auto"
              dangerouslySetInnerHTML={{
                __html: watch(`messageText.${locale}`),
              }}
            ></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-blue">
            <FormattedMessage id="save" />
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="close" />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
