import { useRef } from "react";
import imageUpload from "assets/svgs/product/gallery-export.svg";
import { ReactComponent as RemoveImg } from "assets/svgs/close-icon.svg";
import server from "api/server";
import { getPublicImage, handleImageLink } from "helpers/functions";
import { toast } from "react-toastify";

const ImageUpload = ({
  selectedImage,
  setSelectedImage,
  imageUrl = "",
  className = "",
}) => {
  const inputRef = useRef(null);
  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await server().post("/upload", formData);

        setSelectedImage({
          preview: e.target.files[0],
          path: getPublicImage(data.data.files[0]),
        });
      } catch (error) {
        console.log("error", error);
        toast.error(
          error?.response?.data?.errors?.file || error?.message || error?.file
        );
      }
    }
  };

  const removeSelectedImage = () => {
    if (inputRef.current != null) inputRef.current.value = "";
    setSelectedImage("");
  };

  return (
    <div className={`image-uploader image-contain ${className}`}>
      <div className="img-pat">
        <img src={imageUpload} width={46} height={46} alt="" />
      </div>
      <label>
        {(selectedImage?.preview ||
          selectedImage?.path ||
          !!selectedImage?.length) && (
          <div>
            <img
              src={
                selectedImage
                  ? handleImageLink(selectedImage?.path || selectedImage)
                  : handleImageLink(imageUrl)
              }
              width={160}
              height={160}
              alt=""
            />
            <button
              type="button"
              className="removeImg"
              onClick={removeSelectedImage}
            >
              <RemoveImg />
            </button>
          </div>
        )}
        <input
          accept="image/*"
          type="file"
          onChange={imageChange}
          ref={inputRef}
        />
      </label>
    </div>
  );
};

export default ImageUpload;
