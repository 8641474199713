import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editProductCategories, getSingleProductCategory } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import Loader from "../../components/shared/Loader";
import StickyLoader from "components/shared/StickyLoader";
import { toast } from "react-toastify";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import SeoForm from "components/shared/SeoForm";
import { cleanImagelink, handleImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";
const EditCategory = ({ category = false, handleClose = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let { singleCategory, loading, editLoading, error } = useSelector(
    (state) => state.productCategories
  );
  if (category !== false) singleCategory = category;

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    // dispatch(getOptions());
    if (id?.length > 0) dispatch(getSingleProductCategory(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (error === "Validation Error")
      toast.error("تاكد من صحة البيانات (منها عدم تكرار الاسم)");
  }, [error]);

  useEffect(() => {
    reset({
      name: singleCategory?.name,
      image: cleanImagelink(singleCategory?.image),
      // options: (singleCategory?.options || [])?.map((option) => option.id),
      seo: {
        title: singleCategory?.seo?.title,
        description: singleCategory?.seo?.description,
      },
    });
  }, [reset, singleCategory]);

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path)
    // data["image"] = selectedImage?.path || "";
    if (data.image) {
      //   data.image = "uploads/" + data.image.split("/").pop();
      data.icon = data.image;
    }

    dispatch(
      editProductCategories({
        id: category?.id || id,
        data,
        navigate,
        handleClose,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      {!!editLoading && <StickyLoader fill="#FC6B14" />}
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات التصنيف</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة التصنيف</h5>
                      <Controller
                        control={control}
                        name="image"
                        render={({ field: { value, onChange } }) => (
                          <ImageUpload
                            selectedImage={value}
                            setSelectedImage={({ path }) => onChange(path)}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <MultiLangWrapper
                        label="إسم التصنيف"
                        errors={errors?.name}
                        extraListener={[watch("name")]}
                      >
                        {({ lang, classes, key }) => (
                          <input
                            key={key}
                            type="text"
                            className={`form-control form-outline ${classes}`}
                            placeholder="اسم التصنيف"
                            {...register(`name.${lang}`, { required: true })}
                          />
                        )}

                        <p className="error-hint">
                          {errors?.name && "يرجي ادخال اسم التصنيف"}
                        </p>
                      </MultiLangWrapper>
                    </div>
                  </Col>

                  <SeoForm errors={errors} register={register} />

                  <Col lg={12} xs={12}>
                    <div className="form-group d-inline-flex">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
