import React, { useCallback, useMemo, useState } from "react";
import { Accordion, Form } from "react-bootstrap";

import filterIcon from "assets/svgs/filter.svg";
import closeIcon from "assets/svgs/close.svg";
import statusIcon from "assets/svgs/order-status.svg";

import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";

const CustomersFilterSidebar = ({ filtersObj, href = "/customers" }) => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [, setUpdater] = useState(0);

  // const [isLoading, setIsLoading] = useState(false);

  const getDecodedParam = (param) => {
    try {
      return JSON.parse(decodeURI(searchParams?.get(param)));
    } catch (error) {
      return decodeURI(searchParams?.get(param));
    }
  };

  const updateParams = (name, value, arrFlag = false) => {
    let finalValue = "";
    // if (searchParams?.has(name)) {
    if (arrFlag) {
      finalValue = getDecodedParam(name) || [];
      if (!finalValue?.includes(value))
        // finalValue = finalValue?.filter((item) => item !== value);
        // else
        finalValue = JSON.stringify([...finalValue, value]);
    } else {
      if (value?.length === 0) return removeParams(name);
      finalValue = value;
    }
    // }
    searchParams.set(name, finalValue);
    setUpdater(Date.now());
  };

  const removeParams = (name) => {
    searchParams.delete(name);
    setUpdater(Date.now());
  };

  const handleFilterSubmit = async () => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";

    navigate(`${href}${paramsStr}`, { replace: true });

    handleclose();
  };

  const handleReset = () => {
    navigate(`${href}`, {
      replace: true,
    });
    handleclose();
  };

  const handleclose = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const AreasFilter = useCallback(
    ({ filterObj, index }) => {
      const filteredCountry = searchParams.get("country"),
        filteredCity = searchParams.get("city"),
        filteredArea = searchParams.get("area"),
        countries = filterObj?.countries?.data,
        cities = filterObj?.cities?.data?.filter(
          (e) =>
            getId(e?.country) === filteredCountry ||
            filteredCountry === "all" ||
            !filteredCountry
        ),
        areas = filterObj?.areas?.data?.filter(
          (e) =>
            getId(e?.city) === filteredCity ||
            (filteredCity === "all" && filteredCountry === "all") ||
            (!filteredCity && !filteredCountry)
        );

      return (
        <Accordion.Item eventKey={index}>
          <Accordion.Header>
            <span>
              <img src={statusIcon} alt="" />
              <FormattedMessage id="areas" />
            </span>
          </Accordion.Header>
          <Accordion.Body className={filterObj?.className || ""}>
            <ul>
              {[
                { data: countries, name: "country", selected: filteredCountry },
                { data: cities, name: "city", selected: filteredCity },
                { data: areas, name: "area", selected: filteredArea },
              ].map(({ data, name, selected }, index) => (
                <li key={index}>
                  <label>
                    <span>
                      <FormattedMessage id={name} />
                    </span>
                    <Form.Select
                      type={filterObj?.type}
                      // value={selected}
                      onClick={({ target: { value } }) =>
                        updateParams(name, value)
                      }
                    >
                      <option value="all">
                        <FormattedMessage id="all" />
                      </option>
                      {data?.map((item, index) => (
                        <option
                          key={index}
                          value={getId(item)}
                          selected={selected === getId(item)}
                        >
                          {item?.name?.[locale] || (
                            <FormattedMessage id={item} />
                          )}
                        </option>
                      ))}
                    </Form.Select>
                  </label>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, searchParams]
  );

  const renderFilters = useMemo(
    () =>
      Object.keys(filtersObj).map((filterName, index) => {
        const filterObj = filtersObj?.[filterName];
        let data = filterObj?.data;
        if (filterName === "areas")
          data = data?.filter(
            (a) => getId(a?.city) === getId(searchParams?.get("city"))
          );
        if (filterName === "city")
          data = data?.filter(
            (a) => getId(a?.country) === getId(searchParams?.get("country"))
          );
        if (filterObj?.type === "none") return null;
        if (filterObj?.type === "areas")
          return (
            <AreasFilter filterObj={filtersObj} index={index} key={index} />
          );
        if (filterObj?.type === "dateRange")
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                <span>
                  <img src={statusIcon} alt="" />
                  <FormattedMessage id={filterName} />
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="from" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={getDecodedParam("from")}
                        onChange={({ target: { value } }) =>
                          updateParams("from", value)
                        }
                      />
                    </label>
                  </li>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="to" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={getDecodedParam("to")}
                        onChange={({ target: { value } }) =>
                          // (e) =>
                          updateParams("to", value)
                        }
                      />
                    </label>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          );
        if (data?.length === 0) return null;

        return (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <span>
                <img src={statusIcon} alt="" />
                <FormattedMessage id={filterName} />
              </span>
            </Accordion.Header>
            <Accordion.Body className={filterObj?.className || ""}>
              <ul>
                {data?.map((filter, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type={filterObj?.type}
                        name={filterName}
                        value={getId(filter)}
                        onClick={({ target: { value } }) =>
                          updateParams(
                            filterName,
                            value,
                            filterObj?.type === "checkbox"
                          )
                        }
                        checked={
                          getDecodedParam(filterName)?.includes(
                            getId(filter)
                          ) ||
                          searchParams?.get(filterName)?.includes(getId(filter))
                        }
                      />
                      <span>
                        {filter?.name?.[locale] || (
                          <FormattedMessage id={filter} />
                        )}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filtersObj), getDecodedParam, locale, updateParams]
  );

  // if (isLoading) return <StickyLoader fill="#FC6B14" />;

  return (
    <div className="ordersFilterSidebar">
      <div className="sidebarHead">
        <span>
          <img src={filterIcon} alt="" />
          فرز حسب
        </span>
        <button className="closeSidebar" onClick={handleclose}>
          <img src={closeIcon} alt="" />
        </button>
      </div>
      <div className="sidebarBody">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {renderFilters}
        </Accordion>
      </div>
      <div className="sidebarFooter">
        <button className="btn btn-blue" onClick={handleFilterSubmit}>
          حفظ التغييرات
        </button>
        <button className="btn btn-red" onClick={handleReset}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default CustomersFilterSidebar;
