import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import {
  changeProductsReviewStatus,
  deleteProductsReview,
  replyProductsReview,
} from "store/actions";
import Swal from "sweetalert2";
import { ReactComponent as DeleteIcon } from "assets/svgs/trash.svg";
import { ReactComponent as ReplyIcon } from "assets/svgs/reply.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as MarkIcon } from "assets/svgs/mark.svg";
import { Link } from "react-router-dom";
import { getId, handleImage } from "helpers/functions";
import TimeInst from "components/shared/TimeInst";
import useLimitDescription from "components/shared/useLimitDescription";
import { toast } from "react-toastify";

const Card = ({
  data: reviewData,
  index,
  isCheck,
  handleClick,
  // duplicateProduct,
}) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [displayReplyInput, setDisplayReplyInput] = useState(false);
  const [reply, setReply] = useState("");
  const [isRejected, setIsRejected] = useState(false);

  useEffect(() => {
    setReply(reviewData?.answer);
  }, [reviewData]);

  const handleDelete = () => {
    Swal.fire({
      title: formatMessage({ id: "deleteProductReview" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProductsReview(getId(reviewData)));
      }
    });
  };

  const handlePublish = (state) => {
    if (reviewData?.isShow === state) return;
    if (state === false) {
      toast(formatMessage({ id: "addReason" }));
      setIsRejected(true);
      return setDisplayReplyInput(true);
    }
    changeStatus({ isShow: state });
  };

  const changeStatus = (data) =>
    dispatch(changeProductsReviewStatus({ id: getId(reviewData), data: data }));

  const handleToggleReply = () => {
    setDisplayReplyInput(!displayReplyInput);
    // dispatch(replyProductsReview({ id: getId(data), data: { isShown: true } }));
  };

  const handleSendReply = () => {
    const data = { hiddenReason: reply };
    if (isRejected) {
      data.isShow = false;
      setIsRejected(false);
      setDisplayReplyInput(false);
      return changeStatus(data);
    }
    dispatch(
      replyProductsReview({
        id: getId(reviewData),
        data,
        callBack: handleToggleReply,
      })
    );
  };

  const questionTextElem = useLimitDescription({ formatMessage });

  return (
    <div className="card productReview productDataModal modal-dialog w-auto h-auto ">
      <div className="modal-header w-100">
        {/* <img src={handleImage(data?.product?.image)} alt="" /> */}
        <Link to={`/products/${getId(reviewData?.product)}`} target="_blank">
          <h5>{reviewData?.product?.name?.[locale]}</h5>
        </Link>
        <div className="date-tag">
          <h5>
            <TimeInst date={new Date(reviewData?.createdAt)} />
          </h5>

          <div className="tag">
            <FormattedMessage id={reviewData?.type} />
          </div>
        </div>
      </div>
      <div className="user-info">
        <img src={handleImage(reviewData?.user?.image)} alt="" />

        <div>
          <h5>{reviewData?.user?.username}</h5>
          <h6 ref={questionTextElem}>{reviewData?.comment}</h6>
        </div>
      </div>

      <div className="actions">
        <div className="buttons">
          <button
            className={`allow ${reviewData?.isShow ? "active" : ""}`}
            onClick={() => handlePublish(true)}
          >
            <MarkIcon />
            <FormattedMessage id="published" />
          </button>
          <button
            className={`prevent ${!reviewData?.isShow ? "active" : ""}`}
            onClick={() => handlePublish(false)}
          >
            <CloseIcon />
            <FormattedMessage id="unpublished" />
          </button>
          <button onClick={handleToggleReply}>
            <ReplyIcon />
          </button>
        </div>
        <div>
          <button className="delete" onClick={handleDelete}>
            <DeleteIcon />
          </button>
        </div>
      </div>

      {displayReplyInput && (
        <div className="actions reply-form">
          <div className="">
            <textarea
              type="text"
              onChange={(e) => setReply(e.target.value)}
              value={reply}
            />
            <div className="buttons">
              <button type="button" onClick={handleSendReply}>
                <FormattedMessage id="send" />
                {/* <SendIcon /> */}
              </button>
              <button type="button" onClick={handleToggleReply}>
                <FormattedMessage id="cancel" />
                {/* <SendIcon /> */}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
