export default function ColorsList({ variation, selectedOption, onChange }) {
  return (
    <label
      href="#"
      className="color-options-div cursor-pointer"
      title="اختر من قائمة الالوان"
    >
      <div
        className="color-snippet"
        style={{ background: selectedOption?.value }}
      ></div>
      <input type="checkbox" className="checkbox-show-next d-none" />
      <div className="position-absolute">
        <div className="position-relative">
          <div className="color-select">
            {(variation?.variationOption || variationOption)
              ?.map((v) => ({
                label: v,
                value: v,
              }))
              ?.map((option, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className={`color-snippet ${
                    selectedOption?.value === option?.value ? "selected" : ""
                  }`}
                  key={index}
                  style={{ background: option?.value }}
                  onClick={() => onChange(option)}
                >
                  <input type="hidden" />
                </a>
              ))}
          </div>
        </div>
      </div>
    </label>
  );
}

const variationOption = [
  "black",
  "blue",
  "green",
  "aqua",
  "red",
  "fuchsia",
  "yellow",
  "white",
];
