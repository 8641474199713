import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { ReactComponent as GlobIcon } from "assets/svgs/product/global-edit.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getId, getOptionsFrom } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import Select from "react-select";
import { editStoreSlugSetting } from "store/actions";

const TransfereToTjaaraModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, control, watch, setValue } = useForm();
  const [params] = useSearchParams();

  const { settings, loading } = useSelector((state) => state?.settings) || {};

  useEffect(() => {
    reset(settings || {});
  }, [reset, settings]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {
    // e.preventDefault();

    dispatch(
      editStoreSlugSetting({
        data,
        slug: "walletSettings",
        navigate: handleClose,
      })
    );
  };

  return (
    <Modal
      dialogClassName="productDataModal transfere-domain"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <GlobIcon />
            <FormattedMessage id="transfereToTjaara" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-group">
          <h5>
            <FormattedMessage id="domainTrasfereTerms" />
          </h5>
          <ul>
            <li>
              <h5>ان يكون متبقى على الدومين 60 يوم على الاقل قبل الانتهاء</h5>
            </li>
            <li>
              <h5>
                ان يكون البريد الالكترونى هو نفس البريد المستخدم فى الدومين
                الحالى
              </h5>
            </li>
            <li>
              <h5>تزويدنا بالكود الخاص بالدومين</h5>
            </li>
            <li>
              <h5>ان تكون حاله الدومين غير مقفلة</h5>
            </li>
            <li>
              <h5>ان تكون حماية الخصوصية غير مفعلة</h5>
            </li>
          </ul>
          <Row className="form-body">
            <Col xs={12} className="form-group p-0 px-2">
              <h5>
                <FormattedMessage id="domainName" />
              </h5>
              <div className="">
                <input
                  className="form-control form-outline"
                  {...register("domainName", { required: true })}
                />
                <GlobIcon fill="#E4E4E4" />
              </div>
            </Col>
            <Col xs={12} className="form-group p-0 px-2">
              <h5>
                <FormattedMessage id="licenceCode" />
              </h5>
              <div>
                <input
                  className="form-control form-outline"
                  {...register("licenceCode", { required: true })}
                />
                <GlobIcon fill="#E4E4E4" />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {loading && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default TransfereToTjaaraModal;
