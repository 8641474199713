import { takeLatest, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addProductApi,
  deleteAllProductsInStore,
  deleteMultipleProductsApi,
  deleteProductApi,
  editProductApi,
  getProductsApi,
  getProductsInventoryApi,
  getProductsTagsApi,
  getProductsVariationsApi,
  getProductsVariationsStylesApi,
  getSingleProductApi,
  hideProductApi,
  orderProductsApi,
  updateProductMainDataApi,
} from "api/products";

// Login Redux States
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
  GET_SINGLE_PRODUCT,
  UPDATE_PRODUCT_MAIN_DATA,
  ADD_FAKE_PRODUCT,
  DELETE_EMPTY_PRODUCT,
  GET_VARIATIONS,
  GET_VARIATIONS_STYLES,
  DELETE_PRODUCTS,
  LOAD_MORE_PRODUCTS,
  ORDER_PRODUCTS,
  GET_PRODUCTS_INVENTORY,
  GET_PRODUCTS_TAGS,
  HIDE_PRODUCT,
} from "./actionTypes";
import {
  addProductFailure,
  addProductSuccess,
  deleteProductFailure,
  deleteProductSuccess,
  editProductFailure,
  editProductSuccess,
  getProductsFailure,
  getProductsSuccess,
  getSingleProductFailure,
  getSingleProductSuccess,
  updateProductMainDataFailure,
  addFakeProductSuccess,
  addFakeProductFailure,
  updateProductMainDataSuccess,
  deleteEmptyProductSuccess,
  deleteEmptyProductFailure,
  getProductsVariationsSuccess,
  getProductsVariationsFailure,
  getProductsVariationsStylesSuccess,
  getProductsVariationsStylesFailure,
  deleteProductsSuccess,
  deleteProductsFailure,
  loadMoreProductsSuccess,
  loadMoreProductsFailure,
  getProductsInventoryFailure,
  getProductsInventorySuccess,
  orderProductsSuccess,
  orderProductsFailure,
  getProductsTagsSuccess,
  getProductsTagsFailure,
  hideProductSuccess,
  hideProductFailure,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

// =============================================================
// ================== Sagas ====================================

function* getProducts({ payload }) {
  try {
    const { data } = yield call(getProductsApi, payload);
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getProductsFailure(error?.response?.data));
  }
}
// ======================================================

function* getProductsTags({ payload }) {
  try {
    const { data } = yield call(getProductsTagsApi, payload);
    yield put(getProductsTagsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getProductsTagsFailure(error?.response?.data));
  }
}

// =============================================================

function* getProductsInventory({ payload }) {
  try {
    const { data } = yield call(getProductsInventoryApi, payload);
    yield put(getProductsInventorySuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getProductsInventoryFailure(error?.response?.data));
  }
}

// =============================================================

function* getProductsVariations() {
  try {
    const { data } = yield call(getProductsVariationsApi);
    yield put(getProductsVariationsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getProductsVariationsFailure(error?.response?.data));
  }
}

// =============================================================

function* getProductsVariationsStyles() {
  try {
    const { data } = yield call(getProductsVariationsStylesApi);
    yield put(getProductsVariationsStylesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getProductsVariationsStylesFailure(error?.response?.data));
  }
}

// =============================================================

function* addProduct({ payload }) {
  try {
    if (payload?.multi) return yield duplicateProducts({ payload });

    const { data } = yield call(addProductApi, payload.data);
    yield put(addProductSuccess(data));
    // if (typeof payload?.callBack == "function") payload.callBack();
    payload?.navigate("/products");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(addProductFailure(error.response?.data || error));
  }
}

function* duplicateProducts({ payload }) {
  try {
    for (let product of payload?.data) {
      try {
        const { data } = yield call(addProductApi, product);
        yield put(addProductSuccess({ ...data, loading: true }));
      } catch (error) {
        console.log(error);
        toastErrorMessage({ error: error?.response?.data });
      }
    }
    // if (typeof payload?.callBack == "function") payload.callBack();
    yield getProducts(payload?.searchParams);

    // payload?.navigate("/products");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(addProductFailure(error.response?.data || error));
  }
}

// =============================================================

function* getSingleProduct({ payload: id }) {
  try {
    const { data } = yield call(getSingleProductApi, id);
    // if (data?.product) data.product.id = id;
    yield put(getSingleProductSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getSingleProductFailure(error?.response?.data || error));
  }
}

// =============================================================

function* editProduct({ payload }) {
  try {
    const { data } = yield call(editProductApi, payload);
    yield put(editProductSuccess(data));
    payload?.navigate("/products");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });

    console.log("error", error);
    yield put(editProductFailure(error?.response?.data));
  }
}

// =============================================================

function* deleteProduct({ payload }) {
  try {
    yield call(deleteProductApi, payload);
    yield put(deleteProductSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteProductFailure(error?.response?.data || error));
  }
}

// =============================================================

function* deleteProducts({ payload: { products, searchParams } }) {
  try {
    // for (let index in products) {
    //   yield call(deleteProductApi, products[index]);
    // }
    if (products === "all") yield call(deleteAllProductsInStore);
    else yield call(deleteMultipleProductsApi, { products });
    yield put(deleteProductsSuccess(products));

    yield getProducts(searchParams);
    // //renewing the products
    // const { data } = yield call(getProductsApi, searchParams);
    // yield put(getProductsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteProductsFailure(error?.response?.data || error));
  }
}

// =============================================================

function* updateProductMainData({ payload }) {
  try {
    const { data } = yield call(updateProductMainDataApi, payload);
    yield put(updateProductMainDataSuccess(data.product));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(updateProductMainDataFailure(error?.response?.data || error));
  }
}

// =============================================================

function* hideProduct({ payload }) {
  try {
    const { data } = yield call(hideProductApi, payload);
    yield put(hideProductSuccess(data.product));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(hideProductFailure(error?.response?.data || error));
  }
}

// =============================================================

function* addFakeProduct({ payload }) {
  try {
    yield put(addFakeProductSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(addFakeProductFailure(error));
  }
}

// =============================================================

function* deleteEmptyProduct({ payload }) {
  try {
    yield put(deleteEmptyProductSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteEmptyProductFailure(error?.response?.data || error));
  }
}

// =============================================================

function* loadMoreProducts({ payload }) {
  try {
    const { data } = yield call(getProductsApi, payload);
    yield put(loadMoreProductsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(loadMoreProductsFailure(JSON.stringify(error)));
  }
}

// =============================================================

function* orderProducts({ payload }) {
  try {
    const { data } = yield call(orderProductsApi, payload);
    yield put(orderProductsSuccess(data));
    if (payload?.callBack) payload.callBack();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(orderProductsFailure(JSON.stringify(error)));
  }
}

// =====================================================
// ================== Watchers =========================

export function* watchGetProducts() {
  yield takeLatest(GET_PRODUCTS, getProducts);
}

export function* watchGetProductsTags() {
  yield takeLatest(GET_PRODUCTS_TAGS, getProductsTags);
}

export function* watchGetProductsInventory() {
  yield takeLatest(GET_PRODUCTS_INVENTORY, getProductsInventory);
}

export function* watchAddProduct() {
  yield takeLatest(ADD_PRODUCT, addProduct);
}

export function* watchGetSingleProduct() {
  yield takeLatest(GET_SINGLE_PRODUCT, getSingleProduct);
}

export function* watchEditProduct() {
  yield takeLatest(EDIT_PRODUCT, editProduct);
}

export function* watchUpdateProductMainData() {
  yield takeLatest(UPDATE_PRODUCT_MAIN_DATA, updateProductMainData);
}

export function* watchHideProduct() {
  yield takeLatest(HIDE_PRODUCT, hideProduct);
}

export function* watchDeleteProduct() {
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
}
export function* watchDeleteProducts() {
  yield takeLatest(DELETE_PRODUCTS, deleteProducts);
}

export function* watchAddFakeProduct() {
  yield takeLatest(ADD_FAKE_PRODUCT, addFakeProduct);
}

export function* watchDeleteEmptyProduct() {
  yield takeLatest(DELETE_EMPTY_PRODUCT, deleteEmptyProduct);
}

export function* watchGetProductsVariations() {
  yield takeLatest(GET_VARIATIONS, getProductsVariations);
}

export function* watchGetProductsVariationsStyles() {
  yield takeLatest(GET_VARIATIONS_STYLES, getProductsVariationsStyles);
}

export function* watchLoadMoreProducts() {
  yield takeLatest(LOAD_MORE_PRODUCTS, loadMoreProducts);
}

export function* watchOrderProducts() {
  yield takeLatest(ORDER_PRODUCTS, orderProducts);
}

// =====================================================
// ================== Root Saga ========================

function* productsSaga() {
  yield all([fork(watchGetProducts)]);
  yield all([fork(watchGetProductsTags)]);
  yield all([fork(watchGetProductsInventory)]);
  yield all([fork(watchAddProduct)]);
  yield all([fork(watchGetSingleProduct)]);
  yield all([fork(watchEditProduct)]);
  yield all([fork(watchDeleteProduct)]);
  yield all([fork(watchDeleteProducts)]);
  yield all([fork(watchHideProduct)]);
  yield all([fork(watchUpdateProductMainData)]);
  yield all([fork(watchAddFakeProduct)]);
  yield all([fork(watchDeleteEmptyProduct)]);
  yield all([fork(watchGetProductsVariations)]);
  yield all([fork(watchGetProductsVariationsStyles)]);
  yield all([fork(watchLoadMoreProducts)]);
  yield all([fork(watchOrderProducts)]);
}

export default productsSaga;
