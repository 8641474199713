import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as RemoveIcon } from "assets/svgs/close-icon.svg";
import { getId } from "helpers/functions";
import { useIntl, FormattedMessage } from "react-intl";

export default function ParamsFilters({
  categories = [],
  productTypes = [],
  brands = [],
  countries = [],
  cities = [],
  areas = [],
  groups = [],
  href = "/products",
}) {
  const navigate = useNavigate();
  const { locale } = useIntl();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState([]);

  const queryParams = Object.fromEntries([...searchParams]);
  const paramsArray = Object.entries(queryParams);

  const tryParse = (v) => {
    try {
      return JSON.parse(v);
    } catch (error) {
      console.log(error);
      return v;
    }
  };

  useEffect(() => {
    setParams(
      paramsArray?.filter(
        (e) =>
          e?.[0] !== "nameText" &&
          e?.[0] !== "searchText" &&
          e?.[0] !== "username" &&
          e?.[0] !== "search" &&
          e?.[0] !== "product" &&
          e?.[0] !== "group" &&
          e?.[0] !== "page" &&
          e?.[0] !== "limit" &&
          e?.[0] !== "sort"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const removeParams = (name) => {
    searchParams.delete(name);
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`${href}${paramsStr}`, {
      replace: true,
    });
  };

  if (params?.length <= 0) return null;

  return (
    <div className="filter-params">
      {params?.map((param, index) => {
        return (
          <div key={index} className="param-item">
            <button
              type="button"
              onClick={() => {
                removeParams(param[0]);
              }}
            >
              <RemoveIcon />
            </button>
            {param?.[0] === "categories"
              ? tryParse(param?.[1])
                  ?.map(
                    (id) =>
                      categories.find((category) => getId(category) === id)
                        ?.name?.[locale]
                  )
                  ?.join(", ")
              : ""}
            {param?.[0] === "type"
              ? tryParse(param?.[1])
                  ?.map(
                    (id) =>
                      productTypes.find((type) => getId(type) === id)?.name?.[
                        locale
                      ]
                  )
                  ?.join(", ")
              : ""}
            {param?.[0] === "types"
              ? tryParse(param?.[1])
                  ?.map(
                    (id) =>
                      productTypes.find((type) => getId(type) === id)?.name?.[
                        locale
                      ]
                  )
                  ?.join(", ")
              : ""}
            {param?.[0] === "brands"
              ? tryParse(param?.[1])
                  ?.map(
                    (id) =>
                      brands.find((brand) => getId(brand) === id)?.name?.[
                        locale
                      ]
                  )
                  ?.join(", ")
              : ""}
            {(param?.[0] === "stock" || param?.[0] === "quantity") &&
            param?.[1] === "0"
              ? "منتجات نفذت"
              : ""}
            {param?.[0] === "isShown" && param?.[1] === "false"
              ? "منتجات مخفية"
              : ""}
            {param?.[0] === "isRequiredShipping" && param?.[1] === "true"
              ? "منتجات تتطلب شحن"
              : ""}
            {param?.[0] === "outOfStock" && param?.[1] === "true"
              ? "منتجات قاربت على النفاذ"
              : ""}
            {param?.[0] === "nameText" ? param?.[1] : ""}
            {param?.[0] === "from" ? param?.[1] : ""}
            {param?.[0] === "to" ? param?.[1] : ""}
            {param?.[0] === "tags"
              ? tryParse(param?.[1] || "[]").join(", ")
              : ""}
            {param?.[0] === "createdAt" ? param?.[1] : ""}
            {param?.[0] === "country"
              ? countries.find((country) => getId(country) === param?.[1])
                  ?.name?.[locale] || param?.[1]
              : ""}
            {param?.[0] === "city"
              ? cities.find((city) => getId(city) === param?.[1])?.name?.[
                  locale
                ] || param?.[1]
              : ""}
            {param?.[0] === "area"
              ? areas.find((area) => getId(area) === param?.[1])?.name?.[
                  locale
                ] || param?.[1]
              : ""}
            {param?.[0] === "groups"
              ? tryParse(param?.[1] || "[]")
                  ?.map(
                    (c) =>
                      groups.find((group) => getId(group) === c)?.name?.[
                        locale
                      ] || c
                  )
                  .join(", ")
              : ""}
            {param?.[0] === "gender" ? (
              <FormattedMessage id={param?.[1] || " "} />
            ) : (
              ""
            )}
            {param?.[0] === "status" ? (
              <FormattedMessage id={param?.[1] || " "} />
            ) : (
              ""
            )}
          </div>
        );
      })}

      <button
        type="button"
        onClick={() => {
          setParams([]);
          navigate(href);
        }}
        className="btn btn-blue"
      >
        مسح الكل
      </button>
    </div>
  );
}
