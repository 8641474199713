import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addFaqApi,
  deleteFaqApi,
  editFaqApi,
  getFaqsApi,
  getSingleFaqApi,
} from "api/faqs";

// Login Redux States
import {
  ADD_FAQ,
  DELETE_FAQ,
  EDIT_FAQ,
  GET_FAQS,
  GET_SINGLE_FAQ,
} from "./actionTypes";
import {
  addFaqFailure,
  addFaqsuccess,
  deleteFaqFailure,
  deleteFaqsuccess,
  editFaqFailure,
  editFaqsuccess,
  getFaqsFailure,
  getFaqsSuccess,
  getSingleFaqFailure,
  getSingleFaqsuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getFaqs() {
  try {
    const { data } = yield call(getFaqsApi);
    yield put(getFaqsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getFaqsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addFaq({ payload }) {
  try {
    const { data } = yield call(addFaqApi, payload.data);
    yield put(addFaqsuccess(data));
    yield payload.navigate("/faqs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(addFaqFailure(error.response?.data?.message || error));
  }
}

function* getSingleFaq({ payload }) {
  try {
    const { data } = yield call(getSingleFaqApi, payload);
    yield put(getSingleFaqsuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getSingleFaqFailure(error?.response?.data || error));
  }
}

function* editFaq({ payload }) {
  try {
    yield call(editFaqApi, payload);
    yield put(editFaqsuccess(payload));
    yield payload.navigate("/faqs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(editFaqFailure(error?.response?.data || error));
  }
}

function* deleteFaq({ payload }) {
  try {
    yield call(deleteFaqApi, payload);
    yield put(deleteFaqsuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(deleteFaqFailure(error?.response?.data || error));
  }
}

export function* watchGetFaqs() {
  yield takeEvery(GET_FAQS, getFaqs);
}

export function* watchAddFaq() {
  yield takeEvery(ADD_FAQ, addFaq);
}

export function* watchGetSingleFaq() {
  yield takeEvery(GET_SINGLE_FAQ, getSingleFaq);
}

export function* watchEditFaq() {
  yield takeEvery(EDIT_FAQ, editFaq);
}

export function* watchDeleteFaq() {
  yield takeEvery(DELETE_FAQ, deleteFaq);
}

function* blogsSaga() {
  yield all([fork(watchGetFaqs)]);
  yield all([fork(watchAddFaq)]);
  yield all([fork(watchGetSingleFaq)]);
  yield all([fork(watchEditFaq)]);
  yield all([fork(watchDeleteFaq)]);
}

export default blogsSaga;
