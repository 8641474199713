import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getStoresApi,
  addStoreApi,
  editStoreApi,
  deleteStoreApi,
  getActiveStoreApi,
  getStoreThemesApi,
  setActiveThemeApi,
  addThemeToStoreApi,
  changestoreStatusApi,
  editStoreThemeApi,
} from "../../api/stores";

// Login Redux States
import {
  ADD_STORE,
  DELETE_STORE,
  EDIT_STORE,
  GET_ACTIVE_STORE,
  SET_ACTIVE_THEME,
  GET_STORES,
  ADD_THEME_TO_STORE,
  CHANGE_STORE_STATUS,
  EDIT_STORE_THEME,
  GET_ACTIVE_STORE_THEMES,
} from "./actionTypes";
import {
  addStoreFailure,
  addStoreSuccess,
  deleteStoreFailure,
  deleteStoreSuccess,
  editStoreFailure,
  editStoreSuccess,
  getActiveStoreFailure,
  getActiveStoreSuccess,
  setActiveThemeFailure,
  setActiveThemeSuccess,
  getStoresFailure,
  getStoresSuccess,
  addThemeToStoreSuccess,
  addThemeToStoreFailure,
  changeStoreStatusSuccess,
  changeStoreStatusFailure,
  editStoreThemeSuccess,
  editStoreThemeFailure,
  getActiveStoreThemesSuccess,
  getActiveStoreThemesFailure,
} from "./actions";

function* getStores() {
  try {
    const { data } = yield call(getStoresApi);
    yield put(getStoresSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getStoresFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getActiveStore({ payload }) {
  try {
    const { data } = yield call(getActiveStoreApi, payload);

    yield put(getActiveStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getActiveStoreFailure(error?.response?.data || error));
  }
}

function* getActiveStoreThemes({ payload }) {
  try {
    const { data } = yield call(getStoreThemesApi, payload);

    yield put(getActiveStoreThemesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getActiveStoreThemesFailure(error?.response?.data || error));
  }
}

function* setActiveTheme({ payload }) {
  try {
    const { data } = yield call(setActiveThemeApi, payload);
    yield put(setActiveThemeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(setActiveThemeFailure(error?.response?.data || error));
  }
}

function* addThemeToStore({ payload }) {
  try {
    const { data, success } = yield call(addThemeToStoreApi, payload);
    if (success) yield put(addThemeToStoreSuccess(data));
    // yield getActiveStoreThemes({ payload });
  } catch (error) {
    console.log(error);
    yield put(addThemeToStoreFailure(error?.response?.data || error));
  }
}

function* addStore({ payload }) {
  try {
    const { data } = yield call(addStoreApi, payload.data);
    yield put(addStoreSuccess(data));
    yield payload.navigate("/stores");
  } catch (error) {
    console.log(error);
    yield put(addStoreFailure(error.response?.data?.message || error));
  }
}

function* editStore({ payload }) {
  try {
    yield call(editStoreApi, payload);
    yield put(editStoreSuccess(payload));
    yield payload.navigate("/stores");
  } catch (error) {
    console.log(error);
    yield put(editStoreFailure(error?.response?.data || error));
  }
}

function* deleteStore({ payload }) {
  try {
    yield call(deleteStoreApi, payload);
    yield put(deleteStoreSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteStoreFailure(error?.response?.data || error));
  }
}

function* changeStoreStatus({ payload }) {
  try {
    const { data } = yield call(changestoreStatusApi, payload);
    yield put(changeStoreStatusSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(changeStoreStatusFailure(error.response?.data?.message || error));
  }
}

function* editStoreTheme({ payload: { themeId, formData, setRefreshIframe } }) {
  try {
    const { data } = yield call(editStoreThemeApi, { themeId, formData });
    yield put(editStoreThemeSuccess(data));
    yield setRefreshIframe(Math.random());
  } catch (error) {
    console.log(error);
    yield put(editStoreThemeFailure(error.response?.data?.message || error));
  }
}

export function* watchGetStores() {
  yield takeEvery(GET_STORES, getStores);
}

export function* watchGetActiveStore() {
  yield takeEvery(GET_ACTIVE_STORE, getActiveStore);
}

export function* watchGetActiveStoreThemes() {
  yield takeEvery(GET_ACTIVE_STORE_THEMES, getActiveStoreThemes);
}

export function* watchSetActiveTheme() {
  yield takeEvery(SET_ACTIVE_THEME, setActiveTheme);
}

export function* watchAddThemeToStore() {
  yield takeEvery(ADD_THEME_TO_STORE, addThemeToStore);
}

export function* watchAddStore() {
  yield takeEvery(ADD_STORE, addStore);
}

export function* watchEditStore() {
  yield takeEvery(EDIT_STORE, editStore);
}

export function* watchDeleteStore() {
  yield takeEvery(DELETE_STORE, deleteStore);
}

export function* watchChangeStoreStatus() {
  yield takeEvery(CHANGE_STORE_STATUS, changeStoreStatus);
}

export function* watchEditStoreTheme() {
  yield takeEvery(EDIT_STORE_THEME, editStoreTheme);
}

function* storesSaga() {
  yield all([fork(watchGetStores)]);
  yield all([fork(watchGetActiveStore)]);
  yield all([fork(watchGetActiveStoreThemes)]);
  yield all([fork(watchSetActiveTheme)]);
  yield all([fork(watchAddThemeToStore)]);
  yield all([fork(watchAddStore)]);
  yield all([fork(watchEditStore)]);
  yield all([fork(watchDeleteStore)]);
  yield all([fork(watchChangeStoreStatus)]);
  yield all([fork(watchEditStoreTheme)]);
}

export default storesSaga;
