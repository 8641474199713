import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  EDIT_SETTINGS,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAILURE,
  EDIT_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAILURE,
  EDIT_STORE_SLUG_SETTING_SUCCESS,
  EDIT_STORE_SLUG_SETTING_FAILURE,
  EDIT_STORE_SLUG_SETTING,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILURE,
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
} from "./actionTypes";

export const getSettings = (payload) => {
  return {
    type: GET_SETTINGS,
    payload: payload,
  };
};
export const getSettingsSuccess = (payload) => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const getSettingsFailure = (error) => {
  return {
    type: GET_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editSettings = (payload) => {
  return {
    type: EDIT_SETTINGS,
    payload: payload,
  };
};
export const editSettingsSuccess = (payload) => {
  return {
    type: EDIT_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editSettingsFailure = (error) => {
  return {
    type: EDIT_SETTINGS_FAILURE,
    payload: error,
  };
};

// get bank accounts ------------------------------------
export const getBankAccounts = (payload) => {
  return {
    type: GET_BANK_ACCOUNTS,
    payload: payload,
  };
};
export const getBankAccountsSuccess = (payload) => {
  return {
    type: GET_BANK_ACCOUNTS_SUCCESS,
    payload: payload,
  };
};

export const getBankAccountsFailure = (error) => {
  return {
    type: GET_BANK_ACCOUNTS_FAILURE,
    payload: error,
  };
};

//add bank account
export const addBankAccount = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT,
    payload,
  };
};

export const addBankAccountSuccess = (id) => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
    payload: id,
  };
};

export const addBankAccountFailure = (error) => {
  return {
    type: ADD_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

//edit bank account
export const editBankAccount = (payload) => {
  return {
    type: EDIT_BANK_ACCOUNT,
    payload,
  };
};

export const editBankAccountSuccess = (id) => {
  return {
    type: EDIT_BANK_ACCOUNT_SUCCESS,
    payload: id,
  };
};

export const editBankAccountFailure = (error) => {
  return {
    type: EDIT_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

//edit bank account
export const setDefaultBankAccount = (payload) => {
  return {
    type: SET_DEFAULT_BANK_ACCOUNT,
    payload,
  };
};

export const setDefaultBankAccountSuccess = (id) => {
  return {
    type: SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
    payload: id,
  };
};

export const setDefaultBankAccountFailure = (error) => {
  return {
    type: SET_DEFAULT_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

//slug settings
export const editStoreSlugSetting = (payload) => {
  return {
    type: EDIT_STORE_SLUG_SETTING,
    payload,
  };
};

export const editStoreSlugSettingSuccess = (id) => {
  return {
    type: EDIT_STORE_SLUG_SETTING_SUCCESS,
    payload: id,
  };
};

export const editStoreSlugSettingFailure = (error) => {
  return {
    type: EDIT_STORE_SLUG_SETTING_FAILURE,
    payload: error,
  };
};
//delete settings
export const deleteBankAccount = (payload) => {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload,
  };
};

export const deleteBankAccountSuccess = (id) => {
  return {
    type: DELETE_BANK_ACCOUNT_SUCCESS,
    payload: id,
  };
};

export const deleteBankAccountFailure = (error) => {
  return {
    type: DELETE_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};
