import {
  GET_THEME_TEMPLATES,
  GET_THEME_TEMPLATES_SUCCESS,
  GET_THEME_TEMPLATES_FAILURE,
} from "./actionTypes";

export const getThemeTemplates = (payload) => {
  return {
    type: GET_THEME_TEMPLATES,
    payload,
  };
};

export const getThemeTemplatesSuccess = (themes) => {
  return {
    type: GET_THEME_TEMPLATES_SUCCESS,
    payload: themes,
  };
};

export const getThemeTemplatesFailure = (error) => {
  return {
    type: GET_THEME_TEMPLATES_FAILURE,
    payload: error,
  };
};
