export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const EDIT_SETTINGS_SUCCESS = "EDIT_SETTINGS_SUCCESS";
export const EDIT_SETTINGS_FAILURE = "EDIT_SETTINGS_FAILURE";

export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_BANK_ACCOUNTS_SUCCESS = "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_FAILURE = "GET_BANK_ACCOUNTS_FAILURE";

export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const EDIT_BANK_ACCOUNT = "EDIT_BANK_ACCOUNT";
export const EDIT_BANK_ACCOUNT_SUCCESS = "EDIT_BANK_ACCOUNT_SUCCESS";
export const EDIT_BANK_ACCOUNT_FAILURE = "EDIT_BANK_ACCOUNT_FAILURE";

export const SET_DEFAULT_BANK_ACCOUNT = "SET_DEFAULT_BANK_ACCOUNT";
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "SET_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  "SET_DEFAULT_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const EDIT_STORE_SLUG_SETTING = "EDIT_STORE_SLUG_SETTING";
export const EDIT_STORE_SLUG_SETTING_SUCCESS =
  "EDIT_STORE_SLUG_SETTING_SUCCESS";
export const EDIT_STORE_SLUG_SETTING_FAILURE =
  "EDIT_STORE_SLUG_SETTING_FAILURE";

export const EDIT_STORE_BASIC_SETTINGS = "EDIT_STORE_BASIC_SETTINGS";
export const EDIT_STORE_BASIC_SETTINGS_SUCCESS =
  "EDIT_STORE_BASIC_SETTINGS_SUCCESS";
export const EDIT_STORE_BASIC_SETTINGS_FAILURE =
  "EDIT_STORE_BASIC_SETTINGS_FAILURE";
