export const GET_FAQ_CATEGORIES = "GET_FAQ_CATEGORIES";
export const GET_FAQ_CATEGORIES_SUCCESS = "GET_FAQ_CATEGORIES_SUCCESS";
export const GET_FAQ_CATEGORIES_FAILURE = "GET_FAQ_CATEGORIES_FAILURE";

export const GET_SINGLE_FAQ_CATEGORY = "GET_SINGLE_FAQ_CATEGORY";
export const GET_SINGLE_FAQ_CATEGORY_SUCCESS =
  "GET_SINGLE_FAQ_CATEGORY_SUCCESS";
export const GET_SINGLE_FAQ_CATEGORY_FAILURE =
  "GET_SINGLE_FAQ_CATEGORY_FAILURE";

export const ADD_FAQ_CATEGORY = "ADD_FAQ_CATEGORY";
export const ADD_FAQ_CATEGORY_SUCCESS = "ADD_FAQ_CATEGORY_SUCCESS";
export const ADD_FAQ_CATEGORY_FAILURE = "ADD_FAQ_CATEGORY_FAILURE";

export const EDIT_FAQ_CATEGORY = "EDIT_FAQ_CATEGORY";
export const EDIT_FAQ_CATEGORY_SUCCESS = "EDIT_FAQ_CATEGORY_SUCCESS";
export const EDIT_FAQ_CATEGORY_FAILURE = "EDIT_FAQ_CATEGORY_FAILURE";

export const DELETE_FAQ_CATEGORY = "DELETE_FAQ_CATEGORY";
export const DELETE_FAQ_CATEGORY_SUCCESS = "DELETE_FAQ_CATEGORY_SUCCESS";
export const DELETE_FAQ_CATEGORY_FAILURE = "DELETE_FAQ_CATEGORY_FAILURE";
