import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_FAILURE,
  ADD_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORIES_SUCCESS,
  ADD_PRODUCT_CATEGORIES_FAILURE,
  EDIT_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_FAILURE,
  DELETE_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES_SUCCESS,
  DELETE_PRODUCT_CATEGORIES_FAILURE,
  GET_SINGLE_PRODUCT_CATEGORY,
  GET_SINGLE_PRODUCT_CATEGORY_SUCCESS,
  GET_SINGLE_PRODUCT_CATEGORY_FAILURE,
  EDIT_PRODUCT_CATEGORIES_ORDER,
  EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE,
  EDIT_SUB_PRODUCT_CATEGORIES_FAILURE,
  EDIT_SUB_PRODUCT_CATEGORIES_SUCCESS,
  EDIT_SUB_PRODUCT_CATEGORIES,
  ADD_SUB_PRODUCT_CATEGORIES_FAILURE,
  ADD_SUB_PRODUCT_CATEGORIES_SUCCESS,
  ADD_SUB_PRODUCT_CATEGORIES,
} from "./actionTypes";

export const getProductCategories = (payload) => {
  return {
    type: GET_PRODUCT_CATEGORIES,
    payload,
  };
};

export const getProductCategoriesSuccess = (payload) => {
  return {
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const getProductCategoriesFailure = (error) => {
  return {
    type: GET_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const getSingleProductCategory = (id) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY,
    payload: id,
  };
};

export const getSingleProductCategorySuccess = (productCategory) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategory,
  };
};

export const getSingleProductCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const addProductCategories = (payload) => {
  return {
    type: ADD_PRODUCT_CATEGORIES,
    payload,
  };
};

export const addProductCategoriesSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const addProductCategoriesFailure = (error) => {
  return {
    type: ADD_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const editProductCategories = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES,
    payload,
  };
};

export const editProductCategoriesSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const editProductCategoriesFailure = (error) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const editProductCategoriesOrder = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER,
    payload,
  };
};

export const editProductCategoriesOrderSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS,
    payload,
  };
};

export const editProductCategoriesOrderFailure = (error) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const deleteProductCategories = (id) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES,
    payload: id,
  };
};

export const deleteProductCategoriesSuccess = (id) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES_SUCCESS,
    payload: id,
  };
};

export const deleteProductCategoriesFailure = (error) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const addSubProductCategories = (payload) => {
  return {
    type: ADD_SUB_PRODUCT_CATEGORIES,
    payload,
  };
};

export const addSubProductCategoriesSuccess = (payload) => {
  return {
    type: ADD_SUB_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const addSubProductCategoriesFailure = (error) => {
  return {
    type: ADD_SUB_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

// ===============================================================

export const editSubProductCategories = (payload) => {
  return {
    type: EDIT_SUB_PRODUCT_CATEGORIES,
    payload,
  };
};

export const editSubProductCategoriesSuccess = (payload) => {
  return {
    type: EDIT_SUB_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const editSubProductCategoriesFailure = (error) => {
  return {
    type: EDIT_SUB_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};
