import {
  ADD_FAQ_CATEGORY,
  ADD_FAQ_CATEGORY_FAILURE,
  ADD_FAQ_CATEGORY_SUCCESS,
  DELETE_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY_FAILURE,
  DELETE_FAQ_CATEGORY_SUCCESS,
  GET_FAQ_CATEGORIES,
  GET_FAQ_CATEGORIES_FAILURE,
  GET_FAQ_CATEGORIES_SUCCESS,
  GET_SINGLE_FAQ_CATEGORY,
  GET_SINGLE_FAQ_CATEGORY_FAILURE,
  GET_SINGLE_FAQ_CATEGORY_SUCCESS,
} from "./actionTypes";

const initialState = {
  faqsCategories: [],
  loading: false,
  error: "",
  singleFaqCategory: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FAQ_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        faqsCategories: action.payload.categories,
      };
      break;
    case GET_FAQ_CATEGORIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_FAQ_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_FAQ_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleFaqCategory: action.payload.category,
      };
      break;
    case GET_SINGLE_FAQ_CATEGORY_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleFaqCategory: {},
      };
      break;

    case ADD_FAQ_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_FAQ_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        faqsCategories: action.payload.faqsCategories,
      };
      break;
    case ADD_FAQ_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_FAQ_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FAQ_CATEGORY_SUCCESS:
      const faqsCategoriesAfterDeleteing = [
        ...state?.faqsCategories?.filter(
          (faqCategory) => faqCategory?.id !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        faqsCategories: faqsCategoriesAfterDeleteing,
      };
      break;
    case DELETE_FAQ_CATEGORY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
