import { toast } from "react-toastify";
import En from "lang/en.json";
import Ar from "lang/ar.json";

export const toastSuccessMessage = ({
  msg,
  locale = "ar",
  autoClose = 2000,
}) => {
  if (document?.documentElement?.lang) locale = document.documentElement.lang;

  const langFile = locale === "ar" ? Ar : En;

  let message =
    msg?.errors?.length > 0
      ? msg?.errors
          ?.map(
            (e) =>
              `${e?.param ? `(${langFile?.[e?.param] || e?.param}) : ` : ""}${
                e?.msg
              }`
          )
          ?.join(", ")
      : msg?.message || msg?.statusCode || `${langFile?.[msg] || msg}`;
  if (message?.length > 120) message = message?.slice(0, 120) + " ...";

  toast.success(<div dir="ltr">{message}</div>, {
    position: locale === "ar" ? "bottom-left" : "bottom-right",
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
