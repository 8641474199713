import {
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  ADD_OPTION,
  ADD_OPTION_SUCCESS,
  ADD_OPTION_FAILURE,
  DELETE_OPTION,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAILURE,
} from "./actionTypes";

const initialState = {
  options: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_OPTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        options: action.payload.options,
      };
      break;
    case GET_OPTIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_OPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_OPTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        options: action.payload.options,
      };
      break;
    case ADD_OPTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_OPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_OPTION_SUCCESS:
      const optionsAfterDeleteing = [
        ...state?.options?.filter((option) => option?.id !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        options: optionsAfterDeleteing,
      };
      break;
    case DELETE_OPTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
