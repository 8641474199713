import React, { useCallback, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as StockIcon } from "assets/svgs/settings/money-recive.svg";
import { ReactComponent as CardIcon } from "assets/svgs/card.svg";
import { ReactComponent as Dollar } from "assets/svgs/product/dollar.svg";
import { ReactComponent as Card2Icon } from "assets/svgs/settings/card.svg";
import { ReactComponent as BankIcon } from "assets/svgs/bank.svg";
import { Input } from "components/shared/FormComponents";
import { getId, handleImage } from "helpers/functions";
import PaymentCardInputs from "./PaymentCardInputs";
import ImageUpload from "components/shared/ImageUpload";
import { useNavigate, useSearchParams } from "react-router-dom";

const BalanceModal = ({ defaultBank, modalShow, setModalShow, settings }) => {
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [params] = useSearchParams();
  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };
  const handleClose = () => removeParams("modal");

  const BankInfo = () =>
    watch("method") === "bank" && (
      <div className="bank-info">
        <div>
          <p>
            <BankIcon />
            <FormattedMessage id="bankInfo" />
          </p>
          <p>
            {[defaultBank?.bank?.name?.[locale], defaultBank?.userName]
              .filter(Boolean)
              .join(", ")}
          </p>
          <p className="small">{defaultBank?.iban}</p>
          <p className="small">{defaultBank?.swift}</p>
        </div>
        <div>
          <p>
            <FormattedMessage id="addTransfereImage" />
          </p>
          <ImageUpload
            className="m-1"
            selectedImage={watch("transfereImage")}
            setSelectedImage={(obj) => setValue("transfereImage", obj)}
          />
        </div>
      </div>
    );

  const renderCards = (
    settings?.cards || [
      {
        id: "qwewqewqe",
        name: "card1",
        number: "123412341234234",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png",
      },
      {
        id: "qwew23qewqe",
        name: "card1",
        number: "1234123412334",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png",
      },
    ]
  )?.map((card, index) => (
    <label
      className={`card ${
        watch("paymentMethod") === getId(card) ? "active" : ""
      }`}
      key={index}
    >
      <div className="info">
        <Card2Icon />
        <img src={handleImage(card?.image)} width="25" alt={card?.name} />
        <p>{card?.number?.slice(-4)}</p>
      </div>
      <input
        type="radio"
        {...register("paymentMethod")}
        value={getId(card)}
        id=""
      />
    </label>
  ));

  const onSubmit = (data) => {
    console.log("data", data);
  };

  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal storePayment"
        show={modalShow}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Dollar fill="#FC6B14" />
            <FormattedMessage id="addBalance" />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="balance-modal">
          <Modal.Body>
            <Col>
              <div className="card settingItem">
                <div className="card-head m-0 productDataModal justify-content-center">
                  <div className="form-group mb-0">
                    <div className="form-head mb-2">
                      <div>
                        <h5 title={formatMessage({ id: "storeBalanceDesc" })}>
                          <Dollar className="mx-0" />{" "}
                          <FormattedMessage id="currentBalance" />
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="text-center">
                  {settings?.storeBalance || (
                    <span className="small">
                      <FormattedMessage id="noBalance" />
                    </span>
                  )}
                </h4>
              </div>
            </Col>
            <div className="form-group">
              <div className="form-body">
                <Col xs={12}>
                  <label htmlFor="">
                    <FormattedMessage id="amount" />
                  </label>

                  <Input
                    type="number"
                    name="amount"
                    register={register}
                    errors={errors}
                    rules={{ required: true }}
                    icon={<StockIcon fill="#E4E4E4" />}
                  />
                </Col>
                <Col xs={12} className="">
                  <label>
                    <FormattedMessage id="selectPaymentMethod" />
                  </label>
                  <label
                    className={`method ${
                      watch("method") === "card" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      errors={errors}
                      {...register("method")}
                      value={"card"}
                    />
                    <h5 className="mb-0">
                      <CardIcon />
                      <FormattedMessage id="card" />
                    </h5>
                  </label>
                </Col>
                <div
                  className={`bank-info ${
                    watch("method") === "card" ? "" : "d-none"
                  }`}
                >
                  {renderCards}
                </div>
                <Col xs={12}>
                  <label
                    className={`method ${
                      watch("method") === "bank" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      errors={errors}
                      {...register("method")}
                      value={"bank"}
                    />
                    <h5 className="mb-0">
                      <BankIcon />
                      <FormattedMessage id="bankAccount" />
                    </h5>
                  </label>
                </Col>
                <BankInfo />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline"
              onClick={handleClose}
            >
              الغاء
            </button>
            <button className="btn btn-blue">تم</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default BalanceModal;
