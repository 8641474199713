import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

export default function useLimitDescription({ formatMessage }) {
  const descriptionElem = useRef(null);
  useEffect(() => {
    if (descriptionElem.current) {
      if (
        descriptionElem.current.textContent?.length > 240 &&
        (!descriptionElem.current.classList.contains("long") ||
          !document.getElementById("expandLink"))
      ) {
        descriptionElem.current.classList.add("long");
        descriptionElem.current.classList.add("half-hidden");

        const shrinkLink = document.createElement("a");
        shrinkLink.href = "#";
        shrinkLink.id = "expandLink";
        shrinkLink.classList.add("shrink");
        shrinkLink.innerText = formatMessage({ id: "showLess" });
        shrinkLink.addEventListener("click", (e) => {
          e.preventDefault();
          descriptionElem.current.classList.toggle("half-hidden");
        });

        const expandLink = document.createElement("a");
        expandLink.href = "#";
        expandLink.id = "expandLink";
        expandLink.classList.add("expand");
        expandLink.innerText = formatMessage({ id: "showMore" });
        expandLink.addEventListener("click", (e) => {
          e.preventDefault();
          descriptionElem.current.classList.toggle("half-hidden");
        });

        descriptionElem.current.append(shrinkLink, expandLink);
      }
    }
  });

  return descriptionElem;
}
