import {
  GET_THEME_PAGES,
  GET_THEME_PAGES_FAILURE,
  GET_THEME_PAGES_SUCCESS,
  GET_THEME_COMPONENTS_TYPE,
  GET_THEME_COMPONENTS_TYPE_FAILURE,
  GET_THEME_COMPONENTS_TYPE_SUCCESS,
  GET_THEME_FONTS,
  GET_THEME_FONTS_SUCCESS,
  GET_THEME_FONTS_FAILURE,
  GET_THEME,
  GET_THEME_SUCCESS,
  GET_THEME_FAILURE,
  EDIT_THEME_COMPONENT,
  EDIT_THEME_COMPONENT_FAILURE,
  EDIT_THEME_COMPONENT_SUCCESS,
  EDIT_THEME_CSS,
  EDIT_THEME_CSS_SUCCESS,
  EDIT_THEME_CSS_FAILURE,
  EDIT_THEME_SETTINGS,
  EDIT_THEME_SETTINGS_SUCCESS,
  EDIT_THEME_SETTINGS_FAILURE,
  EDTI_STORE_SETTINGS,
  EDTI_STORE_SETTINGS_SUCCESS,
  EDTI_STORE_SETTINGS_FAILURE,
} from "./actionTypes";

const initialState = {
  themePages: [],
  themeComponentsType: [],
  themeFonts: [],
  theme: {},
  loading: false,
  error: "",
};

const builder = (state = initialState, action) => {
  switch (action.type) {
    case GET_THEME_PAGES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_THEME_PAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        themePages: action.payload.themePages,
      };
      break;

    case GET_THEME_PAGES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case GET_THEME_COMPONENTS_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_THEME_COMPONENTS_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        themeComponentsType: action.payload.themeComponentTypes,
      };
      break;

    case GET_THEME_COMPONENTS_TYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case GET_THEME_FONTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEME_FONTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        themeFonts: action.payload.fonts,
      };
      break;
    case GET_THEME_FONTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_THEME:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEME_SUCCESS:
      state = {
        ...state,
        loading: false,
        theme: action.payload.storeTheme,
      };
      break;
    case GET_THEME_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_THEME_COMPONENT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case EDIT_THEME_COMPONENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case EDIT_THEME_COMPONENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case EDIT_THEME_CSS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_THEME_CSS_SUCCESS:
      state = {
        ...state,
        loading: false,
        themeCss: action.payload,
      };
      break;
    case EDIT_THEME_CSS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_THEME_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_THEME_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        themeSettings: action.payload,
      };
      break;
    case EDIT_THEME_SETTINGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDTI_STORE_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case EDTI_STORE_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        storeSettings: action.payload,
      };
      break;

    case EDTI_STORE_SETTINGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default builder;
