import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import stores from "./stores/reducer";
import categories from "./categories/reducer";
import packages from "./packages/reducer";
import customers from "./customers/reducer";
import productCategories from "./productCategories/reducer";
import productTypes from "./productTypes/reducer";
import products from "./products/reducer";
import brands from "./brands/reducer";
import options from "./options/reducer";
import features from "./features/reducer";
import themeTemplates from "./themeTemplates/reducer";
import blogs from "./blogs/reducer";
import blogCategories from "./blogCategories/reducer";
import languages from "./languages/reducer";
import orders from "./orders/reducer";
import dashboard from "./dashboard/reducer";
import notifications from "./notifications/reducer";
import coupons from "./coupons/reducer";
import units from "./measuringUnits/reducer";
import settings from "./settings/reducer";
import pages from "./pages/reducer";
import currencies from "./currencies/reducer";
import paymentMethods from "./paymentMethods/reducer";
import visitsReport from "./visitsReport/reducer";
import groups from "./groups/reducer";
import builder from "./builder/reducer";
import productsReviews from "./productsReviews/reducer";
import faqs from "./faqs/reducer";
import faqsCategories from "./faqs/categories/reducer";

const rootReducer = combineReducers({
  authentication,
  stores,
  categories,
  packages,
  customers,
  productCategories,
  productTypes,
  products,
  brands,
  options,
  features,
  themeTemplates,
  blogs,
  blogCategories,
  languages,
  orders,
  dashboard,
  notifications,
  coupons,
  units,
  settings,
  pages,
  currencies,
  paymentMethods,
  visitsReport,
  groups,
  builder,
  productsReviews,
  faqs,
  faqsCategories,
});

export default rootReducer;
