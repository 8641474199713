import BreadCrumb from "components/shared/BreadCrumb";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { blockCustomer, editOrder, getSingleOrder } from "store/actions";
import Loader from "components/shared/Loader";
import { ReactComponent as Calendar } from "assets/svgs/order-date.svg";
import { ReactComponent as Setting } from "assets/svgs/Setting.svg";
import { ReactComponent as Status } from "assets/svgs/order-status.svg";
import { ReactComponent as User } from "assets/svgs/order-employee.svg";
import { ReactComponent as Phone } from "assets/svgs/call.svg";
import { ReactComponent as Ship } from "assets/svgs/ship.svg";
import { ReactComponent as Whatsapp } from "assets/svgs/whatsapp.svg";
import { ReactComponent as Email } from "assets/svgs/mail.svg";
import visa from "assets/svgs/order-payment.svg";
import check from "assets/svgs/check.svg";
import productsIcon from "assets/svgs/products.svg";
import historyIcon from "assets/svgs/order-history.svg";
import orderOnDelivery from "assets/svgs/settings/2.svg";
import orderPaid from "assets/svgs/settings/16.svg";
import orderCancel from "assets/svgs/box-remove.svg";
import { ReactComponent as PersonBlockIcon } from "assets/svgs/person-block.svg";

import TimeInst from "components/shared/TimeInst";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { getId } from "helpers/functions";
import Swal from "sweetalert2";
import StickyLoader from "components/shared/StickyLoader";

const ShowOrder = () => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singleOrder, loading } = useSelector((state) => state.orders);
  const { loading: customerLoading } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, [dispatch, id]);

  const renderOrderProducts = singleOrder?.products?.map((product, index) => (
    <tr key={index}>
      <td>
        <div className="product">
          <img src={product?.product?.mainImage} alt="" />
          <Link to={`/products/${product?.product?.id}`}>
            {product?.product?.name?.[locale]}
          </Link>
        </div>
      </td>
      <td>{product?.quantity}</td>
      <td>
        {product?.product?.price} <FormattedMessage id="ryial" />
      </td>
      <td>
        {singleOrder?.total} <FormattedMessage id="ryial" />
      </td>
    </tr>
  ));

  const renderOrderHistory = singleOrder?.logs?.map((log) => {
    switch (log?.status) {
      case "onDelivery":
        return (
          <li>
            <div className="h-img onDelivery">
              <img src={orderOnDelivery} alt="" />
            </div>
            <div className="h-info">
              <div>
                <h5>
                  <FormattedMessage id={log?.status} />
                </h5>
                <p>
                  {log?.user?.name || log?.user?.username}: {log?.notes}
                </p>
              </div>
              <span>
                <TimeInst date={new Date(log?.date)} />
              </span>
            </div>
          </li>
        );
      case "delivered":
        return (
          <li>
            <div className="h-img delivered">
              <Ship />
            </div>
            <div className="h-info">
              <div>
                <h5>
                  <FormattedMessage id={log?.status} />
                </h5>
                <p>
                  {log?.user?.name || log?.user?.username}: {log?.notes}
                </p>
              </div>
              <span>
                <TimeInst date={new Date(log?.date)} />
              </span>
            </div>
          </li>
        );
      case "canceled":
        return (
          <li>
            <div className="h-img canceled">
              <img src={orderCancel} alt="" />
            </div>
            <div className="h-info">
              <div>
                <h5>
                  <FormattedMessage id={log?.status} />
                </h5>
                <p>
                  {log?.user?.name || log?.user?.username}: {log?.notes}
                </p>
              </div>

              <span>
                <TimeInst date={new Date(log?.date)} />
              </span>
            </div>
          </li>
        );
      case "paid":
        return (
          <li>
            <div className="h-img paid">
              <img src={orderPaid} alt="" />
            </div>
            <div className="h-info">
              <div>
                <h5>
                  <FormattedMessage id={log?.status} />
                </h5>
                <p>
                  {log?.user?.name || log?.user?.username}: {log?.notes}
                </p>
              </div>
              <span>
                <TimeInst date={new Date(log?.date)} />
              </span>
            </div>
          </li>
        );
      default:
        return null;
    }
  });

  const handleBlockUser = () => {
    Swal.fire({
      title: `${formatMessage({
        id: singleOrder?.user?.isBlocked ? "unblockCustomer" : "blockCustomer",
      })} (${singleOrder?.user?.username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          blockCustomer({
            data: getId(singleOrder?.user),
            callBack: () => dispatch(getSingleOrder(id)),
          })
        );
      }
    });
  };

  const handleOrderStatus = (status) => {
    dispatch(editOrder({ data: { status }, id: getId(singleOrder) }));
  };

  if (loading) return <Loader />;

  return (
    <>
      {customerLoading && <StickyLoader fill="#FC6B14" />}
      <div className="acc-form">
        <BreadCrumb
          // pageName="الطلبات"
          pageName={singleOrder?.uniqueCode || "asdasd"}
          parent={{ text: "الطلبات", link: "/orders" }}
        />
        <div className="orderTop">
          <div className="card">
            <ul>
              <li>
                <span># رقم الطلب</span>
                {singleOrder?.uniqueCode}
              </li>
              <li>
                <span>
                  <Calendar />
                  تاريخ الطلب
                </span>
                {new Date(singleOrder?.createdAt).toLocaleString(locale, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </li>
              <li>
                <span>
                  <Status />
                  <FormattedMessage id="orderStatus" />
                </span>
                <div className="ordersServices">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Setting />
                      <FormattedMessage id={singleOrder?.status || " "} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleOrderStatus("onDelivery")}
                      >
                        <FormattedMessage id="onDelivery" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleOrderStatus("canceled")}
                      >
                        <FormattedMessage id="canceled" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleOrderStatus("delivered")}
                      >
                        <FormattedMessage id="delivered" />
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOrderStatus("paid")}>
                        <FormattedMessage id="paid" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="orderBlocks">
          <Row>
            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
              <div className="card block">
                <div className="c-head">
                  <span>
                    <User />
                    العـميل
                  </span>
                  <div className="ordersServices">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Setting />
                        خـيــارات
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          href={`/customers/show/${getId(singleOrder?.user)}`}
                        >
                          <User />
                          <FormattedMessage id="customerOrders" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleBlockUser}>
                          <PersonBlockIcon />
                          <FormattedMessage
                            id={
                              singleOrder?.user?.isBlocked
                                ? "unblockCustomer"
                                : "blockCustomer"
                            }
                          />
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          href={`/customers/${getId(
                            singleOrder?.user
                          )}/messagesArchive`}
                        >
                          <User />
                          <FormattedMessage id="messagesArchive" />
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="c-body">
                  <div className="userInfo">
                    <div className="userImg">
                      <img src={singleOrder?.user?.image} alt="" />
                    </div>
                    <div className="userDetails">
                      <h4>{singleOrder?.user?.username}</h4>
                      <p>{singleOrder?.user?.phone}</p>
                      <a
                        href={`tel:${singleOrder?.user?.phone}`}
                        className="btn btn-blue"
                      >
                        اتصـال
                        <Phone />
                      </a>
                    </div>
                  </div>
                  <div className="userSocial">
                    <span>أو تواصل عبر</span>
                    <ul>
                      <li>
                        <a
                          href={`https://wa.me/${singleOrder?.user?.phone}`}
                          target="_blank"
                          rel="noreferrer"
                          className={
                            singleOrder?.user?.phone ? "" : "opacity-50"
                          }
                        >
                          <Whatsapp />
                          واتس اب
                        </a>
                      </li>
                      {/* <li>
                        <a href="#sms" target="_blank" rel="noreferrer">
                          <Sms />
                          رسالة نصية
                        </a>
                      </li> */}
                      <li>
                        <a href="mailto:info@abc.com">
                          <Email />
                          بـريد الإلكتروني
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
              <div className="card block">
                <div className="c-head">
                  <span>
                    <Ship />
                    الشحن
                  </span>
                  <div className="ordersServices">
                    {/* <button className="btn btn-blue">
                      <img src={print} alt="" />
                      طباعة
                    </button> */}
                  </div>
                </div>
                <div className="c-body">
                  <div className="shipInfo">
                    {/* <img src={shipImg} alt="" /> */}
                    <p>{`${singleOrder?.userAddress?.name}`}</p>
                    <h5>
                      {`${singleOrder?.userAddress?.city?.name?.[locale]} , ${singleOrder?.userAddress?.country?.name?.[locale]}.`}
                    </h5>
                    <p>{`${singleOrder?.userAddress?.addressDetails} - ${singleOrder?.userAddress?.area?.name?.[locale]}`}</p>
                    <a href={`tel:${singleOrder?.userAddress?.phone}`}>
                      {singleOrder?.userAddress?.phone}
                    </a>
                  </div>
                  {/* 
                  <div className="reviewShip">
                    <h5>
                      بوليصة الشحن :<Link to="/">AY3871949965</Link>
                      <div>
                        <button>
                          <img src={print} alt="" />
                          طباعة
                        </button>
                        <button>
                          <Remove fill="#ff3f78" />
                          إالغاء
                        </button>
                      </div>
                    </h5>
                    <h5>رقم المراجعة : 43296903</h5>
                    
                  </div> */}
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
              <div className="card block">
                <div className="c-head">
                  <span>
                    <img src={visa} alt="" />
                    الدفع
                  </span>
                  <div className="ordersServices">
                    {/* <button className="btn btn-blue">
                        <img src={bill} alt="" />
                        اصدار فاتورة
                      </button> */}
                  </div>
                </div>
                <div className="c-body">
                  <div className="payInfo">
                    <i>
                      <img src={check} alt="" />
                    </i>
                    <FormattedMessage id="paidBy" />
                    {singleOrder?.paymentMethod?.name?.[locale]}
                  </div>
                  <div className="payDetails">
                    <p>
                      تم إضافة مبلغ الطلب إلي رصيد
                      <Link to="/"> المدفوعات الإلكترونية</Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <div className="card block productsWrapper">
              <div className="c-head">
                <span>
                  <img src={productsIcon} alt="" />
                  المنتجات
                </span>
                <div className="ordersServices">
                  {/* <button className="btn btn-blue">
                      <img src={print} alt="" />
                      طباعة
                    </button> */}
                </div>
              </div>
              <div className="c-body">
                {singleOrder?.products?.length > 0 ? (
                  <>
                    <div className="productsTable">
                      <table>
                        <thead>
                          <tr>
                            <th>المنتج</th>
                            <th>الكمية</th>
                            <th>السعر</th>
                            <th>المجموع</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderOrderProducts}

                          <tr>
                            <td colSpan="3">
                              <b>المجموع الفرعي</b>
                            </td>
                            <td>
                              {singleOrder?.subtotal === null
                                ? "غير محدد"
                                : `
                              ${singleOrder?.subtotal} ${formatMessage({
                                    id: "ryial",
                                  })}
                            `}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <b>الشحن</b>
                            </td>
                            <td>مجاني</td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <b>إجمــالي</b>
                            </td>
                            <td>
                              <strong>
                                {singleOrder?.total === null
                                  ? "غير محدد"
                                  : `
                              ${singleOrder?.total} ${formatMessage({
                                      id: "ryial",
                                    })}
                            `}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="noData">
                    <Col xxl={5} lg={6} md={8} xs={12}>
                      <p className="alert">لا يوجد منتجات مضافة</p>
                    </Col>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <div className="card block productsWrapper">
              <div className="c-head">
                <span>
                  <img src={historyIcon} alt="" />
                  سـجل الطلب
                </span>
              </div>

              <div className="c-body">
                {singleOrder?.logs?.length > 0 ? (
                  <ul className="historyInfo">{renderOrderHistory}</ul>
                ) : (
                  <div className="noData">
                    <Col xxl={5} lg={6} md={8} xs={12}>
                      <p className="alert">لا يوجد سجل لهذا الطلب</p>
                    </Col>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};

export default ShowOrder;
