import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductsInventory } from "store/actions";
import BreadCrumb from "components/shared/BreadCrumb";
import { FormattedMessage, useIntl } from "react-intl";
import Table from "./InventoryTable";

const Inventory = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getProductsInventory());
  }, [dispatch]);

  return (
    <>
      <div className="products-wrap">
        <BreadCrumb
          pageName={formatMessage({
            id: "productsInventory",
          })}
          parent={{ text: "المنتجات", link: "/products" }}
        />

        <div className="card-head mb-2">
          <div>
            <h4>
              <FormattedMessage id="productsInventory" />{" "}
            </h4>
          </div>
        </div>

        <Table />

        {/* <BootyPagination metaData={metadata} /> */}
      </div>
    </>
  );
};

export default Inventory;
