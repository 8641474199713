import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "./Card";
import BootyPagination from "../shared/Pagination";

const CardsList = ({
  listView = true,
  metadata,
  filteredItems,
  isCheck,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const paramsArray = Object.entries(queryParams);

  const [cards, setCards] = useState();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState([]);

  const productsAsStr = JSON.stringify(filteredItems);

  useEffect(() => {
    setCards(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsStr]);

  const renderProducts = useMemo(
    () =>
      cards?.map((product, index) => {
        return (
          <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={3} key={getId(product)}>
            <Card data={product} isCheck={isCheck} handleClick={handleClick} />
          </Col>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards, handleClick, isCheck, locale]
  );

  useEffect(() => {
    setPage(metadata?.pagination?.page || 1);
  }, [metadata]);

  const totalPages = Math.ceil(
    metadata?.totalResults / metadata?.pagination?.limit
  );

  useEffect(() => {
    setParams(paramsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className={listView ? "productsList listView" : "productsList"}>
      <Row>
        {filteredItems?.length > 0 ? (
          renderProducts
        ) : (
          <div className="no-data alert alert-warning text-center m-0 mt-4">
            <p>
              <FormattedMessage id="noData" />
            </p>
          </div>
        )}
      </Row>

      {page < totalPages &&
        filteredItems?.length > metadata?.pagination?.limit && (
          <BootyPagination metaData={metadata} />
        )}
    </div>
  );
};

export default CardsList;
