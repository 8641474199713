/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Accordion, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getProductCategories,
  getProducts,
  getSettings,
} from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as TitleIcon } from "assets/svgs/settings/box.svg";
import { ReactComponent as SettingIcon } from "assets/svgs/settings/setting.svg";
import { ReactComponent as TrashIcon } from "assets/svgs/trash.svg";
import { ReactComponent as InfoIcon } from "assets/svgs/settings/info-circle.svg";
import { ReactComponent as MarkIcon } from "assets/svgs/mark.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-doen.svg";
import { ReactComponent as StarIcon } from "assets/svgs/star.svg";
import BreadCrumb from "components/shared/BreadCrumb";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const StoreApps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { formatMessage } = useIntl();

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("shippingSettings"));
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getProductCategories());
  }, [dispatch]);

  const apps = settings?.apps || ["ارامكس", "تجارة", "تطبيق"];
  const appsCategories = settings?.appsCategories || [
    1, 2, 3, 4, 4, 5, 6, 7, 8, 9,
  ];

  const renderTabHeads = appsCategories?.map((app, index) => (
    <SwiperSlide key={index}>
      <Nav.Item>
        <Nav.Link className="btn btn-blue" eventKey={index}>
          {app?.name || "تصنيف " + app}
        </Nav.Link>
      </Nav.Item>
    </SwiperSlide>
  ));

  const renderTabPanes = appsCategories?.map((app, index) => (
    <Tab.Pane eventKey={index} key={index} className="apps">
      <Row>
        {apps?.map((app, index) => (
          <Dropdown key={index} className="col-6 col-md-4 col-lg-3">
            <div className="app">
              <div className="info">
                <img
                  src="https://play-lh.googleusercontent.com/t0eA56wZRHCD6Xd0N52J6KcbFiS7g3IEk6YoKuQssqHIJY7TIBCcm_KUcNjUb39LoA8"
                  alt=""
                />
                <div>
                  <h5>{app?.name || app}</h5>
                  <h5 className="rate">
                    {app?.rate || 4} <StarIcon />
                  </h5>
                  <h5>{app?.author || app}</h5>
                </div>
                <MarkIcon />
              </div>
              <Dropdown.Toggle eventKey={index}>
                <FormattedMessage id="appOptions" />
                <ArrowIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={index}>
                  <SettingIcon />
                  <FormattedMessage id="appSettings" />
                </Dropdown.Item>
                <Dropdown.Item eventKey={index}>
                  <InfoIcon />
                  <FormattedMessage id="subscriptionDetails" />
                </Dropdown.Item>
                <Dropdown.Item eventKey={index}>
                  <TrashIcon />
                  <FormattedMessage id="removeApp" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        ))}
      </Row>
    </Tab.Pane>
  ));

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page store-apps">
      <BreadCrumb
        pageName={formatMessage({
          id: "apps",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />

      {dataSaveLoading && <StickyLoader fill="#FC6B14" />}

      <div className="card">
        <div className="productDataModal mb-4">
          <div className="card-head form-group mb-0">
            <div className="form-head mb-0">
              <i>
                <TitleIcon />
              </i>
              <div>
                <h4 className="mb-0">
                  <FormattedMessage id="allApps" /> ({apps?.length || 0})
                </h4>
              </div>
            </div>
          </div>
        </div>

        <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
          <Nav>
            <div className="swiper-button-prev">
              <ArrowIcon />
            </div>
            <Swiper
              loop={false}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              spaceBetween="14"
              slidesPerView={"auto"}
              // className="w-100"
            >
              {renderTabHeads}
            </Swiper>
            <div className="swiper-button-next">
              <ArrowIcon />
            </div>
          </Nav>
          <Tab.Content>{renderTabPanes}</Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default StoreApps;
