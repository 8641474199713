import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Map from "./Map";

let searchTimeout = null;

export default function RuleTypeInputs({ register, control, index, ruleType }) {
  const { locale, formatMessage } = useIntl();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { flatCategories } = useSelector((state) => state.productCategories);
  const { groups } = useSelector((state) => state.groups);

  const rulesTypes = ["lessThan", "greaterThan", "equal"].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));

  const getOptionsByName = (arr) =>
    //add default option
    [
      // {
      //   label: "اختر او ابحث",
      //   value: "",
      // },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale] || item,
        value: getId(item),
      }))
    );

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    if (searchTimeout !== null) clearInterval(searchTimeout);
    searchTimeout = setTimeout(() => {
      navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
    }, 400);
  };
  const filteredSelectedItems = (txt) =>
    flatCategories?.filter(
      (item) =>
        JSON.stringify({ id: getId(item), name: item?.name })
          .toLowerCase()
          .indexOf(txt.toLowerCase()) !== -1
    );

  if (ruleType === "cartTotal" || ruleType === "cartWeight") {
    const amountUnit = ruleType === "cartTotal" ? "ryial" : "kg";
    return (
      <>
        <Form.Group className="mb-3 col-12 col-md-4">
          <Form.Label>
            <FormattedMessage id="conditionType" />
          </Form.Label>
          <Controller
            control={control}
            name={`rules.${index}.type`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                options={rulesTypes}
                placeholder={formatMessage({
                  id: "conditionType",
                })}
                isMulti={false}
                value={rulesTypes?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Form.Group>
        <Form.Group className={`mb-3 col-12 col-md-4`}>
          <Form.Label>
            <FormattedMessage id="conditionValue" />
            (<FormattedMessage id={amountUnit} />)
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={formatMessage({ id: "conditionValue" })}
            {...register(`rules.${index}.value`, {
              required: true,
            })}
          />
        </Form.Group>
      </>
    );
  }

  if (ruleType === "categoryProducts")
    return (
      <Form.Group className={`mb-3 col-12 col-md-8`}>
        <Form.Label>
          <FormattedMessage id="conditionValue" />
        </Form.Label>
        <Controller
          control={control}
          name={`exceptionProducts`}
          rules={{ required: true }}
          render={({ field: { onChange, value: value1, selected } }) => (
            <Select
              isMulti={true}
              isRtl={true}
              isSearchable={true}
              className="basic-single w-100 form-control"
              classNamePrefix="select"
              options={getOptionsByName(flatCategories)}
              placeholder={formatMessage({
                id: "exceptionProducts",
              })}
              onChange={(selected) => {
                onChange(selected.map((selected) => selected?.value));
              }}
              value={getOptionsByName(
                flatCategories?.filter((s) => value1?.includes(getId(s)))
              )}
              onInputChange={(value) => {
                if (filteredSelectedItems(value)?.length <= 0)
                  updateParams("nameText", value);
              }}
            />
          )}
        />
      </Form.Group>
    );

  if (ruleType === "perLocations")
    return (
      <Form.Group className="mb-3 col-12 col-md-12">
        <Form.Label>
          <FormattedMessage id="locations" />
        </Form.Label>
        <Controller
          control={control}
          name={`rules.${index}.locations`}
          rules={{ required: true }}
          render={({ field: { onChange, value, selected } }) => (
            <Map data={value || ""} setData={onChange} />
          )}
        />
      </Form.Group>
    );

  if (ruleType === "customerGroup")
    return (
      <Form.Group className="mb-3 col-12 col-md-8">
        <Form.Label>
          <FormattedMessage id="group" />
        </Form.Label>
        <Controller
          control={control}
          name={`rules.${index}.group`}
          rules={{ required: true }}
          render={({ field: { onChange, value, selected } }) => (
            <Select
              isRtl={true}
              isSearchable={false}
              className="basic-single"
              classNamePrefix="select"
              options={getOptionsByName(groups)}
              placeholder={formatMessage({ id: "group" })}
              isMulti={false}
              value={getOptionsByName(groups)?.find((o) => o?.value === value)}
              onChange={({ value }) => {
                onChange(value);
              }}
            />
          )}
        />
      </Form.Group>
    );
}
