import { getId } from "helpers/functions";
import React from "react";
import { Link } from "react-router-dom";
import {
  XAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import angle from "../../../src/assets/svgs/angle-left.svg";

const Reports = ({ data }) => {
  const monthSummary = [];

  for (let index = 1; index <= 31; index++) {
    const currentDay = data?.find((item) => getId(item) === index);
    monthSummary?.push({
      name: "Day " + index,
      orderCount: currentDay?.totalCount || 0,
      totalOrderValue: currentDay?.totalOrders || 0,
    });
  }

  return (
    <div className="card">
      <div className="card-head">
        <h4>
          ملخص الشهر
          <Link to="/reports">
            المزيد من التقارير
            <img src={angle} alt="arrow" />
          </Link>
        </h4>
      </div>

      <div
        className="chart-body barChart"
        style={{ width: "100%", height: 222 }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={monthSummary}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="rgba(52, 107, 244, 0.1)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="rgba(255, 255, 255, 0)"
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FC6B14" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#FC6B14" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <CartesianGrid strokeDasharray="1 0" vertical={false} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="orderCount"
              stroke="#FC6B141A"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              unit={" قطعة"}
            />
            <Area
              type="monotone"
              dataKey="totalOrderValue"
              stroke="#FC6B14"
              fillOpacity={1}
              fill="url(#colorPv)"
              strokeWidth={3}
              maskContentUnits="ccc"
              unit={" ريال"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Reports;
