import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Import Api files
import {
  addBrandApi,
  deleteBrandApi,
  deleteMultipleBrandsApi,
  editBrandApi,
  editBrandsSettingApi,
  getBrandsApi,
  getBrandsSettingApi,
  getSingleBrandApi,
  orderBrandsSettingApi,
} from "api/brands";

// Login Redux States
import {
  ADD_BRAND,
  ADD_EMPTY_BRAND,
  DELETE_BRAND,
  DELETE_EMPTY_BRAND,
  DELETE_MULTIPLE_BRANDS,
  EDIT_BRAND,
  EDIT_BRANDS_SETTING,
  GET_BRANDS,
  GET_BRANDS_SETTING,
  GET_SINGLE_BRAND,
  ORDER_BRAND,
} from "./actionTypes";
import {
  addBrandFailure,
  addBrandSuccess,
  addEmptyBrandFailure,
  addEmptyBrandSuccess,
  deleteBrandFailure,
  deleteBrandSuccess,
  deleteEmptyBrandFailure,
  deleteEmptyBrandSuccess,
  deleteMultipleBrandsFailure,
  deleteMultipleBrandsSuccess,
  editBrandFailure,
  editBrandSuccess,
  editBrandsSettingsFailure,
  editBrandsSettingsSuccess,
  getBrandsFailure,
  getBrandsSettingsFailure,
  getBrandsSettingsSuccess,
  getBrandsSuccess,
  getSingleBrandFailure,
  getSingleBrandSuccess,
  orderBrandFailure,
  orderBrandSuccess,
} from "./actions";
import { globalToastOptions } from "helpers/functions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

// ================== GET BRANDS ==================

function* getBrands({ payload }) {
  try {
    const { data } = yield call(getBrandsApi, payload);
    yield put(getBrandsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getBrandsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

// ================== GET SINGLE BRAND ==================

function* getSingleBrand({ payload }) {
  try {
    const { data } = yield call(getSingleBrandApi, payload);
    yield put(getSingleBrandSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getSingleBrandFailure(error?.response?.data || error));
  }
}

// ================== ADD BRAND ==================

function* addBrand({ payload }) {
  try {
    const { data } = yield call(addBrandApi, payload.data);
    yield put(addBrandSuccess({ brand: data.brand, id: payload.id }));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(addBrandFailure(error.response?.data?.message || error));
    // yield payload?.toast?.error(
    //   error.response?.data?.message,
    //   globalToastOptions
    // );
  }
}

// ================== EDIT BRAND ==================

function* editBrand({ payload }) {
  try {
    const { data } = yield call(editBrandApi, payload);
    yield put(editBrandSuccess(data.brand));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(editBrandFailure(error?.response?.data || error));
    // yield payload?.toast?.error(
    //   error.response?.data?.message,
    //   globalToastOptions
    // );
  }
}

function* orderBrand({ payload }) {
  try {
    const { data } = yield call(orderBrandsSettingApi, payload);
    yield put(orderBrandSuccess(data));
    // yield getBrands();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(orderBrandFailure(error?.response?.data || error));
  }
}

// ================== DELETE BRAND ==================

function* deleteBrand({ payload }) {
  try {
    yield call(deleteBrandApi, payload);
    yield put(deleteBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteBrandFailure(error?.response?.data || error));
  }
}

// ================== ADD EMPTY BRAND ==================

function* addEmptyBrand({ payload }) {
  try {
    yield put(addEmptyBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(addEmptyBrandFailure(error));
  }
}

// ================== DELETE EMPTY BRAND ==================

function* deleteEmptyBrand({ payload }) {
  try {
    yield put(deleteEmptyBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteEmptyBrandFailure(error?.response?.data || error));
  }
}

// ================== GET BRANDS SETTING ==================

function* getBrandsSetting({ payload }) {
  try {
    const { data } = yield call(getBrandsSettingApi, payload);
    yield put(getBrandsSettingsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getBrandsSettingsFailure(error?.response?.data || error));
  }
}

// ================== EDIT BRANDS SETTING ==================

function* editBrandsSetting({ payload }) {
  try {
    const { data } = yield call(editBrandsSettingApi, payload);
    yield put(editBrandsSettingsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(editBrandsSettingsFailure(error?.response?.data || error));
  }
}

// ================== DELETE MULTIPLE BRANDS ==================

function* deleteMultipleBrands({ payload }) {
  try {
    yield call(deleteMultipleBrandsApi, payload);
    yield put(deleteMultipleBrandsSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteMultipleBrandsFailure(error?.response?.data || error));
  }
}

// ================================================================
// ================== WATCHERS ====================================

export function* watchGetBrands() {
  yield takeEvery(GET_BRANDS, getBrands);
}

export function* watchSingleBrand() {
  yield takeEvery(GET_SINGLE_BRAND, getSingleBrand);
}

export function* watchAddBrand() {
  yield takeEvery(ADD_BRAND, addBrand);
}

export function* watchEditBrand() {
  yield takeEvery(EDIT_BRAND, editBrand);
}

export function* watchOrderBrand() {
  yield takeEvery(ORDER_BRAND, orderBrand);
}

export function* watchDeleteBrand() {
  yield takeEvery(DELETE_BRAND, deleteBrand);
}

export function* watchAddEmptyBrand() {
  yield takeLatest(ADD_EMPTY_BRAND, addEmptyBrand);
}

export function* watchDeleteEmptyBrand() {
  yield takeLatest(DELETE_EMPTY_BRAND, deleteEmptyBrand);
}

export function* watchGetBrandsSetting() {
  yield takeLatest(GET_BRANDS_SETTING, getBrandsSetting);
}

export function* watchEditBrandsSetting() {
  yield takeLatest(EDIT_BRANDS_SETTING, editBrandsSetting);
}

export function* watchDeleteMultipleBrands() {
  yield takeLatest(DELETE_MULTIPLE_BRANDS, deleteMultipleBrands);
}

// ================== BRANDS SAGA ====================================

function* brandsSaga() {
  yield all([fork(watchGetBrands)]);
  yield all([fork(watchSingleBrand)]);
  yield all([fork(watchAddBrand)]);
  yield all([fork(watchEditBrand)]);
  yield all([fork(watchOrderBrand)]);
  yield all([fork(watchDeleteBrand)]);
  yield all([fork(watchAddEmptyBrand)]);
  yield all([fork(watchDeleteEmptyBrand)]);
  yield all([fork(watchGetBrandsSetting)]);
  yield all([fork(watchEditBrandsSetting)]);
  yield all([fork(watchDeleteMultipleBrands)]);
}

export default brandsSaga;
