import React from "react";
import { Accordion } from "react-bootstrap";

import filterIcon from "assets/svgs/filter.svg";
import closeIcon from "assets/svgs/close.svg";
import statusIcon from "assets/svgs/order-status.svg";
import typeIcon from "assets/svgs/order-type.svg";
import brandIcon from "assets/svgs/products.svg";
import shipIcon from "assets/svgs/ship.svg";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getId } from "helpers/functions";

const ProductsFilterSidebar = ({
  showFilter,
  setShowFilter,
  // setFilteredProducts,
  // products,
  // filterProductsByParams,
  selectedCategory,
  setSelectedCategory,
  selectedType,
  setSelectedType,
  selectedBrand,
  setSelectedBrand,
  selectedStatus,
  setSelectedStatus,
  categories,
  productTypes,
  brands,
}) => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  // const [isLoading, setIsLoading] = useState(false);

  const handleCategoryChange = (event) => {
    let val = event?.target?.value;
    if (selectedCategory?.includes(val))
      setSelectedCategory(selectedCategory?.filter((e) => e !== val));
    else setSelectedCategory([...selectedCategory, val]);
    // setSelectedCategory(event.target.value);
  };

  const handleTypeChange = (event) => {
    let val = event?.target?.value;
    if (selectedType?.includes(val))
      setSelectedType(selectedType?.filter((e) => e !== val));
    else setSelectedType([...selectedType, val]);
    // setSelectedType(event.target.value);
  };
  const handleBrandChange = (event) => {
    let val = event?.target?.value;
    if (selectedBrand?.includes(val))
      setSelectedBrand(selectedBrand?.filter((e) => e !== val));
    else setSelectedBrand([...selectedBrand, val]);
    // setSelectedBrand(event.target.value);
  };

  const handleFilterSubmit = async () => {
    // setIsLoading(true);
    const queryParams = [];
    if (selectedCategory?.length)
      queryParams.push(`categories=${JSON.stringify(selectedCategory)}`);
    if (selectedType?.length)
      queryParams.push(`types=${JSON.stringify(selectedType)}`);
    if (selectedBrand?.length)
      queryParams.push(`brands=${JSON.stringify(selectedBrand)}`);
    if (selectedStatus)
      queryParams.push(
        `${selectedStatus === "isShown" ? "isShown=false" : ""}${
          selectedStatus === "stock" ? "quantity=0" : ""
        }${
          selectedStatus === "isRequiredShipping"
            ? "isRequiredShipping=true"
            : ""
        }${selectedStatus === "outOfStock" ? "outOfStock=true" : ""}`
      );
    const queryString = queryParams.join("&");

    try {
      // setFilteredProducts(data?.data?.products);
      setShowFilter(false);
      // setIsLoading(false);
      navigate(`/products?${queryString}`, { replace: true });
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  const handleReset = () => {
    // Reset the selected filters to their initial values (empty strings)
    setSelectedCategory([]);
    setSelectedType([]);
    setSelectedBrand([]);
    setSelectedStatus("");

    // Reset the filtered products to the initial products
    // setFilteredProducts(products);

    // Close the filter sidebar
    setShowFilter(false);

    // Reset the URL to the initial URL
    // navigate("/products");
    window.history.pushState({}, null, "/products");
  };

  // if (isLoading) return <StickyLoader fill="#FC6B14" />;

  return (
    <div
      className={
        showFilter ? "ordersFilterSidebar active" : "ordersFilterSidebar"
      }
    >
      <div className="sidebarHead">
        <span>
          <img src={filterIcon} alt="" />
          فرز المنتحات حسب
        </span>
        <button
          className="closeSidebar"
          onClick={() => {
            setShowFilter(false);
          }}
        >
          <img src={closeIcon} alt="" />
        </button>
      </div>
      <div className="sidebarBody">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span>
                <img src={statusIcon} alt="" />
                التصنيفات
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {categories?.map((category, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        name="category"
                        value={getId(category)}
                        onClick={handleCategoryChange}
                        checked={selectedCategory?.includes(getId(category))}
                      />
                      <span>{category?.name?.[locale]}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span>
                <img src={typeIcon} alt="" />
                الانواع
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {productTypes?.map((type, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        name="type"
                        value={getId(type)}
                        onClick={handleTypeChange}
                        checked={selectedType?.includes(getId(type))}
                      />
                      <span>{type?.name?.[locale]}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span>
                <img src={brandIcon} alt="" />
                العلامات التجارية
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {brands?.map((brand, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        name="brand"
                        value={getId(brand)}
                        onClick={handleBrandChange}
                        checked={selectedBrand?.includes(getId(brand))}
                      />
                      <span>{brand?.name?.[locale]}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span>
                <img src={shipIcon} alt="" />
                حالة المنتج
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="isShown"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      checked={selectedStatus === "isShown"}
                    />
                    <span>منتجات مخفية</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="stock"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      checked={selectedStatus === "stock"}
                    />
                    <span>منتجات نفذت</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="isRequiredShipping"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      checked={selectedStatus === "isRequiredShipping"}
                    />
                    <span>منتجات تتطلب شحن</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="outOfStock"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      checked={selectedStatus === "outOfStock"}
                    />
                    <span>منتجات قاربت علي النفاذ</span>
                  </label>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="sidebarFooter">
        <button className="btn btn-blue" onClick={handleFilterSubmit}>
          حفظ التغييرات
        </button>
        <button className="btn btn-red" onClick={handleReset}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default ProductsFilterSidebar;
