import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { activateCoupon, deleteCoupon } from "store/actions";
import Loader from "components/shared/Loader";
import { getId, handleIsoDate } from "helpers/functions";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";

const Table = ({ coupons, handleShow }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const filteredItems = coupons?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDeleteCoupon = (id) => {
    Swal.fire({
      title: formatMessage({ id: "removeCoupon" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d83c9",
      cancelButtonColor: "#d33",
      confirmButtonText: formatMessage({ id: "yes" }),
      cancelButtonText: formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCoupon(id));
      }
    });
  };

  const columns = [
    {
      name: "كود الكوبون",
      selector: (row) => (
        <button
          className="couponCode"
          onClick={() => {
            handleShow(getId(row));
          }}
        >
          {row.code}
        </button>
      ),
      sortable: true,
    },

    {
      name: " بداية الكوبون",
      // selector: (row) => row?.startDate?.substring(0, 10),
      selector: (row) => handleIsoDate(row?.startDate),
      sortable: true,
    },

    {
      name: " نهاية الكوبون",
      selector: (row) => handleIsoDate(row?.endDate),
      sortable: true,
    },
    {
      name: "(مفعل / غير مفعل)",
      cell: (row) => (
        <>
          <div className="activate">
            <label className="switcher">
              <input
                type="checkbox"
                className="switcher-input"
                defaultChecked={row?.isActive === true ? true : false}
                onChange={() => {
                  dispatch(activateCoupon(getId(row)));
                }}
              />
              <div className="knobs"></div>
              <div className="layer"></div>
            </label>
          </div>
        </>
      ),
    },
    {
      cell: (row) => (
        <button
          className="btn-delete"
          onClick={(e) => handleDeleteCoupon(getId(row))}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="#fff"
            className="bi bi-trash show"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </button>
      ),
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>جدول الكوبونات</h4>
                <p>يعرض هذا الجدول جميع تفاصيل الكوبونات المضافة</p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
            {filteredItems?.length === 0 && (
              <div className="no-data alert alert-warning text-center m-0 mt-4">
                <p>لا توجد كوبونات</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
