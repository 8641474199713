import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getCurrenciesApi } from "api/currencies";

// Login Redux States
import { GET_CURRENCIES } from "./actionTypes";

import { getCurrenciesFailure, getCurrenciesSuccess } from "./actions";

function* getCurrencies() {
  try {
    const { data } = yield call(getCurrenciesApi);
    yield put(getCurrenciesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCurrenciesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetCurrencies() {
  yield takeEvery(GET_CURRENCIES, getCurrencies);
}

function* CurrenciesSaga() {
  yield all([fork(watchGetCurrencies)]);
}

export default CurrenciesSaga;
