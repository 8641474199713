import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAILURE,
  ADD_FAQ,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_FAILURE,
  GET_SINGLE_FAQ,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_FAILURE,
  EDIT_FAQ,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_FAILURE,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILURE,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
} from "./actionTypes";

export const getFaqs = () => {
  return {
    type: GET_FAQS,
  };
};

export const getFaqsSuccess = (blogs) => {
  return {
    type: GET_FAQS_SUCCESS,
    payload: blogs,
  };
};

export const getFaqsFailure = (error) => {
  return {
    type: GET_FAQS_FAILURE,
    payload: error,
  };
};

export const getSingleFaq = (id) => {
  return {
    type: GET_SINGLE_FAQ,
    payload: id,
  };
};

export const getSingleFaqsuccess = (blog) => {
  return {
    type: GET_SINGLE_FAQ_SUCCESS,
    payload: blog,
  };
};

export const getSingleFaqFailure = (error) => {
  return {
    type: GET_SINGLE_FAQ_FAILURE,
    payload: error,
  };
};

export const addFaq = (payload) => {
  return {
    type: ADD_FAQ,
    payload,
  };
};

export const addFaqsuccess = (payload) => {
  return {
    type: ADD_FAQ_SUCCESS,
    payload,
  };
};

export const addFaqFailure = (error) => {
  return {
    type: ADD_FAQ_FAILURE,
    payload: error,
  };
};

export const editFaq = (payload) => {
  return {
    type: EDIT_FAQ,
    payload,
  };
};

export const editFaqsuccess = (payload) => {
  return {
    type: EDIT_FAQ_SUCCESS,
    payload,
  };
};

export const editFaqFailure = (error) => {
  return {
    type: EDIT_FAQ_FAILURE,
    payload: error,
  };
};

export const deleteFaq = (id) => {
  return {
    type: DELETE_FAQ,
    payload: id,
  };
};

export const deleteFaqsuccess = (id) => {
  return {
    type: DELETE_FAQ_SUCCESS,
    payload: id,
  };
};

export const deleteFaqFailure = (error) => {
  return {
    type: DELETE_FAQ_FAILURE,
    payload: error,
  };
};
