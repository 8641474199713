import { Modal } from "react-bootstrap";

export default function QuickModal({
  title,
  children,
  handleClose,
  props = {},
  footer = true,
}) {
  return (
    <Modal
      dialogClassName="productDataModal"
      show={true}
      onHide={handleClose}
      size="xl"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!!footer && (
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
