import { priceInputValidations } from "helpers/configs";
import { getId } from "helpers/functions";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ReactComponent as CodeIcon } from "assets/svgs/coupon/coupon-code.svg";

export default function ConditionsField({
  index,
  control,
  register,
  setValue,
  updateParams,
  selectedItemsType,
  selectedItemsTypeData,
  watch,
  remove,
}) {
  const { formatMessage, locale } = useIntl();

  const { countries, cities } = useSelector((state) => state.customers);

  const { groups } = useSelector((state) => state.groups);

  const companies = [];

  const getOptionsByName = (arr) =>
    //add default option
    [
      {
        label: "اختر",
        value: "",
      },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale],
        value: getId(item),
      }))
    );
  const filteredSelectedItems = (txt) =>
    selectedItemsTypeData?.filter(
      (item) =>
        JSON.stringify({ id: getId(item), name: item?.name })
          .toLowerCase()
          .indexOf(txt.toLowerCase()) !== -1
    );

  return (
    <Row className="mb-3">
      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="countries" />
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.countries`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single w-100"
                classNamePrefix="select"
                options={getOptionsByName(countries)}
                placeholder={formatMessage({ id: "selectCountry" })}
                isMulti={false}
                value={getOptionsByName(countries)?.find(
                  (o) => o?.value === value
                )}
                onChange={({ value }) => {
                  onChange(value);
                  setValue("city", "");
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="cities" />
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.city`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single w-100"
                classNamePrefix="select"
                options={getOptionsByName(
                  cities?.filter((e) => e?.country === watch("countries"))
                )}
                placeholder={formatMessage({ id: "conditionRule" })}
                isMulti={false}
                value={getOptionsByName(
                  cities?.filter((e) => e?.country === watch("countries"))
                )?.find((o) => o?.value === value)}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor=""> الحد الادنى من المشتريات </label>
        <div>
          <input
            className="form-control"
            {...priceInputValidations}
            placeholder="الحد الادنى من المشتريات"
            {...register(`conditions.${index}.minimumPurchases`)}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="companies" />
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.companies`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single w-100"
                classNamePrefix="select"
                options={getOptionsByName(companies)}
                placeholder={formatMessage({ id: "selectCompany" })}
                isMulti={false}
                value={getOptionsByName(companies)?.find(
                  (o) => o?.value === value
                )}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="maxWeightLimit" />
        </label>
        <div>
          <input
            className="form-control"
            {...priceInputValidations}
            placeholder="0"
            {...register(`conditions.${index}.maxWeightLimit`)}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="customersGroup" />
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.group`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single w-100"
                classNamePrefix="select"
                options={getOptionsByName(groups)}
                placeholder={formatMessage({
                  id: "selectCustomerGroup",
                })}
                isMulti={false}
                value={getOptionsByName(groups)?.find(
                  (o) => o?.value === value
                )}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="selectSupportedItemsType" />
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.selectedItemsType`}
            rules={{ required: true }}
            render={({ field: { onChange, value, selected } }) => (
              <Select
                isRtl={true}
                isSearchable={false}
                className="basic-single w-100"
                classNamePrefix="select"
                options={["products", "categories"].map((type) => ({
                  label: formatMessage({ id: type || " " }),
                  value: type,
                }))}
                placeholder={formatMessage({
                  id: "selectCustomerGroup",
                })}
                isMulti={false}
                value={getOptionsByName(groups)?.find(
                  (o) => o?.value === value
                )}
                onChange={({ value }) => {
                  onChange(value);
                  setValue("selectedItemsTypeData", []);
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>

      <Col xs={12}>
        <label htmlFor="">
          <FormattedMessage id="selectSupportedItems" /> (
          <FormattedMessage id={selectedItemsType || " "} />)
        </label>
        <div className="selectHolder">
          <Controller
            control={control}
            name={`conditions.${index}.selectedItemsTypeData`}
            rules={{ required: true }}
            render={({ field: { onChange, value: value1, selected } }) => (
              <Select
                isMulti={true}
                isRtl={true}
                isSearchable={true}
                className="basic-single w-100"
                classNamePrefix="select"
                options={getOptionsByName(selectedItemsTypeData)}
                placeholder={formatMessage({
                  id: "selectCustomerGroup",
                })}
                onChange={(selected) => {
                  onChange(selected.map((selected) => selected?.value));
                }}
                value={getOptionsByName(
                  selectedItemsTypeData?.filter((s) =>
                    value1?.includes(getId(s))
                  )
                )}
                onInputChange={(value) => {
                  if (filteredSelectedItems(value)?.length <= 0)
                    updateParams("nameText", value);
                }}
              />
            )}
          />
          <CodeIcon fill="#E4E4E4" />
        </div>
      </Col>
      <div>
        <button className="btn btn-danger w-auto" onClick={() => remove(index)}>
          <FormattedMessage id="removeCondition" />
        </button>
      </div>
      {/* ================================================ */}
    </Row>
  );
}
