import React from "react";
import { useParams } from "react-router-dom";

import PageContent from "./pageContent";

const EditPage = () => {
  const { id } = useParams();

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل الصفحة</h4>
          </div>
          <div className="card-body">
            <PageContent id={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
