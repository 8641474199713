export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAILURE = "GET_PRODUCT_CATEGORIES_FAILURE";

// ===============================================================

export const GET_SINGLE_PRODUCT_CATEGORY = "GET_SINGLE_PRODUCT_CATEGORY";
export const GET_SINGLE_PRODUCT_CATEGORY_SUCCESS =
  "GET_SINGLE_PRODUCT_CATEGORY_SUCCESS";
export const GET_SINGLE_PRODUCT_CATEGORY_FAILURE =
  "GET_SINGLE_PRODUCT_CATEGORY_FAILURE";

// ===============================================================

export const ADD_PRODUCT_CATEGORIES = "ADD_PRODUCT_CATEGORIES";
export const ADD_PRODUCT_CATEGORIES_SUCCESS = "ADD_PRODUCT_CATEGORIES_SUCCESS";
export const ADD_PRODUCT_CATEGORIES_FAILURE = "ADD_PRODUCT_CATEGORIES_FAILURE";

// ===============================================================

export const EDIT_PRODUCT_CATEGORIES = "EDIT_PRODUCT_CATEGORIES";
export const EDIT_PRODUCT_CATEGORIES_SUCCESS =
  "EDIT_PRODUCT_CATEGORIES_SUCCESS";
export const EDIT_PRODUCT_CATEGORIES_FAILURE =
  "EDIT_PRODUCT_CATEGORIES_FAILURE";

// ===============================================================

export const EDIT_PRODUCT_CATEGORIES_ORDER = "EDIT_PRODUCT_CATEGORIES_ORDER";
export const EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS =
  "EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS";
export const EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE =
  "EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE";

// ===============================================================

export const DELETE_PRODUCT_CATEGORIES = "DELETE_PRODUCT_CATEGORIES";
export const DELETE_PRODUCT_CATEGORIES_SUCCESS =
  "DELETE_PRODUCT_CATEGORIES_SUCCESS";
export const DELETE_PRODUCT_CATEGORIES_FAILURE =
  "DELETE_PRODUCT_CATEGORIES_FAILURE";

// ===============================================================

export const ADD_SUB_PRODUCT_CATEGORIES = "ADD_SUB_PRODUCT_CATEGORIES";
export const ADD_SUB_PRODUCT_CATEGORIES_SUCCESS =
  "ADD_SUB_PRODUCT_CATEGORIES_SUCCESS";
export const ADD_SUB_PRODUCT_CATEGORIES_FAILURE =
  "ADD_SUB_PRODUCT_CATEGORIES_FAILURE";

export const EDIT_SUB_PRODUCT_CATEGORIES = "EDIT_SUB_PRODUCT_CATEGORIES";
export const EDIT_SUB_PRODUCT_CATEGORIES_SUCCESS =
  "EDIT_SUB_PRODUCT_CATEGORIES_SUCCESS";
export const EDIT_SUB_PRODUCT_CATEGORIES_FAILURE =
  "EDIT_SUB_PRODUCT_CATEGORIES_FAILURE";

// ===============================================================
