/* eslint-disable no-unused-vars */
import { Col, Form, Modal, Row } from "react-bootstrap";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/product/modal-header.svg";
import { ReactComponent as TruckIcon } from "assets/svgs/product/truck.svg";
import { useForm } from "react-hook-form";

const NotificationModal = ({
  handleClose,
  minQuantityToNotify,
  setMinQuantityToNotify,
  reorderQuantity,
  setReorderQuantity,
  modalType,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormSubmit = (data) => {
    setMinQuantityToNotify(data.minQuantityToNotify);
    setReorderQuantity(data.reorderQuantity);
    handleClose();
  };

  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal"
        show={modalType === "notifyModal"}
        onHide={handleClose}
        size="lg"
      >
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <ModalHeaderIcon fill="#FC6B14" />
              تنبيهات المنتج
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="form-body">
                <Row>
                  <Col xs={12}>
                    <label htmlFor="">نبهني عند وصول كمية المنتج إلى</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        {...register("reorderQuantity", {
                          required: true,
                        })}
                        defaultValue={reorderQuantity}
                      />
                      <TruckIcon fill="#E4E4E4" />
                      <div className="qty">
                        <span>قطعة</span>
                      </div>
                    </div>
                    <p className="form-hint">
                      سيتم إشعارك قبل انتهاء كمية المنتج
                    </p>
                    <p>
                      {errors.minQuantityToNotify?.type === "required" &&
                        "هذا الحقل مطلوب"}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="">
                      تنبيه العملاء المشتركين في "أعلمني عند التوفر" عند توفر
                      كمية للمنتج أكبر من
                    </label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        {...register("minQuantityToNotify", {
                          required: true,
                        })}
                        defaultValue={minQuantityToNotify}
                      />
                      <TruckIcon fill="#E4E4E4" />
                      <div className="qty">
                        <span>قطعة</span>
                      </div>
                    </div>
                    <p>
                      {errors.minQuantityToNotify?.type === "required" &&
                        "هذا الحقل مطلوب"}
                    </p>
                    <p className="form-hint">
                      لتجنب إرسال الإشعارات للعملاء عند توفر كمية بسيطة من
                      المنتج، يمكنك تحديد الحد الأدنى المطلوب توفره للبدء في
                      إرسال الإشعارات للعملاء، القيمة الافتراضية 15
                    </p>
                  </Col>
                  {/* <Col xs={12}>
                  <label htmlFor="">نسبة العملاء المراد إشعارهم</label>
                  <div>
                    <input type="number" className="form-control" />
                    <TruckIcon fill="#E4E4E4" />
                    <div className="qty">
                      <span>%</span>
                    </div>
                  </div>
                  <p className="form-hint">
                    سيتم إرسال إشعار توفر المنتج لنسبة من العملاء المشتركين في
                    "أعلمني عند التوفر". عدد العملاء المشتركين الآن 0 عملاء.
                  </p>
                </Col> */}
                </Row>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline"
              onClick={handleClose}
              type="button"
            >
              إلغاء
            </button>
            <button type="submit" className="btn btn-blue">
              حفظ
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default NotificationModal;
