import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import blogIcon from "../../assets/svgs/blog.svg";
import Table from "./Table";
const Index = () => {
  const { faqs } = useSelector((state) => state.faqs);

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة سؤال شائع"
          cardTxt="الاسئلة الشائعة"
          icon={blogIcon}
          url="/faqs/add"
        />
        <Table data={faqs} />
      </div>
    </>
  );
};

export default Index;
