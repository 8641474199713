import { Col, Modal, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { ReactComponent as BoxIcon } from "assets/svgs/product/box.svg";
import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";
import { ReactComponent as DiscPriceDownIcon } from "assets/svgs/product/price-down.svg";
import {
  columnsHeadNames,
  columnsNames,
  conditionOptions,
  getTypeByName,
  isShownOptions,
} from "./VariationsTable";
import { priceInputValidations } from "helpers/configs";

export default function VariationInputModal({
  selectedRows,
  register,
  control,
  handleClose,
  modalType,
  setValue,
}) {
  const updateRows = (name, value) => {
    // eslint-disable-next-line array-callback-return
    selectedRows?.map(({ index }) => {
      setValue(`variationsOptionsValues.${index}.${name}`, value);
    });
    // forceRerender();
  };
  const InputElement = ({ name }) => {
    let element = null,
      options = conditionOptions;

    switch (name) {
      case "isShown":
        options = isShownOptions;
        element = (
          <div className="selectHolder w-100">
            <Select
              isRtl={true}
              isSearchable={false}
              className="basic-single"
              classNamePrefix="select"
              options={options}
              placeholder="اختر حالة عرض المنتج"
              isMulti={false}
              onChange={({ value }) => {
                updateRows(name, value);
              }}
            />
            <BoxIcon fill="#E4E4E4" />
          </div>
        );
        break;
      case "condition":
        element = (
          <div className="selectHolder w-100">
            <Select
              isRtl={true}
              isSearchable={false}
              className="basic-single"
              classNamePrefix="select"
              options={options}
              placeholder="اختر حالة المنتج"
              isMulti={false}
              onChange={({ value }) => {
                updateRows(name, value);
              }}
            />
            <BoxIcon fill="#E4E4E4" />
          </div>
        );
        break;
      case "discountDate":
        element = (
          <div className="selectHolder w-100">
            <Flatpickr
              className="form-control form-outline"
              // placeholder="تاريخ الانتهاء"
              placeholder="القيمة الاساسية لن تتغير اذا لم تدخل قيمة"
              onChange={([date]) => {
                updateRows(name, date);
              }}
            />
            <DiscPriceDownIcon fill="#E4E4E4" />
          </div>
        );
        break;
      case "price":
      case "discountPrice":
        element = (
          <>
            <input
              className="form-control"
              placeholder="القيمة الاساسية لن تتغير اذا لم تدخل قيمة"
              onChange={({ target: { value } }) => {
                updateRows(name, value);
              }}
              {...priceInputValidations}
            />
            <StockIcon fill="#E4E4E4" />
          </>
        );
        break;
      default:
        element = (
          <>
            <input
              className="form-control"
              type={getTypeByName(name)}
              placeholder="القيمة الاساسية لن تتغير اذا لم تدخل قيمة"
              onChange={({ target: { value } }) => {
                updateRows(name, value);
              }}
            />
            <StockIcon fill="#E4E4E4" />
          </>
        );
    }
    return (
      <Col {...inputColsBreaks} className="mb-4">
        <label htmlFor="">{columnsHeadNames?.[name] || ""}</label>
        <div>{element}</div>
      </Col>
    );
  };

  const renderInputs = () =>
    columnsNames
      ?.filter((n) => n !== "name" && n !== "images")
      ?.map((name, index) => <InputElement name={name} key={index} />);

  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal"
        show={modalType === "variationTableInputs"}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`تعديل اجمالي عدد : ${
              selectedRows?.length || ""
            } -> ( ${selectedRows?.map((e) => e?.name)?.join("  --  ")}) `}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="form-body">
              <Row className="2">{renderInputs()}</Row>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-blue">
            تم
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const inputColsBreaks = { lg: 4, md: 6, sm: 12, xs: 12 };
