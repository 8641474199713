import server from "./server";

export const getFaqCategoriesApi = async () => {
  const response = await server().get("/faqsCategories");
  return response.data;
};

export const getSingleFaqCategoryApi = async (id) => {
  const response = await server().get(`/faqsCategories/${id}`);
  return response.data;
};

export const addFaqCategoryApi = async (payload) => {
  const response = await server().post("/faqsCategories", payload);
  return response.data;
};

export const editFaqCategoryApi = async ({ id, data }) => {
  const response = await server().put(`/faqsCategories/${id}`, data);
  return response.data;
};

export const deleteFaqCategoryApi = async (id) => {
  const response = await server().delete(`/faqsCategories/${id}`);
  return response.data;
};
