import { getId } from "helpers/functions";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { deleteBankAccount, setDefaultBankAccount } from "store/actions";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as TrashIcon } from "assets/svgs/trash.svg";
import Swal from "sweetalert2";

const CheckCard = ({ data, selectBank, editDeleteFlag = true }) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  // const mainCheckbox = useRef(null);

  const handleChangeDefault = (e) => {
    // e.stopPropagation();
    if (!editDeleteFlag) return;
    dispatch(setDefaultBankAccount(getId(data)));
  };

  const handleSelect = (e) => {
    // e.stopPropagation();
    selectBank(data);
  };

  const handleDelete = (e) => {
    // e.stopPropagation();
    Swal.fire({
      title: `${formatMessage({ id: "deleteBankAccount" })} (${data.userName})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d83c9",
      cancelButtonColor: "#d33",
      confirmButtonText: formatMessage({ id: "yes" }),
      cancelButtonText: formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBankAccount(getId(data)));
      }
    });
  };
  return (
    <>
      <Col lg={4} xs={6} className="bank">
        <div className="card p-2">
          <div className="card-inner bank">
            <div className="symbol fs-6">
              <h5>
                {data?.bank?.name?.[locale]} - {data?.userName}
              </h5>
              <h5 className="muted" title={data?.iban}>
                {data?.iban}
              </h5>
            </div>

            <div className="checker">
              {!editDeleteFlag && (
                <input
                  type="radio"
                  name="selected-bank"
                  className="switcher-input"
                />
              )}
            </div>
            <label
              className="switcher"
              title={formatMessage({ id: "setDefault" })}
            >
              <input
                type="checkbox"
                className="switcher-input"
                checked={data?.isDefault}
                onChange={handleChangeDefault}
              />
              <div className="knobs"></div>
              <div className="layer"></div>
            </label>

            {editDeleteFlag && (
              <div className="text icon">
                <button type="button" onClick={handleSelect}>
                  <EditIcon />
                </button>
                <button type="button" onClick={handleDelete}>
                  <TrashIcon fill="#FC6B14" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default CheckCard;
