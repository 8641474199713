import { Link } from "react-router-dom";

import controlPanel from "assets/svgs/controlPanel.svg";
const BreadCrumb = ({ pageName, parent, child = false }) => {
  return (
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">
            <img src={controlPanel} alt="control panel" />
            لوحة التحكم
          </Link>
        </li>
        {parent && (
          <li>
            <Link to={parent?.link}>{parent?.text}</Link>
          </li>
        )}
        {child && (
          <li>
            <Link to={child?.link}>{child?.text}</Link>
          </li>
        )}
        <li>{pageName}</li>
      </ul>
    </div>
  );
};

export default BreadCrumb;
