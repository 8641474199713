import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getVisitsReportApi } from "api/visitsReport";

// Login Redux States
import { GET_VISITS_REPORT } from "./actionTypes";
import { getVisitsReportFailure, getVisitsReportSuccess } from "./actions";

function* getData() {
  try {
    const { data } = yield call(getVisitsReportApi);
    yield put(getVisitsReportSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getVisitsReportFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetData() {
  yield takeEvery(GET_VISITS_REPORT, getData);
}

function* themesSaga() {
  yield all([fork(watchGetData)]);
}

export default themesSaga;
