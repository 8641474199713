/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";

import { ReactComponent as SeoIcon } from "assets/svgs/product/eye.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import FilesUpload from "../FilesUpload";

export default function Attachments({ product, setValue }) {
  const { locale } = useIntl();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(product?.attachedFiles || []);
  }, [product?.attachedFiles]);

  useEffect(() => {
    setValue("attachedFiles", files);
  }, [files, setValue]);

  return (
    <div className="form-group">
      <div className="form-head">
        <i>
          <SeoIcon fill="#FC6B14" />
        </i>
        <div>
          <h5>
            <FormattedMessage id="productAttachments" />
          </h5>
          <span>
            <FormattedMessage id="productAttachmentsDescription" />
          </span>
        </div>
      </div>
      <div className="form-body">
        <Row>
          <FilesUpload files={files} setFiles={setFiles} />
        </Row>
      </div>
    </div>
  );
}
