export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const GET_CUSTOMERS_TAGS = "GET_CUSTOMERS_TAGS";
export const GET_CUSTOMERS_TAGS_SUCCESS = "GET_CUSTOMERS_TAGS_SUCCESS";
export const GET_CUSTOMERS_TAGS_FAILURE = "GET_CUSTOMERS_TAGS_FAILURE";

export const GET_SINGLE_CUSTOMER = "GET_SINGLE_CUSTOMER";
export const GET_SINGLE_CUSTOMER_SUCCESS = "GET_SINGLE_CUSTOMER_SUCCESS";
export const GET_SINGLE_CUSTOMER_FAILURE = "GET_SINGLE_CUSTOMER_FAILURE";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

export const BLOCK_CUSTOMER = "BLOCK_CUSTOMER";
export const BLOCK_CUSTOMER_SUCCESS = "BLOCK_CUSTOMER_SUCCESS";
export const BLOCK_CUSTOMER_FAILURE = "BLOCK_CUSTOMER_FAILURE";

export const ADD_CUSTOMER_TO_GROUP = "ADD_CUSTOMER_TO_GROUP";
export const ADD_CUSTOMER_TO_GROUP_SUCCESS = "ADD_CUSTOMER_TO_GROUP_SUCCESS";
export const ADD_CUSTOMER_TO_GROUP_FAILURE = "ADD_CUSTOMER_TO_GROUP_FAILURE";

export const DELETE_CUSTOMER_FROM_GROUP = "DELETE_CUSTOMER_FROM_GROUP";
export const DELETE_CUSTOMER_FROM_GROUP_SUCCESS =
  "DELETE_CUSTOMER_FROM_GROUP_SUCCESS";
export const DELETE_CUSTOMER_FROM_GROUP_FAILURE =
  "DELETE_CUSTOMER_FROM_GROUP_FAILURE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_AREAS = "GET_AREAS";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_FAILURE = "GET_AREAS_FAILURE";
