import React from "react";
import { Col, Row } from "react-bootstrap";
import MultiLangWrapper from "./MultiLangsWrapper";

const SeoForm = ({ errors, register, required = true }) => {
  return (
    <div className="seo-form">
      <h4>معلومات السيو (SEO)</h4>
      <Row>
        <Col lg={6}>
          <MultiLangWrapper label="عنوان السيو" errors={errors?.title}>
            {({ lang, classes, key }) => (
              <input
                key={key}
                type="text"
                className={`form-control form-outline ${classes}`}
                placeholder="عنوان السيو"
                {...register(`seo.title.${lang}`, { required: required })}
              />
            )}
            <p className="error-hint">
              {errors?.seo?.title && "يرجي ادخال العنوان"}
            </p>
          </MultiLangWrapper>
        </Col>

        <Col lg={6}>
          <MultiLangWrapper label="وصف السيو" errors={errors?.description}>
            {({ lang, classes, key }) => (
              <input
                key={key}
                type="text"
                className={`form-control form-outline ${classes}`}
                placeholder="وصف السيو"
                {...register(`seo.description.${lang}`, {
                  required: required,
                })}
              />
            )}
            <p className="error-hint">
              {errors?.seo?.description && "يرجي ادخال الوصف"}
            </p>
          </MultiLangWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default SeoForm;
