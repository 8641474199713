import BreadCrumb from "components/shared/BreadCrumb";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  addThemeToStore,
  getActiveStoreThemes,
  getThemeTemplates,
} from "store/actions";

const Index = () => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const { themeTemplates } = useSelector((state) => state.themeTemplates);
  const { activeStore, activeStoreThemes, loading } = useSelector(
    (state) => state.stores
  );
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  useEffect(() => {
    dispatch(getThemeTemplates(deviceType));
    dispatch(getActiveStoreThemes({ storeId: activeStore?._id }));
  }, [activeStore?._id, deviceType, dispatch, searchParams]);

  const renderThemes = themeTemplates
    ?.filter((theme) => theme.deviceType === deviceType)
    ?.map((theme, index) => {
      const isThemeExist = activeStoreThemes?.find(
        (storeTheme) => getId(storeTheme?.theme) === theme?.id
      );
      return (
        <Col key={theme?.id} xxl={3} lg={4} md={4} xs={6}>
          <div className="themes-item">
            <div className="themes-img">
              <img src={theme?.image} alt="" />
              <span className="theme-price">{theme?.price}</span>
            </div>
            <div className="themes-content">
              <h5>{theme?.name?.[locale]}</h5>

              {isThemeExist ? (
                <span>تم الشراء</span>
              ) : (
                <button
                  type="button"
                  className="btn btn-blue btn-outline"
                  onClick={() => {
                    dispatch(
                      addThemeToStore({
                        storeId: activeStore?.id,
                        themeId: theme?.id,
                        deviceType,
                      })
                    );
                  }}
                >
                  شراء
                </button>
              )}
            </div>
          </div>
        </Col>
      );
    });

  if (loading) return <Loader />;

  return (
    <>
      <div className="stores-wrap themes-wrap">
        <BreadCrumb pageName="الثيمات" />
        <Row>{renderThemes}</Row>
      </div>
    </>
  );
};

export default Index;
