import { getId } from "helpers/functions";
import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_FAILURE,
  ADD_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORIES_SUCCESS,
  ADD_PRODUCT_CATEGORIES_FAILURE,
  DELETE_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES_SUCCESS,
  DELETE_PRODUCT_CATEGORIES_FAILURE,
  GET_SINGLE_PRODUCT_CATEGORY,
  GET_SINGLE_PRODUCT_CATEGORY_SUCCESS,
  GET_SINGLE_PRODUCT_CATEGORY_FAILURE,
  EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_ORDER,
  EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE,
  EDIT_PRODUCT_CATEGORIES_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_FAILURE,
  EDIT_PRODUCT_CATEGORIES,
  ADD_SUB_PRODUCT_CATEGORIES,
  ADD_SUB_PRODUCT_CATEGORIES_SUCCESS,
  ADD_SUB_PRODUCT_CATEGORIES_FAILURE,
} from "./actionTypes";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const initialState = {
  loading: false,
  editLoading: false,
  error: "",
  categories: [],
  singleCategory: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload.categories,
        flatCategories: action.payload.categories?.flatMap((cat) => [
          cat,
          ...(cat?.subcategories || []),
        ]),
        error: "",
      };
      break;
    case GET_PRODUCT_CATEGORIES_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = { ...state, error: action.payload, loading: false };
      break;

    // ===============================================================

    case GET_SINGLE_PRODUCT_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PRODUCT_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCategory: {
          ...action.payload.category,
          products: (action.payload.category?.products || []).sort(
            (a, b) => a?.orderInCategory - b?.orderInCategory
          ),
        },
        error: "",
      };
      break;

    case GET_SINGLE_PRODUCT_CATEGORY_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleCategory: {},
      };
      break;

    // ===============================================================

    case ADD_PRODUCT_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: [action.payload.category, ...state.categories],
        error: "",
      };
      break;
    case ADD_PRODUCT_CATEGORIES_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = { ...state, error: action.payload, loading: false };
      break;

    // ===============================================================

    case EDIT_PRODUCT_CATEGORIES:
      state = {
        ...state,
        editLoading: true,
      };
      break;
    case EDIT_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        editLoading: false,
        error: "",
        categories: [
          ...state?.categories?.map((category) => {
            if (getId(category) === getId(action.payload?.category)) {
              return action.payload?.category;
            }
            return category;
          }),
        ],
      };
      break;
    case EDIT_PRODUCT_CATEGORIES_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = {
        ...state,
        editLoading: false,
      };
      break;

    // ===============================================================

    case EDIT_PRODUCT_CATEGORIES_ORDER:
      state = {
        ...state,
        editLoading: true,
      };
      break;
    case EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS:
      state = {
        ...state,
        editLoading: false,
        // categories: [
        //   ...state?.categories,
        // ],
        // categories: action.payload.orderedCategories,
        error: "",
      };
      break;
    case EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = {
        ...state,
        editLoading: false,
        error: action.payload,
      };
      break;

    // ===============================================================

    case DELETE_PRODUCT_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: [
          ...state?.categories?.filter(
            (category) => category?._id !== action.payload
          ),
        ],
        error: "",
      };
      break;
    case DELETE_PRODUCT_CATEGORIES_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ===============================================================

    case ADD_SUB_PRODUCT_CATEGORIES:
      state = {
        ...state,
        editLoading: true,
      };
      break;

    case ADD_SUB_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        editLoading: false,
        error: "",
        categories: [
          ...state?.categories?.map((category) => {
            if (category?._id === action.payload?.category?.parentCategory) {
              return {
                ...category,
                subcategories: [
                  action.payload?.category,
                  ...category?.subcategories,
                ],
              };
            }
            return category;
          }),
        ],
      };
      break;

    case ADD_SUB_PRODUCT_CATEGORIES_FAILURE:
      toastErrorMessage({ error: action.payload });

      state = {
        ...state,
        editLoading: false,
        error: action.payload,
      };
      break;

    // ===============================================================

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
