import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

const ServerError = () => {
  const { code } = useParams();
  return (
    <>
      {/* <div className="error-outer">
        <h1>Error 404</h1>
      </div> */}
      <section className="page_404">
        <div className="text-center">
          <div className="d-flex flex-column gap-5">
            <img
              src="https://cdn.dribbble.com/users/387195/screenshots/3326215/media/5f977d77091a9c985e7734d8466d6014.png?resize=800x600&vertical=center"
              alt=""
              style={{ width: "300px", margin: "auto" }}
            />
            <h1 className="text-center h1">
              <FormattedMessage id={code || " "} />
              :(
            </h1>
            <h2 className="text-center ">
              {" - "}
              <FormattedMessage id={"recordedAndWorkingOnIt"} />
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServerError;
