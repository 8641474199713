import server from "./server";

// export const getProductsApi = async (payload) => {
//   const response = payload?.searchParams
//     ? await server().get(
//       `/products?page=${payload?.searchParams?.page || 1}&limit=${payload?.searchParams?.limit || 10
//       }`
//     )
//     : await server().get(`/products?limit=10`);
//   return response.data;
// };

export const getProductsApi = async (payload) => {
  const response = await server().get(`/products${payload || ""}`);
  return response.data;
};

export const getProductsInventoryApi = async (payload) => {
  const response = await server().get(`/products/inventory${payload || ""}`);
  return response.data;
};

export const getProductsVariationsApi = async () => {
  const response = await server().get("/variations");
  return response.data;
};

export const getProductsVariationsStylesApi = async () => {
  const response = await server().get("/variationStyles");
  return response.data;
};

export const getSingleProductApi = async (id) => {
  const response = await server().get(`/products/${id}`);
  return response.data;
};

export const addProductApi = async (payload) => {
  const response = await server().post("/products", payload);
  return response.data;
};

export const editProductApi = async ({ id, data }) => {
  const response = await server().put(`/products/${id}`, data);
  return response.data;
};

export const updateProductMainDataApi = async ({ id, data }) => {
  const response = await server().put(`/products/updateMainData/${id}`, {
    ...data,
  });
  return response.data;
};

export const hideProductApi = async (id) => {
  const response = await server().get(`/products/hiddenProducts/${id}`);
  return response.data;
};

export const deleteProductApi = async (id) => {
  const response = await server().delete(`/products/${id}`);
  return response.data;
};

export const deleteMultipleProductsApi = async (data) => {
  const response = await server().delete(`/products/deleteMultiProducts/`, {
    data,
  });
  return response.data;
};

export const deleteAllProductsInStore = async () => {
  const response = await server().delete(`/products/deleteAllProductsInStore`);
  return response.data;
};

export const orderProductsApi = async (payload) => {
  const response = await server().put(
    `/products/reorderProducts/${payload?.category}`,
    { products: payload?.products }
  );
  return response.data;
};

export const getProductsTagsApi = async () => {
  const response = await server().get("/products/getAllTags");
  return response.data;
};
