/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";

import ProductVariationsChildren from "./variationComponents/VariationsChildren";
import ProductVariationsTable from "./variationComponents/VariationsTable";
import AddVariantModal from "./variationComponents/AddVariantModal";

export default function VariationTabBody({
  selectedVariations,
  register,
  watch,
  remove,
  control,
  forceRerender,
  getValues,
  setValue,
  variationsDataList,
  errors,
  displayedListType,
  appendSelectedVariations,
}) {
  const [quickModal, setQuickModal] = useState({});
  const renderSelectedOptions = selectedVariations.map((item, index) => (
    <div className="variation-option" key={index}>
      <a
        onClick={() => {
          remove(index);
          forceRerender();
        }}
      >
        <CloseIcon />
      </a>
      {`${item?.label} / ${item?.variationStyleLabel}`}
    </div>
  ));

  const renderSelectedOptionsRows = selectedVariations.map((item, index) => (
    <ProductVariationsChildren
      variation={item}
      index={index}
      control={control}
      forceRerender={forceRerender}
      handleRemove={() => remove(index)}
      setValue={setValue}
      key={"VariationsChild" + index}
    />
  ));

  return (
    <div className="form-group variations-pane">
      <div className="form-body">
        <Row>
          <Col xs={12}>
            <div className="selectHolder">
              <label className="variations-add-title d-flex align-items-center">
                المتغيرات
                <div className="d-flex gap-2 ps-2">
                  *مسموح بعدد ( 2 ) نوع متغير فقط ، تم اختيار (
                  {selectedVariations?.length} / 2)
                </div>
              </label>
              <div className="variations-add">
                <Row>
                  <Col xs="10">
                    <div className="variations-wrapper">
                      {renderSelectedOptions}
                    </div>
                  </Col>

                  <Col xs="2" className="text-left">
                    <button
                      type="button"
                      className="btn btn-blue"
                      disabled={watch("variationsOptions")?.length > 1}
                      onClick={() => {
                        let handleClose = () => setQuickModal({});
                        setQuickModal({
                          show: true,
                          props: { size: "md" },
                          footer: false,
                          handleClose,
                        });
                      }}
                    >
                      إضافة متغير
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {renderSelectedOptionsRows}
        {/* ------------------------------------------- */}
        {watch("variationsOptions")?.length > 0 && (
          <Row>
            <ProductVariationsTable
              variations={watch("variationsOptions")}
              register={register}
              setValue={setValue}
              control={control}
              errors={errors}
              getValues={getValues}
              forceRerender={forceRerender}
            />
          </Row>
        )}
      </div>

      <AddVariantModal
        quickModal={quickModal}
        formVariationsOptions={getValues("variationsOptions")}
        variationsDataList={variationsDataList}
        displayedListType={displayedListType}
        appendSelectedVariations={appendSelectedVariations}
        handleClose={() => setQuickModal({})}
      />
    </div>
  );
}
