import {
  GET_BANKS,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILURE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
} from "./actionTypes";

const initialState = {
  paymentMethods: [],
  banks: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANKS:
      state = { ...state, loading: true };
      break;
    case GET_BANKS_SUCCESS:
      state = {
        ...state,
        loading: false,
        banks: action.payload.banks,
      };
      break;
    case GET_BANKS_FAILURE:
      state = { ...state, loading: false };
      break;

    //payment methods
    case GET_PAYMENT_METHODS:
      state = { ...state, loading: true };
      break;
    case GET_PAYMENT_METHODS_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
      };
      break;
    case GET_PAYMENT_METHODS_FAILURE:
      state = { ...state, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
