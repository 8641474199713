import StickyLoader from "components/shared/StickyLoader";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ReactComponent as TruckIcon } from "assets/svgs/settings/truck-tick.svg";

function DeliveryCompanies() {
  const { locale } = useIntl();
  const { settings, loading } = useSelector((state) => state?.settings) || {};

  const items = settings?.companies || [];

  const renderItems = items?.length ? (
    items?.map((item, index) => (
      <div className="company" key={index}>
        {item?.name?.[locale]}
      </div>
    ))
  ) : (
    <div className="no-data">
      <TruckIcon />
      <h4>
        <FormattedMessage id="addFirstPilot" />
      </h4>
      <p>
        <FormattedMessage id="addFirstPilotDesc" />
      </p>
    </div>
  );

  if (loading) return <StickyLoader />;
  return <div className="delivery-companies">{renderItems}</div>;
}

export default DeliveryCompanies;
