import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addProductCategoriesApi,
  getProductCategoriesApi,
  deleteProductCategoriesApi,
  editProductCategoriesApi,
  getSingleProductCategoryApi,
  editProductCategoriesOrderApi,
} from "api/productCategories";

// Login Redux States
import {
  ADD_PRODUCT_CATEGORIES,
  ADD_SUB_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES_ORDER,
  GET_PRODUCT_CATEGORIES,
  GET_SINGLE_PRODUCT_CATEGORY,
} from "./actionTypes";
import {
  addProductCategoriesFailure,
  addProductCategoriesSuccess,
  addSubProductCategoriesFailure,
  addSubProductCategoriesSuccess,
  deleteProductCategoriesFailure,
  deleteProductCategoriesSuccess,
  editProductCategoriesFailure,
  editProductCategoriesOrderFailure,
  editProductCategoriesOrderSuccess,
  editProductCategoriesSuccess,
  getProductCategoriesFailure,
  getProductCategoriesSuccess,
  getSingleProductCategoryFailure,
  getSingleProductCategorySuccess,
} from "./actions";

function* getProductCategories({ payload }) {
  try {
    const { data } = yield call(getProductCategoriesApi, payload);
    yield put(getProductCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getProductCategoriesFailure(error?.response?.data));
  }
}

// ===========================================================

function* getSingleProductCategory({ payload }) {
  try {
    const { data } = yield call(getSingleProductCategoryApi, payload);
    yield put(getSingleProductCategorySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleProductCategoryFailure(error?.response?.data || error));
  }
}

// ===========================================================

function* addProductCategories({ payload }) {
  try {
    const { data, success } = yield call(addProductCategoriesApi, payload.data);
    yield put(addProductCategoriesSuccess(data));
    if (success) if (payload.handleClose) payload.handleClose();
    yield getProductCategories({});
  } catch (error) {
    console.log(error);
    yield put(
      addProductCategoriesFailure(error.response?.data?.message || error)
    );
  }
}

// ===========================================================

function* editProductCategories({ payload }) {
  try {
    let { data } = yield call(editProductCategoriesApi, payload);
    yield put(editProductCategoriesSuccess(data));
    yield getProductCategories({});
    // if (success)
    if (payload.callBack) payload.callBack();
    if (payload.handleClose) payload.handleClose();
  } catch (error) {
    console.log(error);
    yield put(editProductCategoriesFailure(error?.response?.data || error));
  }
}

// ===========================================================

function* editProductCategoriesOrder({ payload }) {
  try {
    const { data } = yield call(editProductCategoriesOrderApi, payload);
    yield put(editProductCategoriesOrderSuccess(data));
    yield getProductCategories({});
    // yield payload.navigate("/categories");
  } catch (error) {
    console.log(error);
    yield put(
      editProductCategoriesOrderFailure(error?.response?.data || error)
    );
  }
}

// ===========================================================

function* deleteProductCategories({ payload }) {
  try {
    yield call(deleteProductCategoriesApi, payload);
    yield put(deleteProductCategoriesSuccess(payload));
    yield getProductCategories({});
  } catch (error) {
    console.log(error);
    yield put(deleteProductCategoriesFailure(error.response?.data || error));
  }
}

// ===========================================================

function* addSubProductCategories({ payload }) {
  try {
    const { data, success } = yield call(addProductCategoriesApi, payload.data);
    yield put(addSubProductCategoriesSuccess(data));
    if (success) payload.handleClose();
    yield getProductCategories({});
  } catch (error) {
    console.log(error);
    yield put(addSubProductCategoriesFailure(error?.response?.data || error));
  }
}

// ===========================================================
// ===========================================================

export function* watchProductCategories() {
  yield takeEvery(GET_PRODUCT_CATEGORIES, getProductCategories);
}

export function* watchGetSingleProductCategory() {
  yield takeEvery(GET_SINGLE_PRODUCT_CATEGORY, getSingleProductCategory);
}

export function* watchAddProductCategories() {
  yield takeEvery(ADD_PRODUCT_CATEGORIES, addProductCategories);
}

export function* watchEditProductCategories() {
  yield takeEvery(EDIT_PRODUCT_CATEGORIES, editProductCategories);
}

export function* watchEditProductCategoriesOrder() {
  yield takeEvery(EDIT_PRODUCT_CATEGORIES_ORDER, editProductCategoriesOrder);
}

export function* watchDeleteProductCategories() {
  yield takeEvery(DELETE_PRODUCT_CATEGORIES, deleteProductCategories);
}

export function* watchAddSubProductCategories() {
  yield takeEvery(ADD_SUB_PRODUCT_CATEGORIES, addSubProductCategories);
}

// ===========================================================
// ===========================================================

function* productCategoriesSaga() {
  yield all([fork(watchProductCategories)]);
  yield all([fork(watchGetSingleProductCategory)]);
  yield all([fork(watchAddProductCategories)]);
  yield all([fork(watchEditProductCategories)]);
  yield all([fork(watchEditProductCategoriesOrder)]);
  yield all([fork(watchDeleteProductCategories)]);
  yield all([fork(watchAddSubProductCategories)]);
}

export default productCategoriesSaga;
