import { Dropdown } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { deleteGroup } from "store/actions";
import Swal from "sweetalert2";
import "swiper/css";
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { ReactComponent as GroupTitleIcon } from "assets/svgs/product/title.svg";
import { ReactComponent as GroupSettingIcon } from "assets/svgs/product/settings.svg";
// import { ReactComponent as GroupMoreIcon } from "assets/svgs/product/more.svg";
import { ReactComponent as ThreeDotsIcon } from "assets/svgs/three-dots.svg";
import { useNavigate } from "react-router-dom";
import { getId, handleImage } from "helpers/functions";
import peopleIcon from "assets/svgs/people.svg";

const GroupCard = ({ group, params }) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteGroup = () => {
    Swal.fire({
      title: formatMessage({ id: "deleteGroup" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteGroup(getId(group)));
      }
    });
  };
  const handleNavigateToGroup = () => {
    params.append("group", getId(group));
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`/customers${paramsStr}`);
  };

  if (getId(group) === "new")
    return (
      <div className="card plus cursor-pointer" onClick={handleNavigateToGroup}>
        <PlusIcon />
        <h4>
          <FormattedMessage id="addNewGroup" />
        </h4>
      </div>
    );
  return (
    <div className="card">
      <div className="productCard">
        <div className="p-data">
          <div className="form-group group-icon">
            <img
              src={group?.icon ? handleImage(group?.icon) : peopleIcon}
              alt=""
            />
          </div>
          <div className="form-group">
            <div>
              <GroupTitleIcon fill="#E4E4E4" />
              <input
                type="text"
                className="form-control"
                placeholder={formatMessage({ id: "groupTitle" })}
                value={group?.name?.[locale]}
                disabled
              />
            </div>
          </div>
          <div className="form-group has-btns last-row">
            <button type="button" onClick={handleNavigateToGroup}>
              <GroupSettingIcon fill="#FC6B14" />
              <FormattedMessage id="groupDetails" />
            </button>
            <Dropdown>
              <Dropdown.Toggle>
                <ThreeDotsIcon width={15} color="#FC6B14" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="delete"
                  onClick={() => {
                    handleDeleteGroup(getId(group));
                  }}
                >
                  <DeleteIcon fill="#f00" />
                  <FormattedMessage id="delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
