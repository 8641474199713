import {
  GET_VISITS_REPORT,
  GET_VISITS_REPORT_SUCCESS,
  GET_VISITS_REPORT_FAILURE,
} from "./actionTypes";

const initialState = {
  data: [],
  loading: false,
  error: "",
  singleTheme: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_VISITS_REPORT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VISITS_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload.visits,
      };
      break;
    case GET_VISITS_REPORT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
