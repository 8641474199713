import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import StoresSaga from "./stores/saga";
import CategoriesSaga from "./categories/saga";
import PackagesSaga from "./packages/saga";
import UsersSaga from "./customers/saga";
import productCategoriesSaga from "./productCategories/saga";
import productTypesSaga from "./productTypes/saga";
import productsSaga from "./products/saga";
import brandsSaga from "./brands/saga";
import optionsSaga from "./options/saga";
import featuresSaga from "./features/saga";
import themeTemplates from "./themeTemplates/saga";
import blogsSaga from "./blogs/saga";
import blogCategoriesSaga from "./blogCategories/saga";
import languagesSaga from "./languages/saga";
import ordersSaga from "./orders/saga";
import dashboardSaga from "./dashboard/saga";
import notificationsSaga from "./notifications/saga";
import couponsSaga from "./coupons/saga";
import unitsSaga from "./measuringUnits/saga";
import settingsSaga from "./settings/saga";
import pagesSaga from "./pages/saga";
import currenciesSaga from "./currencies/saga";
import paymentMethodsSaga from "./paymentMethods/saga";
import visitsReportSaga from "./visitsReport/saga";
import groupsSaga from "./groups/saga";
import builderSaga from "./builder/saga";
import productsReviewsSaga from "./productsReviews/saga";
import faqsSaga from "./faqs/saga";
import faqsCategoriesSaga from "./faqs/categories/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    StoresSaga(),
    CategoriesSaga(),
    PackagesSaga(),
    UsersSaga(),
    productCategoriesSaga(),
    productTypesSaga(),
    productsSaga(),
    brandsSaga(),
    optionsSaga(),
    featuresSaga(),
    themeTemplates(),
    blogsSaga(),
    blogCategoriesSaga(),
    languagesSaga(),
    ordersSaga(),
    dashboardSaga(),
    notificationsSaga(),
    couponsSaga(),
    unitsSaga(),
    settingsSaga(),
    pagesSaga(),
    currenciesSaga(),
    paymentMethodsSaga(),
    visitsReportSaga(),
    groupsSaga(),
    builderSaga(),
    productsReviewsSaga(),
    faqsSaga(),
    faqsCategoriesSaga(),
  ]);
}
