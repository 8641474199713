import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editCustomer,
  getCustomersTags,
  getSingleCustomer,
} from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import Creatable from "react-select/creatable";
import { FormattedMessage, useIntl } from "react-intl";
import { getId } from "helpers/functions";

const EditCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { locale, formatMessage } = useIntl();

  const { singleUser, loading } = useSelector((state) => state?.customers);
  const { register, handleSubmit, control, reset } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  useEffect(() => {
    dispatch(getCustomersTags());
    dispatch(getSingleCustomer(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      username: singleUser?.username,
      email: singleUser?.email,
      // password: singleUser?.password,
      phone: singleUser?.phone,
      tags: singleUser?.tags || [],
      addresses: singleUser?.addresses,
    });
  }, [reset, singleUser]);

  const getOptionsByName = (arr) =>
    arr?.map((item) => ({
      label: item.name?.[locale] || item,
      value: getId(item),
    }));

  const onSubmit = (data) => {
    dispatch(editCustomer({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات العميل</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم العميل</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم العميل"
                          {...register("username", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رقم الجوال</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رقم الجوال"
                          {...register("phone", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>البريد الالكتروني</h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder="البريد الالكتروني"
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="tags" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <>
                              <Creatable
                                className="basic-single"
                                classNamePrefix="select"
                                options={getOptionsByName(
                                  singleUser.tags || []
                                )}
                                placeholder={formatMessage({
                                  id: "selectTag",
                                })}
                                isMulti={true}
                                value={getOptionsByName(value || [])}
                                onChange={(value) => {
                                  onChange(value?.map((e) => e?.value));
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>الرقم السري</h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder="الرقم السري"
                          {...register("password", { required: true })}
                        />
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={12}>
                    <div className="form-group branches">
                      <h5>العناوين</h5>

                      {fields.map((item, index) => {
                        return (
                          <div key={item.id} className="add-multi-component">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="removeImg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                />
                              </svg>
                            </button>
                            <Row>
                              <Col lg={12} xs={12}>
                                <div className="form-group">
                                  <h5>العنوان التفصيلي</h5>

                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="العنوان التفصيلي"
                                    {...register(
                                      `addresses.${index}.addressDetail`
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Col lg={3} xs={12} className="text-left">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          اضافة
                        </button>
                      </Col>
                    </div>
                  </Col> */}
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
