import {
  GET_GROUPS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  EDIT_GROUP,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  GET_SINGLE_GROUP,
  GET_SINGLE_GROUP_SUCCESS,
  GET_SINGLE_GROUP_FAILURE,
  GET_GROUP_RULES,
  GET_GROUP_RULES_SUCCESS,
  GET_GROUP_RULES_FAILURE,
} from "./actionTypes";

export const getGroups = (payload) => {
  return {
    type: GET_GROUPS,
    payload,
  };
};

export const getGroupsSuccess = (users) => {
  return {
    type: GET_GROUPS_SUCCESS,
    payload: users,
  };
};

export const getGroupsFailure = (error) => {
  return {
    type: GET_GROUPS_FAILURE,
    payload: error,
  };
};

export const getGroupRules = (payload) => {
  return {
    type: GET_GROUP_RULES,
    payload,
  };
};

export const getGroupRulesSuccess = (users) => {
  return {
    type: GET_GROUP_RULES_SUCCESS,
    payload: users,
  };
};

export const getGroupRulesFailure = (error) => {
  return {
    type: GET_GROUP_RULES_FAILURE,
    payload: error,
  };
};

export const getSingleGroup = (id) => {
  return {
    type: GET_SINGLE_GROUP,
    payload: id,
  };
};

export const getSingleGroupSuccess = (user) => {
  return {
    type: GET_SINGLE_GROUP_SUCCESS,
    payload: user,
  };
};

export const getSingleGroupFailure = (error) => {
  return {
    type: GET_SINGLE_GROUP_FAILURE,
    payload: error,
  };
};

export const addGroup = (payload) => {
  return {
    type: ADD_GROUP,
    payload,
  };
};

export const addGroupSuccess = (payload) => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload,
  };
};

export const addGroupFailure = (error) => {
  return {
    type: ADD_GROUP_FAILURE,
    payload: error,
  };
};

export const editGroup = (payload) => {
  return {
    type: EDIT_GROUP,
    payload,
  };
};

export const editGroupSuccess = (payload) => {
  return {
    type: EDIT_GROUP_SUCCESS,
    payload,
  };
};

export const editGroupFailure = (error) => {
  return {
    type: EDIT_GROUP_FAILURE,
    payload: error,
  };
};

export const deleteGroup = (id) => {
  return {
    type: DELETE_GROUP,
    payload: id,
  };
};

export const deleteGroupSuccess = (id) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: id,
  };
};

export const deleteGroupFailure = (error) => {
  return {
    type: DELETE_GROUP_FAILURE,
    payload: error,
  };
};
