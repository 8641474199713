import React from "react";
import sales from "../../../src/assets/images/sales.png";
import salesAngle from "../../../src/assets/images/2.svg";
import downAngle from "../../../src/assets/images/2.svg";

const Sales = ({ data }) => {
  return (
    <div className="card">
      <h5>المبيعات</h5>
      <div className="card-body">
        <div className="inner sales">
          <div className="i-icon">
            <img src={sales} alt="" />
          </div>
          <div className="i-text">
            <h4>{data?.monthCountOrders}</h4>
            <p>
              <img
                src={data?.count_Percentage > 0 ? salesAngle : downAngle}
                alt=""
              />
              <i className={`${data?.count_Percentage > 0 ? "up" : "down"}`}>
                {data?.count_Percentage?.toFixed(0)}%
              </i>{" "}
              أخر شهر
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
