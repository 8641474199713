import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addCustomer, getCustomersTags } from "store/actions";

import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Creatable from "react-select/creatable";
import { getId } from "helpers/functions";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const AddCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getCustomersTags());
  }, [dispatch]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  const { tags } = useSelector((state) => state.customers);

  const getOptionsByName = (arr) =>
    arr?.map((item) => ({
      label: item.name?.[locale] || item,
      value: getId(item),
    }));

  const onSubmit = (data) => {
    dispatch(addCustomer({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف عضو جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم العضو</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم العضو"
                          {...register("username", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.username?.type === "required" &&
                          "يرجي ادخال اسم العضو"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رقم الجوال</h5>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) =>
                            value?.length > 13 || value?.length < 9
                              ? "enterCorrectPhone"
                              : true,
                        }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneInput
                            country="sa"
                            inputClass="form-control form-outline"
                            placeholder="Enter phone number"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {/* <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رقم الجوال"
                          {...register("phone", { required: true })}
                        />
                      </div> */}
                      <p className="error-hint text-danger">
                        {errors?.phone?.type === "required" ? (
                          "يرجي ادخال رقم الجوال"
                        ) : (
                          <FormattedMessage
                            id={errors?.phone?.message || " "}
                          />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>البريد الالكتروني</h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder="البريد الالكتروني"
                          {...register("email", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" &&
                          "يرجي ادخال البريد الالكتروني"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>الرقم السري</h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder="الرقم السري"
                          {...register("password", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.password?.type === "required" &&
                          "يرجي ادخال الرقم السري"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="tags" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <>
                              <Creatable
                                className="basic-single"
                                classNamePrefix="select"
                                options={getOptionsByName(tags || [])}
                                placeholder={formatMessage({
                                  id: "selectTag",
                                })}
                                isMulti={true}
                                value={getOptionsByName(value || [])}
                                onChange={(value) => {
                                  onChange(value?.map((e) => e?.value));
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={12}>
                    <div className="form-group branches">
                      <h5>العناوين</h5>

                      {fields.map((item, index) => {
                        return (
                          <div key={item.id} className="add-multi-component">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="removeImg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                />
                              </svg>
                            </button>
                            <Row>
                              <Col lg={12} xs={12}>
                                <div className="form-group">
                                  <h5>العنوان التفصيلي</h5>

                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="العنوان التفصيلي"
                                    {...register(
                                      `addresses.${index}.addressDetail`
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Col lg={3} xs={12} className="text-left">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          إضافة عنوان
                        </button>
                      </Col>
                    </div>
                  </Col> */}
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
