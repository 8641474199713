import { getId } from "helpers/functions";
import server from "./server";

export const getCustomersApi = async (payload) => {
  const response = await server().get(`/users${payload || ""}`);
  return response.data;
};

export const getCustomersTagsApi = async (payload) => {
  const response = await server().get(`/users/getAllTags`);
  return response.data;
};

export const getSingleCustomerApi = async (id) => {
  const response = await server().get(`/users/${id}`);
  return response.data;
};

export const addCustomerApi = async (payload) => {
  const response = await server().post("/users", payload);
  return response.data;
};

export const editCustomerApi = async ({ id, data }) => {
  const response = await server().put(`/users/${id}`, data);
  return response.data;
};

export const deleteCustomerApi = async (id) => {
  const response = await server().delete(`/users/${id}`);
  return response.data;
};

export const blockCustomerApi = async (id) => {
  const response = await server().get(`/users/blockedUser/${id}`);
  return response.data;
};

export const addCustomerToGroupApi = async (payload) => {
  const response = await server().post(
    `/users/addToGroup/${getId(payload)}`,
    payload?.data
  );
  return response.data;
};

export const deleteCustomerFromGroupApi = async (payload) => {
  const response = await server().delete(
    `/users/removeFromGroup/${getId(payload)}`,
    { data: payload?.data }
  );
  return response.data;
};

export const getCountriesApi = async (payload) => {
  const response = await server().get(`/countries${payload || ""}`);
  return response.data;
};

export const getCitiesApi = async (payload) => {
  const response = await server().get(`/cities${payload || ""}`);
  return response.data;
};

export const getAreasApi = async (payload) => {
  const response = await server().get(`/areas${payload || ""}`);
  return response.data;
};
