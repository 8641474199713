import {
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
} from "./actionTypes";

export const getFeatures = () => {
  return {
    type: GET_FEATURES,
  };
};

export const getFeaturesSuccess = (features) => {
  return {
    type: GET_FEATURES_SUCCESS,
    payload: features,
  };
};

export const getFeaturesFailure = (error) => {
  return {
    type: GET_FEATURES_FAILURE,
    payload: error,
  };
};
