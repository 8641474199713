import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting, getLanguages, getSettings } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { getId } from "helpers/functions";
import { useIntl } from "react-intl";
import { ReactComponent as LangsIcon } from "assets/svgs/settings/7.svg";
import BreadCrumb from "components/shared/BreadCrumb";

const StoreLangs = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [isDefault, setIsDefault] = useState("");

  const { languages, loading: langsLoading } =
    useSelector((state) => state?.languages) || {};

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  const dispatch = useDispatch();

  const { register, handleSubmit, reset, watch } = useForm();

  useEffect(() => {
    if (!languages?.length > 0) dispatch(getLanguages());
    dispatch(getSettings("languageSettings"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    reset({
      languages:
        settings?.languages
          ?.map(({ language }) => getId(language))
          ?.filter((id) => id?.length > 0) || [],
    });
    setIsDefault(
      getId(settings?.languages?.find((c) => c?.isDefault)?.language) || ""
    );
  }, [reset, settings]);

  // useEffect(() => {
  //   if (!(watch("languages") || [])?.includes(isDefault)) setIsDefault("");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [watch("languages"), isDefault]);

  const renderLanguages = languages?.map((lang, index) => (
    <Col xs="12" lg="4" md="6" key={index}>
      <div className="lang card">
        <p>{lang?.name}</p>
        <label>
          <input
            type="radio"
            onChange={({ target }) =>
              target?.checked &&
              (watch("languages") || [])?.includes(getId(lang)) &&
              setIsDefault(target?.value)
            }
            checked={isDefault === getId(lang)}
            value={getId(lang)}
            title={formatMessage({ id: "isDefault" })}
          />
          <p className="active">{formatMessage({ id: "isDefault" })}</p>
          <p className="inactive">{formatMessage({ id: "setAsDefault" })}</p>
        </label>
        <label
          className="switcher"
          title={formatMessage({ id: "addLangToStore" })}
        >
          <input
            type="checkbox"
            className="switcher-input"
            defaultChecked={false}
            {...{
              ...register("languages"),
            }}
            value={getId(lang)}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </div>
    </Col>
  ));

  const onSubmit = (data) => {
    data.languages =
      data?.languages?.map((c) => ({
        language: c,
        isDefault: isDefault === c,
      })) || [];
    delete data.isDefault;
    dispatch(
      editStoreSlugSetting({
        data,
        slug: "languageSettings",
        navigate,
      })
    );
  };

  if (langsLoading || loading) return <Loader />;

  return (
    <div className="acc-form store-langs">
      {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
      <BreadCrumb
        pageName={formatMessage({
          id: "storeLangs",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <div className="card flex-row justify-content-start align-items-center gap-2 card-head">
        <LangsIcon />
        <h4 className="m-0">اختر لغات المتجر</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col xl={10} md={12}>
          <Row className="langs">{renderLanguages}</Row>
          <Row>
            <Col lg={4} xs={12}>
              <div className="form-group">
                <button type="submit" className="btn btn-blue">
                  حفظ التعديلات
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </div>
  );
};

export default StoreLangs;
