/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "store/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";

import { ReactComponent as BankIcon } from "assets/svgs/bank.svg";
import { ReactComponent as Dollar } from "assets/svgs/product/dollar.svg";
import { ReactComponent as ProductSettingsIcon } from "assets/svgs/product/settings.svg";
import { ReactComponent as CardPosIcon } from "assets/svgs/settings/card-pos.svg";
import { ReactComponent as WalletIcon } from "assets/svgs/settings/wallet.svg";
import { ReactComponent as InfoIcon } from "assets/builder/info-circle.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow.svg";
import { ReactComponent as RoutingIcon } from "assets/svgs/settings/routing-2.svg";
import { ReactComponent as ArrangeIcon } from "assets/svgs/settings/arrange-square.svg";
import { ReactComponent as ReceiptIcon } from "assets/svgs/settings/receipt.svg";

import BankModal from "components/settings/storeWallet/BankModal";
import BreadCrumb from "components/shared/BreadCrumb";
import BalanceModal from "components/settings/storeWallet/BalanceModal";
import WithdrawModal from "components/settings/storeWallet/WithdrawModal";
import DefaultPaymentModal from "components/settings/storeWallet/DefaultPaymentModal";
import CompanyTitleModal from "components/settings/storeWallet/CompanyTitleModal";
import PurchasesReceipts from "components/settings/storeWallet/PurchasesReceipts";
import RecordsModal from "components/settings/storeWallet/RecordsModal";

const StorePayments = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const { settings, loading, dataSaveLoading, bankAccounts } =
    useSelector((state) => state?.settings) || {};

  const [selectedBank, setSelectedBank] = useState();
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [widthdrawModal, setWithdrawModal] = useState(false);

  useEffect(() => {
    dispatch(getSettings("walletSettings"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  return (
    <div className="acc-form storePayment store-settings-page">
      {(loading || dataSaveLoading) && <StickyLoader fill="#FC6B14" />}
      {/* <BreadCrumb pageName={formatMessage({ id: "storePayments" })} /> */}
      <BreadCrumb
        pageName={formatMessage({
          id: "storePayments",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <Row>
        <Col xs="6" xl="4">
          <div className="card settingItem balance">
            <div className="card-head m-0 productDataModal">
              <div className="form-group mb-0">
                <div className="form-head ">
                  <i>
                    <Dollar />
                  </i>
                  <div>
                    <h5 title={formatMessage({ id: "storeBalanceDesc" })}>
                      رصيد المتجر
                      <InfoIcon />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <Dollar fill="#FC6B14" width={47} height={47} className="mx-auto" />

            <h4 className="content">
              {settings?.storeBalance || (
                <span>
                  <FormattedMessage id="noBalance" />
                </span>
              )}
            </h4>
            <div className="d-flex gap-2 justify-content-around">
              <button
                type="button"
                className="btn btn-blue"
                onClick={() => updateParams("modal", "balance-withdraw")}
              >
                <FormattedMessage id="addBalance" />
              </button>
              <button
                disabled={!settings?.storeBalance}
                title={
                  settings?.storeBalance
                    ? formatMessage({ id: "withdrawBalance" })
                    : formatMessage({ id: "noBalance" })
                }
                type="button"
                className="btn btn-blue"
                onClick={() => setWithdrawModal(true)}
              >
                <FormattedMessage id="withdrawBalance" />
              </button>
            </div>
            <Link
              className="records-link"
              to={`${window.location.pathname}?modal=records`}
            >
              <FormattedMessage id="transactionsHistory" />
            </Link>
          </div>
        </Col>

        <Col xs="6" xl="4">
          <div className="card settingItem balance">
            <div className="card-head m-0 productDataModal">
              <div className="form-group mb-0">
                <div className="form-head  ">
                  <i>
                    <CardPosIcon />
                  </i>
                  <div>
                    <h5 title={formatMessage({ id: "storeRevenueDesc" })}>
                      رصيد الدفوعات الالكترونية
                      <InfoIcon />
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <Dollar fill="#FC6B14" width={47} height={47} className="mx-auto" />

            <h4 className="content">
              {settings?.electronicPaymentBalance || (
                <span>
                  <FormattedMessage id="noBalance" />
                </span>
              )}
            </h4>
            <Link
              className="records-link"
              to={`${window.location.pathname}/?modal=records`}
            >
              <FormattedMessage id="transactionsHistory" />
            </Link>
          </div>
        </Col>

        <Col xs="6" xl="4">
          <div className="card settingItem balance">
            <div className="card-head m-0 productDataModal">
              <div className="form-group mb-0">
                <div className="form-head  ">
                  <i>
                    <WalletIcon />
                  </i>
                  <div>
                    <h5 title={formatMessage({ id: "storeRevenueDesc" })}>
                      رصيد مدفوعات الدفع عند الاستلام <InfoIcon />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <Dollar fill="#FC6B14" width={47} height={47} className="mx-auto" />

            <h4 className="content">
              {settings?.electronicPaymentBalance || (
                <span>
                  <FormattedMessage id="noBalance" />
                </span>
              )}
            </h4>
            <Link
              className="records-link"
              to={`${window.location.pathname}/?modal=records`}
            >
              <FormattedMessage id="transactionsHistory" />
            </Link>
          </div>
        </Col>
      </Row>

      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="2" className="delivery-companies">
          <div className="acc-form card shipping-methods">
            {/* -------------------------------------- */}
            <Accordion.Header>
              <div className="card-head m-0 productDataModal">
                <div className="form-group mb-0">
                  <div className="form-head mb-0">
                    <i>
                      <ProductSettingsIcon width="25" height="25" />
                    </i>
                    <div>
                      <h4>
                        <FormattedMessage id="receiptSettings" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="items settings-cards">
                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "default-payment")}
                >
                  <div className="icon">
                    <ArrangeIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="defaultPaymentMethod" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="walletStoreBalance" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "cash-delivery")}
                >
                  <div className="icon">
                    <CardPosIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="eCards" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="mada, creditCards" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  // onClick={() => updateParams("modal", "bank-account")}
                  onClick={() => navigate("/settings/storePaymentMethods")}
                >
                  <div className="icon">
                    <BankIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="bankAccount" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="addAccountToRecievePayments" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "company-address")}
                >
                  <div className="icon">
                    <RoutingIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="companyTitle" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="calculateShippingFeeDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>

        <Accordion.Item className="delivery-companies">
          <Row>
            <Col xs="9">
              <Accordion.Header className="card">
                <div className="card-head m-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      {/* <i> */}
                      <ReceiptIcon />
                      {/* </i> */}
                      <div>
                        <h4>
                          <FormattedMessage id="purchasesReceipts" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
            </Col>
            <Col xs="3">
              <button
                className="btn btn-blue btn-sm w-100 add-company"
                // onClick={() => updateParams("modal", "free-shipping")}
              >
                <FormattedMessage id="pastReceipts" />
              </button>
            </Col>
          </Row>

          <Accordion.Body className="px-0">
            <div className="acc-form card shipping-methods">
              <PurchasesReceipts />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <DefaultPaymentModal show={params?.get("modal") === "default-payment"} />

      <BankModal
        selectedBank={selectedBank}
        // modalShow={bankAccountModal}
        modalShow={params?.get("modal") === "bank-account"}
        setModalShow={setBankAccountModal}
        settings={settings}
      />

      <CompanyTitleModal show={params?.get("modal") === "company-address"} />

      <BalanceModal
        defaultBank={
          bankAccounts?.find((e) => e?.isDefault) || bankAccounts?.[0]
        }
        modalShow={params.get("modal") === "balance-withdraw"}
        settings={settings}
      />

      <WithdrawModal
        defaultBank={
          bankAccounts?.find((e) => e?.isDefault) || bankAccounts?.[0]
        }
        modalShow={widthdrawModal}
        setModalShow={setWithdrawModal}
        settings={settings}
      />

      <RecordsModal
        modalShow={params.get("modal") === "records"}
        data={[
          {
            id: 1,
            date: "2022-12-12",
            amount: 5000,
            type: "withdraw",
            status: "pending",
          },
          {
            id: 1,
            date: "2022-12-12",
            amount: 5000,
            type: "withdraw",
            status: "pending",
          },
          {
            id: 1,
            date: "2022-12-12",
            amount: 5000,
            type: "withdraw",
            status: "pending",
          },
        ]}
      />
    </div>
  );
};

export default StorePayments;
