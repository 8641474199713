export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAILURE = "GET_STORES_FAILURE";

export const GET_ACTIVE_STORE = "GET_ACTIVE_STORE";
export const GET_ACTIVE_STORE_SUCCESS = "GET_ACTIVE_STORE_SUCCESS";
export const GET_ACTIVE_STORE_FAILURE = "GET_ACTIVE_STORE_FAILURE";

export const GET_ACTIVE_STORE_THEMES = "GET_ACTIVE_STORE_THEMES";
export const GET_ACTIVE_STORE_THEMES_FAILURE =
  "GET_ACTIVE_STORE_THEMES_FAILURE";
export const GET_ACTIVE_STORE_THEMES_SUCCESS =
  "GET_ACTIVE_STORE_THEMES_SUCCESS";

export const SET_ACTIVE_THEME = "SET_ACTIVE_THEME";
export const SET_ACTIVE_THEME_SUCCESS = "SET_ACTIVE_THEME_SUCCESS";
export const SET_ACTIVE_THEME_FAILURE = "SET_ACTIVE_THEME_FAILURE";

export const ADD_THEME_TO_STORE = "ADD_THEME_TO_STORE";
export const ADD_THEME_TO_STORE_SUCCESS = "ADD_THEME_TO_STORE_SUCCESS";
export const ADD_THEME_TO_STORE_FAILURE = "ADD_THEME_TO_STORE_FAILURE";

export const ADD_STORE = "ADD_STORE";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_FAILURE = "ADD_STORE_FAILURE";

export const EDIT_STORE = "EDIT_STORE";
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS";
export const EDIT_STORE_FAILURE = "EDIT_STORE_FAILURE";

export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAILURE = "DELETE_STORE_FAILURE";

export const CHANGE_STORE_STATUS = "CHANGE_STORE_STATUS";
export const CHANGE_STORE_STATUS_SUCCESS = "CHANGE_STORE_STATUS_SUCCESS";
export const CHANGE_STORE_STATUS_FAILURE = "CHANGE_STORE_STATUS_FAILURE";

export const EDIT_STORE_THEME = "EDIT_STORE_THEME";
export const EDIT_STORE_THEME_SUCCESS = "EDIT_STORE_THEME_SUCCESS";
export const EDIT_STORE_THEME_FAILURE = "EDIT_STORE_THEME_FAILURE";
