/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "./lang/ar.json";

const Wrapper = (props) => {
  const [locale, setLocale] = useState("ar");
  const [messages, setMessages] = useState(Arabic);

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      const editorAlert = document.querySelectorAll(
        ".tox-notifications-container"
      );
      if (editorAlert.length)
        editorAlert.forEach((e) => (e.style.display = "none"));
    });
  }, []);

  return (
    // selectLanguage
    <Context.Provider value={{ locale }}>
      <IntlProvider messages={messages} locale={locale} onError={() => {}}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
