import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/Setting.svg";
import server from "api/server";
import SeoForm from "components/shared/SeoForm";
import { getPublicImage, handleImage } from "helpers/functions";
import { useForm } from "react-hook-form";
import ImageUpload from "components/shared/ImageUpload";

const SettingModal = ({
  brand,
  handleClose,
  modalType,
  handleSubmitMainData,
  // register,
  // errors,
  bannerImage,
  setBannerImage,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  // Setting Modals Data On Start
  useEffect(() => {
    reset({
      image: brand?.image || "",
      name: brand?.name || "",
      seo: {
        title: brand?.seo?.title || "",
        description: brand?.seo?.description || "",
      },
      isShown: brand?.isShown || brand?.isNew,
      showProducts: brand?.showProducts || brand?.isNew,
    });
  }, [reset, brand]);

  // const imageChange = async (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     const { data } = await server().post("/upload", formData);
  //     setBannerImage({
  //       preview: e.target.files[0],
  //       path: getPublicImage(data.data.files[0]),
  //     });
  //   }
  // };

  // const removeBannerImage = () => {
  //   setBannerImage();
  // };

  // const handleSubmit = () => {
  // if (submitBtn.current) submitBtn.current.onsubmit();
  // };

  return (
    <div className="productModal brand">
      <Modal
        dialogClassName="productDataModal brandSettingModal"
        show={modalType === "settingModal"}
        onHide={handleClose}
      >
        <form onSubmit={handleSubmit(handleSubmitMainData)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <ModalHeaderIcon />
              اعدادات العلامة التجارية
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <h5>صوره كبيره (banner)</h5>
              <div className="form-body">
                <div className="multiUploadWrapper">
                  {/* <div className="image-uploader image-contain">
                    <div className="img-pat">
                      <img src={imageUpload} width={46} height={46} alt="" />
                    </div>
                    <label>
                      {bannerImage && (
                        <div>
                          <img
                            src={
                              bannerImage?.path && bannerImage.preview
                                ? URL.createObjectURL(bannerImage.preview)
                                : handleImage(bannerImage?.path)
                            }
                            width={160}
                            height={160}
                            alt=""
                          />
                          <button
                            className="removeImg"
                            onClick={removeBannerImage}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="currentColor"
                              className="bi bi-x-lg"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        onChange={imageChange}
                      />
                    </label>
                  </div> */}
                  <ImageUpload
                    className="m-1"
                    selectedImage={bannerImage}
                    setSelectedImage={setBannerImage}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <h5>إظهار / إخفاء منتجات العلامة التجارية</h5>
              <div className="d-inline-block">
                <label className="switcher">
                  <input
                    type="checkbox"
                    className="switcher-input"
                    {...register("showProducts")}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </label>
              </div>
            </div>
            <div className="form-group">
              <h5>إظهار / إخفاء العلامة التجارية</h5>
              <div className="d-inline-block">
                <label className="switcher">
                  <input
                    type="checkbox"
                    className="switcher-input"
                    {...register("isShown")}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </label>
              </div>
            </div>
            <div className="form-group">
              <SeoForm errors={errors} register={register} required={false} />
            </div>
            <div className="form-group"></div>
          </Modal.Body>
          <Modal.Footer>
            <input type="submit" className="btn btn-blue" value="حفظ" />
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleClose}
            >
              الغاء
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default SettingModal;
