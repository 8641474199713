import ImagesModal from "components/products/ImagesModal";
import { useEffect, useState } from "react";
import { ReactComponent as UploadImgsIcon } from "assets/svgs/product/gallery-export.svg";

export default function MultiImageBtnComponent({
  selectedImage,
  setSelectedImage,
  transparentBtn = false,
}) {
  const [imgs, setImgs] = useState([]);
  const [modalType, setModalType] = useState("");
  const handleClose = () => setModalType("");

  useEffect(() => {
    setImgs(() => {
      if (
        !selectedImage.find((img) => img?.isMain) &&
        selectedImage?.length > 0
      )
        selectedImage[0].isMain = true;
      return selectedImage;
    });
  }, [selectedImage]);

  return (
    <>
      <button
        className={`btn btn-blue images-btn ${imgs?.length > 0 ? "pl-3" : ""} ${
          transparentBtn ? "opacity-0" : ""
        }`}
        type="button"
        onClick={() => {
          setModalType("imagesModal");
        }}
      >
        {imgs?.length > 0 ? (
          imgs?.length
        ) : (
          <UploadImgsIcon fill="#fff" width="14" height="14" />
        )}
      </button>
      <ImagesModal
        handleClose={handleClose}
        modalType={modalType}
        images={imgs || []}
        pathType="fullpath"
        setImages={setSelectedImage}
      />
    </>
  );
}
