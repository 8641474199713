import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getCategoriesApi } from "api/categories";

// Login Redux States
import { GET_CATEGORIES } from "./actionTypes";
import { getCategoriesFailure, getCategoriesSuccess } from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getCategories() {
  try {
    const { data } = yield call(getCategoriesApi);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getCategoriesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, getCategories);
}

function* categoriesSaga() {
  yield all([fork(watchGetCategories)]);
}

export default categoriesSaga;
