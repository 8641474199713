import { useState } from "react";
import server from "api/server";

import { ReactComponent as Remove } from "assets/svgs/x.svg";
import { ReactComponent as Upload } from "assets/svgs/product/uploadFile.svg";
import { ReactComponent as File } from "assets/svgs/product/file.svg";
import { ReactComponent as Download } from "assets/svgs/product/download.svg";
import { ReactComponent as EyeIcon } from "assets/svgs/product/eye.svg";
import { Col, Row } from "react-bootstrap";
import StickyLoader from "components/shared/StickyLoader";
import { handleImage, isImage } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";

const FilesUpload = ({ files, setFiles, pathType = "path" }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileBlob, setFileBlob] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const handleFileBlob = (e) => {
    if (fileUrl?.length > 0) setFileUrl("");
    setFileBlob(e?.target?.files);
    setFileName(e?.target?.files?.[0]?.name);
  };
  const handleFileUrl = (e) => {
    if (fileBlob?.length > 0) setFileBlob([]);
    setFileUrl(e.target.value);
    setFileName(e.target.value?.split("/")?.pop());
  };

  const onSubmit = async (e) => {
    setLoading(true);
    if (
      !(fileName?.length > 0 && (fileBlob?.length > 0 || fileUrl?.length > 0))
    ) {
      toast(formatMessage({ id: "fileNameAndUrlRequired" }));
      return setLoading(false);
    }
    if (fileBlob?.length > 0) {
      if (fileBlob && fileBlob.length > 0) {
        for (let i = 0; i < fileBlob.length; i++) {
          const formData = new FormData();
          formData.append("file", fileBlob[i]);

          const { data } = await server().post("/upload", formData, {
            headers: {
              uploadsType: "products",
            },
          });
          setFiles((oldFiles) => [
            ...oldFiles,
            {
              file: fileName,
              url: data?.data?.files?.[0]?.[pathType],
            },
          ]);
        }
        setLoading(false);
      } else setLoading(false);
    } else {
      setFiles((oldFiles) => [
        ...oldFiles,
        {
          file: fileName,
          url: fileUrl,
        },
      ]);
      setLoading(false);
    }
    setFileName("");
    setFileBlob([]);
    setFileUrl("");
  };
  const removeImages = (url) => {
    setFiles(files.filter((img) => img.url !== url));
  };
  return (
    <div className="form-group required product-attachmets">
      <div>
        <div className="form-uploader">
          <FormattedMessage id="addAttachments" />
          <label>
            <FormattedMessage id="fileName" />
            <input
              type="text"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </label>
          <div className="form">
            <div className="file-url">
              <label>
                <FormattedMessage id="uploadFile" />
                <input
                  type="file"
                  multiple
                  accept="*/*"
                  onChange={handleFileBlob}
                  // onChange={onImageChange}
                  id="filo"
                  name="images"
                />
                {fileBlob?.[0] && isImage(fileBlob?.[0]?.name) === true ? (
                  <img src={URL.createObjectURL(fileBlob?.[0])} alt="" />
                ) : (
                  <Upload
                    width={32}
                    height={32}
                    stroke={fileBlob?.length > 0 ? "#FC6B14" : ""}
                  />
                )}
              </label>
              <div>
                <FormattedMessage id="or" />
              </div>

              <label>
                <FormattedMessage id="enterUrl" />

                <input type="text" value={fileUrl} onChange={handleFileUrl} />
              </label>
            </div>
            <button type="button" className="btn" onClick={onSubmit}>
              <FormattedMessage id="add" />
            </button>
          </div>
        </div>
        {files.length > 0 ? (
          <div className="thumbs">
            <div className="d-flex gap-3 flex-wrap">
              {files?.map(({ file: fileName, url }, index) => {
                const extensionIsImage = isImage(url);
                return (
                  <div key={index}>
                    <div
                      className={`thumb ${
                        extensionIsImage === true ? "" : "file"
                      }`}
                    >
                      <button
                        type="button"
                        className="btn-remove"
                        onClick={() => removeImages(url)}
                      >
                        <Remove fill="#fff" />
                      </button>
                      <div className="top-actions">
                        <div className="preview">
                          <a
                            href={handleImage(url)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <EyeIcon width="14" height="14" />
                          </a>
                        </div>
                      </div>
                      {extensionIsImage === true ? (
                        <img
                          src={handleImage(url)}
                          width={100}
                          height={100}
                          alt=""
                        />
                      ) : (
                        <>
                          <File className="file-icon" />
                          <div className="extension">{extensionIsImage}</div>
                          <p>{fileName}</p>
                        </>
                      )}
                      {/* <div>
                        <label>
                          <input
                            type="radio"
                            name="mainImage"
                            onChange={(e) => {
                              setFiles(() =>
                                files?.map((image, i) => {
                                  return i === index
                                    ? { ...image, isMain: true }
                                    : { ...image, isMain: false };
                                })
                              );
                            }}
                            defaultChecked={imageSrc.isMain}
                          />
                          <span>الصورة الرئيسية</span>
                        </label>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
            {loading && <StickyLoader fill="#FC6B14" />}
          </div>
        ) : (
          <div className="emptyPhotos">
            <Upload fill="#ddd" />
            لا مرفقات لهذا المنتج !
          </div>
        )}
      </div>
    </div>
  );
};

export default FilesUpload;
