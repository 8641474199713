// import { useState } from "react";
import DndList from "./DndList";
import DropZone from "./DropZone";
// import { Modal } from "react-bootstrap";

// import { useIntl } from "react-intl";
// import EditCategory from "views/categories/EditCategory";
// import AddCategory from "views/categories/AddCategory";
import { getId } from "helpers/functions";

export default function DndWrapper({ categories, setModalProps }) {
  // const { locale } = useIntl();

  // const [modalProps, setModalProps] = useState({ show: false });

  return (
    <>
      <DropZone>
        <DndList
          categories={categories}
          parentSlugPath={"wrapper"}
          setModalProps={setModalProps}
        />
      </DropZone>

      {/* <Modal
        dialogClassName="productDataModal"
        show={modalProps?.show}
        onHide={() => setModalProps({})}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!modalProps?.add
              ? ` تعديل التصنيف : ${modalProps?.category?.name?.[locale]}`
              : "إضافة تصنيف"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!modalProps?.add ? (
            <EditCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          ) : (
            <AddCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          )}
        </Modal.Body>
      </Modal> */}
    </>
  );
}

//-----------------========================---------------------------
export const handleDrop = (item, data) => {
  if (data?.subcategories) data.subcategories = [...data?.subcategories, item];
  return data;
};

//-----------------========================---------------------------
export const isDirectParentOf = (source, target) => {
  return (
    target.parentSlugPath === joinSlugs([source?.parentSlugPath, source.id])
  );
};

//-----------------========================---------------------------
export const joinSlugs = (arr) => arr.join("-");

//-----------------========================---------------------------
export const getFlatCats = (parentCat) => {
  if (parentCat?.length > 0 && !parentCat?.slug)
    parentCat = { subcategories: [...parentCat] };
  // if (!e?.subcategories?.length > 0) return [];
  return (
    parentCat?.subcategories?.flatMap((e, index) => [
      { id: e?.id, order: index + 1, parentCategory: parentCat?.id || null },
      ...(getFlatCats(e)?.flatMap((e) => e) || []),
    ]) || []
  );
  // .filter((e) => e?.slug);
};

//-----------------========================---------------------------
export const getNestedCats = (parent, categories) => {
  let subCats = categories.filter(
      (c) =>
        (getId(c?.parentCategory) || c?.parentCategory || null) ===
        getId(parent)
    ),
    restOfCats = categories.filter(
      (c) => (getId(c?.parentCategory) || c?.parentCategory) !== getId(parent)
    );
  return (
    subCats?.map((c) => ({
      ...c,
      subcategories: getNestedCats(c, restOfCats),
    })) || []
  );
};
