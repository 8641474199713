import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { handleImage } from "helpers/functions";
import { useIntl } from "react-intl";
import MultiImageBtnComponent from "./MultiImageBtnComponent";
import ColorsList from "./ColorsList";
import { useState } from "react";
import { useEffect } from "react";

export default function VariationChild({
  variation,
  variationIndex,
  item,
  index,
  remove,
  forceRerender,
  setValue,
  formName,
  update,
}) {
  const [images, setImages] = useState(item?.extraData?.images || []);
  const { locale } = useIntl();

  useEffect(() => {
    let newItem = item;
    newItem.extraData = { ...newItem.extraData, images: images };
    setValue(`${formName}.${index}`, newItem);
    update(index, newItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(images)]);

  let displayedText =
    item?.value?.[locale] || ////+++++++ multi lang feature
    item?.value ||
    item?.extraData?.text?.[locale] ||
    item?.extraData?.text ||
    item?.value;
  return (
    <div className="variation-option" key={index}>
      <button
        type="button"
        onClick={() => {
          remove(index);
          forceRerender();
        }}
      >
        <CloseIcon width="14" height="14" />
      </button>
      <input
        type="text"
        className="variation-option-input"
        value={displayedText}
        title={displayedText}
        onChange={({ target: { value } }) => {
          if (value?.length <= 0) return;
          // );
          let newItem = item;
          newItem.value = value;
          setValue(`${formName}.${index}`, newItem);
          update(index, newItem);
        }}
      />

      {variation?.value === "color" && (
        <ColorsList
          selectedOption={{ value: item?.extraData?.text }}
          onChange={(option) => {
            // );
            let newItem = item;
            newItem.label = option.label;
            newItem.extraData = {
              ...newItem.extraData,
              text: option.value,
            };

            setValue(`${formName}.${index}`, newItem);
            update(index, newItem);
          }}
        />
      )}
      {variationIndex === 0 && (
        <div
          className="color-snippet cursor-pointer overflow-hidden"
          style={{
            background: `url(${handleImage(
              item?.extraData?.images?.find((e) => e?.isMain)?.image || " "
            )})`,
          }}
        >
          <MultiImageBtnComponent
            selectedImage={images}
            setSelectedImage={setImages}
            transparentBtn={true}
          />
        </div>
      )}
    </div>
  );
}
