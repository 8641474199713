import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllPages } from "store/actions";
import ControlArea from "components/Layout/ControlArea";
import Icon from "assets/svgs/blog.svg";
import Table from "./Table";

const Pages = () => {
  const { pages, loading, error } = useSelector((state) => state.pages);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  // error
  useEffect(() => {
    if (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }));
    }
  }, [error, formatMessage, locale]);
  useEffect(() => {
    dispatch(getAllPages());
  }, [dispatch]);

  return (
    <>
      <div className="pages-wrap">
        {
          <ControlArea
            btnTxt="اضافة صفحة"
            cardTxt="الصفحات التعريفية"
            icon={Icon}
            url="/pages/addPage"
          />
        }

        <Table data={pages} loading={loading} />
      </div>
    </>
  );
};

export default Pages;
