import {
  GET_DASHBOARD_HOME,
  GET_DASHBOARD_HOME_FAILURE,
  GET_DASHBOARD_HOME_SUCCESS,
} from "./actionTypes";

export const getDashboardHome = () => {
  return {
    type: GET_DASHBOARD_HOME,
  };
};

export const getDashboardHomeSuccess = (dashboard) => {
  return {
    type: GET_DASHBOARD_HOME_SUCCESS,
    payload: dashboard,
  };
};

export const getDashboardHomeFailure = (error) => {
  return {
    type: GET_DASHBOARD_HOME_FAILURE,
    payload: error,
  };
};
