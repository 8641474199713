import {
  GET_BANKS,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILURE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
} from "./actionTypes";

//get banks
export const getBanks = (payload) => {
  return {
    type: GET_BANKS,
    payload,
  };
};

export const getBanksSuccess = (id) => {
  return {
    type: GET_BANKS_SUCCESS,
    payload: id,
  };
};

export const getBanksFailure = (error) => {
  return {
    type: GET_BANKS_FAILURE,
    payload: error,
  };
};

//get payment methods
export const getPaymentMethods = (payload) => {
  return {
    type: GET_PAYMENT_METHODS,
    payload,
  };
};

export const getPaymentMethodsSuccess = (id) => {
  return {
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: id,
  };
};

export const getPaymentMethodsFailure = (error) => {
  return {
    type: GET_PAYMENT_METHODS_FAILURE,
    payload: error,
  };
};

