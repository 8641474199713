import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_TAGS,
  GET_CUSTOMERS_TAGS_FAILURE,
  GET_CUSTOMERS_TAGS_SUCCESS,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  BLOCK_CUSTOMER,
  BLOCK_CUSTOMER_SUCCESS,
  BLOCK_CUSTOMER_FAILURE,
  ADD_CUSTOMER_TO_GROUP,
  ADD_CUSTOMER_TO_GROUP_SUCCESS,
  ADD_CUSTOMER_TO_GROUP_FAILURE,
  DELETE_CUSTOMER_FROM_GROUP,
  DELETE_CUSTOMER_FROM_GROUP_SUCCESS,
  DELETE_CUSTOMER_FROM_GROUP_FAILURE,
  GET_SINGLE_CUSTOMER,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_FAILURE,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_AREAS,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
} from "./actionTypes";

export const getCustomers = (payload) => {
  return {
    type: GET_CUSTOMERS,
    payload,
  };
};

export const getCustomersSuccess = (users) => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: users,
  };
};

export const getCustomersFailure = (error) => {
  return {
    type: GET_CUSTOMERS_FAILURE,
    payload: error,
  };
};

export const getCustomersTags = (payload) => {
  return {
    type: GET_CUSTOMERS_TAGS,
    payload,
  };
};

export const getCustomersTagsSuccess = (users) => {
  return {
    type: GET_CUSTOMERS_TAGS_SUCCESS,
    payload: users,
  };
};

export const getCustomersTagsFailure = (error) => {
  return {
    type: GET_CUSTOMERS_TAGS_FAILURE,
    payload: error,
  };
};

export const getSingleCustomer = (id) => {
  return {
    type: GET_SINGLE_CUSTOMER,
    payload: id,
  };
};

export const getSingleCustomerSuccess = (user) => {
  return {
    type: GET_SINGLE_CUSTOMER_SUCCESS,
    payload: user,
  };
};

export const getSingleCustomerFailure = (error) => {
  return {
    type: GET_SINGLE_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const addCustomer = (payload) => {
  return {
    type: ADD_CUSTOMER,
    payload,
  };
};

export const addCustomerSuccess = (payload) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload,
  };
};

export const addCustomerFailure = (error) => {
  return {
    type: ADD_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const editCustomer = (payload) => {
  return {
    type: EDIT_CUSTOMER,
    payload,
  };
};

export const editCustomerSuccess = (payload) => {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    payload,
  };
};

export const editCustomerFailure = (error) => {
  return {
    type: EDIT_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const deleteCustomer = (id) => {
  return {
    type: DELETE_CUSTOMER,
    payload: id,
  };
};

export const deleteCustomerSuccess = (id) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: id,
  };
};

export const deleteCustomerFailure = (error) => {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const blockCustomer = (payload) => {
  return {
    type: BLOCK_CUSTOMER,
    payload,
  };
};

export const blockCustomerSuccess = (payload) => {
  return {
    type: BLOCK_CUSTOMER_SUCCESS,
    payload,
  };
};

export const blockCustomerFailure = (error) => {
  return {
    type: BLOCK_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const addCustomerToGroup = (payload) => {
  return {
    type: ADD_CUSTOMER_TO_GROUP,
    payload,
  };
};

export const addCustomerToGroupSuccess = (payload) => {
  return {
    type: ADD_CUSTOMER_TO_GROUP_SUCCESS,
    payload,
  };
};

export const addCustomerToGroupFailure = (error) => {
  return {
    type: ADD_CUSTOMER_TO_GROUP_FAILURE,
    payload: error,
  };
};

export const deleteCustomerFromGroup = (payload) => {
  return {
    type: DELETE_CUSTOMER_FROM_GROUP,
    payload,
  };
};

export const deleteCustomerFromGroupSuccess = (payload) => {
  return {
    type: DELETE_CUSTOMER_FROM_GROUP_SUCCESS,
    payload,
  };
};

export const deleteCustomerFromGroupFailure = (error) => {
  return {
    type: DELETE_CUSTOMER_FROM_GROUP_FAILURE,
    payload: error,
  };
};

export const getCountries = (payload) => {
  return {
    type: GET_COUNTRIES,
    payload,
  };
};

export const getCountriesSuccess = (payload) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload,
  };
};

export const getCountriesFailure = (error) => {
  return {
    type: GET_COUNTRIES_FAILURE,
    payload: error,
  };
};

export const getCities = (payload) => {
  return {
    type: GET_CITIES,
    payload,
  };
};

export const getCitiesSuccess = (payload) => {
  return {
    type: GET_CITIES_SUCCESS,
    payload,
  };
};

export const getCitiesFailure = (error) => {
  return {
    type: GET_CITIES_FAILURE,
    payload: error,
  };
};

export const getAreas = (payload) => {
  return {
    type: GET_AREAS,
    payload,
  };
};

export const getAreasSuccess = (payload) => {
  return {
    type: GET_AREAS_SUCCESS,
    payload,
  };
};

export const getAreasFailure = (error) => {
  return {
    type: GET_AREAS_FAILURE,
    payload: error,
  };
};
