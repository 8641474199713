import { useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { forwardRef } from "react";
import Filter from "components/shared/Filter";
import { useCallback } from "react";
import VariationInputModal from "./TableInputsModal";
import { ReactComponent as EditIcon } from "assets/svgs/edit-2.svg";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import TableInputsComponent from "./TableInputsComponent";
import { useSelector } from "react-redux";

export default function ProductVariationsTable({
  variations,
  register,
  control,
  setValue,
  errors,
  getValues,
  forceRerender,
}) {
  const { locale } = useIntl();
  const { units } = useSelector((state) => state.units) || [];

  const [modalType, setModalType] = useState("");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const twoDMatrix =
    variations?.length > 0
      ? variations
          ?.map(
            (variation) =>
              variation?.options?.length &&
              variation?.options?.flatMap((varVal) => {
                return [
                  varVal?.value?.[locale] ||
                    varVal?.value ||
                    varVal?.extraData?.text?.[locale] ||
                    varVal?.extraData?.text ||
                    varVal?.value ||
                    "",
                ];
              })
          )
          ?.filter((e) => e?.length > 0) || []
      : [];

  const twoDMatrixCalced = useMemo(
    () =>
      combos(twoDMatrix)?.map((e, index) => {
        let name = e?.join("-");
        setValue(`variationsOptionsValues.${index}.name`, name);
        return {
          name,
          index,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twoDMatrix]
  );

  useEffect(() => {
    if (
      getValues("variationsOptionsValues")?.length !== twoDMatrixCalced?.length
    ) {
      let sliced = getValues("variationsOptionsValues")?.slice(
        0,
        twoDMatrixCalced?.length
      );
      setValue("variationsOptionsValues", sliced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoDMatrixCalced]);

  const handleRowSelected = useCallback(
    (state) => {
      if (state.selectedRows?.length !== selectedRows?.length)
        setSelectedRows(state.selectedRows);
    },
    [selectedRows]
  );

  const contextActions = useMemo(() => {
    const handleEdit = () => {
      setModalType("variationTableInputs");
    };

    return (
      <div className="row" style={{ width: "20vw" }}>
        <div className="col">
          <Button key="delete" onClick={handleEdit} color="info">
            <EditIcon />
            تعديل
          </Button>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoDMatrixCalced, selectedRows]);

  const handleClose = () => {
    setModalType("");
  };

  //--table columns component
  const columns = columnsNames?.map((name, keyIndex) => ({
    name: (
      <div title={columnsHeadNames?.[name] || ""}>
        {columnsHeadNames?.[name] || ""}
      </div>
    ),
    selector: (row, index) => {
      let text = name === "name" ? row?.name : "";
      return (
        <div key={index} title={text}>
          {text}
          <TableInputsComponent
            name={name}
            varIndex={row?.index}
            text={text}
            key={keyIndex}
            errors={errors}
            register={register}
            hasVariations={getValues("hasVariations")}
            setValue={setValue}
            control={control}
            units={units}
            locale={locale}
          />
        </div>
      );
    },
    style: {
      textAlign: "start",
      textTransform: "capitalize",
      padding: "5px !important",
    },
    ...columnWidthCheck(name),
    sortable: name === "name",
  }));

  const filteredItems = twoDMatrixCalced?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  //--table search input component---------------
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="table-wrapper">
      <div
        className={`table-hint ${
          twoDMatrixCalced?.length > 0 && selectedRows?.length === 0
            ? ""
            : "opacity-0"
        }`}
      >
        *يُمكن تعديل اكثر من صف من خلال اختيارهم و الضغظ على زر التعديل الذي
        سيظهر.
      </div>

      <DataTable
        selectableRows
        subHeader
        title=" "
        columns={columns}
        data={filteredItems}
        subHeaderComponent={subHeaderComponent}
        selectableRowsComponent={BootyCheckbox}
        selectableRowSelected={(row) =>
          selectedRows?.find((s) => s?.name === row?.name)
        }
        contextActions={contextActions}
        onSelectedRowsChange={(state) => {
          if (state.selectedRows?.length !== selectedRows?.length)
            handleRowSelected(state);
        }}
        clearSelectedRows={selectedRows?.length > 0}
        {...(twoDMatrixCalced?.length > 10 ? { pagination: true } : {})}
      />
      <VariationInputModal
        selectedRows={selectedRows}
        register={register}
        setValue={setValue}
        control={control}
        handleClose={handleClose}
        modalType={modalType}
        forceRerender={forceRerender}
      />
    </div>
  );
}

///// variations table helpers-----------------------============================------------------------

function combos(list, n = 0, result = [], current = []) {
  if (!list?.length > 0) return [];
  if (n === list.length) result.push(current);
  else if (list[n]?.length > 0)
    list[n].forEach((item) => combos(list, n + 1, result, [...current, item]));
  return result;
}

const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check">
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
));

export const columnsNames = [
    "name",
    "price",
    "costPrice",
    "discountPrice",
    "discountDate",
    "weight",
    "barcode",
    "sku",
    "GTIN",
    "MPN",
    "maxPerOrder",
    "quantity",
    "minQuantityToNotify",
    "measurementUnit",
    "isShown",
    "condition",
    // "images",
  ],
  columnsHeadNames = {
    name: "الاسم",
    price: "السعر",
    costPrice: "سعر التكلفة",
    discountPrice: "السعر المخفض",
    discountDate: "انتهاء التخفيض",
    weight: "الوزن",
    barcode: "الباركود",
    sku: "SKU",
    GTIN: "GTIN",
    MPN: "MPN",
    quantity: "الكمية",
    minQuantityToNotify: "الكمية الادنى للتنبيه",
    measurementUnit: "وحدة القياس",
    isShown: "العرض",
    condition: "الحالة",
    // images: "الصور",
    maxPerOrder: "اقصى كمية للطلب",
  },
  conditionOptions = [
    { label: "جديد", value: "new" },
    { label: "قديم", value: "used" },
  ],
  isShownOptions = [
    { label: "اظهار", value: true },
    { label: "اخفاء", value: false },
  ];

export const getTypeByName = (name) => {
  switch (name) {
    case "costPrice":
    case "price":
    case "discountPrice":
    case "maxPerOrder":
    case "quantity":
      return "number";
    case "discountDate":
      return "date";
    // case "isShown":
    //   return "checkbox";
    // case "condition":
    //   return "radio";
    default:
      return "text";
  }
};

const columnWidthCheck = (name) => {
  switch (name) {
    case "quantity":
    case "price":
    case "costPrice":
    case "discountPrice":
    case "weight":
    case "isShown":
    case "condition":
      return { minWidth: "65px" };
    default:
      return {};
  }
};
