import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getOrdersApi,
  getSingleOrderApi,
  addOrderApi,
  editOrderApi,
  deleteOrderApi,
} from "api/orders";

// Login Redux States
import {
  GET_ORDERS,
  GET_SINGLE_ORDER,
  ADD_ORDER,
  DELETE_ORDER,
  EDIT_ORDER,
} from "./actionTypes";
import {
  getOrdersFailure,
  getOrdersSuccess,
  getSingleOrderFailure,
  getSingleOrdersuccess,
  addOrderFailure,
  addOrderSuccess,
  editOrderSuccess,
  editOrderFailure,
  deleteOrderSuccess,
  deleteOrderFailure,
} from "./actions";

function* getOrders({ payload }) {
  try {
    const { data } = yield call(getOrdersApi, payload);
    yield put(getOrdersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getOrdersFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleOrder({ payload }) {
  try {
    const { data } = yield call(getSingleOrderApi, payload);
    yield put(getSingleOrdersuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleOrderFailure(error?.response?.data || error));
  }
}

function* addOrder({ payload }) {
  try {
    const { data } = yield call(addOrderApi, payload);
    yield put(addOrderSuccess(data));
    yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(addOrderFailure(error.response?.data?.message || error));
  }
}

function* editOrder({ payload }) {
  try {
    const { data } = yield call(editOrderApi, payload);
    yield put(editOrderSuccess(data));
    if (payload.navigate) yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(editOrderFailure(error.response?.data?.message || error));
  }
}

function* deleteOrder({ payload }) {
  try {
    yield call(deleteOrderApi, payload);
    yield put(deleteOrderSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteOrderFailure(error?.response?.data || error));
  }
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

export function* watchGetSingleOrder() {
  yield takeEvery(GET_SINGLE_ORDER, getSingleOrder);
}

export function* watchAddOrder() {
  yield takeEvery(ADD_ORDER, addOrder);
}

export function* watchEditOrder() {
  yield takeEvery(EDIT_ORDER, editOrder);
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

function* ordersSaga() {
  yield all([fork(watchGetOrders)]);
  yield all([fork(watchGetSingleOrder)]);
  yield all([fork(watchAddOrder)]);
  yield all([fork(watchEditOrder)]);
  yield all([fork(watchDeleteOrder)]);
}

export default ordersSaga;
