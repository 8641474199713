import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  deleteProducts,
  getBrands,
  getProductCategories,
  getProducts,
  getProductsTags,
  getProductsVariations,
  getProductsVariationsStyles,
  getProductTypes,
  getSingleProductCategory,
  getUnits,
} from "store/actions";
import BreadCrumb from "components/shared/BreadCrumb";
import OrdersFilterSidebar from "components/shared/OrdersFilterSidebar";
import ProductList from "../../components/products/ProductList";
import filterIcon from "assets/svgs/filter.svg";
import settings from "assets/svgs/Setting.svg";
// import printIcon from "assets/svgs/download.svg";
import { ReactComponent as SettingsIcon } from "assets/svgs/Setting.svg";
import { ReactComponent as GridIcon } from "assets/svgs/gridLayout.svg";
import { ReactComponent as ListIcon } from "assets/svgs/listLayout.svg";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { ReactComponent as ExportIcon } from "assets/svgs/export.svg";
import { ReactComponent as ExclamationIcon } from "assets/svgs/exclamation.svg";
import { ReactComponent as PricesIcon } from "assets/svgs/money.svg";
import { ReactComponent as TrashIcon } from "assets/svgs/trash.svg";
import { ReactComponent as OrderIcon } from "assets/svgs/order.svg";
import { useState } from "react";
import StickyLoader from "components/shared/StickyLoader";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { FormattedMessage, useIntl } from "react-intl";
import { getId, handleIsoDate } from "helpers/functions";
// import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
// import { db } from "helpers/firebase";
import useSearchFormParam from "components/shared/SearchHook";
import ParamsFilters from "components/shared/ParamsFilters";
import Swal from "sweetalert2";
import Select from "react-select";
import ProductsFilterSidebar from "components/shared/ProductsFilterSidebar";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerSearch, handleSubmitSearch, searchParams, EmptyButton] =
    useSearchFormParam();
  const prms = useParams();
  const { locale, formatMessage, formatDate } = useIntl();

  const getDecodedParam = (param) => {
    try {
      return JSON.parse(decodeURI(searchParams?.get(param)));
    } catch (error) {
      return decodeURI(searchParams?.get(param));
    }
  };

  const { products, metadata, loading } = useSelector(
    (state) => state.products
  );
  const { flatCategories } = useSelector((state) => state.productCategories);
  const { productTypes } = useSelector((state) => state.productTypes);
  const { brands } = useSelector((state) => state.brands);

  const [listView, setListView] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  // const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [xlsxData, setXlsxData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [realTimeNotifs, setRealTimeNotifs] = useState([]);

  const arrangeProductsFlag = !!searchParams.get("arrange");

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(collection(db, "notifications"), (snap) => {
  //     setRealTimeNotifs(
  //       snap?.docs
  //         ?.map((e) => ({ ...e?.data(), id: e?.id }))
  //         ?.filter((notif) => !notif?.read && notif?.type === "outOfStock")
  //     );
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getProductTypes());
    dispatch(getBrands());
    dispatch(getUnits());
    dispatch(getProductsVariations());
    dispatch(getProductsVariationsStyles());
    dispatch(getProductsTags());
    // dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    let searchParams1 = new URLSearchParams(searchParams);
    searchParams1.delete("arrange");
    const paramsStr =
      searchParams1?.size > 0 ? `?${searchParams1.toString()}` : "";

    if (arrangeProductsFlag && getDecodedParam("categories")?.[0])
      dispatch(getSingleProductCategory(getDecodedParam("categories")?.[0]));
    else dispatch(getProducts(paramsStr));

    // ---------------------------------------------------------------

    setSelectedBrand(getDecodedParam("brands") || []);
    setSelectedType(getDecodedParam("types") || []);
    setSelectedCategory(getDecodedParam("categories") || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setIsCheck([]);
  }, [loading]);

  // useEffect(() => {
  //   setFilteredProducts(products);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(products)]);

  useEffect(() => {
    setXlsxData(
      products.map((product) => {
        return {
          "رقم المنتج": product?.id || product?._id,
          "اسم المنتج": product?.name?.[locale],
          التصنيف: product?.category?.name?.[locale],
          النوع: product?.type?.name?.[locale],
          الماركة: product?.brand?.name?.[locale],
          السعر: product?.price,
          الكمية: product?.quantity,
          "صور المنتج": product?.images?.map((image) => image?.image).join(","),
          الوصف: `${product?.description}`.replace(/<[^>]+>/g, ""),
          "تاريخ الانشاء": handleIsoDate(product?.createdAt),
          التقييم: product?.averageRate,
          "سعر التخفيض": product?.discountPrice || product?.discount,
          "اظهار المنتج": product?.isShown ? "نعم" : "لا",
          SKU: product?.sku,
          MPN: product?.mpn,
          GTIN: product?.gtin,
          "وحدة القياس": product?.measurementUnit?.name?.[locale],
          "الوزن (كجم)": product?.weight,
          "هل يتطلب شحن": product?.isRequiredShipping ? "نعم" : "لا",
          "اقصي كمية للطلب": product?.maxPerOrder,
        };
      })
    );
  }, [products, locale]);

  const getOptionsByName = (arr) =>
    //add default option
    arr?.map((item) => ({
      label: item.name?.[locale],
      value: getId(item),
    }));

  const exportToXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `products-${formatDate(new Date(), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}.xlsx`
    );
  };

  const exportToCSV = (data) => {
    // Ensure data is an array of objects with keys representing column headers
    if (
      !Array.isArray(data) ||
      data.length === 0 ||
      typeof data[0] !== "object"
    ) {
      console.error("Invalid data format for CSV export");
      return;
    }

    // Convert data to a CSV string
    const csv = Papa.unparse(data);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `products-${formatDate(new Date(), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })}.csv`;
    link.click();
  };

  const handleSelectAll = useCallback(
    (e) => {
      if (isCheckAll) {
        setIsCheck([]);
        return setIsCheckAll(!isCheckAll);
      }
      setIsCheck(products.map((product) => getId(product)));
      setIsCheckAll(!isCheckAll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(products), isCheckAll]
  );

  // console.log("-------------------------------------------------------");
  // console.log("-------------------------------------------------------");
  // console.log("products", products);
  // console.log("filteredProducts", filteredProducts);
  // console.log("isCheckAll", isCheckAll);
  // console.log("isCheck", isCheck);
  // console.log("-------------------------------------------------------");
  // console.log("-------------------------------------------------------");

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((product) => product !== id));
    }
  };

  const readNotif = useCallback(async (notif) => {
    // await updateDoc(doc(db, "notifications", notif?.id), {
    //   read: true,
    // });
  }, []);
  const updateParams = (name, value) => {
    searchParams.set(name, value);
    if (!value) searchParams.delete(name);
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`/products/${paramsStr}`, { replace: true });
  };

  const handleOutOfStock = () => {
    updateParams("quantity", 0);
    realTimeNotifs?.map((notif) => readNotif(notif));
  };

  const filterLength = [...searchParams.entries()]
    ?.map((e) => e[0])
    ?.filter((e) => e !== "page" && e !== "limit" && e !== "nameText")?.length;

  const getRawProduct = (product) => ({
    ...product,
    brand: getId(product?.brand),
    category: getId(product?.category),
    measurementUnit: getId(product?.measurementUnit),
    type: getId(product?.type),
    images: product?.images?.map((obj) => {
      let image = { ...obj };
      // image.image = "uploads/" + image.image.split("/").pop();
      return image;
    }),
    variationsOptions: product?.variationsOptions?.map((vp) => ({
      ...vp,
      variation: getId(vp.variation),
      variationStyle: getId(vp.variationStyle),
    })),
  });

  const duplicateSelected = useCallback(() => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";

    const rawProducts = isCheck?.map((id) =>
      getRawProduct(products?.find((p) => getId(p) === id) || {})
    );

    dispatch(
      addProduct({
        data: rawProducts,
        multi: true,
        navigate,
        searchParams: paramsStr,
      })
    );
  }, [dispatch, isCheck, navigate, products, searchParams]);

  const duplicateProduct = (product) => {
    Swal.fire({
      title: formatMessage({
        id: "confirmDuplicate",
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          addProduct({
            data: getRawProduct(product),
            navigate,
          })
        );
      }
    });
  };

  const handleDeleteProducts = useCallback(() => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    Swal.fire({
      title: formatMessage(
        {
          id: "confirmDeleteProducts",
        },
        { count: isCheck?.length }
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteProducts({ products: isCheck, searchParams: paramsStr })
        );
      }
    });
  }, [dispatch, formatMessage, isCheck, searchParams]);

  const handleDeleteAllProducts = () => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    Swal.fire({
      title: `${formatMessage({
        id: "deleteAllProducts",
      })} (${products?.length})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProducts({ products: "all", searchParams: paramsStr }));
      }
    });
  };

  const SettingsDropdown = () => (
    <Dropdown>
      <Dropdown.Toggle>
        <SettingsIcon width="20" style={{ fill: "black" }} />
        <FormattedMessage id="settings" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            exportToXLSX(xlsxData);
          }}
        >
          <ExportIcon />
          <FormattedMessage id="exportProductsXlsx" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            exportToCSV(xlsxData);
          }}
        >
          <ExportIcon />
          <FormattedMessage id="exportProductsCsv" />
        </Dropdown.Item>
        {/* <NavLink to="/products/edit-prices" className="dropdown-item">
          <PricesIcon />
          تعديل الاسعار
        </NavLink> */}
        <NavLink to="/products/inventory" className="dropdown-item">
          <PricesIcon />
          <FormattedMessage id="productsInventory" />
        </NavLink>
        <NavLink exact to="/products/?arrange=true" className="dropdown-item">
          <OrderIcon />
          <FormattedMessage id="orderProducts" />
        </NavLink>
        {/* -------------postponed------------ */}
        {/* <NavLink to="/products/adjustData" className="dropdown-item">
          <PricesIcon />
          <FormattedMessage id="adjustData" />
        </NavLink> */}
        <Dropdown.Item onClick={handleDeleteAllProducts}>
          <TrashIcon />
          <FormattedMessage id="deleteAllProducts" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const TopBar = useCallback(
    () =>
      searchParams.has("arrange") ? (
        <>
          <h5 className="small mb-3">
            <FormattedMessage id="selectCategoryToReorder" />
          </h5>
          <Select
            isRtl={true}
            isSearchable={false}
            className="basic-single mb-3"
            classNamePrefix="select"
            options={getOptionsByName(flatCategories)}
            placeholder="اختار تصنيف المنتج"
            isMulti={false}
            isClearable
            value={getOptionsByName(flatCategories)?.find(
              (t) => t.value === getDecodedParam("categories")?.[0]
            )}
            onChange={(selected) => {
              // onChange(selected?.value);
              if (!selected?.value)
                return updateParams("categories", selected?.value);
              updateParams("categories", JSON.stringify([selected?.value]));
            }}
          />
        </>
      ) : (
        <div className="filter-toolbar">
          <div className="filter-toolbar__right">
            <div className="filter-toolbar__selection">
              <label htmlFor="select-all">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheck?.length === products?.length}
                />
                {/* <SelectProducts /> */}
                <Dropdown>
                  <Dropdown.Toggle>
                    <span>
                      {isCheck?.length > 0
                        ? `${isCheck?.length} ${formatMessage({
                            id: "from",
                          })}  (${products?.length}) ${formatMessage({
                            id: "from",
                          })} ${formatMessage({
                            id: "products",
                          })} ⯆`
                        : ` ${formatMessage({ id: "products" })} (${
                            products?.length || 0
                          })`}
                    </span>
                  </Dropdown.Toggle>
                  {isCheck?.length > 0 && (
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={duplicateSelected}>
                        <FormattedMessage id="duplicateSelected" />
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleDeleteProducts}>
                        <FormattedMessage id="deleteSelectedProducts" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </label>
            </div>
            <div className="filter-toolbar__layout">
              <button
                className={listView ? "gridLayout" : "gridLayout active"}
                onClick={() => setListView(false)}
              >
                <GridIcon fill="#F2F2F2" stroke="#292D32" />
              </button>
              <button
                className={listView ? "listLayout active" : "listLayout"}
                onClick={() => setListView(true)}
              >
                <ListIcon fill="#292D32" stroke="#292D32" />
              </button>
            </div>
          </div>
          <div className="filter-toolbar__left">
            <div className="filter-toolbar__action">
              <button
                className="btn btn-black"
                onClick={() => setShowFilter(true)}
                type="button"
              >
                <img src={filterIcon} alt="settings" />
                <FormattedMessage id="filter" />
                {!!filterLength && (
                  <span className="filterLength">{filterLength}</span>
                )}
              </button>
            </div>
            <div className="filter-toolbar__settings">
              <SettingsDropdown />
            </div>
            <form
              className="filter-toolbar__search"
              onSubmit={handleSubmitSearch}
            >
              <input
                type="search"
                placeholder={formatMessage({ id: "productNameSearch" })}
                className="form-control"
                {...registerSearch("txt")}
              />
              <EmptyButton />

              <button>
                <SearchIcon />
              </button>
            </form>
          </div>
          <ParamsFilters
            categories={flatCategories}
            productTypes={productTypes}
            brands={brands}
          />
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(brands),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(products),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(flatCategories),
      filterLength,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(isCheckAll),
      isCheck,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(listView),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(productTypes),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      `${searchParams}`,
    ]
  );

  return (
    <>
      <div className="stores-wrap products-view">
        {loading && <StickyLoader fill="#FC6B14" />}
        <BreadCrumb
          pageName={formatMessage({
            id: arrangeProductsFlag ? "arrangeProducts" : "products",
          })}
          parent={
            arrangeProductsFlag && { text: "المنتجات", link: "/products" }
          }
        />
        <div className="ordersActions">
          <button
            className="btn btn-blue"
            onClick={() => {
              navigate("/products/new");
            }}
          >
            إضافة منتج +
          </button>
        </div>
        <TopBar />
        {realTimeNotifs?.length > 0 && (
          <div className="outOfStockAlerts">
            <ExclamationIcon width={24} height={24} />
            <FormattedMessage id="youHaveProductsOutOfStock" />
            <button onClick={handleOutOfStock} className="btn">
              <FormattedMessage id="pressHere" />
            </button>
            <FormattedMessage id="toKnowMore" />
          </div>
        )}

        {(searchParams.get("categories")?.length > 0 ||
          !searchParams.get("arrange")) && (
          <ProductList
            listView={listView}
            // metadata={metadata}
            // filteredProducts={filteredProducts}
            isCheck={isCheck}
            handleClick={handleClick}
            duplicateProduct={duplicateProduct}
          />
        )}
        <Outlet />
        <ProductsFilterSidebar
          loading={loading}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          categories={flatCategories}
          productTypes={productTypes}
          brands={brands}
        />

        <div
          className={showFilter ? "overlay-g active" : "overlay-g"}
          onClick={() => {
            setShowFilter(false);
          }}
        ></div>
      </div>
    </>
  );
};

export default Index;
