import React from "react";
import { useSelector } from "react-redux";

import BreadCrumb from "components/shared/BreadCrumb";

import StickyLoader from "components/shared/StickyLoader";


const EditPrices = () => {
  const { loading } = useSelector((state) => state.products);

  return (
    <>
      <div className="stores-wrap products-view">
        {loading && <StickyLoader fill="#FC6B14" />}
        <BreadCrumb
          pageName="تعديل الأسعار"
          parent={{ text: "المنتجات", link: "/products" }}
        />
      </div>
    </>
  );
};

export default EditPrices;
