import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getProductsReviewsApi,
  getSingleProductsReviewApi,
  addProductsReviewApi,
  editProductsReviewApi,
  deleteProductsReviewApi,
  changeProductsReviewStatusApi,
  replyToProductsReviewApi,
} from "api/productsReviews";

// Login Redux States
import {
  GET_PRODUCTS_REVIEWS,
  GET_SINGLE_PRODUCTS_REVIEW,
  ADD_PRODUCTS_REVIEW,
  DELETE_PRODUCTS_REVIEW,
  EDIT_PRODUCTS_REVIEW,
  CHANGE_PRODUCTS_REVIEW_STATUS,
  REPLY_PRODUCTS_REVIEW,
} from "./actionTypes";
import {
  getProductsReviewsFailure,
  getProductsReviewsSuccess,
  getSingleProductsReviewFailure,
  getSingleProductsReviewsuccess,
  addProductsReviewFailure,
  addProductsReviewSuccess,
  editProductsReviewSuccess,
  editProductsReviewFailure,
  deleteProductsReviewSuccess,
  deleteProductsReviewFailure,
} from "./actions";

function* getProductsReviews({ payload }) {
  try {
    const { data } = yield call(getProductsReviewsApi, payload);
    yield put(getProductsReviewsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getProductsReviewsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getSingleProductsReview({ payload }) {
  try {
    const { data } = yield call(getSingleProductsReviewApi, payload);
    yield put(getSingleProductsReviewsuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleProductsReviewFailure(error?.response?.data || error));
  }
}

function* addProductsReview({ payload }) {
  try {
    const { data } = yield call(addProductsReviewApi, payload);
    yield put(addProductsReviewSuccess(data));
    if (payload?.callBack) payload?.callBack();
    // yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(addProductsReviewFailure(error.response?.data?.message || error));
  }
}

function* editProductsReview({ payload }) {
  try {
    const { data } = yield call(editProductsReviewApi, payload);
    yield put(editProductsReviewSuccess(data));
    if (payload?.callBack) payload?.callBack();
    // yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(
      editProductsReviewFailure(error.response?.data?.message || error)
    );
  }
}

function* replyProductsReview({ payload }) {
  try {
    const { data } = yield call(replyToProductsReviewApi, payload);
    yield put(editProductsReviewSuccess(data));
    if (payload?.callBack) payload?.callBack();
    // yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(
      editProductsReviewFailure(error.response?.data?.message || error)
    );
  }
}

function* changeProductsReviewStatus({ payload }) {
  try {
    const { data } = yield call(changeProductsReviewStatusApi, payload);
    yield put(editProductsReviewSuccess(data));
    if (payload?.callBack) payload?.callBack();
    // yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    yield put(
      editProductsReviewFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteProductsReview({ payload }) {
  try {
    yield call(deleteProductsReviewApi, payload);
    yield put(deleteProductsReviewSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteProductsReviewFailure(error?.response?.data || error));
  }
}

export function* watchGetProductsReviews() {
  yield takeEvery(GET_PRODUCTS_REVIEWS, getProductsReviews);
}

export function* watchGetSingleProductsReview() {
  yield takeEvery(GET_SINGLE_PRODUCTS_REVIEW, getSingleProductsReview);
}

export function* watchAddProductsReview() {
  yield takeEvery(ADD_PRODUCTS_REVIEW, addProductsReview);
}

export function* watchEditProductsReview() {
  yield takeEvery(EDIT_PRODUCTS_REVIEW, editProductsReview);
}

export function* watchReplyProductsReview() {
  yield takeEvery(REPLY_PRODUCTS_REVIEW, replyProductsReview);
}

export function* watchChangeProductsReviewStatus() {
  yield takeEvery(CHANGE_PRODUCTS_REVIEW_STATUS, changeProductsReviewStatus);
}

export function* watchDeleteProductsReview() {
  yield takeEvery(DELETE_PRODUCTS_REVIEW, deleteProductsReview);
}

function* ordersSaga() {
  yield all([fork(watchGetProductsReviews)]);
  yield all([fork(watchGetSingleProductsReview)]);
  yield all([fork(watchAddProductsReview)]);
  yield all([fork(watchEditProductsReview)]);
  yield all([fork(watchReplyProductsReview)]);
  yield all([fork(watchChangeProductsReviewStatus)]);
  yield all([fork(watchDeleteProductsReview)]);
}

export default ordersSaga;
