import { Input, Textarea } from "components/shared/FormComponents";
import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting, getSettings } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import BreadCrumb from "components/shared/BreadCrumb";
import { useIntl } from "react-intl";
import { ReactComponent as RocketIcon } from "assets/svgs/rocket.svg";

const StoreInfo = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const {
    register: registerInfo,
    handleSubmit: handleSubmitInfo,
    reset: resetInfo,
    formState: { errors: errorsInfo },
    setValue,
    getValues,
    watch,
  } = useForm();
  const { loading, dataSaveLoading, settings } = useSelector(
    (state) => state?.settings
  );

  useEffect(() => {
    dispatch(getSettings("seoSettings"));
  }, [dispatch]);

  useEffect(() => {
    resetInfo({
      title: settings?.seo?.title || "",
      description: settings?.seo?.description || "",
      keywords: settings?.seo?.keywords || "",
    });
  }, [resetInfo, settings]);

  const handleKeywordsChange = (e) => {
    const keywords = e.target.value;
    if (keywords.length > 0 && keywords?.includes(",")) {
      setValue("keywords", getValues("keywords") + keywords);
      e.target.value = "";
    }
  };

  const handleRemoveKeyword = (word) => {
    setValue("keywords", watch("keywords").replace(word, ""));
  };

  const onSubmitInfo = (data) => {
    dispatch(
      editStoreSlugSetting({
        data: { seo: data },
        slug: "seoSettings",
        navigate,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <BreadCrumb
        pageName={formatMessage({
          id: "storeSeo",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <div className="acc-form card store-seo">
        {!!dataSaveLoading && <StickyLoader fill="#FC6B14" />}
        <div className="card-head">
          <h4 className="mb-0">
            <RocketIcon /> تحسين SEO
          </h4>
        </div>
        <form onSubmit={handleSubmitInfo(onSubmitInfo)}>
          <Col xl={10} md={12}>
            <Row>
              <Col md={6} xs={12}>
                <div className="form-group required">
                  <h5>عنوان المتجر </h5>
                  <div>
                    <Input
                      name="title"
                      register={registerInfo}
                      errors={errorsInfo}
                      rules={{ required: false }}
                    />
                  </div>
                </div>
              </Col>

              <Col md={6} xs={12}>
                <div className="form-group required">
                  <h5>الوصف</h5>

                  <Input
                    name="description"
                    register={registerInfo}
                    errors={errorsInfo}
                    rules={{ required: false }}
                  />
                </div>
              </Col>

              <Col xs={12}>
                <div className="form-group required">
                  <h5 className="mb-0">الكلمات الدلالية</h5>
                  <p>
                    <small style={{ fontSize: "0.8rem", color: "inherit" }}>
                      (مفصولة باستخدام ",")
                    </small>
                  </p>
                  <div>
                    <div className="form-control form-outline p-0 h-auto">
                      <div className="keywords-items">
                        {watch("keywords")
                          ?.split(",")
                          ?.filter((keyword) => keyword?.length > 0)
                          ?.map((keyword, index) => (
                            <div className="item" key={index}>
                              {keyword}
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => handleRemoveKeyword(keyword)}
                              ></button>
                            </div>
                          ))}
                      </div>
                      <input
                        name="keywords"
                        className="form-control bg-transparent border-0 w-100"
                        onChange={handleKeywordsChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={12}>
                <div className="form-group">
                  <button type="submit" className="btn btn-blue">
                    حفظ التعديلات
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </form>
      </div>
    </>
  );
};

export default StoreInfo;
