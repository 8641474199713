import React, { useEffect, useState } from "react";
import OrdersTable from "components/dashboard/OrdersTable";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { getOrders } from "store/actions";
// import {utils,writeFile} as XLSX from "xlsx";
// import OrdersFilterSidebar from "components/shared/OrdersFilterSidebar";
// import filterIcon from "assets/svgs/filter.svg";
import { utils, writeFile } from "xlsx";
import BreadCrumb from "components/shared/BreadCrumb";
import settings from "assets/svgs/Setting.svg";
import Papa from "papaparse";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDateYmd, handleIsoDate } from "helpers/functions";
import StickyLoader from "components/shared/StickyLoader";
import BootyPagination from "components/shared/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import ParamsFilters from "components/shared/ParamsFilters";
import ReactSelect from "react-select";
import Flatpickr from "react-flatpickr";
import { ReactComponent as CaretIcon } from "assets/svgs/caret-left.svg";

const Index = () => {
  const dispatch = useDispatch();
  const { formatMessage, formatDate, locale } = useIntl();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [xlsxData, setXlsxData] = useState([]);

  const { orders, metadata, loading } = useSelector((state) => state.orders);

  const updateParams = (name, value) => {
    if (!value) searchParams.delete(name);
    else searchParams.set(name, value);
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const filterToggle = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };
  // eslint-disable-next-line no-unused-vars
  // const [metadata, setMetadata] = useState({});

  // useEffect(() => {
  //   dispatch(getOrders());
  // }, [dispatch]);

  useEffect(() => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    dispatch(getOrders(paramsStr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    //   fetchMetaData();
    setXlsxData(
      orders.map((order) => {
        return {
          [formatMessage({ id: "orderCode" })]: order?.uniqueCode,
          [formatMessage({ id: "createdAt" })]: handleIsoDate(order?.createdAt),
          [formatMessage({ id: "paymentMethod" })]:
            order?.paymentMethod?.name?.[locale],
          [formatMessage({ id: "productsNum" })]: order?.products?.length,
          [formatMessage({ id: "products" })]: order?.products
            ?.map((prod) => prod?.product?.name?.[locale] || "...")
            .join("\r\n - "),
          [formatMessage({ id: "status" })]: formatMessage({
            id: order?.status,
          }),
          [formatMessage({ id: "total" })]: formatMessage({
            id: order?.total,
          }),
          [formatMessage({ id: "userName" })]: order?.user?.username || "--",
          [formatMessage({ id: "email" })]: order?.user?.email || "--",
        };
      })
    );
  }, [formatMessage, locale, orders]);

  // const fetchMetaData = async () => {
  //   const response = await server().get(`/orders`);
  //   setMetadata(response.data.data.metadata);
  //   return response.data;
  // };

  const exportToXLSX = () => {
    const worksheet = utils.json_to_sheet(xlsxData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeFile(
      workbook,
      `orders-${formatDate(new Date(), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}.xlsx`
    );
  };

  const exportToCSV = () => {
    // Ensure data is an array of objects with keys representing column headers
    if (
      !Array.isArray(xlsxData) ||
      xlsxData.length === 0 ||
      typeof xlsxData[0] !== "object"
    ) {
      console.error("Invalid data format for CSV export");
      return;
    }

    // Convert data to a CSV string
    const csv = Papa.unparse(xlsxData);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `orders-${formatDate(new Date(), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })}.csv`;
    link.click();
  };

  const filterLength = [...searchParams.entries()]
    ?.map((e) => e[0])
    ?.filter(
      (e) => e !== "page" && e !== "limit" && e !== "nameText" && e !== "status"
    )?.length;

  const statusEnums = ["paid", "onDelivery", "delivered", "canceled"];

  const renderStatusOptions = statusEnums.map((status, index) => ({
    label: formatMessage({ id: status }),
    value: status,
  }));

  // const renderStatusFilters = statusEnums.map((status, index) => {
  //   const isActive = searchParams.get("status") === status;
  //   return (
  //     <Card
  //       key={index}
  //       className={isActive ? "active" : ""}
  //       onClick={() => updateParams("status", !isActive ? status : "")}
  //     >
  //       {isActive && (
  //         <span className="removeBtn">
  //           <span className="btn-close"></span>
  //         </span>
  //       )}
  //       <h5>
  //         <FormattedMessage id={status} />
  //       </h5>
  //     </Card>
  //   );
  // });

  return (
    <div className="orders-wrap">
      <BreadCrumb pageName="الطلبات" />
      {loading && <StickyLoader fill="#FC6B14" />}

      <div className="filter-toolbar">
        <div className="ordersServices">
          <Dropdown>
            <Dropdown.Toggle>
              <img src={settings} alt="settings" />
              <FormattedMessage id="exportOrders" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={exportToXLSX}>
                <img src={settings} alt="" />
                <FormattedMessage id="exportOrdersXlsx" />
              </Dropdown.Item>
              <Dropdown.Item onClick={exportToCSV}>
                <img src={settings} alt="" />
                <FormattedMessage id="exportOrdersCsv" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="filter-toolbar__left">
          <Flatpickr
            className="form-control form-outline"
            placeholder="تاريخ البداية"
            value={searchParams.get("from")}
            onChange={([date]) => updateParams("from", formatDateYmd(date))}
            readOnly={false}
          />
          <CaretIcon width="20" height="20" style={{ flex: "none" }} />
          <Flatpickr
            className="form-control form-outline"
            placeholder="تاريخ الانتهاء"
            value={searchParams.get("to")}
            onChange={([date]) => updateParams("to", formatDateYmd(date))}
            readOnly={false}
          />
          <ReactSelect
            classNamePrefix={`${searchParams.get("status")} react-select`}
            options={renderStatusOptions}
            placeholder="اختر الحالة"
            value={
              searchParams.get("status")
                ? renderStatusOptions?.find(
                    (e) => e.value === searchParams.get("status")
                  )
                : null
            }
            onChange={({ value }) => updateParams("status", value)}
          />
          {/* <div className="filter-toolbar__action">
            <button className="btn btn-black" onClick={filterToggle}>
              <img src={filterIcon} alt="settings" />
              <FormattedMessage id="filter" />
              {!!filterLength && (
                <span className="filterLength">{filterLength}</span>
              )}
            </button>
          </div> */}
        </div>
        <ParamsFilters href="/orders" />
      </div>

      {/* <div className="orders-statuses">{renderStatusFilters}</div> */}

      <OrdersTable data={orders} title="الطلبات" href="/orders" />

      {/* <OrdersFilterSidebar handleClose={filterToggle} /> */}
      {/* <div className="overlay-g" onClick={filterToggle}></div> */}
      <BootyPagination metaData={metadata} />
    </div>
  );
};

export default Index;
