import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { getSingleBlog } from "store/actions";

import { useParams } from "react-router-dom";

import Loader from "../../components/shared/Loader";

const ShowBlog = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singleBlog, loading } = useSelector((state) => state.blogs);
  // fetch subscriber data
  useEffect(() => {
    dispatch(getSingleBlog(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4> بيانات المقال</h4>
          </div>
          <div className="card-body">
            <ul>
              <li>
                <h6>صورة المقال:</h6>
                <img src={singleBlog?.image} alt="" />
              </li>
              <li>
                عنوان المقال:
                <strong>{singleBlog?.title?.[locale]}</strong>
              </li>
              <li>
                تصنيف المقال:
                <strong>{singleBlog?.category?.name?.[locale]}</strong>
              </li>
              <li>
                وصف المقال:
                <strong
                  dangerouslySetInnerHTML={{
                    __html: singleBlog?.description?.[locale],
                  }}
                ></strong>
              </li>
              <li>
                كلمات دلالية :
                {singleBlog?.tags?.map((tag) => (
                  <strong key={tag}>{`${tag} , `}</strong>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowBlog;
