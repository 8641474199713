import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import {
  editStore,
  getCategories,
  getPackages,
  getThemeTemplates,
  changeStoreStatus,
} from "store/actions";

import { useNavigate, useSearchParams } from "react-router-dom";
import server from "api/server";
// import SelectMultiple from "react-select";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import Loader from "../../components/shared/Loader";
import { getPublicImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";

const EditStore = () => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [status, setStatus] = useState("");
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, control, reset } = useForm();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { categories } = useSelector((state) => state.categories);
  const { packages } = useSelector((state) => state.packages);
  const { SingleStore, loading } = useSelector((state) => state?.stores);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "branches",
  });

  const store = localStorage.getItem("store");
  const theme = localStorage.getItem("data-theme");

  useEffect(() => {
    setStatus(SingleStore?.status);
  }, [SingleStore?.status]);

  // fetch subscriber data
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getPackages());
    dispatch(getThemeTemplates());
  }, [deviceType, dispatch, store, theme]);

  useEffect(() => {
    reset({
      category: SingleStore?.category?.id,
      package: SingleStore?.package?.id,
      name: SingleStore?.name,
      phone: SingleStore?.phone,
      domain: SingleStore?.domain,
      description: SingleStore?.description,
      logo: SingleStore?.logo,
      branches: SingleStore?.branches,
      themes: SingleStore?.themes,
      status: SingleStore?.status,
    });
  }, [reset, SingleStore]);

  const onSubmit = async (data) => {
    if (selectedImage && selectedImage.path) data["logo"] = selectedImage.path;
    // data.logo = "uploads/" + data.logo.split("/").pop();
    data.status = status;
    dispatch(editStore({ data, navigate }));
  };

  // const imageChange = async (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     const { data } = await server().post("/upload", formData);
  //     setSelectedImage({
  //       preview: e.target.files[0],
  //       path: getPublicImage(data.data.files[0]),
  //     });
  //   }
  // };

  // const removeSelectedImage = () => {
  //   setSelectedImage();
  // };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات المتجر</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة المتجر</h5>
                      {/* <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <Controller
                                control={control}
                                name="image"
                                render={() => {
                                  return (
                                    <img
                                      src={
                                        selectedImage && selectedImage.preview
                                          ? URL.createObjectURL(
                                              selectedImage.preview
                                            )
                                          : SingleStore?.logo
                                      }
                                      width={160}
                                      height={160}
                                      alt=""
                                    />
                                  );
                                }}
                              />

                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>
                      </div> */}
                      <ImageUpload
                        className="m-1"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>التصنيفات</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("category", { required: true })}
                        >
                          <option value="">اختر التصنيف</option>
                          {categories?.map((category) => (
                            <option value={category?.id} key={category.id}>
                              {category?.name?.[locale]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>الباقات</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("package", { required: true })}
                        >
                          <option value="">اختر الباقة</option>
                          {packages?.map((packagee) => (
                            <option value={packagee?.id} key={packagee.id}>
                              {packagee?.name?.[locale]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>إسم المتجر بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="إسم المتجر بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                        <input
                          type="hidden"
                          className="form-control form-outline"
                          placeholder="الصورة"
                          value="hhhhh"
                          {...register("image", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>إسم المتجر بالإنجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="إسم المتجر بالإنجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رقم الجوال</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رقم الجوال"
                          {...register("phone", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم النطاق</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم النطاق"
                          {...register("domain", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف المتجر بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="وصف المتجر بالعربية"
                          {...register("description[ar]", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف المتجر بالإنجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="وصف المتجر بالإنجليزية"
                          {...register("description[en]", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group branches">
                      <h5>الفروع</h5>

                      {fields.map((item, index) => {
                        return (
                          <div key={item.id} className="add-multi-component">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="removeImg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                />
                              </svg>
                            </button>
                            <Row>
                              <Col lg={6} xs={12}>
                                <div className="form-group">
                                  <h5>اسم الفرع بالعربية</h5>
                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="اسم الفرع بالعربية"
                                    {...register(`branches.${index}.name[ar]`, {
                                      required: true,
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col lg={6} xs={12}>
                                <div className="form-group">
                                  <h5>اسم الفرع بالإنجليزية</h5>
                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="اسم الفرع بالإنجليزية"
                                    {...register(`branches.${index}.name[en]`, {
                                      required: true,
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col lg={6} xs={12}>
                                <div className="form-group">
                                  <h5>رقم الجوال</h5>
                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="رقم الجوال"
                                    {...register(`branches.${index}.phone`, {
                                      required: true,
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col lg={6} xs={12}>
                                <div className="form-group">
                                  <h5>عنوان الفرع</h5>

                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder="عنوان الفرع"
                                    {...register(
                                      `branches.${index}.address[addressDetail]`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Col lg={2} xs={12} className="text-left">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          إضافة فرع
                        </button>
                      </Col>
                    </div>
                  </Col>
                  <input type="hidden" {...register("logo")} />
                </Row>
              </Col>

              <Col lg={{ span: 4, offset: 5 }}>
                <div className="form-group required">
                  <h5>حالة المتجر (مفتوح / مغلق)</h5>
                  <div className="activate">
                    <div className="switcher">
                      <input
                        type="checkbox"
                        className="switcher-input"
                        defaultChecked={
                          SingleStore?.status === "opened" ? true : false
                        }
                        onChange={() => {
                          let newStatus = "";
                          status === "closed" && (newStatus = "opened");
                          status === "opened" && (newStatus = "closed");

                          dispatch(
                            changeStoreStatus({
                              status: newStatus,
                              storeId: SingleStore.id,
                            })
                          );
                        }}
                      />
                      <div className="knobs"></div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} xs={12}>
                <div className="form-group">
                  <button type="submit" className="btn btn-blue">
                    حفظ التعديلات
                  </button>
                </div>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStore;
