import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Row } from "react-bootstrap";

import StickyLoader from "components/shared/StickyLoader";

import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import BrandCard from "./BrandCard";
import { deleteMultipleBrands, orderBrand } from "store/actions";
import { Card } from "./card";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";

const DndBrandsList = ({ listView }) => {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const { brands, loading } = useSelector((state) => state.brands);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [cards, setCards] = useState();
  const brandsAsStr = JSON.stringify(brands);

  useEffect(() => {
    setCards(brands);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsAsStr]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(brands.map((brand) => getId(brand)));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((brand) => brand !== id));
    }
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) =>
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      ),
    []
  );

  const renderProducts = useMemo(
    () =>
      cards?.map((brand, index) => (
        <Col key={brand.id} xs={12} sm={6} md={4} lg={4} xl={listView ? 6 : 4}>
          <Card
            index={index}
            id={getId(brand)}
            text={brand.name?.[locale]}
            moveCard={moveCard}
          >
            <BrandCard
              brand={brand}
              isCheck={isCheck}
              handleClick={handleClick}
            />
          </Card>
        </Col>
      )),
    [cards, handleClick, isCheck, listView, locale, moveCard]
  );

  const handleSaveOrder = () => {
    if (cards?.find((card) => !card?._id))
      return toast.warn(formatMessage({ id: "saveFirst" }));
    dispatch(
      orderBrand({
        brands: cards?.map((card, index) => ({
          id: getId(card),
          order: index,
        })),
      })
    );
  };

  const saveBtn = useMemo(
    () =>
      JSON?.stringify(brands) !== JSON?.stringify(cards) && (
        <div className="d-flex justify-content-between align-items-center my-2 border border-secondary border-1 rounded-4 p-2">
          <span className="dark-white mx-2">
            <FormattedMessage id="saveChanges" />
          </span>
          <button className="btn btn-blue" onClick={handleSaveOrder}>
            <FormattedMessage id="save" />
          </button>
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brands, cards]
  );

  return (
    <div className={listView ? "productsList listView" : "productsList"}>
      <div className="productsSelection">
        <input
          type="checkbox"
          name="selectAll"
          id="selectAll"
          onChange={handleSelectAll}
          checked={isCheckAll}
        />
        <Dropdown>
          <Dropdown.Toggle>
            <FormattedMessage id="selectedItems" />
            {isCheck?.length > 0 && <h5>&#11206;</h5>}
            <i>{isCheck?.length}</i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                dispatch(deleteMultipleBrands({ brands: isCheck }));
                setIsCheck([]);
              }}
            >
              <DeleteIcon fill="#f00" />
              <FormattedMessage id="deleteItems" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {saveBtn}
      <Row>
        <DndProvider backend={HTML5Backend}>
          {brands?.length > 0 ? (
            renderProducts
          ) : (
            // <DndCards data={brands} childrenComponents={renderProducts} />
            <div className="no-data alert alert-warning text-center m-0 mt-4">
              <p>لا توجد علامات تجارية</p>
            </div>
          )}
        </DndProvider>
      </Row>
      {loading && <StickyLoader fill="#FC6B14" />}
    </div>
  );
};

export default DndBrandsList;
