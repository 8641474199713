import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccounts, getBanks, getSettings } from "store/actions";
import Loader from "components/shared/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import BreadCrumb from "components/shared/BreadCrumb";
import { useForm } from "react-hook-form";
import { ReactComponent as NotifIcon } from "assets/svgs/settings/archive-book.svg";
import { Col, Row } from "react-bootstrap";
import ReceiptTemplate from "components/settings/ReceiptTemplate";

const StoreReceipts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { formatMessage } = useIntl();

  const { register, handleSubmit, reset, watch } = useForm();

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("storeReceiptSetting"));
    dispatch(getBanks());
    dispatch(getBankAccounts());
  }, [dispatch]);

  const shipping = settings?.shipping;

  const CheckLabel = ({ name, label = name }) => (
    <Col className="card shipping-card w-100">
      <div className="info w-100 flex-column align-items-start">
        <p>
          <FormattedMessage id={label} />
        </p>
      </div>

      <div className="switcher-wrapper p-0 m-0">
        <label
          className="switcher position-relative mb-0"
          style={{ translate: "none", inset: "auto" }}
        >
          <input
            type="checkbox"
            className="switcher-input"
            {...register(name)}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </div>
    </Col>
  );

  const onSubmit = (data) => {
    console.log(data);
  };

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page store-receipt">
      <BreadCrumb
        pageName={formatMessage({
          id: "storeReceiptSetting",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="acc-form shipping-methods">
          {dataSaveLoading && <StickyLoader fill="#FC6B14" />}

          <div className="card productDataModal">
            <div className="card-head form-group mb-0">
              <div className="form-head mb-0">
                <i>
                  <NotifIcon />
                </i>
                <div>
                  <h4 className="mb-0">
                    <FormattedMessage id="storeReceiptSetting" />
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col xs="12" lg="4" md="6">
              <div className="card items settings-cards flex-column w-100">
                <CheckLabel name="showProductImage" />
                <CheckLabel name="showProductDescription" />
                <CheckLabel name="showContactInfo" />
                <CheckLabel name="showReceiptSummeryAcknowledgment" />
                <CheckLabel name="showOrderNote" />
                <CheckLabel name="showStoreAddress" />
                <CheckLabel name="showBarCode" />
                <CheckLabel name="showProductWeight" />
                <CheckLabel name="showProductStorageSerial" />
                <CheckLabel name="autoSendReceipt" />
              </div>
            </Col>

            <Col>
              <div className="card">
                <ReceiptTemplate watch={watch} />
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default StoreReceipts;
