import {
  GET_GROUPS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  GET_SINGLE_GROUP,
  GET_SINGLE_GROUP_FAILURE,
  GET_SINGLE_GROUP_SUCCESS,
  GET_GROUP_RULES,
  GET_GROUP_RULES_SUCCESS,
  GET_GROUP_RULES_FAILURE,
} from "./actionTypes";

const initialState = {
  groups: [],
  groupRules: [],
  loading: false,
  error: "",
  singleGroup: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GROUPS_SUCCESS:
      state = {
        ...state,
        loading: false,
        groups: action.payload.groups,
        metadata: {
          ...(action.payload.metadata || {}),
          results: action.payload?.groups?.length,
        },
      };
      break;
    case GET_GROUPS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_GROUP_RULES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GROUP_RULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        groupRules: action.payload.groupRules,
      };
      break;
    case GET_GROUP_RULES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleGroup: action.payload.group,
      };
      break;
    case GET_SINGLE_GROUP_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        groups: action.payload.groups,
      };
      break;
    case ADD_GROUP_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_GROUP_SUCCESS:
      const groupsAfterDeleteing = [
        ...state?.groups?.filter((group) => group?.id !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        groups: groupsAfterDeleteing,
      };
      break;
    case DELETE_GROUP_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
