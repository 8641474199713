export const GET_PRODUCTS_REVIEWS = "GET_PRODUCTS_REVIEWS";
export const GET_PRODUCTS_REVIEWS_SUCCESS = "GET_PRODUCTS_REVIEWS_SUCCESS";
export const GET_PRODUCTS_REVIEWS_FAILURE = "GET_PRODUCTS_REVIEWS_FAILURE";

export const GET_SINGLE_PRODUCTS_REVIEW = "GET_SINGLE_PRODUCTS_REVIEW";
export const GET_SINGLE_PRODUCTS_REVIEW_SUCCESS =
  "GET_SINGLE_PRODUCTS_REVIEW_SUCCESS";
export const GET_SINGLE_PRODUCTS_REVIEW_FAILURE =
  "GET_SINGLE_PRODUCTS_REVIEW_FAILURE";

export const ADD_PRODUCTS_REVIEW = "ADD_PRODUCTS_REVIEW";
export const ADD_PRODUCTS_REVIEW_SUCCESS = "ADD_PRODUCTS_REVIEW_SUCCESS";
export const ADD_PRODUCTS_REVIEW_FAILURE = "ADD_PRODUCTS_REVIEW_FAILURE";

export const EDIT_PRODUCTS_REVIEW = "EDIT_PRODUCTS_REVIEW";
export const EDIT_PRODUCTS_REVIEW_SUCCESS = "EDIT_PRODUCTS_REVIEW_SUCCESS";
export const EDIT_PRODUCTS_REVIEW_FAILURE = "EDIT_PRODUCTS_REVIEW_FAILURE";

export const REPLY_PRODUCTS_REVIEW = "REPLY_PRODUCTS_REVIEW";

export const CHANGE_PRODUCTS_REVIEW_STATUS = "CHANGE_PRODUCTS_REVIEW_STATUS";

export const DELETE_PRODUCTS_REVIEW = "DELETE_PRODUCTS_REVIEW";
export const DELETE_PRODUCTS_REVIEW_SUCCESS = "DELETE_PRODUCTS_REVIEW_SUCCESS";
export const DELETE_PRODUCTS_REVIEW_FAILURE = "DELETE_PRODUCTS_REVIEW_FAILURE";
