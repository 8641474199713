import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
} from "./actionTypes";

export const getPackages = () => {
  return {
    type: GET_PACKAGES,
  };
};

export const getPackagesSuccess = (stores) => {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload: stores,
  };
};

export const getPackagesFailure = (error) => {
  return {
    type: GET_PACKAGES_FAILURE,
    payload: error,
  };
};
