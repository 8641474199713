import React from "react";
import { useIntl } from "react-intl";
const TimeInst = ({ date }) => {
  const MONTH_IN_MILLS = 2629743833.3334,
    WEEK_IN_MILLIS = 6.048e8,
    DAY_IN_MILLIS = 8.64e7,
    HOUR_IN_MILLIS = 3.6e6,
    MIN_IN_MILLIS = 6e4,
    SEC_IN_MILLIS = 1e3;

  const getUTCTime = (date) =>
    date.getTime() - date.getTimezoneOffset() * 60000;

  // For testing only, remove the constructor argument in production.
  const getCurrentUTCTime = () => getUTCTime(new Date());

  const defaultFormatOptions = {
    style: "long",
  };

  const timeFromNow = (date, intl, options = defaultFormatOptions) => {
    const millis =
        typeof date === "string"
          ? getUTCTime(new Date(date))
          : getUTCTime(date),
      diff = millis - getCurrentUTCTime();
    if (Math.abs(diff) > MONTH_IN_MILLS)
      return intl.formatRelativeTime(
        Math.trunc(diff / MONTH_IN_MILLS),
        "month",
        options
      );
    else if (Math.abs(diff) > WEEK_IN_MILLIS)
      return intl.formatRelativeTime(
        Math.trunc(diff / WEEK_IN_MILLIS),
        "week",
        options
      );
    else if (Math.abs(diff) > DAY_IN_MILLIS)
      return intl.formatRelativeTime(
        Math.trunc(diff / DAY_IN_MILLIS),
        "day",
        options
      );
    else if (Math.abs(diff) > HOUR_IN_MILLIS)
      return intl.formatRelativeTime(
        Math.trunc((diff % DAY_IN_MILLIS) / HOUR_IN_MILLIS),
        "hour",
        options
      );
    else if (Math.abs(diff) > MIN_IN_MILLIS)
      return intl.formatRelativeTime(
        Math.trunc((diff % HOUR_IN_MILLIS) / MIN_IN_MILLIS),
        "minute",
        options
      );
    else
      return intl.formatRelativeTime(
        Math.trunc((diff % MIN_IN_MILLIS) / SEC_IN_MILLIS),
        "second",
        options
      );
  };

  const CreatedConsetee = (date) => {
    return timeFromNow(date, useIntl());
  };

  return <>{CreatedConsetee(date)}</>;
};

export default TimeInst;
