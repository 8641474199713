import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editBlogCategory, getSingleBlogCategory } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import {
  textInputLength,
  textInputPattern,
  textInputValidations,
} from "helpers/configs";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { getPublicImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";

const EditBlogCategory = () => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { singleBlogCategory } =
    useSelector((state) => state?.blogCategories) || {};

  useEffect(() => {
    dispatch(getSingleBlogCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      image: singleBlogCategory?.image,
      name: singleBlogCategory?.name,
    });
    setSelectedImage({ path: singleBlogCategory?.image });
  }, [reset, singleBlogCategory]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data.image.split("/").pop();

    dispatch(editBlogCategory({ id, data, navigate }));
  };

  // const imageChange = async (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     const { data } = await server().post("/upload", formData);
  //     setSelectedImage({
  //       preview: e.target.files[0],
  //       path: getPublicImage(data.data.files[0]),
  //     });
  //   }
  // };

  // const removeSelectedImage = () => {
  //   setSelectedImage();
  // };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات التصنيف</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة التصنيف</h5>
                      {/* <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage && selectedImage.preview
                                    ? URL.createObjectURL(selectedImage.preview)
                                    : singleBlogCategory?.image
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>
                      </div> */}
                      <ImageUpload
                        className="m-1"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <MultiLangWrapper
                      label={<label>إسم التصنيف </label>}
                      errors={errors?.name}
                      customClass="form-group selectHolder col-12"
                      required
                    >
                      {({ lang, classes, key }) => (
                        <>
                          <input
                            key={key}
                            type="text"
                            // {...textInputValidations}
                            className={`form-control ${classes}`}
                            {...register(`name.${lang}`, {
                              required: true,
                              validate: (val) =>
                                textInputPattern.test(val) ||
                                "يجب ان يحتوي على حروف وارقام فقط ، واقصى عدد 100 حرف",
                            })}
                          />
                          <h5
                            key={key}
                            className={`my-1 no-require ${classes} ${
                              watch(`name.${lang}`)?.length > textInputLength
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {`${textInputLength} / ${
                              watch(`name.${lang}`)?.length || "0"
                            }`}
                          </h5>
                          <span className={`error-hint ${classes}`} key="1">
                            {errors?.name?.[lang] &&
                              (errors?.name?.[lang]?.message ||
                                "خطأ باسم التصنيف")}
                          </span>
                        </>
                      )}
                      {/* <span className="error-hint" key="1">
                        {errors?.name && "خطأ باسم التصنيف"}
                      </span> */}
                    </MultiLangWrapper>
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالعربية"
                          {...register("name.ar", { required: true })}
                          {...textInputValidations}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالانجليزية"
                          {...register("name.en", { required: true })}
                          {...textInputValidations}
                        />
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      حفظ التعديلات
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlogCategory;
