export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILURE = "GET_GROUPS_FAILURE";

export const GET_GROUP_RULES = "GET_GROUP_RULES";
export const GET_GROUP_RULES_SUCCESS = "GET_GROUP_RULES_SUCCESS";
export const GET_GROUP_RULES_FAILURE = "GET_GROUP_RULES_FAILURE";

export const GET_SINGLE_GROUP = "GET_SINGLE_GROUP";
export const GET_SINGLE_GROUP_SUCCESS = "GET_SINGLE_GROUP_SUCCESS";
export const GET_SINGLE_GROUP_FAILURE = "GET_SINGLE_GROUP_FAILURE";

export const ADD_GROUP = "ADD_GROUP";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

export const EDIT_GROUP = "EDIT_GROUP";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";
