import {
  GET_THEME_PAGES,
  GET_THEME_PAGES_FAILURE,
  GET_THEME_PAGES_SUCCESS,
  GET_THEME_COMPONENTS_TYPE,
  GET_THEME_COMPONENTS_TYPE_SUCCESS,
  GET_THEME_COMPONENTS_TYPE_FAILURE,
  GET_THEME_FONTS,
  GET_THEME_FONTS_SUCCESS,
  GET_THEME_FONTS_FAILURE,
  GET_THEME,
  GET_THEME_SUCCESS,
  GET_THEME_FAILURE,
  EDIT_THEME_COMPONENT,
  EDIT_THEME_COMPONENT_SUCCESS,
  EDIT_THEME_COMPONENT_FAILURE,
  EDIT_THEME_CSS,
  EDIT_THEME_CSS_SUCCESS,
  EDIT_THEME_CSS_FAILURE,
  EDIT_THEME_SETTINGS,
  EDIT_THEME_SETTINGS_SUCCESS,
  EDIT_THEME_SETTINGS_FAILURE,
  EDTI_STORE_SETTINGS,
  EDTI_STORE_SETTINGS_SUCCESS,
  EDTI_STORE_SETTINGS_FAILURE,
} from "./actionTypes";

export const getThemePages = (payload) => {
  return {
    type: GET_THEME_PAGES,
    payload,
  };
};

export const getThemePagesSuccess = (themePages) => {
  return {
    type: GET_THEME_PAGES_SUCCESS,
    payload: themePages,
  };
};

export const getThemePagesFailure = (error) => {
  return {
    type: GET_THEME_PAGES_FAILURE,
    payload: error,
  };
};

export const getThemeComponentsType = (payload) => {
  return {
    type: GET_THEME_COMPONENTS_TYPE,
    payload,
  };
};

export const getThemeComponentsTypeSuccess = (themeComponentsType) => {
  return {
    type: GET_THEME_COMPONENTS_TYPE_SUCCESS,
    payload: themeComponentsType,
  };
};

export const getThemeComponentsTypeFailure = (error) => {
  return {
    type: GET_THEME_COMPONENTS_TYPE_FAILURE,
    payload: error,
  };
};

export const getThemeFonts = (payload) => {
  return {
    type: GET_THEME_FONTS,
    payload,
  };
};

export const getThemeFontsSuccess = (fonts) => {
  return {
    type: GET_THEME_FONTS_SUCCESS,
    payload: fonts,
  };
};

export const getThemeFontsFailure = (error) => {
  return {
    type: GET_THEME_FONTS_FAILURE,
    payload: error,
  };
};

export const getTheme = (payload) => {
  return {
    type: GET_THEME,
    payload,
  };
};

export const getThemeSuccess = (theme) => {
  return {
    type: GET_THEME_SUCCESS,
    payload: theme,
  };
};

export const getThemeFailure = (error) => {
  return {
    type: GET_THEME_FAILURE,
    payload: error,
  };
};

export const editThemeComponent = (payload) => {
  return {
    type: EDIT_THEME_COMPONENT,
    payload,
  };
};

export const editThemeComponentSuccess = (payload) => {
  return {
    type: EDIT_THEME_COMPONENT_SUCCESS,
    payload,
  };
};

export const editThemeComponentFailure = (error) => {
  return {
    type: EDIT_THEME_COMPONENT_FAILURE,
    payload: error,
  };
};

export const editThemeCss = (payload) => {
  return {
    type: EDIT_THEME_CSS,
    payload,
  };
};

export const editThemeCssSuccess = (theme) => {
  return {
    type: EDIT_THEME_CSS_SUCCESS,
    payload: theme,
  };
};

export const editThemeCssFailure = (error) => {
  return {
    type: EDIT_THEME_CSS_FAILURE,
    payload: error,
  };
};

export const editThemeSettings = (payload) => {
  return {
    type: EDIT_THEME_SETTINGS,
    payload,
  };
};

export const editThemeSettingsSuccess = (theme) => {
  return {
    type: EDIT_THEME_SETTINGS_SUCCESS,
    payload: theme,
  };
};

export const editThemeSettingsFailure = (error) => {
  return {
    type: EDIT_THEME_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editStoreSettings = (payload) => {
  return {
    type: EDTI_STORE_SETTINGS,
    payload,
  };
};

export const editStoreSettingsSuccess = (settings) => {
  return {
    type: EDTI_STORE_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const editStoreSettingsFailure = (error) => {
  return {
    type: EDTI_STORE_SETTINGS_FAILURE,
    payload: error,
  };
};
