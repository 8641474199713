/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Form, Modal, Nav, Tab } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as ModalHeaderIcon } from "assets/svgs/product/modal-header.svg";

import { addProduct, editProduct, getSingleProduct } from "store/actions";
import { toast } from "react-toastify";
import NotificationModal from "components/products/NotificaionModal";
import StickyLoader from "components/shared/StickyLoader";
import { getId } from "helpers/functions";
import VariationTabBody from "components/products/modalTabBodies/VariationsTabBody";
import SeoTabBody from "components/products/modalTabBodies/SeoTabBody";
import MainInfoTabBody from "components/products/modalTabBodies/MainInfoTabBody";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Attachments from "components/products/modalTabBodies/Attachments";

const ProductModal = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const { units } = useSelector((state) => state.units) || [];
  const { productTypes } = useSelector((state) => state.productTypes);
  const {
    variations: variationsDataList,
    variationsStyles: displayedListType,
    loading,
    singleProduct: sngle,
    loadingSingleProduct,
  } = useSelector((state) => state.products);

  const [singleProduct, setSingleProduct] = useState({});

  useEffect(() => {
    if (id === "new") return;

    dispatch(getSingleProduct(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id !== "new") setSingleProduct(sngle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sngle]);

  const handleClose = () => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`/products/${paramsStr}`);
  };
  const {
    handleSubmit,
    reset,
    control,
    register,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const {
    fields: selectedVariations,
    append: appendSelectedVariations,
    remove,
  } = useFieldArray({
    control,
    name: "variationsOptions",
  });

  const [description, setDescription] = useState({ ar: "", en: "" });

  const [rerenderNum, setRerenderNum] = useState(0);

  const [isUnlimitedQuantity, setIsUnlimitedQuantity] = useState(false);

  const [minQuantityToNotify, setMinQuantityToNotify] = useState("");
  const [reorderQuantity, setReorderQuantity] = useState("");

  const [images, setImages] = useState([]);

  let keys = Object.keys(errors);
  const forceRerender = () => setRerenderNum((!!Date && Date.now()) || 0);

  const [productModalType, setProductModalType] = useState("");
  let handleProductModalTypeClose = () => setProductModalType("");
  // const
  const handleShow = (modalType) => {
    if (modalType === "notifyModal") {
      setProductModalType("notifyModal");
    } else if (modalType === "settingModal") {
      setProductModalType("settingModal");
    } else {
      setProductModalType("imagesModal");
    }
  };

  useEffect(() => {
    if (!images.find((img) => img?.isMain) && images?.length > 0) {
      let imagesCopy = images;
      imagesCopy[0].isMain = true;
      setImages(imagesCopy);
    }
    setValue("images", images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (getId(singleProduct) !== id) return;

    reset({
      ...singleProduct,

      type: getId(singleProduct?.type) || singleProduct?.type || "",
      brand: getId(singleProduct?.brand) || singleProduct?.brand || "",

      category: getId(singleProduct?.category) || singleProduct?.category || "",

      measurementUnit: getId(singleProduct?.measurementUnit),
      seo: {
        pageTitle: singleProduct?.seo?.pageTitle,
        pageDescription: singleProduct?.seo?.pageDescription,
      },

      variationsOptions: singleProduct?.variationsOptions
        ?.filter((e) => e?.variation !== undefined)
        //------------------getting value if it's id only------------------
        ?.map(({ variation, variationStyle, ...rest }) => ({
          ...rest,
          variation: !variation?.name
            ? variationsDataList?.find((e) => getId(e) === variation)
            : variation,
          variationStyle: !variationStyle?.name
            ? displayedListType?.find((e) => getId(e) === variationStyle)
            : variationStyle,
        }))
        ///////---------------------------
        ?.map(({ variation, variationStyle, ...rest }, index) => ({
          ...rest,
          //----------
          label: variation?.name?.[locale],
          value: variation?.name?.en,
          variation: getId(variation) || variation,
          variationStyle: getId(variationStyle) || variationStyle,
          variationStyleValue: variationStyle?.name?.en,
          variationStyleLabel: variationStyle?.name?.[locale],
          index,
        })),
      variationsOptionsValues: singleProduct?.variationsOptionsValues,
    });

    setMinQuantityToNotify(singleProduct?.minQuantityToNotify);
    setReorderQuantity(singleProduct?.reorderQuantity);
    setIsUnlimitedQuantity(singleProduct?.isUnlimitedQuantity || false);
    setDescription(singleProduct?.description);

    setImages(singleProduct?.images || []);
    forceRerender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, singleProduct]);

  const onSubmit = (data) => {
    if (!images?.length > 0) {
      setError("images", { type: "requried" });
      return toast.error("صور المنتج الاساسية مفقودة");
    }
    data.description = description;
    data.minQuantityToNotify = minQuantityToNotify;
    data.reorderQuantity = reorderQuantity;

    if (!data?.discountPrice) data.discountDate = "";

    if (isUnlimitedQuantity || data.isUnlimitedQuantity) {
      // Unlimited quantity must have quantity for backend logic, task 3539
      data.isUnlimitedQuantity = true;
      data.quantity = 10000;
    }
    data.images =
      data?.images?.map((img) => ({
        ...img,
        // image: "uploads/" + img.image.split("/").pop(),
      })) || [];

    data.variationsOptionsValues =
      data?.variationsOptionsValues?.map((varOp) => ({
        ...varOp,
        images: varOp.images?.map((img) => ({
          ...img,
          // image: "uploads/" + img.image.split("/").pop(),
        })),
      })) || [];

    data.isShown = data.isShown !== undefined ? data.isShown : true;

    if (id !== "new")
      dispatch(
        editProduct({
          data,
          id: singleProduct?.id || id,
          // callBack: handleClose,
          navigate,
        })
      );
    else
      dispatch(
        addProduct({
          data,
          // callBack: handleClose,
          navigate,
        })
      );
  };

  // if (modalType !== "settingModal") return null;
  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal"
        show={true}
        onHide={handleClose}
        size="xl"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Tab.Container
            // defaultActiveKey={0}
            defaultActiveKey={searchParams.has("variation") ? 2 : 0}
            transition={true}
            timeout={1000}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <ModalHeaderIcon fill="#FC6B14" />
                بيانات المنتج
              </Modal.Title>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    eventKey={0}
                    className={`btn ${
                      keys?.filter((k) => firstTab?.includes(k))?.length > 0
                        ? "required"
                        : ""
                    }`}
                  >
                    البيانات الاساسية
                  </Nav.Link>
                </Nav.Item>

                {!!watch("hasVariations") && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey={2}
                      className={`btn ${
                        keys?.filter((k) => thirdTab?.includes(k))?.length > 0
                          ? "required"
                          : ""
                      }`}
                    >
                      {/* النُسّخ المختلفة  */}
                      الاختلافات
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey={3}
                    className={`btn ${
                      keys?.filter((k) => fourthTab?.includes(k))?.length > 0
                        ? "required"
                        : ""
                    }`}
                  >
                    تفاصيل SEO
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={4}
                    className={`btn ${
                      // keys?.filter((k) => fourthTab?.includes(k))?.length > 0
                      //   ? "required"
                      //   :
                      ""
                    }`}
                  >
                    <FormattedMessage id="attachments" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Modal.Header>

            <Modal.Body>
              <Tab.Content>
                <Tab.Pane eventKey={0}>
                  <MainInfoTabBody
                    // product={singleProduct}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors}
                    units={units}
                    watch={watch}
                    productTypes={productTypes}
                    minQuantityToNotify={minQuantityToNotify}
                    setDescription={setDescription}
                    isUnlimitedQuantity={isUnlimitedQuantity}
                    setIsUnlimitedQuantity={setIsUnlimitedQuantity}
                    handleShow={handleShow}
                    setImages={setImages}
                    images={images}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey={2}>
                  <VariationTabBody
                    selectedVariations={selectedVariations}
                    register={register}
                    watch={watch}
                    remove={remove}
                    control={control}
                    forceRerender={forceRerender}
                    getValues={getValues}
                    setValue={setValue}
                    variationsDataList={variationsDataList}
                    errors={errors}
                    displayedListType={displayedListType}
                    appendSelectedVariations={appendSelectedVariations}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <SeoTabBody product={singleProduct} register={register} />
                </Tab.Pane>
                <Tab.Pane eventKey={4}>
                  <Attachments product={singleProduct} setValue={setValue} />
                </Tab.Pane>
              </Tab.Content>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline"
                onClick={handleClose}
              >
                إلغاء
              </button>
              <button type="submit" className="btn btn-blue">
                حفظ
              </button>
            </Modal.Footer>
          </Tab.Container>
          {(loading || loadingSingleProduct) && <StickyLoader fill="#FC6B14" />}
        </Form>
      </Modal>
      <NotificationModal
        handleClose={handleProductModalTypeClose}
        minQuantityToNotify={minQuantityToNotify}
        setMinQuantityToNotify={setMinQuantityToNotify}
        reorderQuantity={reorderQuantity}
        setReorderQuantity={setReorderQuantity}
        modalType={productModalType}
      />
    </div>
  );
};

export default ProductModal;

let firstTab = [
    "images",
    "name",
    "type",
    "price",
    "quantity",
    "category",
    "isRequiredShipping",
    "weight",
    "measurementUnit",
    "costPrice",
    "maxPerOrder",
    "discountPrice",
    "discountDate",
    "sku",
    "GTIN",
    "brand",
    "hasVariations",
    "description",
    "tags",
  ],
  // secondTab = ["tags"],
  thirdTab = ["variationsOptions", "variationsOptionsValues"],
  fourthTab = ["seo"];
