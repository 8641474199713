import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
// import { Editor } from "components/shared/Editor";
import Select from "react-select";

import { ReactComponent as TruckIcon } from "assets/svgs/product/truck.svg";
import { ReactComponent as DollarIcon } from "assets/svgs/product/dollar.svg";
import { ReactComponent as BoxIcon } from "assets/svgs/product/box.svg";
import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";
import { ReactComponent as WeightIcon } from "assets/svgs/product/box-add.svg";
import { ReactComponent as BrandIcon } from "assets/svgs/product/building.svg";
import { ReactComponent as DiscPriceIcon } from "assets/svgs/product/price.svg";
import { ReactComponent as DiscPriceDownIcon } from "assets/svgs/product/price-down.svg";
import { ReactComponent as ProductTitleIcon } from "assets/svgs/product/title.svg";
import { ReactComponent as ProductCategoryIcon } from "assets/svgs/product/category.svg";
import { ReactComponent as ProductPriceIcon } from "assets/svgs/product/price.svg";
import { ReactComponent as ProductQtyIcon } from "assets/svgs/product/quantity.svg";
import { ReactComponent as ProductQtyInfinityIcon } from "assets/svgs/product/infinity.svg";
import { ReactComponent as ProductQtyNotifyIcon } from "assets/svgs/product/notify.svg";
import { ReactComponent as ProductLinkIcon } from "assets/svgs/product/eye.svg";
import { ReactComponent as CopyLinkIcon } from "assets/builder/copy-link.svg";

import MultiUpload from "../MultiUpload";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { getId, globalToastOptions } from "helpers/functions";
import { getActiveStore } from "store/actions";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { priceInputValidations, smallProductImageSize } from "helpers/configs";
import { Editor } from "components/shared/Editor";

export default function MainInfoTabBody({
  register,
  control,
  getValues,
  errors,
  units,
  watch,
  productTypes,
  minQuantityToNotify,
  setDescription,
  isUnlimitedQuantity,
  setIsUnlimitedQuantity,
  handleShow,
  setImages,
  images,
}) {
  const { locale } = useIntl();
  const dir = locale === "ar" ? "rtl" : "ltr";
  const { id } = useParams();

  const dispatch = useDispatch();
  const { activeStore } = useSelector((state) => state.stores);
  const store = localStorage.getItem("store");
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { flatCategories } = useSelector((state) => state.productCategories);
  const { singleProduct: product, tags } = useSelector(
    (state) => state.products
  );
  const { brands } = useSelector((state) => state.brands) || [];

  const [isRequiredShipping, setIsRequiredShipping] = useState(false);
  const getOptionsByName = (arr) =>
    arr?.map((item) => ({
      label: item.name?.[locale],
      value: getId(item),
    }));

  const renderBrands = () => {
    return brands?.map((brand) => ({
      label: brand.name?.[locale],
      value: brand?.name?.[locale],
      id: getId(brand),
    }));
  };

  const renderTags = () => {
    return tags?.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    });
  };

  const renderUnits = units?.map((unit, index) => {
    return (
      <option value={unit?.id} key={index}>
        {unit.name?.[locale]}
      </option>
    );
  });

  const variationStateForInput =
    watch("hasVariations") === true ? { readOnly: true } : { required: true };

  useEffect(() => {
    dispatch(getActiveStore({ storeId: store, deviceType }));
  }, [deviceType, dispatch, store]);

  return (
    <>
      <div className="form-group">
        <div className="form-head">
          <div className="head-right">
            <i>
              <ProductTitleIcon fill="#FC6B14" />
            </i>
            <div>
              <h5>بيانات المنتج</h5>
              <span>السعر و الكمية و التصنيف ... </span>
            </div>
          </div>
          <div className={`head-left ${id === "new" ? "d-none" : ""}`}>
            <OverlayTrigger overlay={<Tooltip>مشاهدة المنتج</Tooltip>}>
              <a
                href={`${process.env.REACT_APP_FRONT_WEBSITE}/${activeStore?.slug}/products/${product?.slug}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <i>
                  <ProductLinkIcon width={20} height={20} fill="#fff" />
                </i>
              </a>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>نسخ رابط المنتج</Tooltip>}>
              <button
                type="button"
                className="link"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_FRONT_WEBSITE}/${activeStore?.slug}/products/${product?.slug}`
                  );
                  toast.success("تم نسخ الرابط بنجاح", globalToastOptions);
                }}
              >
                <i>
                  <CopyLinkIcon width={20} height={20} stroke="#fff" />
                </i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="form-body productCard image-upload-wrapper">
          <Row>
            <Col xs={12}>
              <div className="selectHolder">
                <label>
                  تفعيل الاختلافات (variations)
                  <div className="position-relative">
                    <div
                      className="switcher"
                      style={{ left: "-50%", top: "-1.5rem" }}
                    >
                      <input
                        type="checkbox"
                        className="switcher-input"
                        {...register("hasVariations")}
                      />
                      <div className="knobs"></div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </label>
              </div>
            </Col>

            <Col xs="2"></Col>

            <Col xs={8} className="multiUploadWrapper mb-5 mx-auto">
              <div className="text-center text-danger small">
                *
                <FormattedMessage
                  id="productImagesSize"
                  values={{ smallProductImageSize }}
                />
              </div>
              <Controller
                control={control}
                name={`images`}
                render={({ field: { value, onChange, ...rest } }) => (
                  <MultiUpload
                    lg="4"
                    xl="3"
                    images={images}
                    setImages={setImages}
                    smallImageCheck={true}
                  />
                )}
              />
            </Col>
            <Col xs="2"></Col>

            <Col {...inputColsBreaks}>
              <MultiLangWrapper
                label={<label>إسم المنتج</label>}
                errors={errors?.name}
                customClass="form-group required selectHolder col-12"
                required
              >
                {({ lang, classes, key }) => (
                  <input
                    key={key}
                    type="text"
                    id="productName"
                    className={`form-control ${classes}`}
                    placeholder="اسم المنتج"
                    {...register(`name.${lang}`, {
                      required: true,
                    })}
                  />
                )}
                <ProductTitleIcon fill="#E4E4E4" />
                <span className="error-hint" key="1">
                  {errors?.name && "يجب ادخال اسم المنتج"}
                </span>
              </MultiLangWrapper>
            </Col>

            <Col {...inputColsBreaks} className="form-group required">
              <h5>نوع المنتج</h5>
              <div className="">
                <div className="selectHolder w-100">
                  <div>
                    <Controller
                      control={control}
                      name="type"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, selected } }) => (
                        <Select
                          isRtl={true}
                          isSearchable={false}
                          className="basic-single"
                          classNamePrefix="select"
                          options={getOptionsByName(productTypes)}
                          placeholder="اختار نوع المنتج"
                          isMulti={false}
                          value={
                            getOptionsByName(productTypes)?.find(
                              (t) => t.value === value
                            ) || { label: "اختار نوع المنتج", value: "" }
                          }
                          onChange={(selected) => onChange(selected?.value)}
                        />
                      )}
                    />
                    <ProductTitleIcon fill="#E4E4E4" />
                  </div>
                  <span className="error-hint">
                    {errors?.type?.type === "required" &&
                      "يجب اختيار نوع المنتج"}
                  </span>
                </div>
              </div>
            </Col>

            <Col
              {...inputColsBreaks}
              className={`form-group ${
                variationStateForInput?.required ? "required" : ""
              }`}
            >
              <h5>السعر</h5>
              <div>
                <input
                  id="productWeight"
                  className="form-control"
                  {...register(`price`)}
                  {...variationStateForInput}
                  {...priceInputValidations}
                />
                <ProductPriceIcon fill="#E4E4E4" />
              </div>
              <span className="error-hint">
                {errors?.price?.type === "required" && "يجب ادخال السعر"}
              </span>
            </Col>

            <Col
              {...inputColsBreaks}
              className={`form-group p-data py-0 ${
                variationStateForInput?.required && isUnlimitedQuantity !== true
                  ? "required"
                  : ""
              }`}
            >
              <h5>الكمية</h5>
              <div className="form-group flex-column">
                <div className="d-flex justify-content-between w-100">
                  <input
                    type="number"
                    className="form-control"
                    id="quantity"
                    placeholder="1"
                    {...register("quantity", { min: 1 })}
                    {...variationStateForInput}
                    {...priceInputValidations}
                    {...(isUnlimitedQuantity === true
                      ? { required: false }
                      : {})}
                    // disabled={isUnlimitedQuantity === true}
                  />
                  <ProductQtyIcon fill="#E4E4E4" />
                  {isUnlimitedQuantity === true ? (
                    <span className="unlimited-qty"> الكمية غير محدودة</span>
                  ) : null}
                  <div className="qty">
                    <label
                      className={`my-0 no-after ${
                        isUnlimitedQuantity === true ? "active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        {...register("isUnlimitedQuantity", {
                          // onchange: (e) => {
                          //   console.log("isUnlimitedQuantity", e);
                          //   setIsUnlimitedQuantity(e.target.checked);
                          // },
                        })}
                        // {...variationStateForRegister()}
                        {...variationStateForInput}
                        required={false}
                        onChange={(e) => {
                          setIsUnlimitedQuantity(e.target.checked);
                        }}
                      />
                      <ProductQtyInfinityIcon
                        fill={isUnlimitedQuantity === true ? "#fff" : "#FC6B14"}
                      />
                    </label>

                    <span>خيارات الكمية</span>
                  </div>
                </div>
                <span className="error-hint">
                  {errors?.quantity?.type === "required" && "يجب ادخال الكمية"}
                  {errors?.quantity?.type === "min" && (
                    <FormattedMessage id="minQuantityValue" />
                  )}
                </span>
              </div>
            </Col>
            <Col {...inputColsBreaks} className="p-data py-0">
              <label htmlFor="">تنبيه الكمية</label>
              <div className="position-relative form-group">
                <div
                  className="d-flex justify-content-between w-100 align-items-center cursor-pointer"
                  onClick={() => handleShow("notifyModal")}
                >
                  <ProductQtyIcon fill="#E4E4E4" />
                  <div className="form-control d-flex align-items-center">
                    {minQuantityToNotify?.length > 0 || minQuantityToNotify > 0
                      ? `اقل كمية للتنبيه : ${minQuantityToNotify}`
                      : "غير مفعل"}
                  </div>
                  <div className="qty">
                    <button type="button">
                      <ProductQtyNotifyIcon fill="#FC6B14" />
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col {...inputColsBreaks} className="form-group required">
              <h5>
                <FormattedMessage id="productCategory" />
              </h5>
              <div>
                <div className="selectHolder w-100">
                  <Controller
                    control={control}
                    name="category"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, selected } }) => (
                      <Select
                        isRtl={true}
                        isSearchable={false}
                        className="basic-single"
                        classNamePrefix="select"
                        options={getOptionsByName(flatCategories)}
                        placeholder="اختار تصنيف المنتج"
                        isMulti={false}
                        value={getOptionsByName(flatCategories)?.find(
                          (t) => t.value === value
                        )}
                        onChange={(selected) => {
                          onChange(selected?.value);
                        }}
                      />
                    )}
                  />
                  <ProductCategoryIcon fill="#E4E4E4" />
                  <span className="error-hint">
                    {errors?.category?.type === "required" &&
                      "يجب اختيار نوع المنتج"}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="form-group">
        <div className="form-head">
          <i>
            <TruckIcon fill="#FC6B14" />
          </i>
          <div>
            <h5>معلومات الشحن</h5>
            <span>معلومات شركة الشحن والوزان .. </span>
          </div>
        </div>
        <div className="form-body">
          <Row>
            <Col {...inputColsBreaks}>
              <label htmlFor="askShip">يتطلب شحن / تـوصيل ؟</label>
              <div>
                <select
                  id="askShip"
                  className="form-control"
                  {...register("isRequiredShipping")}
                  onChange={(e) => {
                    setIsRequiredShipping(e.target.value);
                  }}
                >
                  <option value={false}>لا</option>
                  <option value={true}>نعم</option>
                </select>
                <TruckIcon fill="#E4E4E4" />
              </div>
            </Col>
            <Col
              {...inputColsBreaks}
              className={`form-group ${!!isRequiredShipping ? "required" : ""}`}
            >
              <h5>وزن الـمنتج</h5>
              <div>
                <input
                  type="number"
                  id="productWeight"
                  className="form-control"
                  placeholder="0"
                  {...register("weight")}
                  {...(!!isRequiredShipping ? { required: true } : {})}
                />
                <WeightIcon fill="#E4E4E4" />
                <select
                  className="form-control extraSelect"
                  {...register("measurementUnit")}
                  //   onChange={(e) => {
                  //     setChangeWeight(e.target.value);
                  //   }}
                >
                  {renderUnits}
                </select>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="form-group">
        <div className="form-head">
          <i>
            <DollarIcon fill="#FC6B14" />
          </i>
          <div>
            <h5>الســعر والكمية</h5>
            <span>معلومات شركة الشحن والوزان .. </span>
          </div>
        </div>
        <div className="form-body">
          <Row>
            <Col {...inputColsBreaks}>
              <label htmlFor="">سـعر التكلفة</label>
              <div>
                <input
                  className="form-control"
                  {...register("costPrice", {})}
                  {...variationStateForInput}
                  {...priceInputValidations}
                  required={false}
                />
                <DollarIcon fill="#E4E4E4" />
              </div>
            </Col>
            <Col {...inputColsBreaks}>
              <label htmlFor="">اقصي كمية لكل عميل</label>
              <div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="1"
                  min="1"
                  {...register("maxPerOrder")}
                  {...variationStateForInput}
                  required={false}
                />
                <DollarIcon fill="#E4E4E4" />
              </div>
            </Col>
            <Col {...inputColsBreaks}>
              <label htmlFor="">سعر التخفيض</label>
              <div>
                <input
                  className="form-control"
                  {...register("discountPrice")}
                  {...variationStateForInput}
                  required={false}
                  {...priceInputValidations}
                />
                <DiscPriceIcon fill="#E4E4E4" />
              </div>
            </Col>
            <Col
              {...inputColsBreaks}
              className={`form-group ${
                (getValues("discountPrice")?.length > 0
                  ? { ...variationStateForInput }
                  : {}
                )?.required
                  ? "required"
                  : ""
              }`}
            >
              <h5>نهاية التخفيض</h5>

              <div>
                <Controller
                  control={control}
                  name="discountDate"
                  rules={{
                    ...(getValues("discountPrice")?.length > 0
                      ? { ...variationStateForInput }
                      : {}),
                  }}
                  // {...variationStateForInput}
                  render={({ field: { onChange } }) => (
                    <Flatpickr
                      className="form-control form-outline"
                      placeholder="تاريخ الانتهاء"
                      onChange={(date) => onChange(date)}
                      value={product?.discountDate}
                      options={{ allowInput: true }}
                      {...variationStateForInput}
                      required={false}
                    />
                  )}
                />
                <DiscPriceDownIcon fill="#E4E4E4" />
              </div>
              <span className="error-hint">
                {errors?.discountDate?.type === "required" &&
                  "يجب اختيار تاريخ انتهاء التخفيض "}
              </span>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              className={`form-group ${
                variationStateForInput?.required ? "required" : ""
              }`}
            >
              <h5>رمز التخزين (SKU)</h5>
              <div>
                <input
                  type="text"
                  id=""
                  className="form-control"
                  placeholder="رمز التخزين SKU"
                  {...register("sku", {})}
                  {...variationStateForInput}
                />
                <StockIcon fill="#E4E4E4" />
                <span className="error-hint">
                  {errors?.sku?.type === "required" && "يجب ادخال sku"}
                </span>
              </div>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              className={`form-group ${
                variationStateForInput?.required ? "required" : ""
              }`}
            >
              <h5>رقم القطعة حسب الشركة المصنّعة (MPN)</h5>
              <div>
                <input
                  type="text"
                  id=""
                  className="form-control"
                  placeholder="MPN"
                  {...register("MPN", {})}
                  {...variationStateForInput}
                />
                <StockIcon fill="#E4E4E4" />
              </div>
              <span className="error-hint">
                {errors?.MPN?.type === "required" && "يجب ادخال MPN"}
              </span>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              className={`form-group ${
                variationStateForInput?.required ? "required" : ""
              }`}
            >
              <h5>الرقم التجاري العالمي للسلعة (GTIN)</h5>
              <div>
                <input
                  type="text"
                  id=""
                  className="form-control"
                  placeholder="GTIN"
                  {...register("GTIN", {})}
                  {...variationStateForInput}
                />
                <StockIcon fill="#E4E4E4" />
              </div>
              <span className="error-hint">
                {errors?.GTIN?.type === "required" && "يجب ادخال GTIN"}
              </span>
            </Col>
            <Col {...inputColsBreaks} className={`form-group required`}>
              <h5>العلامة التجارية</h5>
              <div className="form-group">
                <div className="selectHolder w-100">
                  <div>
                    <Controller
                      control={control}
                      name="brand"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, selected } }) => (
                        <Select
                          isRtl={true}
                          isSearchable={false}
                          className="basic-single"
                          classNamePrefix="select"
                          options={renderBrands()}
                          placeholder="اختار العلامة التجارية"
                          isMulti={false}
                          value={renderBrands()?.find((op) => op?.id === value)}
                          onChange={(selected) => {
                            onChange(selected?.id);
                          }}
                        />
                      )}
                    />
                    <BrandIcon fill="#E4E4E4" />
                    <span className="error-hint">
                      {errors?.brand?.type === "required" &&
                        "يجب ادخال الماركة"}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="form-group">
        <div className="form-head">
          <i>
            <BoxIcon fill="#FC6B14" />
          </i>
          <div>
            <h5>تفاصيل المنتج</h5>
            <span>معلومات المنتج الاضافية </span>
          </div>
        </div>
        <div className="form-body">
          <Row>
            <Col xs={12}>
              <div className="controllerHolder">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value } }) => (
                    <Editor
                      initialValue={value}
                      onChange={(e, editor) => {
                        const data = editor.getContent();
                        setDescription(data);
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className="selectHolder">
                <label>الكلمات الدلالية</label>

                <div>
                  <Controller
                    control={control}
                    name="tags"
                    defaultValue=""
                    render={({
                      field: { onChange, value: selectedValues },
                    }) => (
                      <CreatableSelect
                        isMulti
                        isRtl={true}
                        className="basic-single creatable-select"
                        classNamePrefix="select"
                        placeholder="اضف كلمات دلالية"
                        options={renderTags()}
                        value={(selectedValues || [])?.map((e) => ({
                          label: e,
                          value: e,
                        }))}
                        onChange={(values) => {
                          onChange(values?.map((v) => v?.value));
                        }}
                      />
                    )}
                  />
                  <StockIcon fill="#E4E4E4" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <NotificationModal
        handleClose={handleProductModalTypeClose}
        minQuantityToNotify={minQuantityToNotify}
        setMinQuantityToNotify={setMinQuantityToNotify}
        reorderQuantity={reorderQuantity}
        setReorderQuantity={setReorderQuantity}
        modalType={productModalType}
      /> */}
    </>
  );
}
export const inputColsBreaks = { lg: 4, md: 6, sm: 12, xs: 12 };
