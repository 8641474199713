import { getId } from "helpers/functions";
import { Dropdown } from "react-bootstrap";
import { deleteProductCategories } from "store/actions";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as AddIcon } from "assets/svgs/plus.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export default function DropdownWrapper({ children, setModalProps, data }) {
  const dispatch = useDispatch();

  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: "تاكيد حذف التصنيف",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProductCategories(id));
      }
    });
  };
  return (
    <div id={data?.slug || ""}>
      <Dropdown>
        {children}

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              setModalProps({
                show: true,
                add: true,
                category: {
                  parentCategory: getId(data),
                },
              })
            }
          >
            <AddIcon fill="#FC6B14" />
            إضافة تصنيف
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              setModalProps({
                show: true,
                category: {
                  ...data,
                  id: getId(data),
                  options: getId(data?.data),
                },
              })
            }
          >
            <EditIcon fill="#FC6B14" />
            تعديل التصنيف
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleDeleteCategory(getId(data));
            }}
          >
            <DeleteIcon fill="#f00" />
            حذف التصنيف
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
