import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadCrumb from "components/shared/BreadCrumb";
import Table from "./Table";

import filterIcon from "assets/svgs/filter.svg";
import CouponModal from "./CouponModal";
import {
  getBrands,
  getCoupons,
  getProductCategories,
  getProducts,
  getCustomers,
} from "store/actions";
import CustomersFilterSidebar from "components/customers/CustomersFilterSidebar";
import { getId } from "helpers/functions";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(false);
  const { coupons } = useSelector((state) => state.coupons);
  const [modalType, setModalType] = useState("add");
  const [selectedCoupon, setSelectedCoupon] = useState({});

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getProducts());
    dispatch(getCustomers());
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    let searchParams1 = new URLSearchParams(searchParams);
    searchParams1.delete("modal");
    const paramsStr =
      searchParams1?.size > 0 ? `?${searchParams1.toString()}` : "";
    dispatch(getCoupons(paramsStr));
  }, [dispatch, searchParams]);

  const filterToggle = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const getSelectedCoupon = (couponId) => {
    if (!couponId) return {};
    return coupons.find((coupon) => getId(coupon) === couponId) || {};
  };

  const handleShow = (couponId) => {
    console.log("couponId", couponId);
    if (couponId) {
      setModalType(() => "edit");
      setSelectedCoupon(getSelectedCoupon(couponId));
    } else {
      setModalType(() => "add");
    }
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedCoupon({});
  };

  return (
    <>
      <div className="orders-wrap coupons-wrap">
        <BreadCrumb pageName="الكوبونات" />
        <div className="ordersActions">
          <button className="btn btn-blue" onClick={() => handleShow()}>
            إضافة كوبون +
          </button>
          <div className="ordersServices">
            <button className="btn btn-black" onClick={filterToggle}>
              <img src={filterIcon} alt="settings" />
              فلترة النتـائج
            </button>
          </div>
        </div>
        <Table coupons={coupons} handleShow={handleShow} />
        <div className="overlay-g" onClick={filterToggle}></div>
        <CustomersFilterSidebar
          href="/coupons"
          searchParams={searchParams}
          filtersObj={{
            type: {
              type: "radio",
              data: ["percent", "amount"],
            },
            status: {
              type: "radio",
              data: ["active", "inactive", "expired", "notStarted"],
            },
            sortBy: {
              type: "radio",
              data: ["newest", "oldest", "startDate", "endDate", "mostUsed"],
            },
          }}
        />

        <CouponModal
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          coupon={selectedCoupon}
          type={modalType}
        />
      </div>
    </>
  );
};

export default Index;
