import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getFeaturesApi } from "api/features";

// Login Redux States
import { GET_FEATURES } from "./actionTypes";

import { getFeaturesFailure, getFeaturesSuccess } from "./actions";

function* getFeatures() {
  try {
    const { data } = yield call(getFeaturesApi);
    yield put(getFeaturesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getFeaturesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetFeatures() {
  yield takeEvery(GET_FEATURES, getFeatures);
}

function* featuresSaga() {
  yield all([fork(watchGetFeatures)]);
}

export default featuresSaga;
