import { Input } from "components/shared/FormComponents";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";

const StoreInfo = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state?.settings);

  const {
    register: registerSocials,
    handleSubmit: handleSubmitSocials,
    reset: resetSocials,
    formState: { errors: errorsSocials },
  } = useForm();

  useEffect(() => {
    resetSocials(data);
  }, [resetSocials, data]);

  const onSubmitSocials = (data) => {
    dispatch(
      editStoreSlugSetting({
        data,
        slug: "basicSettings/updateSocials",
        navigate,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="acc-form">
      {/* <div className="card-head">
        <h4>روابط التواصل</h4>
      </div> */}
      <form onSubmit={handleSubmitSocials(onSubmitSocials)}>
        <Col xl={10} md={12}>
          <Row>
            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>فيسبوك</h5>
                <div>
                  <Input
                    name="facebook"
                    register={registerSocials}
                    errors={errorsSocials}
                    rules={{ required: false }}
                  />
                </div>
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>تويتر</h5>
                <div>
                  <Input
                    name="twitter"
                    register={registerSocials}
                    errors={errorsSocials}
                    rules={{ required: false }}
                  />
                </div>
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>انستاجرام</h5>
                <div>
                  <Input
                    name="instgram"
                    register={registerSocials}
                    errors={errorsSocials}
                    rules={{ required: false }}
                  />
                </div>
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>تيليجرام</h5>
                <div>
                  <Input
                    name="telegram"
                    register={registerSocials}
                    errors={errorsSocials}
                    rules={{ required: false }}
                  />
                </div>
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>واتساب</h5>
                <div>
                  <Input
                    name="whatsup"
                    register={registerSocials}
                    errors={errorsSocials}
                    rules={{ required: false }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={12}>
              <div className="form-group">
                <button type="submit" className="btn btn-blue">
                  حفظ التعديلات
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </div>
  );
};

export default StoreInfo;
