import { getId } from "helpers/functions";
import React from "react";
import { Link } from "react-router-dom";
import {
  XAxis,
  CartesianGrid,
  Tooltip,
  // AreaChart,
  // Area,
  ResponsiveContainer,
  LineChart,
  Line,
  YAxis,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import angle from "assets/svgs/angle-left.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVisitsReport } from "store/actions";
import { Col, Row } from "react-bootstrap";

const dataLine = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data02 = [
  { name: "A2", value: 200 },
  { name: "B1", value: 100 },
  { name: "B2", value: 80 },
  { name: "B3", value: 40 },
  { name: "B4", value: 30 },
  { name: "B5", value: 50 },
];

const COLORS = [
  "#3D3742",
  "#73A1B0",
  "#E5846F",
  "#CAD275",
  "#949373",
  "#C8C7C9",
];
const RADIAN = Math.PI / 180;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ages = ["Under18", "18_24", "24_30", "30_40", "40_50", "Plus60"];

function getWeekMonth(weekIndex) {
  // Create a date object for the first day of the year

  const currentDate = new Date(),
    currentYear = currentDate.getFullYear();

  const startDate = new Date(currentYear, 0, 1);

  // Calculate the offset to the first Monday of the year
  startDate.setDate(startDate.getDate() + ((7 - startDate.getDay() + 1) % 7));

  // Calculate the date of the desired week
  const desiredDate = new Date(startDate);
  desiredDate.setDate(desiredDate.getDate() + (weekIndex - 1) * 7);

  // Extract the month and week information
  const month = desiredDate.getMonth() + 1; // Month is zero-based, so add 1
  const week = Math.ceil(desiredDate.getDate() / 7);

  return `Week ${week} of ${months?.[month - 1]}`;
}

const getOldNewDate = (data, type) => {
  if (type === "week")
    return data?.visitorsTypeweeks?.map((month) => ({
      ...month,
      name: getWeekMonth(month?._id),
    }));
  if (type === "month")
    return data?.visitorsTypeMonthly?.map((month) => ({
      ...month,
      name: months?.[month?._id],
    }));
  if (type === "year")
    return data?.visitorsTypeYears?.map((month) => ({
      ...month,
      name: month?._id,
    }));
};

// eslint-disable-next-line no-empty-pattern
const Reports = ({}) => {
  const monthSummary = [];

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.visitsReport);

  const [oldNewChartFilter, setOldNewChartFilter] = useState("month");

  // months chart-------------------------------------------------
  const dataOldNew = getOldNewDate(data?.[0], oldNewChartFilter);

  // recurring weekly visits
  // const dataWeekly = data?.[0]?.visitorsTypeweeks?.map((week) => ({
  //   ...week,
  //   name: getWeekMonth(week),
  // }));

  // gender and type chart---------------------------------------
  const genderData = data?.[0]?.visitorsAgeAndGender?.[0];
  const genderDataKeys = Object.keys(genderData || []);
  const dataGenderType = ages?.map((data) => ({
    name: data,
    female:
      genderData?.[
        genderDataKeys?.find((e) => e?.includes(data) && e?.includes("female"))
      ],
    male: genderData?.[
      genderDataKeys?.find(
        (e) =>
          e?.includes(data) && e?.includes("male") && !e?.includes("female")
      )
    ],
  }));

  useEffect(() => {
    dispatch(getVisitsReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  for (let index = 1; index <= 31; index++) {
    const currentDay = data?.find((item) => getId(item) === index);
    monthSummary?.push({
      name: "Day " + index,
      orderCount: currentDay?.totalCount || 0,
      totalOrderValue: currentDay?.totalOrders || 0,
    });
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderOldNewFilters = ["week", "month", "year"].map((filter, index) => (
    <div
      className={oldNewChartFilter === filter ? "active" : ""}
      onClick={() => setOldNewChartFilter(filter)}
      key={index}
    >
      {filter}
    </div>
  ));

  return (
    <Row className="reports">
      {/* lines chart */}
      {/* ---------------------------|| Monthly counts ||------------------------------ */}
      <Col xs="6" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>الزيارات حسب الشهر و حالتها</h4>
            <div className="chart-filter">{renderOldNewFilters}</div>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={500}
                data={dataOldNew}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="totalNewVisitorCount"
                  aria-label={"total new visitors count"}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="totalOldVisitorCount"
                  stroke="#82ca9d"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* bar chart */}
      {/* ---------------------------|| Recurring counts per day ||------------------------------ */}

      <Col xs="6" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              معدل التردد حسب الايام
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={500}
                data={dataLine}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="30%" stop-color="#FC6B14" />
                    <stop offset="760%" stop-color="#E74C3C" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="uv"
                  fill="url(#gradient)"
                  radius={[100, 100, 0, 0]}
                  background={{ fill: "#f8f8ff3b", radius: 100 }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* horizontal bars */}
      {/* ---------------------------|| Operating system counts ||------------------------------ */}

      <Col xs="4" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              الزيارات حسب نوع نظام التشغيل
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                width={500}
                height={500}
                barSize={30}
                data={dataLine}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="30%" stop-color="#FC6B14" />
                    <stop offset="760%" stop-color="#E74C3C" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis dataKey="name" textAnchor="middle" type="category" />
                <XAxis axisLine={false} type="number" />
                <Tooltip />
                <Legend />

                <Bar
                  dataKey="uv"
                  radius={[0, 100, 100, 0]}
                  background={{ fill: "#f8f8ff3b", radius: 100 }}
                >
                  {dataLine?.map((_, index) => (
                    <Cell fill={!index ? "url(#gradient)" : ""} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* pie progress */}
      {/* ---------------------------|| Most active users ||------------------------------ */}

      <Col xs="4" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              المستخدمين الاكثر نشاطا
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieProgress progress={65} size={300} strokeWidth={40} />
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* horizontal bars */}
      {/* ---------------------------|| Browsers users ||------------------------------ */}

      <Col xs="4" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              الزيارات حسب نوع المتصفح
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                width={500}
                height={500}
                barSize={30}
                data={dataLine}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="30%" stop-color="#FC6B14" />
                    <stop offset="760%" stop-color="#E74C3C" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis dataKey="name" textAnchor="middle" type="category" />
                <XAxis axisLine={false} type="number" />
                <Tooltip />
                <Legend />

                <Bar
                  dataKey="uv"
                  radius={[0, 100, 100, 0]}
                  background={{ fill: "#f8f8ff3b", radius: 100 }}
                >
                  {dataLine?.map((_, index) => (
                    <Cell fill={!index ? "url(#gradient)" : ""} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* overlapping stacked multi color bars */}
      {/* ---------------------------|| Gender and age users ||------------------------------ */}

      <Col xs="6" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              العمر والنوع
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={dataGenderType}
                // barGap={-50}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="gradient1" gradientTransform="rotate(0)">
                    <stop offset="20%" stop-color="#E74C3C" />
                    <stop offset="300%" stop-color="#FC6B14" />
                  </linearGradient>
                  <linearGradient id="gradient2" gradientTransform="rotate(60)">
                    <stop offset="20%" stop-color="#EE7CAC" />
                    <stop offset="300%" stop-color="#C6457B" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" xAxisId={0} />
                <XAxis dataKey="name" xAxisId={1} hide />
                <YAxis yAxisId={0} dataKey="male" />
                <YAxis yAxisId={1} dataKey="female" hide />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="male"
                  stackId="b"
                  fill="url(#gradient1)"
                  radius={[100, 100, 0, 0]}
                  width={25}
                  barSize={80}
                  xAxisId={1}
                  yAxisId={1}
                />
                <Bar
                  dataKey="female"
                  stackId="a"
                  fill="url(#gradient2)"
                  radius={[100, 100, 0, 0]}
                  barSize={80}
                  xAxisId={0}
                  yAxisId={0}
                  fillOpacity={0.7}
                  background={{ fill: "#f8f8ff3b", radius: [100, 100, 0, 0] }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* pie chart */}
      {/* ---------------------------|| sources of recurring users ||------------------------------ */}

      <Col xs="6" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              مصدر تردد الزيارات
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={data02}
                  cx="50%"
                  cy="50%"
                  label={renderCustomizedLabel}
                  labelLine={false}
                  // outerRadius={80}
                  // fill="#8884d8"
                  dataKey="value"
                >
                  <Tooltip />
                  {data02.map((entry, index) => (
                    <Cell key={index} fill={COLORS?.[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* horizontal bars */}

      <Col xs="5" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              التقرير حسب اللغة
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                width={500}
                height={500}
                data={data?.[0]?.visitorsLangauge}
                barSize={30}
                // margin={{
                //   top: 5,
                //   right: 30,
                //   left: 20,
                //   bottom: 5,
                // }}
              >
                <defs>
                  <linearGradient id="gradient3" gradientTransform="rotate(0)">
                    <stop offset="30%" stop-color="#FC6B14" />
                    <stop offset="760%" stop-color="#E74C3C" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis dataKey="_id" textAnchor="middle" type="category" />
                <XAxis axisLine={false} type="number" />
                <Tooltip />
                <Legend />

                <Bar
                  dataKey="totalCount"
                  radius={[0, 100, 100, 0]}
                  background={{ fill: "#f8f8ff3b", radius: 100 }}
                >
                  {data?.[0]?.visitorsLangauge?.map((_, index) => (
                    <Cell fill={!index ? "url(#gradient3)" : ""} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      {/* multi line bars per data entry */}
      {/* ---------------------------|| Gender and age users ||------------------------------ */}

      <Col xs="7" style={{ flex: "0.5 auto" }}>
        <div className="card">
          <div className="card-head">
            <h4>
              ملخص الشهر
              <Link to="/reports">
                المزيد من التقارير
                <img src={angle} alt="arrow" />
              </Link>
            </h4>
          </div>

          <div
            className="chart-body barChart"
            style={{ width: "100%", height: 500 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={dataLine}
                barSize={10}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="bottom" />
                <Bar dataKey="pv" fill="#E5846F" radius={[20, 20, 0, 0]} />
                <Bar
                  dataKey="uv"
                  fill="url(#gradient)"
                  radius={[20, 20, 0, 0]}
                />
                <Bar dataKey="amt" fill="#CE8484" radius={[20, 20, 0, 0]} />
                <Bar dataKey="uv" fill="#CAD275" radius={[20, 20, 0, 0]} />
                <Bar dataKey="amt" fill="#383C9B" radius={[20, 20, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const PieProgress = ({ progress, size = 200, strokeWidth = 10 }) => {
  const data = [{ value: 100 - progress }, { value: progress }];

  const COLORS = ["#F8F8FF", "url(#gradient)"];

  return (
    <PieChart width={size} height={size} style={{ maxWidth: "100%" }}>
      <defs>
        <linearGradient id="gradient">
          <stop offset="30%" stop-color="#FC6B14" />
          <stop offset="70%" stop-color="#E74C3C" />
        </linearGradient>
        <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="1 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="4" />
          <feOffset dx="0" dy="5" result="offsetblur" />
          <feFlood flood-color="rgba(0, 0, 0,0.15)" result="color" />
          <feComposite in2="offsetblur" operator="in" />
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
      </defs>
      <Pie
        data={data}
        // cx={size / 2}
        // cy={size / 2}
        innerRadius={(size - strokeWidth) / 2}
        outerRadius={size / 2}
        cornerRadius={100}
        paddingAngle={-20}
        startAngle={60}
        endAngle={-300}
        dataKey="value"
        // style={{ maxWidth: "20vw" }}
      >
        <Tooltip />
        <Legend />

        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
            filter={!index && "url(#inset-shadow)"}
            style={{
              maxWidth: "100%",
              // borderRadius: "0",
              //   filter:
              // index && "drop-shadow( 0px -11px 11px rgba(0, 0, 0, 0.8))",
            }}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

export default Reports;
