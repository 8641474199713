import { Input } from "components/shared/FormComponents";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";

const StoreSupport = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state?.settings);

  const {
    register: registerSupport,
    handleSubmit: handleSubmitSupport,
    reset: resetSupport,
    formState: { errors: errorsSupport },
  } = useForm();

  useEffect(() => {
    resetSupport(data);
  }, [resetSupport, data]);

  const onSubmitSupport = (data) => {
    dispatch(
      editStoreSlugSetting({
        data,
        slug: "basicSettings/technicalSupport",
        navigate,
      })
    );
  };
  if (loading) return <Loader />;
  return (
    <div className="acc-form">
      {/* <div className="card-head">
        <h4>الدعم الفني</h4>
      </div> */}
      {/* done */}
      <form onSubmit={handleSubmitSupport(onSubmitSupport)}>
        <Col xl={10} md={12}>
          <Row>
            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>الواتس اب</h5>
                <Input
                  name="whatsup"
                  register={registerSupport}
                  errors={errorsSupport}
                  // rules={{ required: true }}
                />
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>الهاتف</h5>
                <Input
                  name="phone"
                  register={registerSupport}
                  errors={errorsSupport}
                  // rules={{ required: true }}
                />
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>تليجرام</h5>
                <Input
                  name="telegram"
                  register={registerSupport}
                  errors={errorsSupport}
                  // rules={{ required: true }}
                />
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="form-group required">
                <h5>البريد الالكتروني</h5>
                <Input
                  name="email"
                  register={registerSupport}
                  errors={errorsSupport}
                  // rules={{ required: true }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={12}>
              <div className="form-group required mt-2">
                <button type="submit" className="btn btn-blue">
                  حفظ التعديلات
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </div>
  );
};

export default StoreSupport;
