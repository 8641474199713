import { useCallback } from "react";
import DndElement from "./DndElement";
import DropdownWrapper from "./DropDownWrapper";

export default function DndList({
  listClass = "",
  categories,
  parentSlugPath,
  parentIndex = null,
  setModalProps,
}) {
  const renderCategories = useCallback(() => {
    return categories
      ?.sort((a, b) => a?.order - b?.order)
      ?.map((data, index) => (
        <DropdownWrapper setModalProps={setModalProps} data={data} key={index}>
          <div
            className={parentSlugPath === "wrapper" ? "wrapper-element" : ""}
          >
            <input
              key={index}
              type="checkbox"
              className=" sub-cats-check"
              id={parentSlugPath + data?.slug + "check"}
              defaultChecked={parentSlugPath === "wrapper"}
            />
            <DndElement
              parentSlugPath={parentSlugPath}
              category={data}
              brothersCategories={categories}
              index={index}
              parentIndex={parentIndex != null ? parentIndex : index}
              setModalProps={setModalProps}
              catsLength={categories?.length}
            />
            <div>
              <DndList
                listClass="sub-list"
                parentSlugPath={[parentSlugPath, data?.slug].join("-")}
                categories={data?.subcategories?.sort(
                  (a, b) => a?.order - b?.order
                )}
                parentIndex={parentIndex != null ? parentIndex : index}
                setModalProps={setModalProps}
              />
            </div>
          </div>
        </DropdownWrapper>
      ));
  }, [categories, parentSlugPath, parentIndex, setModalProps]);

  return (
    <>
      {categories?.length > 0 && (
        <div className={listClass}>
          <div>{renderCategories()}</div>
        </div>
      )}
    </>
  );
}
