/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getProductCategories,
  getProducts,
  getSettings,
} from "store/actions";
import Loader from "components/shared/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as SearchIcon } from "assets/svgs/settings/search-normal.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/svgs/product/settings.svg";
import { ReactComponent as GlobIcon } from "assets/svgs/product/global-edit.svg";
import { ReactComponent as TruckIcon } from "assets/svgs/product/truck.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow.svg";
import BreadCrumb from "components/shared/BreadCrumb";
import TransfereToTjaaraModal from "components/settings/storeDomain/TransfereToTjaaraModal";
import LinkDomainModal from "components/settings/storeDomain/LinkDomainModal";
import useSearchFormParam from "components/shared/SearchHook";

const StoreDomain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [params] = useSearchParams();
  const { formatMessage } = useIntl();
  const [registerSearch, handleSubmitSearch, params, EmptyButton] =
    useSearchFormParam();

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("shippingSettings"));
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getProductCategories());
  }, [dispatch]);

  const shipping = settings?.shipping;

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const renderSearchResults = (settings?.domainsSearch || [1, 2, 3]).map(
    (item, index) => (
      <Col xs="12" md="6" key={index}>
        <div className="result">
          <h5>{item}</h5>
          <button className="btn btn-blue">
            <FormattedMessage id="getIt" />
          </button>
        </div>
      </Col>
    )
  );

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page">
      <BreadCrumb
        pageName={formatMessage({
          id: "storeDomain",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
      <div className="card productDataModal">
        <div className="card-head form-group mb-0">
          <div className="form-head mb-0">
            <i>
              <GlobIcon />
            </i>
            <div>
              <h4 className="mb-0">
                <FormattedMessage id="storeDomainSettings" />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="acc-form card shipping-methods store-domains">
        <h5 className="text-center">
          <FormattedMessage id="domainOffer" />
        </h5>
        <form className="text-center" onSubmit={handleSubmitSearch}>
          <div className="form-control">
            <SearchIcon className="search" />
            <input type="search" name="" id="" {...registerSearch("txt")} />
            {/* <CloseIcon className="close" /> */}
            <EmptyButton />
          </div>
          <button className="btn btn-blue">
            <FormattedMessage id="search" />
          </button>
        </form>
        <div className="domain-types">
          <div>
            Com.
            <GlobIcon fill="#FC6B14" />
          </div>
          <div>
            Online.
            <GlobIcon fill="#FC6B14" />
          </div>
          <div>
            Shop.
            <GlobIcon fill="#FC6B14" />
          </div>
          <div>
            Blog.
            <GlobIcon fill="#FC6B14" />
          </div>
          <div>
            Design.
            <GlobIcon fill="#FC6B14" />
          </div>
          <div>
            Tech.
            <GlobIcon fill="#FC6B14" />
          </div>
        </div>
        <hr />
        <div className="search-results">
          <h5>
            <FormattedMessage id="getRecommendedDomain" />:
          </h5>
          <Row className="results"> {renderSearchResults}</Row>
        </div>
      </div>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="2" className="delivery-companies">
          <div className="acc-form card shipping-methods">
            {/* -------------------------------------- */}
            <Accordion.Header>
              <div className="card-head m-0 productDataModal">
                <div className="form-group mb-0">
                  <div className="form-head mb-0">
                    <i>
                      <SettingsIcon width="35" height="35" />
                    </i>
                    <div>
                      <h4>
                        <FormattedMessage id="domainSettings" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="items settings-cards">
                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "transfere-domain")}
                >
                  <div className="icon">
                    <GlobIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="transfereToTjaara" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="transfereToTjaaraDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "link-domain")}
                >
                  <div className="icon">
                    <TruckIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="linkOutsideTjaara" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="linkOutsideTjaaraDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>
      </Accordion>

      <TransfereToTjaaraModal
        show={params?.get("modal") === "transfere-domain"}
      />
      <LinkDomainModal show={params?.get("modal") === "link-domain"} />
    </div>
  );
};

export default StoreDomain;
