import {
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILURE,
} from "./actionTypes";

export const getProductTypes = () => {
  return {
    type: GET_PRODUCT_TYPES,
  };
};

export const getProductTypesSuccess = (productTypes) => {
  return {
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: productTypes,
  };
};

export const getProductTypesFailure = (error) => {
  return {
    type: GET_PRODUCT_TYPES_FAILURE,
    payload: error,
  };
};
