import React from "react";
import { useDrop } from "react-dnd";
import { useEffect } from "react";
import { getId } from "helpers/functions";
import { useDispatch } from "react-redux";
import { editProductCategories } from "store/actions";

const DropZone = ({ children, appendItem, deepUpdateSubCat }) => {
  const dispatch = useDispatch();
  const [{ isOver, canDrop, isOverCurrent }, drop] = useDrop({
    accept: "element",
    drop: (source, monitor) => {
      if (monitor.didDrop() || !canDrop) {
        return;
      }

      if (source?.parentSlugPath !== "wrapper")
        return editCategory({
          id: getId(source),
          data: {
            // ...source,
            icon: source?.icon,
            image: source?.image,
            name: source?.name,
            seo: source?.seo,
            parentCategory: null,
          },
        });
    },
    canDrop: (source, monitor) => {
      return source?.parentSlugPath !== "wrapper";
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });
  const editCategory = (data) => {
    dispatch(editProductCategories(data));
  };
  // const borderColor =
  //   isOver && isOverCurrent ? "rgba(186, 186, 186, 0.679)" : "transparent";
  const outline =
    isOver && isOverCurrent ? "3px solid rgba(186, 186, 186, 0.679)" : "none";
  // const ref = useRef();
  useEffect(() => {
    let evt = (e) => {
      const parent = document.documentElement;
      if (e.clientY < 180) {
        parent.scrollTo(0, parent.scrollTop - 20);
      }
    };
    document?.addEventListener("dragover", evt);
    return () => {
      document?.removeEventListener("dragover", evt);
    };
  }, []);
  return (
    <div className="categories-wrapper" style={{ outline }} ref={drop}>
      {children}
    </div>
  );
};
export default DropZone;
