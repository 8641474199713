import {
  ADD_BLOG_CATEGORY,
  ADD_BLOG_CATEGORY_FAILURE,
  ADD_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY_FAILURE,
  DELETE_BLOG_CATEGORY_SUCCESS,
  GET_BLOG_CATEGORIES,
  GET_BLOG_CATEGORIES_FAILURE,
  GET_BLOG_CATEGORIES_SUCCESS,
  GET_SINGLE_BLOG_CATEGORY,
  GET_SINGLE_BLOG_CATEGORY_FAILURE,
  GET_SINGLE_BLOG_CATEGORY_SUCCESS,
} from "./actionTypes";

const initialState = {
  blogCategories: [],
  loading: false,
  error: "",
  singleBlogCategory: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BLOG_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        blogCategories: action.payload.categories,
      };
      break;
    case GET_BLOG_CATEGORIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_BLOG_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BLOG_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBlogCategory: action.payload.category,
      };
      break;
    case GET_SINGLE_BLOG_CATEGORY_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleBlogCategory: {},
      };
      break;

    case ADD_BLOG_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BLOG_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        blogCategories: action.payload.blogCategories,
      };
      break;
    case ADD_BLOG_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_BLOG_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BLOG_CATEGORY_SUCCESS:
      const blogCategoriesAfterDeleteing = [
        ...state?.blogCategories?.filter(
          (blogCategory) => blogCategory?.id !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        blogCategories: blogCategoriesAfterDeleteing,
      };
      break;
    case DELETE_BLOG_CATEGORY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
