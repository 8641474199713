import server from "./server";

export const getGroupsApi = async (payload) => {
  const response = await server().get(`/groups${payload || ""}`);
  return response.data;
};

export const getGroupRulesApi = async () => {
  const response = await server().get("/groupRules");
  return response.data;
};

export const getSingleGroupApi = async (id) => {
  const response = await server().get(`/groups/${id}`);
  return response.data;
};

export const addGroupApi = async (payload) => {
  const response = await server().post("/groups", payload);
  return response.data;
};

export const editGroupApi = async ({ id, data }) => {
  const response = await server().put(`/groups/${id}`, data);
  return response.data;
};

export const deleteGroupApi = async (id) => {
  const response = await server().delete(`/groups/${id}`);
  return response.data;
};
