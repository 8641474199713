import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
} from "./actionTypes";

const initialState = {
  packages: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PACKAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        packages: action.payload.packages,
      };
      break;
    case GET_PACKAGES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
