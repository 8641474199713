import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getCustomersApi,
  addCustomerApi,
  editCustomerApi,
  deleteCustomerApi,
  getSingleCustomerApi,
  deleteCustomerFromGroupApi,
  addCustomerToGroupApi,
  getCountriesApi,
  getCitiesApi,
  getAreasApi,
  blockCustomerApi,
  getCustomersTagsApi,
} from "../../api/customers";

// Login Redux States
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_SINGLE_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER_FROM_GROUP,
  ADD_CUSTOMER_TO_GROUP,
  GET_COUNTRIES,
  GET_CITIES,
  GET_AREAS,
  BLOCK_CUSTOMER,
  GET_CUSTOMERS_TAGS,
} from "./actionTypes";
import {
  addCustomerFailure,
  addCustomerSuccess,
  getCustomersSuccess,
  getCustomersFailure,
  editCustomerSuccess,
  editCustomerFailure,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  getSingleCustomerSuccess,
  getSingleCustomerFailure,
  getCountriesSuccess,
  getCountriesFailure,
  getCitiesSuccess,
  getCitiesFailure,
  getAreasSuccess,
  getAreasFailure,
  addCustomerToGroupSuccess,
  addCustomerToGroupFailure,
  deleteCustomerFromGroupSuccess,
  deleteCustomerFromGroupFailure,
  blockCustomerSuccess,
  blockCustomerFailure,
  getCustomersTagsSuccess,
  getCustomersTagsFailure,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getCustomers({ payload }) {
  try {
    const { data } = yield call(getCustomersApi, payload);
    yield put(getCustomersSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getCustomersFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getCustomersTags({ payload }) {
  try {
    const { data } = yield call(getCustomersTagsApi, payload);
    yield put(getCustomersTagsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getCustomersTagsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleCustomer({ payload }) {
  try {
    const { data } = yield call(getSingleCustomerApi, payload);
    yield put(getSingleCustomerSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getSingleCustomerFailure(error?.response?.data || error));
  }
}

function* addCustomer({ payload }) {
  try {
    const { data } = yield call(addCustomerApi, payload.data);
    yield put(addCustomerSuccess(data));
    yield payload.navigate("/customers");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data?.errors });

    yield put(addCustomerFailure(error.response?.data?.message || error));
  }
}

function* editCustomer({ payload }) {
  try {
    const { data } = yield call(editCustomerApi, payload);
    yield put(editCustomerSuccess(data));
    if (payload.navigate) yield payload.navigate("/customers");
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(editCustomerFailure(error.response?.data?.message || error));
  }
}

function* deleteCustomer({ payload }) {
  try {
    yield call(deleteCustomerApi, payload);
    yield put(deleteCustomerSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(deleteCustomerFailure(error?.response?.data || error));
  }
}

function* blockCustomer({ payload }) {
  try {
    const { data } = yield call(blockCustomerApi, payload?.data || payload);
    yield put(blockCustomerSuccess(data));

    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(blockCustomerFailure(error?.response?.data || error));
  }
}

function* addCustomerToGroup({ payload }) {
  try {
    const { data } = yield call(addCustomerToGroupApi, payload);
    yield put(addCustomerToGroupSuccess(data));
    if (payload?.callBack) payload?.callBack();
    yield getCustomers({ payload: payload?.searchparams || "" });
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(
      addCustomerToGroupFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteCustomerFromGroup({ payload }) {
  try {
    yield call(deleteCustomerFromGroupApi, payload);
    yield put(deleteCustomerFromGroupSuccess(payload));
    if (payload?.callBack) payload?.callBack();
    yield getCustomers({ payload: payload?.searchparams || "" });
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(deleteCustomerFromGroupFailure(error?.response?.data || error));
  }
}

function* getCountries({ payload }) {
  try {
    const { data } = yield call(getCountriesApi, payload);
    yield put(getCountriesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });

    yield put(getCountriesFailure(error?.response?.data || error));
  }
}

function* getCities({ payload }) {
  try {
    const { data } = yield call(getCitiesApi, payload);
    yield put(getCitiesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCitiesFailure(error?.response?.data || error));
  }
}

function* getAreas({ payload }) {
  try {
    const { data } = yield call(getAreasApi, payload);
    yield put(getAreasSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAreasFailure(error?.response?.data || error));
  }
}

export function* watchGetCustomers() {
  yield takeEvery(GET_CUSTOMERS, getCustomers);
}

export function* watchGetCustomersTags() {
  yield takeEvery(GET_CUSTOMERS_TAGS, getCustomersTags);
}

export function* watchSingleCustomer() {
  yield takeEvery(GET_SINGLE_CUSTOMER, getSingleCustomer);
}

export function* watchAddCustomer() {
  yield takeEvery(ADD_CUSTOMER, addCustomer);
}

export function* watchEditCustomer() {
  yield takeEvery(EDIT_CUSTOMER, editCustomer);
}

export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

export function* watchBlockCustomer() {
  yield takeEvery(BLOCK_CUSTOMER, blockCustomer);
}

export function* watchAddCustomToGroup() {
  yield takeEvery(ADD_CUSTOMER_TO_GROUP, addCustomerToGroup);
}

export function* watchDeleteCustomerFromGroup() {
  yield takeEvery(DELETE_CUSTOMER_FROM_GROUP, deleteCustomerFromGroup);
}

export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES, getCountries);
}
export function* watchGetCities() {
  yield takeEvery(GET_CITIES, getCities);
}
export function* watchGetAreas() {
  yield takeEvery(GET_AREAS, getAreas);
}

function* CustomersSaga() {
  yield all([fork(watchGetCustomers)]);
  yield all([fork(watchGetCustomersTags)]);
  yield all([fork(watchSingleCustomer)]);
  yield all([fork(watchAddCustomer)]);
  yield all([fork(watchEditCustomer)]);
  yield all([fork(watchDeleteCustomer)]);
  yield all([fork(watchBlockCustomer)]);

  yield all([fork(watchAddCustomToGroup)]);
  yield all([fork(watchDeleteCustomerFromGroup)]);

  yield all([fork(watchGetCountries)]);
  yield all([fork(watchGetCities)]);
  yield all([fork(watchGetAreas)]);
}

export default CustomersSaga;
