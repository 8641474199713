import { getId } from "helpers/functions";
import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_TAGS,
  GET_CUSTOMERS_TAGS_FAILURE,
  GET_CUSTOMERS_TAGS_SUCCESS,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  BLOCK_CUSTOMER,
  BLOCK_CUSTOMER_SUCCESS,
  BLOCK_CUSTOMER_FAILURE,
  ADD_CUSTOMER_TO_GROUP,
  ADD_CUSTOMER_TO_GROUP_SUCCESS,
  ADD_CUSTOMER_TO_GROUP_FAILURE,
  DELETE_CUSTOMER_FROM_GROUP,
  DELETE_CUSTOMER_FROM_GROUP_SUCCESS,
  DELETE_CUSTOMER_FROM_GROUP_FAILURE,
  GET_SINGLE_CUSTOMER,
  GET_SINGLE_CUSTOMER_FAILURE,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_SUCCESS,
  GET_CITIES,
  GET_CITIES_FAILURE,
  GET_CITIES_SUCCESS,
  GET_AREAS,
  GET_AREAS_FAILURE,
  GET_AREAS_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: "",
  singleUser: {},
  metadata: {},
  countries: [],
  cities: [],
  areas: [],
  tags: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload.users,
        metadata: {
          ...(action.payload.metadata || {}),
          results: action.payload?.users?.length,
        },
      };
      break;
    case GET_CUSTOMERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_CUSTOMERS_TAGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMERS_TAGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        tags: action.payload.tags,
      };
      break;
    case GET_CUSTOMERS_TAGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleUser: action.payload,
      };
      break;
    case GET_SINGLE_CUSTOMER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload.users,
      };
      break;
    case ADD_CUSTOMER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CUSTOMER_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.customers?.filter((user) => getId(user) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        users: usersAfterDeleteing,
      };
      break;
    case DELETE_CUSTOMER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // ---------------------
    case BLOCK_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BLOCK_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: state.users?.map((user) =>
          getId(user) === getId(action.payload?.user)
            ? action.payload?.user
            : user
        ),
      };
      break;
    case BLOCK_CUSTOMER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // ---------------------
    case ADD_CUSTOMER_TO_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CUSTOMER_TO_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case ADD_CUSTOMER_TO_GROUP_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CUSTOMER_FROM_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CUSTOMER_FROM_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_CUSTOMER_FROM_GROUP_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case GET_COUNTRIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        countries: action.payload?.countries,
      };
      break;
    case GET_COUNTRIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_CITIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CITIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: action.payload?.cities,
      };
      break;
    case GET_CITIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_AREAS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_AREAS_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload?.areas,
      };
      break;
    case GET_AREAS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
