import BreadCrumb from "components/shared/BreadCrumb";
import Loader from "components/shared/Loader";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import { getActiveStoreThemes, setActiveTheme } from "store/actions";

const Index = () => {
  const { activeStoreThemes, loading } = useSelector((state) => state?.stores);
  const { locale } = useIntl();
  const dispatch = useDispatch();

  const store = localStorage.getItem("store");

  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  useEffect(() => {
    dispatch(getActiveStoreThemes({ storeId: store }));
  }, [deviceType, dispatch, searchParams, store]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="themes-wrp">
        <BreadCrumb pageName="تصميم المتجر" />
        <Row>
          {activeStoreThemes
            ?.filter((theme) => theme.deviceType === deviceType)
            ?.map((theme) => {
              return (
                <Col key={theme?._id} xxl={3} lg={4} md={4} xs={6}>
                  <div
                    className={
                      theme.isActive === theme?.theme?.id
                        ? "themes-item active-theme"
                        : "themes-item"
                    }
                  >
                    <div className="themes-img">
                      <img src={theme?.theme?.image} alt="" />
                    </div>
                    <div className="themes-content">
                      <h5>{theme?.theme?.name?.[locale]}</h5>
                      {theme?.isActive === false ? (
                        <button
                          type="button"
                          className="btn btn-green btn-outline"
                          onClick={() => {
                            dispatch(
                              setActiveTheme({
                                // storeId: SingleStore?.id,
                                themeId: theme?._id,
                              })
                            );
                          }}
                        >
                          تفعيل
                        </button>
                      ) : (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                          مفعل
                        </span>
                      )}

                      <NavLink
                        to={`/storethemes/builder/edit/${theme?._id}?deviceType=${deviceType}`}
                        className="custom-css"
                      >
                        تخصيص
                      </NavLink>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default Index;
