import {
  getThemePagesApi,
  getThemeComponentsTypeApi,
  getThemeFontsApi,
  getThemeApi,
  editThemeComponentApi,
  editStoreSettingsApi,
  editThemeCssApi,
  editThemeSettingsApi,
} from "api/builder";
import { fork, put, all, call, takeEvery } from "redux-saga/effects";
import {
  getThemePagesFailure,
  getThemePagesSuccess,
  getThemeComponentsTypeFailure,
  getThemeComponentsTypeSuccess,
  getThemeFontsFailure,
  getThemeFontsSuccess,
  getThemeFailure,
  getThemeSuccess,
  editThemeComponentFailure,
  editThemeComponentSuccess,
  editStoreSettingsFailure,
  editStoreSettingsSuccess,
  editThemeCssFailure,
  editThemeCssSuccess,
  editThemeSettingsFailure,
  editThemeSettingsSuccess,
} from "./actions";
import {
  GET_THEME_PAGES,
  GET_THEME_COMPONENTS_TYPE,
  GET_THEME_FONTS,
  GET_THEME,
  EDIT_THEME_COMPONENT,
  EDIT_THEME_CSS,
  EDIT_THEME_SETTINGS,
  EDTI_STORE_SETTINGS,
} from "./actionTypes";

function* getThemePages({ payload }) {
  try {
    const { data } = yield call(getThemePagesApi, payload);
    yield put(getThemePagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getThemePagesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getThemeComponentsType({ payload }) {
  try {
    const { data } = yield call(getThemeComponentsTypeApi, payload);
    yield put(getThemeComponentsTypeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemeComponentsTypeFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getThemeFonts({ payload }) {
  try {
    const { data } = yield call(getThemeFontsApi, payload);
    yield put(getThemeFontsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getThemeFontsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getTheme({ payload }) {
  try {
    const { data } = yield call(getThemeApi, payload);
    yield put(getThemeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getThemeFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* editThemeComponent({ payload }) {
  try {
    const { data, success } = yield call(editThemeComponentApi, payload);
    yield put(
      editThemeComponentSuccess({ store: data.store, themeId: payload.themeId })
    );
    if (success) {
      yield payload.refreshIframe();
    }
  } catch (error) {
    console.log(error);
    yield put(
      editThemeComponentFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* editThemeCss({ payload }) {
  try {
    const { data, success } = yield call(editThemeCssApi, payload);
    yield put(editThemeCssSuccess(data));
    if (success) {
      yield payload.refreshIframe();
    }
  } catch (error) {
    console.log(error);
    yield put(editThemeCssFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* editThemeSettings({ payload }) {
  try {
    const { data, success } = yield call(editThemeSettingsApi, payload);
    yield put(editThemeSettingsSuccess(data));
    if (success) {
      yield payload.refreshIframe();
      yield payload.setActivePicker(null);
    }
  } catch (error) {
    console.log(error);
    yield put(
      editThemeSettingsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* editStoreSettings({ payload }) {
  try {
    const { data, success } = yield call(editStoreSettingsApi, payload);
    yield put(editStoreSettingsSuccess(data));
    if (success) {
      yield payload?.toast?.success("تم تحديث الاعدادات");
    }
  } catch (error) {
    console.log(error);
    yield put(
      editStoreSettingsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
    yield payload?.toast?.error(error?.response?.data?.errors?.[0]?.msg);
  }
}

export function* watchGetThemePages() {
  yield takeEvery(GET_THEME_PAGES, getThemePages);
}

export function* watchGetThemeComponentsType() {
  yield takeEvery(GET_THEME_COMPONENTS_TYPE, getThemeComponentsType);
}

export function* watchGetThemeFonts() {
  yield takeEvery(GET_THEME_FONTS, getThemeFonts);
}

export function* watchGetTheme() {
  yield takeEvery(GET_THEME, getTheme);
}

export function* watchEditThemeComponent() {
  yield takeEvery(EDIT_THEME_COMPONENT, editThemeComponent);
}

export function* watchEditThemeCss() {
  yield takeEvery(EDIT_THEME_CSS, editThemeCss);
}

export function* watchEditThemeSettings() {
  yield takeEvery(EDIT_THEME_SETTINGS, editThemeSettings);
}
export function* watchEditStoreSettings() {
  yield takeEvery(EDTI_STORE_SETTINGS, editStoreSettings);
}

function* builderSaga() {
  yield all([fork(watchGetThemePages)]);
  yield all([fork(watchGetThemeComponentsType)]);
  yield all([fork(watchGetThemeFonts)]);
  yield all([fork(watchGetTheme)]);
  yield all([fork(watchEditThemeComponent)]);
  yield all([fork(watchEditThemeCss)]);
  yield all([fork(watchEditThemeSettings)]);
  yield all([fork(watchEditStoreSettings)]);
}

export default builderSaga;
