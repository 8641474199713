import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getPackagesApi } from "api/packages";

// Login Redux States
import { GET_PACKAGES } from "./actionTypes";
import { getPackagesFailure, getPackagesSuccess } from "./actions";

function* getPackages() {
  try {
    const { data } = yield call(getPackagesApi);
    yield put(getPackagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getPackagesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetPackages() {
  yield takeEvery(GET_PACKAGES, getPackages);
}

function* packagesSaga() {
  yield all([fork(watchGetPackages)]);
}

export default packagesSaga;
