import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addFaqCategoryApi,
  deleteFaqCategoryApi,
  editFaqCategoryApi,
  getFaqCategoriesApi,
  getSingleFaqCategoryApi,
} from "api/faqsCategories";

// Redux States
import {
  addFaqCategoryFailure,
  addFaqCategorySuccess,
  deleteFaqCategoryFailure,
  deleteFaqCategorySuccess,
  editFaqCategoryFailure,
  editFaqCategorySuccess,
  getFaqCategoriesFailure,
  getFaqCategoriesSuccess,
  getSingleFaqCategoryFailure,
  getSingleFaqCategorySuccess,
} from "./actions";
import {
  ADD_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY,
  EDIT_FAQ_CATEGORY,
  GET_FAQ_CATEGORIES,
  GET_SINGLE_FAQ_CATEGORY,
} from "./actionTypes";

function* getFaqCategories() {
  try {
    const { data } = yield call(getFaqCategoriesApi);
    yield put(getFaqCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getFaqCategoriesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addFaqCategory({ payload }) {
  try {
    const { data } = yield call(addFaqCategoryApi, payload.data);
    yield put(addFaqCategorySuccess(data));
    yield payload.navigate("/blogs/categories");
  } catch (error) {
    console.log(error);
    yield put(addFaqCategoryFailure(error.response?.data?.message || error));
  }
}

function* getSingleFaqCategory({ payload }) {
  try {
    const { data } = yield call(getSingleFaqCategoryApi, payload);
    yield put(getSingleFaqCategorySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleFaqCategoryFailure(error?.response?.data || error));
  }
}

function* editFaqCategory({ payload }) {
  try {
    yield call(editFaqCategoryApi, payload);
    yield put(editFaqCategorySuccess(payload));
    yield payload.navigate("/blogs/categories");
  } catch (error) {
    console.log(error);
    yield put(editFaqCategoryFailure(error?.response?.data || error));
  }
}

function* deleteFaqCategory({ payload }) {
  try {
    yield call(deleteFaqCategoryApi, payload);
    yield put(deleteFaqCategorySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteFaqCategoryFailure(error?.response?.data || error));
  }
}

export function* watchGetFaqCategories() {
  yield takeEvery(GET_FAQ_CATEGORIES, getFaqCategories);
}

export function* watchAddFaqCategory() {
  yield takeEvery(ADD_FAQ_CATEGORY, addFaqCategory);
}

export function* watchGetSingleFaqCategory() {
  yield takeEvery(GET_SINGLE_FAQ_CATEGORY, getSingleFaqCategory);
}

export function* watchEditFaqCategory() {
  yield takeEvery(EDIT_FAQ_CATEGORY, editFaqCategory);
}

export function* watchDeleteFaqCategory() {
  yield takeEvery(DELETE_FAQ_CATEGORY, deleteFaqCategory);
}

function* blogCategoriesSaga() {
  yield all([fork(watchGetFaqCategories)]);
  yield all([fork(watchAddFaqCategory)]);
  yield all([fork(watchGetSingleFaqCategory)]);
  yield all([fork(watchEditFaqCategory)]);
  yield all([fork(watchDeleteFaqCategory)]);
}

export default blogCategoriesSaga;
